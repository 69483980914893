var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { "data-behavior": "vue-file-container" } }, [
    _c("input", {
      attrs: {
        type: "hidden",
        name: "id",
        "data-attachment-file-field": "true",
        multiple: "true"
      },
      domProps: { value: _vm.value }
    }),
    _vm._v(" "),
    _c("div", [
      !_vm.disabled
        ? _c("div", { staticClass: "fileupload-buttonbar" }, [
            _c("div", {}, [
              _c(
                "span",
                { staticClass: "btn si-add-file fileinput-button normal" },
                [
                  _vm._v(
                    "\n          " + _vm._s(_vm.t_add_file) + "\n          "
                  ),
                  _c(
                    "svg",
                    {
                      attrs: {
                        "data-v-03643d2d": "",
                        xmlns: "http://www.w3.org/2000/svg",
                        width: "16",
                        height: "16",
                        viewBox: "0 0 16 16"
                      }
                    },
                    [
                      _c("path", {
                        attrs: {
                          "data-v-03643d2d": "",
                          fill: "#5D71FF",
                          "fill-rule": "evenodd",
                          d:
                            "M13 6.1V6c0-2.8-2.2-5-5-5-2.5 0-4.6 1.8-4.9 4.3C1.3 5.9 0 7.5 0 9.5 0 12 2 14 4.5 14H12c2.2 0 4-1.8 4-4 0-1.9-1.3-3.4-3-3.9zM9 9v3H7V9H4l4-4 4 4H9z"
                        }
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      staticClass: "file-btn-label",
                      attrs: { for: _vm.attachment_label_name }
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.t_attachments) +
                          "\n          "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    staticClass: "btn btn-default hide",
                    attrs: {
                      type: "file",
                      multiple: !_vm.single_attachment,
                      multiple: "true",
                      accept: _vm.restricted_file_type,
                      name: "attachments",
                      id: _vm.attachment_label_name,
                      "data-behavior": "attachments-field",
                      "data-file-field-selector": _vm.file_field_selector,
                      "data-attachment-type": _vm.attachment_type,
                      "data-parent-selector": _vm.parent_selector,
                      disabled: _vm.disabled
                    }
                  })
                ]
              ),
              _vm._v(" "),
              _c("span", [_vm._v(" " + _vm._s(_vm.t_file_upload_hint) + " ")])
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "fileupload-loading" }),
      _vm._v(" "),
      _c(
        "table",
        { staticClass: "table smart-table attachment-table vue-file" },
        [
          _c(
            "tbody",
            {
              staticClass: "files",
              attrs: {
                "data-toggle": "modal-gallery",
                "data-target": "#modal-gallery",
                "data-attachment-type": _vm.attachment_type
              }
            },
            _vm._l(_vm.files, function(attachment, index) {
              return _vm.showAttachment(attachment)
                ? _c(
                    "tr",
                    {
                      key: attachment.attachment_attributes.id,
                      staticClass: "template-download fade in",
                      attrs: { "data-id": attachment.attachment_attributes.id }
                    },
                    [
                      !_vm.disabled
                        ? _c("td", { staticClass: "cancel-td" }, [
                            _c("a", { staticClass: "pointer cancel" }, [
                              _c("i", {
                                staticClass: "fa",
                                class: { "fa-close": !_vm.disabled }
                              })
                            ])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "td",
                        { staticClass: "preview", attrs: { width: "40" } },
                        [
                          !attachment.attachment_attributes.errors.upload
                            ? _c(
                                "a",
                                {
                                  attrs: {
                                    target: "_blank",
                                    href:
                                      attachment.attachment_attributes
                                        .document_url,
                                    title:
                                      attachment.attachment_attributes
                                        .document_file_name,
                                    download:
                                      attachment.attachment_attributes
                                        .document_file_name
                                  }
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src:
                                        attachment.attachment_attributes
                                          .document_url,
                                      alt: ""
                                    }
                                  })
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          attachment.attachment_attributes.errors.upload
                            ? _c("img", {
                                attrs: {
                                  src:
                                    attachment.attachment_attributes
                                      .file_icon_name,
                                  alt: ""
                                }
                              })
                            : _vm._e()
                        ]
                      ),
                      _vm._v(" "),
                      _c("td", { staticClass: "name" }, [
                        !attachment.attachment_attributes.errors.upload
                          ? _c(
                              "a",
                              {
                                attrs: {
                                  target: "_blank",
                                  href:
                                    attachment.attachment_attributes
                                      .document_url,
                                  title:
                                    attachment.attachment_attributes
                                      .document_file_name,
                                  download:
                                    attachment.attachment_attributes
                                      .document_file_name
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    attachment.attachment_attributes
                                      .document_file_name
                                  )
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        attachment.attachment_attributes.errors.upload
                          ? _c("div", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    attachment.attachment_attributes
                                      .document_file_name
                                  ) +
                                  "\n            "
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "error text-truncate text-danger m-t-3"
                          },
                          [
                            _c("span", { staticClass: "s" }, [
                              _vm._v(
                                _vm._s(
                                  attachment.attachment_attributes.errors.upload
                                )
                              )
                            ])
                          ]
                        )
                      ])
                    ]
                  )
                : _vm._e()
            }),
            0
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "table",
        {
          staticClass: "table smart-table attachment-table multiple-jquery-file"
        },
        [
          _c(
            "tbody",
            {
              staticClass: "files",
              attrs: {
                "data-toggle": "modal-gallery",
                "data-target": "#modal-gallery",
                "data-attachment-type": _vm.attachment_type
              }
            },
            _vm._l(_vm.files, function(attachment, index) {
              return _vm.showAttachment(attachment)
                ? _c(
                    "tr",
                    {
                      key: attachment.attachment_attributes.id,
                      staticClass: "template-download hide fade in",
                      attrs: { "data-id": attachment.attachment_attributes.id }
                    },
                    [
                      !_vm.disabled
                        ? _c("td", { staticClass: "cancel-td" }, [
                            _c("a", { staticClass: "pointer cancel" }, [
                              _c("i", {
                                staticClass: "fa",
                                class: { "fa-close": !_vm.disabled }
                              })
                            ])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "td",
                        { staticClass: "preview", attrs: { width: "40" } },
                        [
                          _c(
                            "a",
                            {
                              attrs: {
                                target: "_blank",
                                href:
                                  attachment.attachment_attributes.document_url,
                                title:
                                  attachment.attachment_attributes
                                    .document_file_name
                              }
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src:
                                    attachment.attachment_attributes
                                      .file_icon_name,
                                  alt: "",
                                  download:
                                    attachment.attachment_attributes
                                      .document_file_name
                                }
                              })
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("td", { staticClass: "name" }, [
                        _c(
                          "a",
                          {
                            attrs: {
                              target: "_blank",
                              href:
                                attachment.attachment_attributes.document_url,
                              title:
                                attachment.attachment_attributes
                                  .document_file_name,
                              download:
                                attachment.attachment_attributes
                                  .document_file_name
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                attachment.attachment_attributes
                                  .document_file_name
                              )
                            )
                          ]
                        )
                      ])
                    ]
                  )
                : _vm._e()
            }),
            0
          )
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }