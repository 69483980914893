<template>
  <div ref='userGridContent' class="profile-value trigger-block text-truncate">
    <div v-if='Object.keys(currentUserObject).length && componentFullyLoaded' class="parentClass">

      <v-popover placement='top' trigger='hover' popoverArrowClass='hide'>

        <span class='tooltip-target d-block full-width'>
          <span v-if='fullNameWidth' class="d-flex text-truncate align-center">
            <a :href="userShowPath(currentUserObject)" target="_blank" class="clickable-item m-r-5-hard card-assign-user">
              <img :src="currentUserObject.profile_picture_url" class="img img-responsive">
            </a>
            <a :href="userShowPath(currentUserObject)" target="_blank" class="tootltip-name clickable-item d-block text-truncate">{{ currentUserObject.name }}</a>
          </span>

          <span v-if='abbreviatedNameWidth' class="d-flex text-truncate align-center">
            <a :href="userShowPath(currentUserObject)" target="_blank" class="clickable-item m-r-5-hard card-assign-user">
              <img :src="currentUserObject.profile_picture_url" class="img img-responsive">
            </a>
            <a :href="userShowPath(currentUserObject)" target="_blank" class="tootltip-name clickable-item d-block text-truncate">{{ currentUserObject.abbreviated_name }}</a>
          </span>

          <span v-if='profileWidth' class="d-inline-block">
            <span class="m-r-10 card-assign-user clickable-item">
              <a :href="userShowPath(currentUserObject)" target="_blank" class="clickable-item">
                <img :src="currentUserObject.profile_picture_url" class="img img-responsive">
              </a>
            </span>
          </span>
        </span>

        <template slot='popover'>
          <UserHoverDataTooltip :user_id='currentUserObject.id'></UserHoverDataTooltip>
        </template>

      </v-popover>
    </div>
    <div v-else>
      {{ $i18n.tc('default_no_data') }}
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import { userShowPath } from 'concerns/path_helper_methods.js';
  import UserHoverDataTooltip from 'components/shared/users/user_hover_data_tooltip.vue';

  export default {
    name: 'UserTableGridContent',
    props: {
      user_id: {
        required: true,
        default: ''
      },
      parentClass: {
        type: String,
        default: 'full-width'
      },
      contentType: {
        type: String,
        default: ''
      }
    },
    data: function() {
      return {
        componentFullyLoaded: false,
        clientWidth: 0
      }
    },
    components: {
      UserHoverDataTooltip
    },
    mounted() {
      this.componentFullyLoaded = true;
      this.calculateWidth();
      this.bindWindowEvents();
    },
    computed: {
      ...mapState('GeneralConfiguration', ['user_data']),
      currentUserObject() {
        return this.user_data[String(this.user_id)] || {};
      },
      fullNameWidth() {
        if(this.contentType) {
          return this.isContentType('fullName');
        } else {
          return (this.clientWidth >= 170);
        }
      },
      abbreviatedNameWidth() {
        if(this.contentType) {
          return this.isContentType('abbreviatedName');
        } else {
          return ((this.clientWidth > 100) && (this.clientWidth < 170));
        }
      },
      profileWidth() {
        if(this.contentType) {
          return this.isContentType('profilePic');
        } else {
          return ((this.clientWidth <= 100) && (this.clientWidth > 0));
        }
      }
    },
    methods: {
      userShowPath,
      calculateWidth() {
        this.clientWidth = this.$refs.userGridContent.clientWidth;
      },
      bindWindowEvents() {
        var _this = this;
        window.addEventListener('sidebarToggled', (event) => {
          _this.calculateWidth();
        });
      },
      isContentType(contentType) {
        return this.contentType == contentType;
      }
    }
  }
</script>
