var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "in-place-editor", class: _vm.container_classes },
    [
      _c(
        "span",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.editable,
              expression: "!editable"
            }
          ],
          class: _vm.display_text_class,
          on: {
            click: function($event) {
              return _vm.toggleEdit(this)
            }
          }
        },
        [_vm._v(_vm._s(_vm.value))]
      ),
      _vm._v(" "),
      !_vm.textarea
        ? [
            _c("input", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.editable,
                  expression: "editable"
                }
              ],
              ref: "editableInput",
              staticClass: "form",
              class: _vm.input_value_class,
              attrs: { type: "text", placeholder: _vm.placeholder },
              domProps: { value: _vm.value },
              on: {
                focus: _vm.saveValue,
                input: function($event) {
                  return _vm.$emit("input", $event.target.value)
                },
                keydown: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  $event.stopPropagation()
                  return _vm.emitChangeEvent($event)
                },
                focusout: function($event) {
                  $event.stopPropagation()
                  return _vm.emitChangeEvent($event)
                }
              }
            })
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.textarea
        ? [
            _c("textarea", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.editable,
                  expression: "editable"
                }
              ],
              ref: "editableInput",
              staticClass: "form form-control",
              class: _vm.input_value_class,
              attrs: {
                placeholder: _vm.placeholder,
                disabled: !_vm.can_be_edited
              },
              domProps: { value: _vm.value },
              on: {
                focus: _vm.saveValue,
                input: function($event) {
                  return _vm.$emit("input", $event.target.value)
                },
                keydown: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  if (
                    $event.ctrlKey ||
                    $event.shiftKey ||
                    $event.altKey ||
                    $event.metaKey
                  ) {
                    return null
                  }
                  $event.preventDefault()
                  return _vm.emitChangeEvent($event)
                },
                focusout: function($event) {
                  $event.stopPropagation()
                  return _vm.emitChangeEvent($event)
                }
              }
            })
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }