<template>
  <div class="dropdown-div d-flex">
    <a @click='getCancelDescription' data-task-alert-update="true" href="javascript:" :class="[{ disabled: ajaxRequestSent }, buttonClasses]">Cancel</a>
    <sweet-alert @input="cancelCurrentWorkOrder" container_classes='dropdown-div d-flex hide'>
      <a ref="cancelLink" class="full-width nowrap-hard" href="javascript:" :data-description='cancelDescription' :data-sweet-alert-confirm='alertConfirmText' :data-heading='headingText' data-confirm-type='cancel' data-vue-component=true data-confirm-button-text='Yes, Cancel' data-cancel-button-text='Discard'>Cancel</a>
    </sweet-alert>
  </div>
</template>
<script>
  import axios from 'axios';
  import { mapActions, mapState } from "vuex";
  import sweetAlert from 'components/shared/sweet_alert';


  export default {
    name: 'CancelButton',
    props: {
      workOrder: Object,
      buttonClasses: String
    },
    components: {
      sweetAlert
    },
    computed: {
      ...mapState('GeneralConfiguration', [
        'ajaxRequestSent'
      ]),
      alertConfirmText() {
        if(this.workOrder.is_work_order) {
          return 'Are you sure you want to cancel this Work Order?';
        } else {
          return 'Are you sure you want to cancel this Sub-Work Order?'
        }
      },
      headingText() {
        if(this.workOrder.is_work_order) {
          return 'Cancel Work Order';
        } else {
          return 'Cancel Sub-Work Order'
        }
      }
    },
    data: function() {
      return {
        cancelDescription: ''
      }
    },
    methods: {
      ...mapActions('WorkOrderQuickActions', [
        'cancelWorkOrder',
        'cancelSubWorkOrder',
      ]),
      getCancelDescription: function() {
        axios.get(this.workOrder.task_count_path, { params: { message_type: 'cancel' } }).then((response) => {
          this.cancelDescription = response.data.description_message;
          window.setTimeout(() => this.$refs.cancelLink.click(), 100);
        })
      },
      cancelCurrentWorkOrder: function() {
        if (this.workOrder.is_work_order){
          this.cancelWorkOrder({
            apollo: this.$apollo,
            workOrderId: this.workOrder.db_id,
            showAlert: this.$root.showAlert
          }).then(() => this.onSuccess());
        } else {
          this.cancelSubWorkOrder({
            apollo: this.$apollo,
            subWorkOrderId: this.workOrder.db_id,
            showAlert: this.$root.showAlert
          }).then(() => this.onSuccess());
        }
      },
      onSuccess: function() {
        this.$emit('onSuccess');
      }
    }
  }
</script>
