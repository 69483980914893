var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "contactContent", staticClass: "d-inline-block" },
    [
      _c(
        "v-popover",
        {
          attrs: {
            placement: "top",
            trigger: "hover",
            popoverArrowClass: "hide"
          }
        },
        [
          _c("span", { staticClass: "d-flex align-center" }, [
            _c(
              "div",
              {
                staticClass:
                  "tootltip-name clickable-item d-block text-truncate"
              },
              [_vm._v(_vm._s(_vm.contact_object.name))]
            )
          ]),
          _vm._v(" "),
          _c("template", { slot: "popover" }, [
            _c("div", { staticClass: "d-flex justfy-between" }, [
              _c("img", {
                attrs: {
                  src:
                    _vm.contact_object.gravatar_url ||
                    _vm.contact_object.profile_picture_url,
                  height: "40",
                  width: "40"
                }
              }),
              _vm._v(" "),
              _c("div", {}, [
                _c("div", { staticClass: "text-left tooltip-text" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "super-dark clickable-item fs-14 m-b-2 semibold ls-01"
                    },
                    [_vm._v(_vm._s(_vm.contact_object.name))]
                  )
                ]),
                _vm._v(" "),
                _c("div", {
                  staticClass: "super-dark text-left tooltip-text ls-01",
                  domProps: { innerHTML: _vm._s(_vm.contact_object.email) }
                })
              ])
            ])
          ])
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }