<template>
  <span>
    <span v-if="isUpdated">
      <div class="fs-12 d-inline-block">{{ updatedText }}</div>
      <div class="dark m-l-22 wo-tab-detail">{{ from_value | formatSiDateExpanded }}<span class="fontmedium"> › {{ to_value | formatSiDateExpanded }}</span> </div>
    </span>
    <span v-else-if="isRemoved">
      <div class="fs-12 d-inline-block">{{ removedText }}</div>
      <div class="dark m-l-22 wo-tab-detail">{{ from_value | formatSiDateExpanded }}</div>
    </span>
    <span v-else>
      <div class="fs-12 d-inline-block">{{ addedText }}</div>
      <div class="dark m-l-22 wo-tab-detail">{{ to_value | formatSiDateExpanded }}</div>
    </span>
  </span>
</template>

<script>
  export default {
    name: 'update_date_detail',
    props: [
      'updatedText',
      'addedText',
      'removedText',
      'from_value',
      'to_value'
    ],
    computed: {
      isUpdated: function() {
        return this.to_value && this.from_value;
      },
      isRemoved: function() {
        return this.from_value && !this.to_value
      }
    }
  };
</script>

<style lang="css" scoped>
</style>
