<template>
  <div>
    <si-modal :modalOptions="modalOptions" ref="workOrderModal" @onDismiss="hideModal">
      <template slot="modal-content" v-if="workOrder.id">
        <div class='with-separator default p-b-15 p-t-5'>
          <div class="d-flex align-center justify-between">
            <div data-name='true'>
              <span class="label label-warning si-label" :class="workOrder.state"> {{ workOrder.state_name }} </span>
              <span v-if="workOrder.is_archived" class="label label-warning si-label archived">{{ $i18n.t('work_order.quick_show.archived') }}</span>
            </div>
            <div>
              <div data-dropdown="true" class="dropdown-right">
                <div class="dropdown clickable-item d-inline-block" data-menu='dropdown'>
                  <div data-dropdown="true" class="dropdown-right d-flex justify-flex-end">
                    <div class="dropdown clickable-item d-inline-block" data-menu='dropdown'>
                      <button class="btn dropdown-toggle  p-t-b-5 fs-14 p-l-r-10 js-show-button" type="button" data-toggle="dropdown" :class="{ disabled: ajaxRequestSent }">
                        <span class="btn-icon-helper gear-icon m-l-0"></span>
                        <span class="caret"></span>
                      </button>
                      <work-order-quick-actions
                         :work_order="workOrder"
                         :deleteListbutton="true"
                         @quick-action-performed="onSuccess"
                         @onDeleted="onDeleted"
                         showView="true"
                      ></work-order-quick-actions>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class=''>
          <ul class="list-group no-horizontal-border no-btm-margin task-list-group list-unstyled">
            <li class="list-group-item padding-horizontal-none">
              <div class="modal-title p-b-5">
                <span class="fs-24 d-block p-t-15 semibold">
                  <a :href="workOrderShowPath(workOrder)" target="_blank">{{ workOrder.title }}</a>
                </span>
              </div>
              <div v-if="workOrder.formatted_description" data-behavior="task-description" class="task-description-box m-t-0 lh-15 fs-12 v1-launch-hidden">{{ workOrder.formatted_description }}
                <span v-if="descriptionOverFlowed" class="fs-11">
                  <a :href="workOrderShowPath(workOrder)">
                    More
                  </a>
                </span>
              </div>
            </li>

            <li v-if="workOrder.unarchived_sub_work_orders.length" class="list-group-item padding-none">
              <div class="list-detail-block sub-work-orders">
                <div class="title-label text-muted">
                  {{ this.$i18n.tc('work_order.quick_show.sub_wo_count', workOrder.unarchived_sub_work_orders.length) }}
                </div>
                <div class="sub-work-order-list">
                  <div v-for="subWorkOrder in workOrder.unarchived_sub_work_orders" class="sub-work-order-item d-flex justify-between">
                    <div>
                      <span class="link-title clickable-item fs-13 light-gray">
                        #{{ subWorkOrder.id }}
                      </span>
                      -
                      <span>
                        <a :href="subWorkOrderShowPath(workOrder, subWorkOrder)" target="_blank" class="link-title fs-13 text-obsidian">{{ subWorkOrder.title }}</a>
                      </span>
                      <div class="fs-11 m-t-2">
                        <span v-if="subWorkOrder.not_accepted" class="text green-link-secondary d-inlin-block p-r-10">
                          <span v-if="subWorkOrder.accept_by_datetime" :class="{ 'red semibold': subWorkOrder.accept_by_date_passed }">
                            {{ $i18n.t('work_order.quick_show.accept_by') }}
                            {{ subWorkOrder.accept_by_datetime | formatSiDateExpanded }}, {{ subWorkOrder.accept_by_datetime | formatSITime }}
                          </span>
                        </span>
                        <span>{{ $i18n.t("work_order.quick_show.estimated") }} {{ subWorkOrder.estimated_work_time_logged_words }}</span>
                      </div>
                    </div>

                    <div class="d-flex">
                      <div class="label label-warning si-label" :class="subWorkOrder.state">
                        {{ subWorkOrder.state_name }}
                      </div>
                      <div class="card-width m-l-10">
                        <user-table-grid-content :user_id='subWorkOrder.assignee_id'></user-table-grid-content>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>

            <li class="list-group-item">
              <div class="row list-detail-block d-flex align-center">
                <div class="col-md-4 title-label text-muted">{{ $i18n.t("work_order.quick_show.assignee") }}</div>
                <div class="col-md-8 semibold padding-horizontal-none text-obsidian fs-13">
                  <div v-if="workOrder.assignee_id">
                    <user-table-grid-content :user_id='workOrder.assignee_id'></user-table-grid-content>
                  </div>
                  <span v-else>
                    {{ $i18n.t("work_order.quick_show.not_assigned_to_anyone_yet") }}
                  </span>
                </div>
              </div>
            </li>

            <li class="list-group-item">
              <div class="row list-detail-block d-flex align-center">
                <div class="col-md-4 title-label text-muted">{{ $i18n.t("work_order.quick_show.creator") }}</div>
                <div class="col-md-8 semibold padding-horizontal-none text-obsidian fs-13">
                  <user-table-grid-content :user_id='workOrder.creator_id'></user-table-grid-content>
                </div>
              </div>
            </li>

            <li class="list-group-item" v-if="workOrder.not_accepted">
              <div class="row list-detail-block d-flex align-center">
                <div class="col-md-4 title-label text-muted">{{ $i18n.t("work_order.quick_show.accept_by") }}</div>
                <div class="col-md-8  padding-horizontal-none fs-13" data-due-date='true'>
                  <span v-if="workOrder.accept_by_datetime">
                    <span v-if="workOrder.accept_by_date_passed" class="red semibold">
                      {{ workOrder.accept_by_datetime | formatSiDateExpanded }}, <span class='red'>{{ workOrder.accept_by_datetime | formatSITime }}</span>
                    </span>
                    <span v-else class="text semibold green-link-secondary">
                      {{ workOrder.accept_by_datetime | formatSiDateExpanded }}, <span class='text green-link-secondary'>{{ workOrder.accept_by_datetime | formatSITime }}</span>
                    </span>
                  </span>
                  <span v-else>{{ $i18n.tc('default_no_data') }}</span>
                </div>
              </div>
            </li>

            <li class="list-group-item">
              <div class="row list-detail-block d-flex align-center">
                <div class="col-md-4 title-label text-muted">{{ $i18n.t("work_order.quick_show.due_date") }}</div>
                <div class="col-md-8 padding-horizontal-none fs-13" data-due-date='true'>
                  <span v-if="workOrder.due_datetime">
                    <span class="text-obsidian semibold">{{ workOrder.due_datetime | formatSiDateExpanded }}</span>,
                    <span>{{ workOrder.due_datetime | formatSITime }}</span>
                  </span>
                  <span v-else>{{ $i18n.tc('default_no_data') }}</span>
                </div>
              </div>
            </li>

            <li class="list-group-item">
              <div class="row list-detail-block d-flex align-center">
                <div class="col-md-4 title-label text-muted" data-behavior="formatted-time-logged">
                  {{ $i18n.t("work_order.quick_show.total_time_logged") }}
                </div>
                <div class="col-md-8 semibold padding-horizontal-none text-obsidian fs-13">{{ workOrder.estimated_work_time_logged_words || $i18n.tc('default_no_data') }}</div>
              </div>
            </li>

            <li class="list-group-item">
              <div class="row list-detail-block d-flex align-center">
                <div class="col-md-4 title-label text-muted" data-behavior="formatted-time-logged">{{ $i18n.t("work_order.quick_show.time_spent") }}</div>
                <div class="col-md-8 semibold padding-horizontal-none text-obsidian fs-13">
                  {{ workOrder.associated_total_time_logged_words || $i18n.tc('default_no_data') }}
                </div>
              </div>
            </li>

            <li class="list-group-item" v-if="workOrder.account && !workOrder.is_internal">
              <div class="row list-detail-block d-flex align-center">
                <div class="col-md-4 title-label text-muted">{{ $i18n.t("work_order.quick_show.account") }}</div>
                <div class="col-md-8 semibold padding-horizontal-none text-obsidian fs-13">
                  <div class="user-name-with-image trigger-block">
                    <AccountDataTooltip :accountData="workOrder.account"></AccountDataTooltip>
                  </div>
                </div>
              </div>
            </li>

            <li class="list-group-item" v-if="workOrder.contact && !workOrder.is_internal">
              <div class="row list-detail-block d-flex align-center">
                <div class="col-md-4 title-label text-muted">{{ $i18n.t("work_order.quick_show.contact") }}</div>
                <div class="col-md-8 padding-horizontal-none semibold text-obsidian fs-13">
                  <div class="user-name-with-image trigger-block">
                    <ContactDataTooltip :contactData="workOrder.contact"></ContactDataTooltip>
                  </div>
                </div>
              </div>
            </li>

            <li class="list-group-item" v-if="workOrder.work_order_follower_ids.length">
              <div class="row list-detail-block d-flex align-center">
                <div class="col-md-4 title-label text-muted">{{ $i18n.t("work_order.quick_show.followers") }}</div>
                <div class="col-md-8 semibold padding-horizontal-none text-obsidian fs-13">
                  <div class="d-flex align-center">
                      <span v-for="(userId, index) in followerIds">
                        <div class='pop-over-box d-inline-block'>
                         <UserTableGridContent
                          :user_id='String(userId)'
                          parentClass= ''
                        ></UserTableGridContent>
                        </div>
                      </span>
                      <a v-if="workOrder.work_order_follower_ids.length > 5" :href="workOrderShowPath(workOrder)" class="card-assign-user">
                        <span class="fs-11">+{{ workOrder.work_order_follower_ids.length - 5 }} {{ $i18n.t("work_order.quick_show.more_followers") }}</span>
                      </a>
                  </div>
                </div>
              </div>
            </li>

            <li class="list-group-item-helper no-btm-border last-item-group padding-horizontal-none">
              <a :href="workOrderShowPath(workOrder)" class= 'btn btn-si btn-block m-t-b-15 semibold btn-with-icon icon-right' target='_blank'>
                <span class="relative fs-14">
                  {{ $i18n.t("work_order.quick_show.view_completed") }}
                </span>
                <div class="btn-icon no-space">
                  <svg class="d-block" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="20px" height="14px" viewBox="6 6 20 20" enable-background="new 6 6 20 20" xml:space="preserve" data-ember-extension="1">
                    <g>
                      <path fill="none" d="M0,0h32v32H0V0z"/>
                      <g>
                        <path fill="none" stroke="#a1a8c3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" d="M7.75,16H23.5 M19,22l6-6    l-6-6" class="stroke-blue-hover"/>
                      </g>
                    </g>
                  </svg>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </template>
    </si-modal>

    <pubnub-entity-updated
      entity_type='WorkOrder'
      :entity_id='quickViewWorkOrderId'
      :updated_at='wo_updated_at'
      @entityUpdated='silentlyLoadData'
    ></pubnub-entity-updated>
  </div>
</template>
<script>
  import { mapActions, mapState } from "vuex";
  import { createHelpers } from 'vuex-map-fields';
  import UserTableGridContent from 'components/shared/users/user_table_grid_content.vue';
  import siModal from 'components/shared/si_modal.vue';
  import AccountDataTooltip from 'components/shared/accounts/account_data_tooltip.vue';
  import ContactDataTooltip from 'components/shared/contacts/contact_data_tooltip.vue';
  import WorkOrderQuickActions from 'components/shared/list/work_order_quick_actions.vue';
  import PubnubEntityUpdated from 'components/shared/pubnub_entity_updated.vue';

  import { workOrderShowPath, subWorkOrderShowPath } from 'concerns/path_helper_methods.js';
  const { mapFields } = createHelpers({
    getterType: 'getWorkOrderQuickShow',
    mutationType: 'updateWorkOrderQuickShow',
  });
  export default {
    name: 'QuickViewWorkOrder',
    components: {
      siModal,
      UserTableGridContent,
      AccountDataTooltip,
      WorkOrderQuickActions,
      ContactDataTooltip,
      PubnubEntityUpdated
    },
    data: function() {
      return {
        workOrder: {
          unarchived_sub_work_orders: []
        }
      }
    },
    watch: {
      quickViewWorkOrderId: function(val) {
        if(val) {
          this.showData(val);
        }
      }
    },
    computed: {
      ...mapState('GeneralConfiguration', [
        'ajaxRequestSent'
      ]),
      ...mapFields('WorkOrderQuickViewWidget', [
        'quickViewWorkOrderId'
      ]),
      followerIds() {
        return this.workOrder.work_order_follower_ids.slice(0, 5);
      },
      descriptionOverFlowed() {
        return this.workOrder.formatted_description && this.workOrder.formatted_description.length > 250;
      },
      wo_updated_at: function() {
        return moment(this.workOrder.updated_at, 'YYYY-MM-DDTHH:mm:ssZ');
      },
      modalOptions: function() {
        return {
          title: `ID #${this.workOrder && this.workOrder.id} &#8226; ${this.workOrder && this.workOrder.details}`,
          topBarColor: '#e67e22',
          pickerOverFlowClass:'no-overflow'
        }
      }
    },
    methods: {
      workOrderShowPath,
      subWorkOrderShowPath,
      ...mapActions('WorkOrderQuickViewWidget', ['fetchWorkOrderQuickViewData']),
      showData(id) {
        this.fetchData(id);
      },
      onSuccess() {
        this.$refs.workOrderModal.hideModal();
        window.dispatchEvent(new CustomEvent('quickViewWoActionPerformed'));
        window.dispatchEvent(new CustomEvent('resetPermissionSystem'));
      },
      onDeleted() {
        this.$refs.workOrderModal.hideModal();
        window.dispatchEvent(new CustomEvent('quickViewWoDeleted', { detail: { woId: this.workOrder.id } }));
        window.dispatchEvent(new CustomEvent('resetPermissionSystem'));
      },
      fetchData(id) {
        this.fetchWorkOrderQuickViewData({
          apollo: this.$apollo,
          id: id,
          showAlert: this.$root.showAlert
        }).then((data) => {
          this.workOrder = data;
          this.displayModal();
        });
      },
      silentlyLoadData: function() {
        window.disableAjaxLoader = true;
        this.updateWorkOrder();
      },
      updateWorkOrder() {
        this.fetchWorkOrderQuickViewData({
          apollo: this.$apollo,
          id: this.quickViewWorkOrderId,
          showAlert: this.$root.showAlert
        }).then((data) => this.workOrder = data);
      },
      hideModal() {
        this.quickViewWorkOrderId = null;
        window.holdAnotherModal = false;
      },
      displayModal() {
        this.$refs.workOrderModal.displayModal();
      }
    }
  }
</script>
