<template>
  <div id='product-create-modal'>
    <quick-add-contact
    :account_team_options="account_team_options"
    :default_account="default_account"
    min_input_for_search='2'
    ref="contactModal"
    @onCreated="refreshContacts" hide_continue_edit="true" disable_account="true" hide_prospect_confirmation="true"></quick-add-contact>
    <si-modal ref='orderCreateModal' :modalOptions='modalOptions' @onDismiss='resetData' @onShown='focusField'>
      <template slot="modal-content">
        <form class="form" @submit.prevent>
          <div class="">
            <div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="pr_create_title" class='required'>Opportunity name</label>
                    <input v-model="name" ref='opportunityName' type="text" autocomplete="off" class="form-control place-holder">
                    <error :errors='errors.name' />
                  </div>
                </div>
                  <div class="col-md-6">
                  <div class="form-group">
                    <div class="d-flex justify-between">
                      <label for="pr_create_title" class='required'>State</label>
                    </div>
                    <input v-model="state" ref='opportunityName' type="text" autocomplete="off" class="form-control captialize-label" disabled>
                    <error :errors='errors.state' />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group relative">
                    <div class="d-flex justify-between">
                      <label for="pr_create_title" class='required'>Account</label>
                      <template v-if='account_id'>
                        <div class="text-right semibold fs-12 account-target-helper account-target">
                          <span v-if='account_is_target' class='text green-link'>
                            {{ this.$i18n.tc('quick_add_contact.account_target')}}
                          </span>
                          <span v-else class='text red'>
                            {{ this.$i18n.tc('quick_add_contact.account_not_target')}}
                          </span>
                        </div>
                      </template>
                      <span v-if="!disable_account_field"><a href="javascript:void(0)" class="label-block-helper blue" @click="openAccountModal">Create New</a></span>
                    </div>
                    <div v-if="disable_account_field" class="disabled-div">
                      {{ this.account_name }}
                    </div>
                    <div v-else>
                      <ajax-select2
                        v-model="account_id"
                        request_param='name_cont'
                        search_input_length='2'
                        :request_path='accountSearchPath()'
                        :options='account_options'
                        ref="accountSelector"
                        @onSelectingOption="selectAccount"
                      ></ajax-select2>
                      <error :errors='errors.account' />
                      <error :errors='errors.account_id' />
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <div class="d-flex justify-between">
                      <label for="pr_create_title" class='required'>Contact</label>
                      <span><a href="javascript:void(0)" v-show="account_id && !disable_contact_field" class="label-block-helper blue" @click="openContactModal">Create New</a></span>
                    </div>
                    <div v-if="disable_contact_field" class="disabled-div">
                      {{ this.contact_name }}
                    </div>
                    <div v-else data-toggle='tooltip' :data-original-title="account_id ? '' : 'Please select the account to see the associated contacts.'">
                      <dependent-select2 :disabled="!account_id" :toggle='account_id ? "" : "tooltip"' title='Please Select Account' :options="contact_options" selectType='select2WithoutTags' v-model="contact_id" :parent='account_id' ref='contactOptions'  placeholder='Please Select'></dependent-select2>
                      <error :errors='errors.contact_id' />
                      <error :errors='errors.contact' />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <info-text :text='$i18n.tc("infotext.opportunity_close_by")'><label for="close_by" class='required'>Opportunity Close By</label></info-text>
                    <div class="form-control-group form-date-group">
                      <date-field v-model="close_by_datetime" id="close_by"></date-field>
                      <label for="close_by" class="cal-icon-right"></label>
                      <error :errors='errors.close_by_datetime' />
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <info-text :text='$i18n.tc("infotext.estimated_revenue")'><label for="pr_create_title" class='required'>Estimated Revenue</label></info-text>
                    <div class="with-image-icon">
                      <input v-model="estimated_revenue" type="text" autocomplete="off" class="dollar-padding form-control place-holder">
                      <label for="base_price" class='dollar-sign'>$</label>
                    </div>
                    <error :errors='errors.estimated_revenue' />
                  </div>
                </div>
              </div>
            </div>
            <div class="row row-col-space-15 m-b-15 p-t-10">
              <div class="col-md-6">
                <button :disabled='loader'  class='btn btn-lg fontmedium btn-block fs-16 btn-with-icon icon-right' @click="saveOrder(true)">Continue Editing
                  <span class="btn-icon"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path fill="#646c9a" fill-rule="evenodd" d="M14.293 8l-4.147-4.146a.5.5 0 0 1 .708-.708l5 5a.5.5 0 0 1 0 .708l-5 5a.5.5 0 0 1-.708-.708L14.293 9H.5a.5.5 0 0 1 0-1h13.793z"></path></svg></span>
                </button>
              </div>
              <div class="col-md-6">
                <button :disabled='loader' class='btn btn-primary btn-block btn-lg fontmedium col-md-8' @click="saveOrder(false)">Save</button>
              </div>
            </div>
          </div>
        </form>
      </template>
    </si-modal>
  </div>
</template>

<script>
  import { mapActions, mapState } from 'vuex';
  import { createHelpers } from 'vuex-map-fields';
  import error from 'components/shared/error'
  import ajaxSelect2 from 'components/shared/ajax_select2.vue';
  import siModal from 'components/shared/si_modal';
  import { accountSearchPath } from 'concerns/path_helper_methods.js';
  import { defaultAccountSetterMixin } from 'mixin/shared/default_account_setter_mixin';
  import select2 from 'components/shared/select2';
  import dependentSelect2 from 'components/shared/dependent_select2'
  import dateField from 'components/shared/date_field.vue';
  import { initialState } from 'store/data/opportunities/state.js';
  import QuickAddContact from 'components/shared/contacts/quick_add_contact.vue';
  import InfoText from 'components/micro-components/infotext.vue';

  const { mapFields } = createHelpers({
    getterType: 'getOpportunity',
    mutationType: 'updateOpportunity',
  });

  const { mapFields: mapQuickAccountFields } = createHelpers({
    getterType: 'getAccount',
    mutationType: 'createAccount',
  });

  export default {
    name: 'OpportunityQuickAddWidget',
    props: ['account_team_vue_options', 'common_modal'],
    data() {
      return {
        disable_contact_field: false,
        contact_name: '',
        account_name: '',
        quick_add_contact_accounts: [],
        account_team_options: [],
        account_is_target: null,
        modalOptions: {
          title: 'Create New Opportunity',
          pickerOverFlowClass: 'overflow-initial-hard',
          modalId: 'create-opportunity-widget'
        },
        account_options: [],
        loader: false
      }
    },
    mixins: [defaultAccountSetterMixin],
    mounted() {
      this.account_team_options = this.account_team_vue_options;
      this.checkNewContactParam();
    },
    computed: {
      ...mapQuickAccountFields('AccountQuickAddWidget', ['accountModalStatus', 'skipAccountValidation', 'createdAccountData']),
      ...mapFields("QuickAddOpportunityWidget", [
        'newOpportunity',
        'newOpportunity.account_id',
        'newOpportunity.contact_id',
        'newOpportunity.state',
        'newOpportunity.close_by_datetime',
        'newOpportunity.in_hands_date',
        'newOpportunity.name',
        'newOpportunity.estimated_revenue',
        'newOpportunity.errors',
        'contact_options',
        'preselected_contact_id'
      ])
    },
    watch: {
      preselected_contact_id: function(value) {
        if(value && this.common_modal) {
          this.fetchContact({ apollo: this.$apollo, contact_id: value })
          .then((data)=> {
            this.disable_account_field = true;
            this.disable_contact_field = true;
            this.account_id = data.account.id;
            this.account_name = data.account.name;
            this.contact_id = data.id;
            this.contact_name = data.name;
            this.account_is_target = data.account.is_target;
            this.displayModal();
          });
        }
      },
      createdAccountData: function(value) {
        if(value) {
          var account_options = [{
            id: value.id,
            text: value.name,
            is_target: value.is_target
          }];
          this.$refs.accountSelector.setOptions(account_options, value.id);
          this.account_is_target = value.is_target;
        }
      }
    },
    methods: {
      accountSearchPath,
      accountsUrl() {
        return router.makePath('fetch_accounts');
      },
      selectAccount: function(account) {
        if(account) {
          this.account_is_target = account.target;
        }
      },
      openContactModal: function() {
        this.$refs.contactModal.displayModalWithAccountSet(this.account_id);
      },
      newAccountPath() {
        return router.makePath('new_account');
      },
      closeModal() {
        this.$refs.orderCreateModal.hideModal()
      },
      checkNewContactParam: function() {
        var new_contact_id = new URLSearchParams(window.location.search).get('contact_id');
        if(new_contact_id) {
          this.preselected_contact_id = new_contact_id;
        }
      },
      openAccountModal: function() {
        this.skipAccountValidation = true;
        this.accountModalStatus = true;
      },
      contactType() {
        if(!this.default_account) {
          return;
        } else {
          return 'Prospect';
        }
      },
      displayModal: function() {
        this.$refs.orderCreateModal.displayModal();
      },
      focusField() {
        this.$refs.opportunityName.focus();
      },
      resetData() {
        this.newOpportunity = initialState();
        this.account_name = '';
        this.contact_name = '';
        this.disable_account_field = false;
        this.disable_contact_field = false;
        this.account_is_target = false;
        this.account_id = null;
      },
      saveOrder(continue_edit) {
        this.loader = true
        this.createOpportunity({ apollo: this.$apollo, showAlert: this.$root.showAlert, newOpportunity: {...this.newOpportunity }, component: this, continue_edit: continue_edit}).then(() => {
          this.closeModal();
        })
      },
      refreshContacts() {
        this.$refs.contactOptions.forceRefreshData();
      },
      ...mapActions('QuickAddOpportunityWidget', ['createOpportunity', 'fetchContact', 'fetchAccount']),
    },
    components: {
      siModal,
      dependentSelect2,
      select2,
      ajaxSelect2,
      error,
      dateField,
      QuickAddContact,
      InfoText
    }
  }
</script>
