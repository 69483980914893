import { render, staticRenderFns } from "./PlaceholderLoading.vue?vue&type=template&id=67985bba&"
import script from "./PlaceholderLoading.vue?vue&type=script&lang=js&"
export * from "./PlaceholderLoading.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../shared/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/si2/shared/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('67985bba')) {
      api.createRecord('67985bba', component.options)
    } else {
      api.reload('67985bba', component.options)
    }
    module.hot.accept("./PlaceholderLoading.vue?vue&type=template&id=67985bba&", function () {
      api.rerender('67985bba', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/javascript/components/shared/loading/PlaceholderLoading.vue"
export default component.exports