<template>
  <div data-behavior="shared-schedule-modal-container">
    <si-modal :modalOptions="modalOptions" @onDismiss="onDismiss">
      <template slot="modal-content">
        <form class="form" v-if="showForm">
          <article class="new-event-form-container">
            <div class="form-group">
              <input class="create-event-input" v-model="scheduleObject.title" type="text" :placeholder="$t('scheduleModal.addTitle')" autocomplete="off">
              <error :errors='scheduleObject.errors.title || []'></error>
            </div>

            <div class="event-create-timing d-flex">
              <div class="event-dt-col event-date-col">
                <div class="form-control-group">
                  <!-- Date Field -->
                  <date-field v-model="scheduleObject.start_date" :not_format="!scheduleObject.formatDate"></date-field>
                  <error :errors='scheduleObject.errors.start_date || []'></error>
                  <error :errors='scheduleObject.errors.start_at || []'></error>
                </div>
              </div>

              <div class="event-dt-col event-time-col">
                <div class="form-control-group">
                  <!-- Time Field -->
                  <time-picker :field_name="'work_order_schedule[start_time]'" :field_value="scheduleObject.start_time" :element_id="'schedule_start_time'" :placeholder="''" @time_value_update="scheduleObject.start_time = $event"></time-picker>
                  <error :errors='scheduleObject.errors.start_time || []'></error>
                </div>
              </div>

              <div class="event-dt-col time-bar">
                <div class="time-to-text p-t-10 m-t-2 fs-15">{{$t('default_calendar_range_label')}}</div>
              </div>

              <div class="event-dt-col event-date-col">
                <div class="form-control-group">
                  <!-- Date Field -->
                  <date-field v-model="scheduleObject.end_date" :not_format="!scheduleObject.formatDate"></date-field>
                  <error :errors='scheduleObject.errors.end_date || []'></error>
                  <error :errors='scheduleObject.errors.end_at || []'></error>
                </div>
              </div>

              <div class="event-dt-col event-time-col">
                <div class="form-control-group">
                  <!-- Time Field -->
                  <time-picker :field_name="'work_order_schedule[end_time]'" @time_value_update="scheduleObject.end_time = $event" :field_value="scheduleObject.end_time" :element_id="'schedule_end_time'" :placeholder="''"></time-picker>
                  <error :errors='scheduleObject.errors.end_time || []'></error>
                </div>
              </div>
            </div>

            <hr>

            <div class="create-event-action row no-side-margin">
              <button type="button" class="btn btn-si-sm btn-primary" @click="submitEventListener">{{!scheduleObject.patch ? $t('scheduleModal.addScheduleActionLabel') : $t('scheduleModal.updateScheduleActionLabel')}}</button>
            </div>

          </article>
        </form>
      </template>
    </si-modal>
  </div>
</template>

<script>
import siModal from './si_modal.vue';
import timePicker from '../global/time_picker.vue';
import dateField from './date_field.vue';
import error from './error.vue';
import axios from 'axios';

export default {
  data() {
    return {
      modalOptions: {
        title: this.$t('scheduleModal.title'),
        modalContainerClass: 'new-event-modal',
        topBarColor: '',
        pickerOverFlowClass:'no-overflow'
      },
      scheduleObject: null,
      showForm: false,
      dateWatcher:null,
      workOrderRef:null
    };
  },
  components: {
    siModal, timePicker, dateField, error
  },
  methods: {
    onDismiss(){
      if(this.scheduleObject){
        this.showForm = false;
        // Dynamic Watcher Removal
        this.dateWatcher();
      }
    },
    submitEventListener() {
      const {title, start_date, start_time, end_date, end_time, authenticity_token, create_link, patch} = this.scheduleObject;
      const _this = this;
      $(document).trigger('ajax:send');
      axios({
        method : patch ? 'patch' : 'post',
        url: this.jsonifyUrl(create_link),
        data: {work_order_schedule: {title, start_date, start_time, end_date, end_time}, authenticity_token, _method : patch ? 'patch' : undefined , utf8: '✓'}
      }).then(response => {
        $('[data-behavior=shared-schedule-modal-container]').find('[data-card-modal=true]').modal('hide');
        _this.workOrderRef.flashManager.showFlash(response.data.message);
        _this.workOrderRef.showMarkInProgessButton();
        _this.workOrderRef.refetchWorkOrders();
        $(document).trigger('ajax:success');
      }).catch(e => {
        _this.scheduleObject.errors = JSON.parse(e.response.data.errors);
        if(_this.hasBaseErrors) {
          _this.$root.showAlert('alert-danger', _this.scheduleObject.errors.base[0]);
        }
        $(document).trigger('ajax:success');
      });
    },
    jsonifyUrl(url){
      if(url.includes('?')){
        return url.split('?').join('.json?');
      }else{
        return url += '.json';
      }
    }
  },
  mounted() {
    const _this = this;
    $('[data-behavior=shared-event-modal-container]').on('schedule-modal-toggle', (event, {options, woRef}) => {
      if(options.formatDate === undefined) {
        options.formatDate = true;
      }
      _this.scheduleObject = {
        ...options,
        errors:{}
      };
      _this.workOrderRef = woRef;
      _this.modalOptions.topBarColor = this.scheduleObject.topBarColor;
      // Dynamic Watcher Adding
      _this.dateWatcher = _this.$watch('scheduleObject.start_date', function(changedDate) {
        this.scheduleObject.end_date = changedDate;
      });
      _this.showForm = true;
      $('[data-behavior=shared-schedule-modal-container]').find('[data-card-modal=true]').modal('show');
    });
  },
  computed: {
    hasBaseErrors: function() {
      return !$.isEmptyObject(this.scheduleObject.errors) && (this.scheduleObject.errors.hasOwnProperty('base'));
    }
  }
};
</script>
