// Any Component using this module
// should implement computed property selectedAccounts
// this is to ensure hiding the selected option from the list
// also add vSelectSearch ref to v-select

import { mapActions } from 'vuex';

export const searchableMixin = {
  data() {
    return {
      accounts: [],
      search_term: '',
    }
  },
  props: ['min_input_for_search'],
  methods: {
    ...mapActions('AccountSearcher', ['searchAccounts']),
    fetchAccounts: _.debounce(function(searchQuery){
      var _this = this;
      this.searchAccounts({
        apollo: this.$apollo,
        searchTerm: searchQuery,
      }).then(function(response){
        if(_this.minimumLengthEntered) {
          _this.accounts = response;
        } else {
          _this.accounts = [];
        }
      });
    }, 200),
    search(searchQuery) {
      this.search_term = searchQuery;
      if(this.minimumLengthEntered){
        this.fetchAccounts(searchQuery);
      } else {
        this.accounts = [];
      }
    },
    resetAccounts() {
      this.accounts = [];
    },
    handleEmptyInput(val) {
      this.search_term = '';
      this.resetAccounts();
    },
    clearSearch() {
      if(this.$refs.vSelectSearch){
        this.$refs.vSelectSearch.search = '';
      }
    }
  },
  computed: {
    minimumLengthEntered: function() {
      return (this.search_term.trim().length >= this.min_input_for_search);
    }
  },
};
