<template>
  <v-popover placement='top' trigger='hover' popoverArrowClass='hide'>
    <slot>
      <a :href="userShowPath(userObject)">{{ userObject.name }}</a>
    </slot>
    <template slot='popover'>
      <user-hover-data-tooltip :user_id='user_id'/>
    </template>
  </v-popover>
</template>

<script>
  import { mapState } from 'vuex';
  import { userShowPath } from 'concerns/path_helper_methods.js';
  import UserHoverDataTooltip from 'components/shared/users/user_hover_data_tooltip.vue';

  export default {
    name: 'UserPopoverTooltip',
    props: {
      user_id: {
        required: true,
        default: ''
      }
    },
    computed: {
      ...mapState('GeneralConfiguration', ['user_data']),
      userObject() {
        return this.user_data[String(this.user_id)] || {};
      },
    },
    methods: {
      userShowPath
    },
    components: {
      UserHoverDataTooltip
    }
  }
</script>
