<template>
  <span>
    <span v-if="contact_changes[1]">
      <span class="d-inline-block fs-12">{{ updateText }}</span>
      <div class="m-l-22 wo-tab-detail">
        <div class="dark tooltip-inline">
          <span class="d-inline-block">
            <ContactDataTooltipWithName v-if="contact_changes[1].loggable_name == 'Contact'" :contact_object='contact_changes[1]'></ContactDataTooltipWithName>
            <SupplierDataTooltip v-else :contact_object='contact_changes[1]'></SupplierDataTooltip>
          </span>
          <span class="fontmedium d-inline-block">
            <span class="d-inline-block">›</span>
            <span class="d-inline-block">
              <ContactDataTooltipWithName v-if="contact_changes[0].loggable_name == 'Contact'" :contact_object='contact_changes[0]'></ContactDataTooltipWithName>
              <SupplierDataTooltip v-else :contact_object='contact_changes[0]'></SupplierDataTooltip>
            </span>
          </span>
        </div>
      </div>
    </span>
    <span v-else>
      <span class="d-inline-block fs-12">{{ addText }}</span>
      <div class="m-l-22 wo-tab-detail dark">
        <ContactDataTooltipWithName v-if="contact_changes[0].loggable_name == 'Contact'" :contact_object='contact_changes[0]'></ContactDataTooltipWithName>
        <SupplierDataTooltip v-else :contact_object='contact_changes[0]'></SupplierDataTooltip>
      </div>
    </span>
  </span>
</template>

<script>
  import ContactDataTooltipWithName  from 'components/shared/contacts/contact_data_tooltip_with_name.vue';
  import SupplierDataTooltip from 'components/shared/catalog/supplier_data_tooltip.vue';

  export default {
    name: 'contactDetail',
    props: [
      'contact_changes',
      'updateText',
      'addText'
    ],
    components: {
      ContactDataTooltipWithName,
      SupplierDataTooltip
    }
  };
</script>
