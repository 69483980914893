<template>
  <div class='d-flex justfy-between'>
    <img :src="loadedContactData.profile_picture" height='40' width='40'>
    <div class="">
      <div class='text-left tooltip-text'>
        <a :href="contactShowPath(loadedContactData)" target="_blank" class="super-dark clickable-item fs-14 m-b-2 semibold ls-01">{{ loadedContactData.name }}</a>
      </div>
      <div class='super-dark text-left tooltip-text fs-13 ls-01' v-html='loadedContactData.email'></div>
    </div>
  </div>
</template>
<script>
  import { mapState } from 'vuex';
  import { contactShowPath } from 'concerns/path_helper_methods.js';

  export default {
    name: 'ContactTooltipHover',
    props: {
      contactId: String
    },
    computed: {
      ...mapState('GeneralConfiguration', ['contactsData']),
      loadedContactData: function() {
        return this.contactsData[this.contactId];
      }
    },
    methods: {
      contactShowPath
    }
  }
</script>
