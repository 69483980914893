var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", { staticClass: "history-manage-block" }, [
    _vm.from_value && _vm.to_value
      ? _c("span", [
          _c("span", { staticClass: "d-inline-block fs-12" }, [
            _vm._v(_vm._s(_vm.updated_text))
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "m-l-22 wo-tab-detail" }, [
            _c("div", { staticClass: "dark tooltip-inline" }, [
              _c("div", { staticClass: "d-inline-block" }, [
                _vm._v("$ " + _vm._s(_vm.from_value))
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "fontmedium d-inline-block" }, [
                _vm._v("›\n          "),
                _c("div", { staticClass: "d-inline-block" }, [
                  _vm._v("$ " + _vm._s(_vm.to_value))
                ])
              ])
            ])
          ])
        ])
      : _c("span", [
          _c("span", { staticClass: "d-inline-block fs-12" }, [
            _vm._v(_vm._s(_vm.updated_text))
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "m-l-22 wo-tab-detail dark" }, [
            _c("div", [_vm._v("$ " + _vm._s(_vm.to_value))])
          ])
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }