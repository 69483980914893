<template>
  <select :multiple='multiple' :data-toggle='toggle' :title='title' :disabled='select_disabled'>
    <slot></slot>
  </select>
</template>

<script>
  import axios from 'axios'
  import '../../../assets/javascripts/admin/select2_builder.js'
  export default {
    name: 'dependentSelect2',
    props: ['options', 'parent', 'value', 'url', 'param_option', 'multiple', 'default_value', 'toggle', 'title', 'disabled', 'placeholder', 'loadInitialData', 'disable_search'],
    data() {
      return {
        select_disabled: false
      };
    },
    methods: {
      forceRefreshData() {
        this.refreshData(this.parent);
      },
      forceRefreshWithSelectedValue(selectedOption) {
        this.refreshData(this.parent, selectedOption);
      },
      setTemplateSelection(selectOptions) {
        selectOptions['templateSelection'] = function (data, container) {
          $(data.element).attr('data-meta', data['data-meta']);
          return data.text;
        }
      },
      refreshData(value, selectedOption) {
        this.select_disabled = true;

        if(!String(value).length) {
          return
        }
        let href = this.url || `/api/v1/admin/accounts/${value}/json_contacts.json`
        let params = {}
        params[this.param_option || 'account_id'] = value;
        params['admin_api_token'] = gon.adminApiToken;
        return axios.get(href, {
          params: params
        }).then(response => {
          var vm = this,
              enabler = new Select2Builder({ select2WithoutTags: $(this.$el) }),
              selectOptions = { data: response.data, dropdownParent: $(this.$el).closest('.form-group') }
          vm.$emit('optionChange', response.data)
          if(this.placeholder) {
            selectOptions['placeholder'] = this.placeholder
          }
          this.setTemplateSelection(selectOptions)
          $(vm.$el).select2('data', null)
          $(vm.$el).select2('destroy');
          $(vm.$el).html('');
          $(vm.$el)
            .select2(selectOptions)
            .on('select2:select', function() {
              enabler.handleSelect(this);
              enabler.addOptionClass(this);
              enabler.handleMultiSelect(this);
            })
            .on('change', function() {
              enabler.addOptionClass(this);
            })
            .val(selectedOption || this.default_value || null)
            .trigger('change')
            .on('change', function (e, internal) {
              if(!internal) {
                vm.$emit('input', $(this).val())
              }
          })
          this.select_disabled = this.disabled;
          // Event triggered when options are changed
          vm.$emit('optionsChanged', response.data);
          if(selectOptions.data.length == 1){
            $(vm.$el).val(selectOptions.data[0].id).trigger('change');
          }
        });
      }
    },
    mounted() {
      this.select_disabled = this.disabled
      var vm = this,
          enabler = new Select2Builder({ select2WithoutTags: $(this.$el) }),
          selectOptions = { data: this.options, minimumResultsForSearch: this.minSearchValue, dropdownParent: $(this.$el).closest('.form-group') }
      if(this.placeholder) {
        selectOptions['placeholder'] = this.placeholder
      }
      this.setTemplateSelection(selectOptions)
      $(this.$el)
        .select2(selectOptions)
        .on('select2:select', function() {
          enabler.handleSelect(this);
          enabler.addOptionClass(this);
          enabler.handleMultiSelect(this);
        })
        .on('change', function() {
          enabler.addOptionClass(this);
        })
        .val(this.value)
        .trigger('change')
        .on('change', function (e, internal) {
          if(!internal) {
            vm.$emit('input', $(this).val())
          }
      })
      var currentValue = this.value
      if(this.loadInitialData) {
        if(this.parent) {
          this.refreshData(this.parent).then(() => {
            $(this.$el).val(currentValue).trigger('change')
          })
        }
      }
      // this.refreshData(value)
    },
    watch: {
      value: function (value) {
        $(this.$el)
          .val(value)
          .trigger('change', true)
        // Focus on select2 to handle escape event
        // this.$el.closest('div').focus();
      },
      parent: function(value) {
        this.refreshData(value, this.value)
      },
      options: function (options) {
        // if(this.default_value != undefined) {
        //   $(this.$el).empty().select2({ data: options, val: this.default_value }).trigger('change')
        // } else {
        //   $(this.$el).empty().val(null).select2({ data: options }).trigger('change')
        // }
        var vm = this,
            enabler = new Select2Builder({ select2WithoutTags: $(this.$el) }),
            selectOptions = { data: options, dropdownParent: $(this.$el).closest('.form-group'), }
        if(this.placeholder) {
          selectOptions['placeholder'] = this.placeholder
        }
        this.setTemplateSelection(selectOptions)
        $(this.$el).select2('data', null)
        $(this.$el).select2('destroy')
        $(this.$el).html('')
        $(this.$el)
          .select2(selectOptions)
          .on('select2:select', function() {
            enabler.handleSelect(this);
            enabler.addOptionClass(this);
            enabler.handleMultiSelect(this);
          })
          .on('change', function() {
            enabler.addOptionClass(this);
          })
          .val(this.value || this.default_value || null)
          .trigger('change')
          .on('change', function (e, internal) {
            if(!internal) {
              vm.$emit('input', $(this).val())
            }
        })
      }
    },
    destroyed: function () {
      $(this.$el).off().select2('destroy')
    },
    computed: {
      disableSelect: function() {
        return !$.isEmptyObject(parent) || this.select_disabled;
      },
      minSearchValue: function() {
        if(this.disable_search == "true") {
          return -1;
        }
      }
    }
  }
</script>

<style scoped>
</style>
