var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { "data-behavior": "shared-schedule-modal-container" } },
    [
      _c(
        "si-modal",
        {
          attrs: { modalOptions: _vm.modalOptions },
          on: { onDismiss: _vm.onDismiss }
        },
        [
          _c("template", { slot: "modal-content" }, [
            _vm.showForm
              ? _c("form", { staticClass: "form" }, [
                  _c("article", { staticClass: "new-event-form-container" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.scheduleObject.title,
                              expression: "scheduleObject.title"
                            }
                          ],
                          staticClass: "create-event-input",
                          attrs: {
                            type: "text",
                            placeholder: _vm.$t("scheduleModal.addTitle"),
                            autocomplete: "off"
                          },
                          domProps: { value: _vm.scheduleObject.title },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.scheduleObject,
                                "title",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("error", {
                          attrs: {
                            errors: _vm.scheduleObject.errors.title || []
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "event-create-timing d-flex" }, [
                      _c(
                        "div",
                        { staticClass: "event-dt-col event-date-col" },
                        [
                          _c(
                            "div",
                            { staticClass: "form-control-group" },
                            [
                              _c("date-field", {
                                attrs: {
                                  not_format: !_vm.scheduleObject.formatDate
                                },
                                model: {
                                  value: _vm.scheduleObject.start_date,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.scheduleObject,
                                      "start_date",
                                      $$v
                                    )
                                  },
                                  expression: "scheduleObject.start_date"
                                }
                              }),
                              _vm._v(" "),
                              _c("error", {
                                attrs: {
                                  errors:
                                    _vm.scheduleObject.errors.start_date || []
                                }
                              }),
                              _vm._v(" "),
                              _c("error", {
                                attrs: {
                                  errors:
                                    _vm.scheduleObject.errors.start_at || []
                                }
                              })
                            ],
                            1
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "event-dt-col event-time-col" },
                        [
                          _c(
                            "div",
                            { staticClass: "form-control-group" },
                            [
                              _c("time-picker", {
                                attrs: {
                                  field_name: "work_order_schedule[start_time]",
                                  field_value: _vm.scheduleObject.start_time,
                                  element_id: "schedule_start_time",
                                  placeholder: ""
                                },
                                on: {
                                  time_value_update: function($event) {
                                    _vm.scheduleObject.start_time = $event
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c("error", {
                                attrs: {
                                  errors:
                                    _vm.scheduleObject.errors.start_time || []
                                }
                              })
                            ],
                            1
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "event-dt-col time-bar" }, [
                        _c(
                          "div",
                          { staticClass: "time-to-text p-t-10 m-t-2 fs-15" },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("default_calendar_range_label"))
                            )
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "event-dt-col event-date-col" },
                        [
                          _c(
                            "div",
                            { staticClass: "form-control-group" },
                            [
                              _c("date-field", {
                                attrs: {
                                  not_format: !_vm.scheduleObject.formatDate
                                },
                                model: {
                                  value: _vm.scheduleObject.end_date,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.scheduleObject,
                                      "end_date",
                                      $$v
                                    )
                                  },
                                  expression: "scheduleObject.end_date"
                                }
                              }),
                              _vm._v(" "),
                              _c("error", {
                                attrs: {
                                  errors:
                                    _vm.scheduleObject.errors.end_date || []
                                }
                              }),
                              _vm._v(" "),
                              _c("error", {
                                attrs: {
                                  errors: _vm.scheduleObject.errors.end_at || []
                                }
                              })
                            ],
                            1
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "event-dt-col event-time-col" },
                        [
                          _c(
                            "div",
                            { staticClass: "form-control-group" },
                            [
                              _c("time-picker", {
                                attrs: {
                                  field_name: "work_order_schedule[end_time]",
                                  field_value: _vm.scheduleObject.end_time,
                                  element_id: "schedule_end_time",
                                  placeholder: ""
                                },
                                on: {
                                  time_value_update: function($event) {
                                    _vm.scheduleObject.end_time = $event
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c("error", {
                                attrs: {
                                  errors:
                                    _vm.scheduleObject.errors.end_time || []
                                }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("hr"),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "create-event-action row no-side-margin" },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-si-sm btn-primary",
                            attrs: { type: "button" },
                            on: { click: _vm.submitEventListener }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                !_vm.scheduleObject.patch
                                  ? _vm.$t(
                                      "scheduleModal.addScheduleActionLabel"
                                    )
                                  : _vm.$t(
                                      "scheduleModal.updateScheduleActionLabel"
                                    )
                              )
                            )
                          ]
                        )
                      ]
                    )
                  ])
                ])
              : _vm._e()
          ])
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }