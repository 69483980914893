var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "invitees-tag-list" }, [
    _c(
      "ul",
      { attrs: { "data-behavior": "select-list" } },
      _vm._l(_vm.invitees, function(invitee, index) {
        return _c("li", { key: index, staticClass: "d-flex align-center" }, [
          _c("div", [
            _c(
              "span",
              { staticClass: "card-assign-user pointer clickable-item" },
              [
                _c("img", {
                  staticClass: "img-responsive assign-user-image",
                  attrs: { src: invitee.imageUrl }
                })
              ]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "invitee-detail-col fs-13 text-truncate" }, [
            _c("div", { staticClass: "col-md-9 col-sm-9" }, [
              _c("div", { staticClass: "black-2" }, [
                _c("span", { staticClass: "d-block text-truncate" }, [
                  _vm._v(
                    "\n              " +
                      _vm._s(invitee.name) +
                      "\n              "
                  ),
                  index == 0
                    ? _c("span", { staticClass: "fs-11 semibold" }, [
                        _vm._v(
                          "(" + _vm._s(_vm.$t("eventModal.organizer")) + ")"
                        )
                      ])
                    : _vm._e()
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "text-truncate" }, [
                _c("span", { staticClass: " fs-11" }, [
                  _vm._v(_vm._s(invitee.email))
                ])
              ])
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-md-3 col-sm-3 p-t-5 m-t-2 text-right" },
              [_c("span", { class: invitee.responseClass })]
            ),
            _vm._v(" "),
            invitee.responseClass == "response-awaiting"
              ? _c("a", {
                  staticClass: "destroy-tag-selected",
                  on: {
                    click: function($event) {
                      return _vm.removeInviteeHandler(invitee.value)
                    }
                  }
                })
              : _vm._e()
          ])
        ])
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }