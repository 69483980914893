<template>
  <div>
    <quick-add-contact
      modal_id='estimate_quick_add_contact'
      :account_team_options="account_team_options"
      :default_account="default_account"
      @onCreated="refreshContacts"
      hide_continue_edit="true"
      disable_account="true"
      ref="contactModal"
      hide_prospect_confirmation="true"
      min_input_for_search='2'
    ></quick-add-contact>

    <si-modal ref='estimateCreateModal' :modalOptions='modalOptions' @onDismiss='resetEstimateState' @onShown='initializeData'>
      <template slot="modal-content">
        <form class="form" @submit.prevent>
          <div class="">
            <div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="pr_create_title" class='required'>Estimate name</label>
                    <input v-model="name" ref='name' type="text" autocomplete="off" class="form-control place-holder">
                    <error :errors='errors.name' />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="in_hands" class='required'>Order In-Hands Date</label>
                    <div class="form-control-group form-date-group">
                      <date-field v-model="in_hands_date" id="in_hands"></date-field>
                      <label for="in_hands" class="cal-icon-right"></label>
                      <error :errors='errors.in_hands_date' />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <div class="d-flex justify-between">
                      <label for="pr_create_title" class='required'>Account</label>
                    </div>
                    <ajax-select2
                      v-model="account_id"
                      request_param='name_cont'
                      search_input_length='2'
                      :request_path='accountSearchPath()'
                    ></ajax-select2>
                    <error :errors='errors.account' />
                    <error :errors='errors.account_id' />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <div class="d-flex justify-between">
                      <label for="pr_create_title" class='required'>Contact</label>
                       <span><a href="javascript:void(0)" v-show="account_id" class="label-block-helper blue" @click="openContactModal">Create New</a></span>
                    </div>
                    <div data-toggle='tooltip' :data-original-title="account_id ? '' : 'Please select the account to see the associated contacts.'">
                      <dependent-select2 :disabled="!account_id" :toggle='account_id ? "" : "tooltip"' title='Please Select Account' :options="contact_options" selectType='select2WithoutTags' v-model="contact_id" :parent='account_id' ref='contactOptions'  placeholder='Please Select'></dependent-select2>
                      <error :errors='errors.contact_id' />
                      <error :errors='errors.contact' />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row row-col-space-15 m-b-15 p-t-10">
              <div class="col-md-6">
                <button :disabled='loader'  class='btn btn-lg fontmedium btn-block fs-16 btn-with-icon icon-right' @click="saveEstimate(true)">Continue Editing
                  <span class="btn-icon"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path fill="#646c9a" fill-rule="evenodd" d="M14.293 8l-4.147-4.146a.5.5 0 0 1 .708-.708l5 5a.5.5 0 0 1 0 .708l-5 5a.5.5 0 0 1-.708-.708L14.293 9H.5a.5.5 0 0 1 0-1h13.793z"></path></svg></span>
                </button>
              </div>
              <div class="col-md-6">
                <button :disabled='loader' class='btn btn-primary btn-block btn-lg fontmedium col-md-8' @click="saveEstimate(false)">Save</button>
              </div>
            </div>
          </div>
        </form>
      </template>
    </si-modal>
  </div>
</template>

<script>
  import { mapActions, mapState } from 'vuex';
  import { createHelpers } from 'vuex-map-fields';
  import error from 'components/shared/error'
  import ajaxSelect2 from 'components/shared/ajax_select2.vue';
  import siModal from 'components/shared/si_modal';
  import select2 from 'components/shared/select2';
  import dependentSelect2 from 'components/shared/dependent_select2'
  import { accountSearchPath } from 'concerns/path_helper_methods.js';
  import { defaultAccountSetterMixin } from 'mixin/shared/default_account_setter_mixin';
  import dateField from 'components/shared/date_field.vue';
  import { initialState } from 'store/data/estimates/state.js'
  import QuickAddContact from 'components/shared/contacts/quick_add_contact.vue';


  const { mapFields } = createHelpers({
    getterType: 'getEstimate',
    mutationType: 'updateEstimate',
  });

  export default {
    name: 'CreateEstimate',
    props: ['account_team_options'],
    data() {
      return {
        modalOptions: {
          title: 'Create New Estimate',
          modalId: 'create-estimate-modal',
          pickerOverFlowClass: 'overflow-initial-hard'
        },
        loader: false,
        contact_options: []
      }
    },
    mixins: [defaultAccountSetterMixin],
    computed: {
      ...mapFields("CreateEstimateWidget", ['newEstimate.account_id', 'newEstimate.contact_id','newEstimate.in_hands_date', 'newEstimate.name', 'newEstimate.estimated_revenue', 'newEstimate.cost_center', 'newEstimate.client_po_number', 'newEstimate.errors', 'newEstimate']),
    },
    methods: {
      accountSearchPath,
      accountsUrl() {
        return router.makePath('fetch_accounts');
      },
      openContactModal: function() {
        this.$refs.contactModal.displayModalWithAccountSet(this.account_id);
      },
      newAccountPath() {
        return router.makePath('new_account');
      },
      closeModal() {
        this.$refs.estimateCreateModal.hideModal()
      },
      contactType() {
        if(!this.default_account) {
          return;
        } else {
          return 'Prospect';
        }
      },
      initializeData() {
        this.$refs.name.focus();
      },
      resetEstimateState() {
        this.newEstimate = initialState();
      },
      saveEstimate(continue_edit) {
        this.loader = true
        this.createEstimate({ apollo: this.$apollo, newEstimate: {...this.newEstimate }, component: this, showAlert: this.$root.showAlert, continue_edit: continue_edit}).then(() => {
          this.closeModal();
        })
      },
      refreshContacts() {
        this.$refs.contactOptions.forceRefreshData();
      },
      ...mapActions('CreateEstimateWidget', ['createEstimate', 'fetchAccounts']),
      ...mapActions('QuickAddOpportunityWidget', ['fetchAccount']),
    },
    components: {
      siModal,
      ajaxSelect2,
      dependentSelect2,
      select2,
      error,
      dateField,
      QuickAddContact
    }
  }
</script>
