<template>
  <div data-behavior='vue-file-container'>
    <input type="hidden" name='id' :value='value' data-attachment-file-field='true' multiple="true">
    <div>
      <!-- The fileupload-buttonbar contains buttons to add/delete files and start/cancel the upload -->

      <div class="fileupload-buttonbar" v-if='!disabled'>
        <div class="">
          <!-- The fileinput-button span is used to style the file input field as button -->
          <span class="btn si-add-file fileinput-button normal">
            {{ t_add_file }}
            <svg data-v-03643d2d="" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
              <path data-v-03643d2d="" fill="#5D71FF" fill-rule="evenodd" d="M13 6.1V6c0-2.8-2.2-5-5-5-2.5 0-4.6 1.8-4.9 4.3C1.3 5.9 0 7.5 0 9.5 0 12 2 14 4.5 14H12c2.2 0 4-1.8 4-4 0-1.9-1.3-3.4-3-3.9zM9 9v3H7V9H4l4-4 4 4H9z"></path>
            </svg>

            <label class="file-btn-label" :for="attachment_label_name">
              {{ t_attachments }}
            </label>

            <input type="file" :multiple='!single_attachment' multiple="true" :accept="restricted_file_type"  name="attachments" :id="attachment_label_name" class='btn btn-default hide' data-behavior='attachments-field' :data-file-field-selector='file_field_selector' :data-attachment-type='attachment_type' :data-parent-selector='parent_selector' :disabled="disabled">
          </span>
          <span> {{ t_file_upload_hint }} </span>
        </div>
      </div>

      <!-- The loading indicator is shown during image processing -->
      <div class="fileupload-loading"></div>

      <!-- The table listing the files available for upload/download -->
      <table class="table smart-table attachment-table vue-file">
        <tbody class="files" data-toggle="modal-gallery" data-target="#modal-gallery" :data-attachment-type="attachment_type">

          <tr class="template-download fade in" v-for='(attachment, index) in files' :key="attachment.attachment_attributes.id" :data-id="attachment.attachment_attributes.id" v-if="showAttachment(attachment)">
            <td class="cancel-td" v-if='!disabled'>
              <a class="pointer cancel">
                <i class="fa" :class="{ 'fa-close': !disabled }"></i>
              </a>
            </td>

            <td class="preview" width="40">
              <a target="_blank" :href="attachment.attachment_attributes.document_url" :title="attachment.attachment_attributes.document_file_name" v-if="!attachment.attachment_attributes.errors.upload" :download="attachment.attachment_attributes.document_file_name">
                <img :src="attachment.attachment_attributes.document_url" alt="">
              </a>

              <img :src="attachment.attachment_attributes.file_icon_name" alt="" v-if="attachment.attachment_attributes.errors.upload">
            </td>
            <td class="name">
              <a target="_blank" v-if="!attachment.attachment_attributes.errors.upload" :href="attachment.attachment_attributes.document_url" :title="attachment.attachment_attributes.document_file_name" :download="attachment.attachment_attributes.document_file_name">{{ attachment.attachment_attributes.document_file_name }}</a>
              <div v-if="attachment.attachment_attributes.errors.upload">
                {{ attachment.attachment_attributes.document_file_name }}
              </div>
              <div class="error text-truncate text-danger m-t-3">
                <span class='s'>{{ attachment.attachment_attributes.errors.upload }}</span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <table class="table smart-table attachment-table multiple-jquery-file">
        <tbody class="files" data-toggle="modal-gallery" data-target="#modal-gallery" :data-attachment-type="attachment_type">

          <tr class="template-download hide fade in" v-for='(attachment, index) in files' :key="attachment.attachment_attributes.id" :data-id="attachment.attachment_attributes.id" v-if="showAttachment(attachment)">
            <td class="cancel-td" v-if='!disabled'>
              <a class="pointer cancel">
                <i class="fa" :class="{ 'fa-close': !disabled }"></i>
              </a>
            </td>

            <td class="preview" width="40">
              <a target="_blank" :href="attachment.attachment_attributes.document_url" :title="attachment.attachment_attributes.document_file_name">
                <img :src="attachment.attachment_attributes.file_icon_name" alt="" :download="attachment.attachment_attributes.document_file_name">
              </a>
            </td>
            <td class="name">
              <a target="_blank" :href="attachment.attachment_attributes.document_url" :title="attachment.attachment_attributes.document_file_name" :download="attachment.attachment_attributes.document_file_name">{{ attachment.attachment_attributes.document_file_name }}</a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
  import '../../../assets/javascripts/admin/shared/attachment_manager.js'

  export default {
    name: 'fileUpload',
    props: ["value", "association_id", "required_class", "attachment_label_name", "t_add_file", "single_attachment", "t_attachments", "file_field_selector", "attachment_type", "parent_selector", "t_file_upload_hint", "attachments", "association_value_ids", 'disabled', 'restricted_file_type'],
    data: function() {
      return {
        attachment_ids: new Set(this.association_value_ids),
        files: this.attachments,
        form: ''
      }
    },
    methods: {
      showAttachment(attachment) {
        return attachment.attachment_attributes.id == attachment.attachment_id && !!attachment.attachment_attributes.document_file_name && !attachment._destroy
      },
      removeAttachment: function(value) {
        this.attachment_ids.delete(parseInt(value));
        this.attachment_ids.delete(value);
        this.$emit('input', Array.from(this.attachment_ids));
        let file = this.files.filter((val) => { return val.attachment_id == value })[0];
        if(file) {
          file._destroy = '1'
          this.$emit('attachment_update', file)
        }
      },
      removeAjaxLoaderAndAttachment(attachment) {
        $(attachment.$el).find('.files').empty();
        if(!this.form.find('.files .cancel').length) {
          $('[data-behavior="ajax-loader"]').removeClass('ajax-send');
        }
        if(!this.form.find('.files .template-upload').length) {
          $('[data-behavior="ajax-loader"]').removeClass('ajax-send');
        }
        var $element = $(this.$el);
        $element.trigger('vue-file-delete');
        var $list = $element.find('tbody.files');
        $list.empty();
        this.form.find("input[type='submit']").removeAttr('disabled');
        this.form.removeAttr('disabled');
      }
    },
    beforeDestroy() {
      this.form.find("input[type='submit']").removeAttr('disabled');
      this.form.removeAttr('disabled');
      if(!this.form.find('.files .cancel').length) {
        $('[data-behavior="ajax-loader"]').removeClass('ajax-send');
      }
    },
    mounted() {
      this.files = this.attachments.map((val) => $.extend(true, {}, val))
      this.form = $(this.$el).closest('form')
      var form = $(this.$el).closest('form');
      form.find('input[type=file]').unbind('change');
      // form.fileupload('destroy');
      var attachmentManager = new AttachmentManager({
        templateName: 'attachment-template',
        formSelector: '[data-attachment-form=true]',
        $uploadForm: form,
        progressBarSelector: '[data-behavior="progress-bar"]',
        listSelector: '[data-behavior="files-container"]'
      });
      attachmentManager.init();
      var _this = this;
      $(this.$el).trigger('vuefile:added')
      $(this.$el).on('vue-file-add', function(event, $file) {
        let newFile = {
          attachment_attributes: {
            errors: {}
          },
        },

        filePresent = false;
        newFile['attachment_id'] = $file['id'];
        newFile['id'] = null;
        newFile['save_id'] = true;
        newFile['_destroy'] = null;
        newFile.attachment_attributes['id'] = $file['id']
        newFile.attachment_attributes['save_id'] = true
        newFile.attachment_attributes['document_url'] = $file['document_url']
        newFile.attachment_attributes['file_icon_name'] = $file['file_icon']
        newFile.attachment_attributes['document_file_name'] = $file['document_file_name']
        newFile.attachment_attributes['errors']['upload'] = $file['errors']
        if($file['errors']) {
          for(let file of _this.files) {
            if(file.attachment_attributes.document_url == $file['document_url']) {
              file = newFile
              filePresent = true
            }
          }
          if(!filePresent) {
          _this.files.push(newFile)
          }
        }
        // if($file.field) {
        //   // $file.field.classList.add('hide')
        // }
        // $(_this.$el).find('.jquery-file').addClass('hide')
        // $(_this.$el).find('.vue-file').removeClass('hide')
        _this.$emit('input', this.value)
        if(!$file['errors']) {
          _this.$emit('attachment_update', newFile);
        }
      })
      $(this.$el).on('vue-file-add', function() {
        _this.attachment_ids.add(parseInt(this.value));
        _this.$emit('input', Array.from(_this.attachment_ids));
      })
      $(this.$el).on('vue-file-start', function() {
        // $(_this.$el).find('.vue-file').addClass('hide')
        // $(_this.$el).find('.jquery-file').removeClass('hide')
      })
      $(this.$el).on('vue-file-delete', function() {
        _this.removeAttachment(this.value);
      })
    },
    watch: {
      attachments: function(values) {
        this.files = this.files.unique().filter(function(value) {
          return value.attachment_attributes['errors']['upload'];
        });
        for(let value of values) {
          this.files.push(value)
        }
      },
      association_value_ids: function(values) {
        this.attachment_ids = new Set(this.association_value_ids)
      }
    }
  }
</script>

<style scoped>
</style>
