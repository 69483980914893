<template>
  <div>
    <si-modal
      :modalOptions="modalOptions"
      ref="contactModal"
      @onDismiss="resetContactForm"
      @onShown="setContactStatus($event)"
    >
      <template slot="modal-content">
        <form class="form" @submit.prevent>
          <article class="new-contact-form-container p-t-15">
            <!-- <div class="fs-14 super-dark semibold p-b-15">{{ this.$i18n.tc('quick_add_contact.contact_type') }}</div> -->

            <!-- <div class="form-group p-b-5 m-t-3 hide">
              <div class="radio-listing-row">
                <label v-for='contact_type in this.contact_type_options' class="d-inline-block m-b-0">
                  <input name="contact[type]" v-model="current_contact_object.type" type="radio"  :id="contact_type" :value="contact_type" class="si-radio">
                  <span class="radio-helper fs-14">{{ contact_type }}</span>
                </label>
              </div>
              <error :errors='current_contact_object.errors.type'></error>
            </div> -->
            <div class="fs-14 super-dark semibold m-b-30">
              {{ this.current_contact_object.type + " Information" }}
            </div>

            <div class="row">
              <div class="form-group p-b-5 col-md-6">
                <label
                  for="contact_name"
                  :class="{ required: isLeadOrProspect }"
                  >{{ this.$i18n.tc("quick_add_contact.name_label") }}</label
                >
                <input
                  class="form-control"
                  v-model="current_contact_object.name"
                  type="text"
                  autocomplete="off"
                  name="contact[name]"
                  id="contact_name"
                  autofocus="true"
                  ref="nameInput"
                />
                <error :errors="current_contact_object.errors.name"></error>
              </div>

              <div class="form-group p-b-5 col-md-6">
                <label
                  for="contact_email"
                  :class="{ required: isLeadOrProspect }"
                  >{{ this.$i18n.tc("quick_add_contact.email_label") }}</label
                >
                <input
                  class="form-control"
                  v-model="current_contact_object.email"
                  type="text"
                  autocomplete="off"
                  name="contact[email]"
                  id="contact_email"
                />
                <span
                  class="warning-span"
                  v-if="current_contact_object.errors.email_taken_warning"
                />
                <error
                  :errors="current_contact_object.errors.email"
                ></error>
              </div>
              <div class="clearfix"></div>

              <div class="form-group col-md-6 p-b-10 m-b-0">
                <label for="contact_phone">{{
                  this.$i18n.tc("quick_add_contact.phone_label")
                }}</label>
                <input
                    class="form-control"
                    v-model="current_contact_object.phone"
                    type="text"
                    autocomplete="off"
                    name="contact[phone]"
                    id="contact_phone"
                />
                <span>Please enter a valid 10 digit number.</span>
                <error :errors="current_contact_object.errors.phone"></error>
              </div>

              <div class="form-group col-md-6 p-b-10 m-b-0">
                <label for="contact_mobile_number">{{
                  this.$i18n.tc("quick_add_contact.mobile_number")
                }}</label>
                <input
                    class="form-control"
                    v-model="current_contact_object.mobile_number"
                    type="text"
                    autocomplete="off"
                    name="contact[mobile_number]"
                    id="contact_mobile_number"
                />
                <span>Please enter a valid 10 digit number.</span>
                <error
                  :errors="current_contact_object.errors.mobile_number"
                ></error>
              </div>
            </div>

            <hr />

            <div class="form-group p-t-10 dropdown-clear">
              <div class="fs-14 super-dark semibold m-b-15 relative">
                <span :class="{ required: prospectOrCustomer }">{{
                  this.$i18n.tc("quick_add_contact.account_label")
                }}</span>

                <template v-if="account && Object.keys(account).length">
                  <div class="text-right semibold fs-12 account-target-helper">
                    <span
                      v-if="account.target || account.is_target"
                      class="text green-link"
                    >
                      {{ this.$i18n.tc("quick_add_contact.account_target") }}
                    </span>
                    <span v-else class="text red">
                      {{
                        this.$i18n.tc("quick_add_contact.account_not_target")
                      }}
                    </span>
                  </div>
                </template>
              </div>
              <div v-if="disable_account_field" class="disabled-div">
                {{ this.account.label || this.account.name }}
              </div>
              <v-select
                name="contact[account]"
                id="contact_account"
                ref="vSelectSearch"
                @input="handleEmptyInput"
                label="name"
                @search="search"
                :options="accounts"
                v-else
                v-model="account"
                :placeholder="'Please Select'"
                :clearable="accountSelected"
              >
                <template slot="option" slot-scope="option">
                  <div class="d-center">
                    <img :src="option.favicon_image" width="16" height="16" />
                    {{ option.name }}
                  </div>
                </template>
                <template slot="selected-option" slot-scope="option">
                  <div class="selected d-center text-truncate fs-13">
                    <img
                      :src="option.favicon_image"
                      width="16"
                      height="16"
                      v-if="option.favicon_image"
                    />
                    {{ option.name }}
                  </div>
                </template>
                <template slot="no-options">
                  <span v-if="minimumLengthEntered">
                    {{ $t("quick_add_contact.no_results_found") }}
                  </span>
                  <span v-else>
                    {{
                      $t("quick_add_contact.helper_message", {
                        min_characters: this.min_input_for_search,
                      })
                    }}
                  </span>
                </template>
              </v-select>
              <error :errors="current_contact_object.errors.account"></error>
            </div>

            <!-- Account team -->
            <!-- <div class="form-group p-t-10 dropdown-clear" v-if='isLeadOrProspect'>
              <div class="fs-14 super-dark semibold m-b-15 relative">
                <span :class="{ 'required': !isLead }">{{ this.$i18n.tc('quick_add_contact.account_team_label')}}</span>
              </div>
              <v-select name="contact[account_team]" id="contact_account_team" :options="configurations.account_team_options" v-model="account_team" :placeholder=" 'Please Select' " :clearable="accountTeamSelected">
                <template slot="option" slot-scope="option">
                  <div class="d-center">
                    {{ option.label }}
                  </div>
                </template>
                <template slot="selected-option" slot-scope="option">
                  <div class="selected d-center text-truncate fs-13">
                    {{ option.label }}
                  </div>
                </template>
                <template slot="no-options">{{$t('quick_add_contact.no_results_found')}}</template>
              </v-select>
              <error :errors='current_contact_object.errors.account_team'></error>
            </div> -->

            <div class="row row-col-space-20 p-t-15 m-b-15">
              <div class="col-md-6">
                <button
                  v-if="hide_continue_edit"
                  type="button"
                  class="btn btn-lg btn-block fs-16 fontmedium blue relative"
                  @click="hideContactFormModal"
                >
                  {{ $t("quick_add_contact.cancel") }}
                </button>
                <button
                  v-else
                  type="button"
                  :class="{ disabled: ajaxRequestSent }"
                  class="btn btn-lg fontmedium btn-block fs-14 btn-with-icon icon-right"
                  @click="requestCreateContact(true)"
                >
                  {{ $t("quick_add_contact.continue_editing") }}
                  <span class="btn-icon"
                    ><svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill="#646c9a"
                        fill-rule="evenodd"
                        d="M14.293 8l-4.147-4.146a.5.5 0 0 1 .708-.708l5 5a.5.5 0 0 1 0 .708l-5 5a.5.5 0 0 1-.708-.708L14.293 9H.5a.5.5 0 0 1 0-1h13.793z"
                      ></path></svg
                  ></span>
                </button>
              </div>
              <div class="col-md-6">
                <button
                  type="submit"
                  class="btn btn-lg btn-block fs-16 btn-primary fontmedium"
                  :disabled="ajaxRequestSent"
                  @click="validateNewContact(false)"
                >
                  {{ $t("quick_add_contact.submit") }}
                </button>
              </div>
            </div>
          </article>
        </form>
      </template>
    </si-modal>
    <div
      data-behavior="account-target-confirmation-modal-container"
      class="modal-480 modal-tm-130 modal-ghost-header"
    >
      <si-modal
        :modalOptions="targetConfirmationModalOptions"
        ref="accountTargetConfirmationModal"
        @onDismiss="removeShadowClass()"
        @onShown="addShadowClass()"
      >
        <template slot="modal-content">
          <div class="p-l-r-10 text-center word-wrap">
            <div class="circle-icon blue-bg">
              <i class="icon--bizdev"></i>
            </div>

            <div class="fs-22 dark p-t-b-20 fontmedium">
              {{ $t("quick_add_contact.targetConfirmationHeading") }}
            </div>

            <div class="account-detail-info  fs-16 light p-b-5 fontmedium">
              {{ current_contact_object.name }}
            </div>

            <hr class="extra-light-border" />

            <div class="row row-col-space-20 p-t-b-15">
              <div class="col-md-6">
                <button
                  type="button"
                  class="btn btn-lg btn-block fs-16 fontmedium blue relative"
                  @click="hideTargetConfirmationModal"
                >
                  <span
                    data-v-fdf14b5e=""
                    class="btn-icon-helper left-center m-l-20 size24 back-nav"
                  ></span>
                  {{ $t("accountQuickAddModal.cancel") }}
                </button>
              </div>
              <div class="col-md-6">
                <button
                  type="button"
                  class="btn btn-lg btn-primary btn-block fs-16 fontmedium"
                  :disabled="ajaxRequestSent"
                  @click="requestCreateContact()"
                >
                  {{ $t("accountQuickAddModal.save_text") }}
                </button>
              </div>
            </div>
          </div>
        </template>
      </si-modal>
    </div>

    <si-modal
      :modalOptions="emailReuseWarningOptions"
      ref="emailReuseWarningModal"
      @onDismiss="removeShadowClass()"
      @onShown="addShadowClass()"
    >
      <template slot="modal-content">
        <div class="p-l-r-10 text-center word-wrap">
          <div class="fs-22 dark p-t-b-20 fontmedium">
            {{ $t("quick_add_contact.email_reuse_warning") }}
          </div>

          <div class="row row-col-space-20 p-t-b-15">
            <div class="col-md-6">
              <button
                type="button"
                class="btn btn-lg btn-block fs-16 fontmedium blue relative"
                @click="hideEmailReuseWarningModal"
              >
                <span
                  data-v-fdf14b5e=""
                  class="btn-icon-helper left-center m-l-20 size24 back-nav"
                ></span>
                No
              </button>
            </div>
            <div class="col-md-6">
              <button
                type="button"
                class="btn btn-lg btn-primary btn-block fs-16 fontmedium"
                :disabled="ajaxRequestSent"
                @click="warningConfirmationCreate()"
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </template>
    </si-modal>
  </div>
</template>

<script>
import siModal from "components/shared/si_modal.vue";
import error from "components/shared/error.vue";
import recipientListOption from "components/shared/recipient_list_option.vue";
import vSelect from "vue-select";
import { searchableMixin } from "mixin/shared/searchable_mixin";
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import { createHelpers } from "vuex-map-fields";
import * as deepmerge from "deepmerge";

const { mapFields: mapQuickContactFields } = createHelpers({
  getterType: "getContact",
  mutationType: "createContact",
});

const { mapFields: mapOpportunityFields } = createHelpers({
  getterType: "getOpportunity",
  mutationType: "updateOpportunity",
});

export default {
  name: "QuickAddContact",
  props: [
    "contact_type_options",
    "account_team_options",
    "contact_type",
    "default_account",
    "hide_continue_edit",
    "disable_account",
    "hide_prospect_confirmation",
    "modal_id",
    "common_modal",
  ],
  mixins: [searchableMixin],
  data: function() {
    return {
      disable_account_field: false,
      modalOptions: {
        title: this.$t("quick_add_contact.modal_title"),
        modalClasses: "",
        modalContainerClass: "new-contact-modal",
        topBarColor: "",
        pickerOverFlowClass: "no-overflow",
        modalId: this.modal_id || "quick_add_contact_modal",
        keyboard: "true",
      },
      targetConfirmationModalOptions: {
        title: this.$t("quick_add_contact.modal_title"),
        modalContainerClass: "new-contact-modal",
        topBarColor: "",
        pickerOverFlowClass: "no-overflow",
        modalId: "quick_add_contact_modal",
      },
      emailReuseWarningOptions: {
        title: "WARNING",
        modalContainerClass: "new-contact-modal",
        topBarColor: "",
        pickerOverFlowClass: "no-overflow",
        modalId: "quick_add_contact_modal",
      },
      emptyContactObject: {
        name: "",
        email: "",
        phone: "",
        mobile_number: "",
        type: "Lead",
        lead_status: "new",
        quick_modal_object: true,
        account: {},
        errors: {},
      },
      validateContact: false,
      account: {},
      // account_team: {},
    };
  },
  mounted() {
    this.current_contact_object.type = this.contact_type;
    this.disable_account_field = this.disable_account;
    this.checkNewAccountParam();
  },
  methods: {
    hideContactFormModal: function() {
      this.$refs.contactModal.hideModal();
      this.$refs.accountTargetConfirmationModal.hideModal();
      this.$refs.emailReuseWarningModal.hideModal();
    },
    displayModalWithAccountSet: function(account_id) {
      this.fetchAccountData(account_id);
    },
    setAccountData: function(data) {
      this.default_account = data;
      this.disable_account_field = true;
      this.current_contact_object.type = "Prospect";
      this.displayModal();
    },
    checkNewAccountParam: function() {
      var new_account_id = new URLSearchParams(window.location.search).get(
        "account_id"
      );
      if (new_account_id) {
        this.preselected_account_id = new_account_id;
      }
    },
    validateNewContact: function(continue_editing) {
      this.validateQuickContact({
        apollo: this.$apollo,
        showAlert: this.$root.showAlert,
        componentObject: this,
        translation: this.$i18n,
        account_id: this.selectedValue,
        continue_editing: continue_editing,
      }).then((data) => {
        if (Object.keys(data.warnings).length > 0) {
          this.$refs.emailReuseWarningModal.displayModal();
        } else if (!Object.keys(data.contact.errors).length > 0) {
          this.requestCreateContact(continue_editing);
        }
      });
    },
    warningConfirmationCreate: function(continue_editing) {
      this.hideEmailReuseWarningModal();
      if (
        this.current_contact_object.type == "Lead" &&
        !this.hide_prospect_confirmation &&
        this.account &&
        Object.keys(this.account).length &&
        (this.account.target || this.account.is_target)
      ) {
        this.$refs.accountTargetConfirmationModal.displayModal();
      } else {
        this.requestCreateContact(continue_editing);
      }
    },

    requestCreateContact: function(continue_editing) {
      this.ajaxRequestSent = true;
      this.createQuickContact({
        apollo: this.$apollo,
        showAlert: this.$root.showAlert,
        componentObject: this,
        translation: this.$i18n,
        account_id: this.selectedValue,
        continue_editing: continue_editing,
      }).then((data) => {
        if (this.common_modal) {
          this.preselected_contact_id = data.contact.id;
        }
        this.$emit("onCreated", data.contact);
        if (data.listNames) {
          window.dispatchEvent(
            new CustomEvent("updateList", {
              detail: { listNames: data.listNames },
            })
          );
        }
      });
    },
    hideTargetConfirmationModal() {
      this.$refs.accountTargetConfirmationModal.hideModal();
    },
    hideEmailReuseWarningModal() {
      this.$refs.emailReuseWarningModal.hideModal();
    },
    setContactStatus: function($event) {
      var contactType = $($event.relatedTarget).attr("data-contact-type");
      if (contactType) {
        this.current_contact_object.type = contactType;
      }
      this.modalOptions.title = this.$t("quick_add_contact.modal_title", {
        title: this.current_contact_object.type,
      });
      this.account = this.default_account;
      this.$refs.nameInput.focus();
    },
    displayModal: function() {
      this.$refs.contactModal.displayModal();
    },
    removeShadowClass: function($event) {
      this.modalOptions.modalClasses = "";
    },
    addShadowClass: function($event) {
      this.modalOptions.modalClasses = "last-modal";
    },
    fetchAccountData: function(value) {
      this.fetchAccount({
        apollo: this.$apollo,
        account_id: String(value),
      }).then((data) => {
        this.setAccountData(data);
      });
    },
    resetContactForm: function() {
      this.current_contact_object = deepmerge({}, this.emptyContactObject);
      this.account = {};
      this.account_team = {};
      this.search_term = "";
      this.default_account = {};
      this.disable_account_field = false;
      this.validateContact = false;
      this.resetAccounts();
      this.clearSearch();
    },
    ...mapActions("ContactQuickAddWidget", [
      "createQuickContact",
      "validateQuickContact",
      "fetchAccount",
    ]),
    ...mapMutations("ContactQuickAddWidget", ["updateCurrentContactObject"]),
  },
  components: {
    siModal,
    vSelect,
    recipientListOption,
    error,
  },
  watch: {
    "current_contact_object.account": function(value) {
      if ($.isEmptyObject(value)) {
        this.account = {};
      } else {
        this.account = {
          name: value.name,
          id: value.id,
          target: value.is_target,
          favicon_image: value.favicon_image,
        };
      }
    },
    "current_contact_object.account_team": function(value) {
      if ($.isEmptyObject(value)) {
        this.account_team = {};
      } else {
        this.account_team = { label: value.name, value: value.id };
      }
    },
    disable_account: function(value) {
      this.disable_account_field = value;
    },
    preselected_account_id: function(value) {
      if (value && this.common_modal) {
        this.fetchAccountData(value);
      }
    },
  },
  computed: {
    contactType: function() {
      return this.current_contact_object && this.current_contact_object.type;
    },
    selectedValue: function() {
      return (this.account || {}).id;
    },
    // selectedAccountTeamValue: function() {
    //   return (this.account_team || {}).value;
    // },
    accountSelected: function() {
      return !$.isEmptyObject(this.account);
    },
    // accountTeamSelected: function() {
    //   return !$.isEmptyObject(this.account_team)
    // },
    prospectOrCustomer: function() {
      var contactType = this.current_contact_object.type;
      return contactType == "Prospect" || contactType == "Customer";
    },
    isLeadOrProspect: function() {
      var contactType = this.current_contact_object.type;
      return contactType == "Prospect" || contactType == "Lead";
    },
    isLead: function() {
      var contactType = this.current_contact_object.type;
      return contactType == "Lead";
    },
    ...mapQuickContactFields("ContactQuickAddWidget", [
      "current_contact_object",
      "contactCreateSuccessful",
      "ajaxRequestSent",
      "preselected_account_id",
    ]),
    ...mapOpportunityFields("QuickAddOpportunityWidget", [
      "preselected_contact_id",
    ]),
    ...mapState("GeneralConfiguration", ["configurations"]),
  },
};
</script>

<style lang="css" scoped></style>
