<template>
  <sweet-alert @input="deleteCurrentWorkOrder" :container_classes='containerClasses'>
    <template>
      <a :class="[{ disabled: ajaxRequestSent }, buttonClasses]" href='javascript:void(0)' :data-description="'#' + workOrder.db_id + ' - ' + workOrder.title" :data-sweet-alert-confirm='alertConfirmText' :data-heading='headingText' data-confirm-type='delete' data-vue-component=true data-confirm-button-text='Delete' data-cancel-button-text='Cancel'>Delete</a>
    </template>
  </sweet-alert>
</template>

<script>
  import { mapActions, mapState } from "vuex";
  import sweetAlert from 'components/shared/sweet_alert';

  export default {
    name: 'DeleteListButton',
    props: {
      workOrder: Object,
      buttonClasses: String,
      containerClasses: String
    },
    computed: {
      ...mapState('GeneralConfiguration', [
        'ajaxRequestSent'
      ]),
      alertConfirmText() {
        if(this.workOrder.is_work_order) {
          return 'Are you sure you want to delete this Work Order?';
        } else {
          return 'Are you sure you want to delete this Sub-Work Order?'
        }
      },
      headingText() {
        if(this.workOrder.is_work_order) {
          return 'Delete Work Order';
        } else {
          return 'Delete Sub-Work Order'
        }
      }
    },
    methods: {
      ...mapActions('WorkOrderQuickActions', [
        'deleteWorkOrder',
        'deleteSubWorkOrder'
      ]),
      deleteCurrentWorkOrder: function() {
        if (this.workOrder.is_work_order) {
          this.deleteWorkOrder({
            apollo: this.$apollo,
            workOrderId: this.workOrder.db_id,
            showAlert: this.$root.showAlert
          }).then(() => this.onSuccess());
        } else {
          this.deleteSubWorkOrder({
            apollo: this.$apollo,
            subWorkOrderId: this.workOrder.db_id,
            showAlert: this.$root.showAlert
          }).then(() => this.onSuccess());
        }
      },
      onSuccess: function() {
        this.$emit('onSuccess');
      }
    },
    components: {
      sweetAlert
    }
  }
</script>
