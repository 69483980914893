var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "form-control-group",
      attrs: { "data-behavior": "autofocusable-field-container" }
    },
    [
      _c("v-select", {
        ref: "selector",
        attrs: {
          options: _vm.user_options,
          placeholder: _vm.placeholder || "Select",
          clearable: _vm.clearable,
          value: _vm.selectedUser
        },
        on: {
          input: _vm.userSelected,
          "search:blur": function($event) {
            return _vm.$emit("focus_out")
          }
        },
        scopedSlots: _vm._u([
          {
            key: "selected-option",
            fn: function(option) {
              return [
                _c("recipient-list-option", { attrs: { recipient: option } })
              ]
            }
          },
          {
            key: "option",
            fn: function(option) {
              return [
                _c("recipient-list-option", { attrs: { recipient: option } })
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }