var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return this.task.timelogs && _vm.timelogs.length
    ? _c(
        "div",
        [
          _c(
            "div",
            {
              staticClass:
                "row time-log-info-header d-flex align-center black fs-14 ",
              attrs: { "data-behavior": "empty-time-log" }
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "fontmedium text-uppercase total-time-entry col-md-8",
                  attrs: { "data-behavior": "time-log-total-entries" }
                },
                [
                  _vm._v(
                    "\n      " + _vm._s(_vm.timelogs.length) + " ENTRIES\n    "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass:
                    "fs-18 fontbold total-time-log col-md-4 text-right ls-04",
                  attrs: { "data-behavior": "time-log-total-time-logged" }
                },
                [
                  _vm._v(
                    "\n      " + _vm._s(_vm.task.total_time_logs) + "\n    "
                  )
                ]
              )
            ]
          ),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _vm._l(_vm.groupBy("loggable_short_date")(_vm.timelogs), function(
            logs,
            value
          ) {
            return [
              _c(
                "div",
                { key: value },
                [
                  _c(
                    "div",
                    {
                      staticClass: "timelog-group-heading semibold light-gray",
                      attrs: {
                        "data-behavior": "timelog-group-heading",
                        "data-date-category": value
                      }
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm._f("dateAndMonth")(value)) +
                          "\n      "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _vm._l(logs, function(log) {
                    return [
                      _c(
                        "div",
                        {
                          key: log.id,
                          attrs: {
                            "data-behavior": "resource-container",
                            "data-id": log.id,
                            "data-form-url":
                              "/admin/work_orders/287/sub_work_orders/36/tasks/152/time_logs/422"
                          }
                        },
                        [
                          _c(
                            "article",
                            {
                              staticClass: "row no-side-margin time-log-row",
                              attrs: { "data-behavior": "resource-detail" }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "time-flex-col col-md-3 padding-horizontal-none fs-14 fontmedium black"
                                },
                                [
                                  _c(
                                    "span",
                                    [
                                      _c("user-table-grid-content", {
                                        attrs: { user_id: log.user.id }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "time-flex-col col-md-6",
                                  attrs: { "data-hook": "date" }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(log.description) +
                                      "\n            "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", {
                                staticClass: "hide",
                                attrs: {
                                  "data-attribute": "time-log-loggable-date",
                                  "data-value": "07/26/2019"
                                }
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "time-flex-col col-md-3 padding-horizontal-none text-right",
                                  attrs: {
                                    "data-attribute": "time-log-duration",
                                    "data-value": log.duration
                                  }
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "fs-14 dark-gray ls-04 semibold"
                                    },
                                    [_vm._v(_vm._s(log.formatted_duration))]
                                  ),
                                  _vm._v(" "),
                                  _vm.currentUser.owner_or_super_admin
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "dropdown card-header-dropdown clickable-item d-inline-block smart-dropdown-menu",
                                          attrs: { "data-menu": "dropdown" }
                                        },
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn btn-xs dropdown-toggle js-show-button",
                                              attrs: {
                                                type: "button",
                                                "data-toggle": "dropdown"
                                              }
                                            },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "dots top-2" },
                                                [
                                                  _c(
                                                    "svg",
                                                    {
                                                      attrs: {
                                                        xmlns:
                                                          "http://www.w3.org/2000/svg",
                                                        width: "18",
                                                        height: "4",
                                                        viewBox: "0 0 18 4"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "g",
                                                        {
                                                          attrs: {
                                                            fill: "#D8E1E9",
                                                            "fill-rule":
                                                              "evenodd"
                                                          }
                                                        },
                                                        [
                                                          _c("circle", {
                                                            attrs: {
                                                              cx: "9",
                                                              cy: "2",
                                                              r: "2"
                                                            }
                                                          }),
                                                          _vm._v(" "),
                                                          _c("circle", {
                                                            attrs: {
                                                              cx: "16",
                                                              cy: "2",
                                                              r: "2"
                                                            }
                                                          }),
                                                          _vm._v(" "),
                                                          _c("circle", {
                                                            attrs: {
                                                              cx: "2",
                                                              cy: "2",
                                                              r: "2"
                                                            }
                                                          })
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "ul",
                                            {
                                              staticClass:
                                                "dropdown-menu dropdown-menu-right"
                                            },
                                            [
                                              _c("li", [
                                                _c(
                                                  "a",
                                                  {
                                                    attrs: {
                                                      "data-behavior":
                                                        "time-log-edit",
                                                      href: "javascript:void(0)"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.editTimeLog(
                                                          log
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                      Edit\n                    "
                                                    )
                                                  ]
                                                )
                                              ]),
                                              _vm._v(" "),
                                              _c(
                                                "li",
                                                [
                                                  _c("destroy-button", {
                                                    attrs: {
                                                      data_confirm_button_text:
                                                        "Delete Permanently",
                                                      data_heading:
                                                        "Delete TimeLog",
                                                      data_sweet_alert_confirm:
                                                        "Are you sure, you want to delete time log entry of " +
                                                        log.formatted_duration +
                                                        " hours?",
                                                      "data-confirm-type":
                                                        "delete",
                                                      value: "Delete"
                                                    },
                                                    on: {
                                                      input: function($event) {
                                                        return _vm.deleteTimeLog(
                                                          log.id
                                                        )
                                                      }
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          !_vm.currentUser.owner_or_super_admin &&
                          log.editable &&
                          !_vm.task.is_completed
                            ? [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "time-edit-row text-center row no-side-margin",
                                    attrs: {
                                      "data-behavior":
                                        "action-buttons-container"
                                    }
                                  },
                                  [
                                    _c("div", { staticClass: "p-10 fs-13" }, [
                                      _c("span", [
                                        _vm._v(
                                          "This entry can be edited or deleted till " +
                                            _vm._s(log.editabled_till) +
                                            "."
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        [
                                          _c(
                                            "a",
                                            {
                                              staticClass:
                                                "fontbold text-underline",
                                              attrs: {
                                                "data-behavior":
                                                  "time-log-edit",
                                                href: "javascript:void(0)"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.editTimeLog(log)
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                    Edit\n                  "
                                              )
                                            ]
                                          ),
                                          _vm._v(
                                            "\n\n                  or\n                  "
                                          ),
                                          _c("destroy-button", {
                                            attrs: {
                                              data_confirm_button_text:
                                                "Delete Permanently",
                                              data_heading: "Delete TimeLog",
                                              data_sweet_alert_confirm:
                                                "Are you sure, you want to delete time log entry of " +
                                                log.formatted_duration +
                                                " hours?",
                                              "data-confirm-type": "delete",
                                              class_names:
                                                "pointer fontbold text-underline",
                                              value: "Delete"
                                            },
                                            on: {
                                              input: function($event) {
                                                return _vm.deleteTimeLog(log.id)
                                              }
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ])
                                  ]
                                )
                              ]
                            : _vm._e()
                        ],
                        2
                      )
                    ]
                  })
                ],
                2
              )
            ]
          })
        ],
        2
      )
    : _c("div", [_vm._m(1)])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "time-log-container" }, [
      _c(
        "div",
        {
          staticClass:
            "row no-side-margin fs-11 semibold text-uppercase task-time-header ls-1 ",
          attrs: { "data-behavior": "empty-time-log" }
        },
        [
          _c(
            "div",
            {
              staticClass: "time-flex-col col-md-3 padding-horizontal-none",
              attrs: { "data-hook": "name" }
            },
            [_vm._v("\n        Date\n      ")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "time-flex-col col-md-6",
              attrs: { "data-hook": "date" }
            },
            [_vm._v("\n        NOTES\n      ")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "time-flex-col col-md-3 padding-horizontal-none text-right",
              attrs: { "data-hook": "date" }
            },
            [_vm._v("\n        Time Logged\n      ")]
          )
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "full-width text-center" }, [
      _c("div", { staticClass: "no-result padding-horizontal-none" }, [
        _c("div", { staticClass: "text-center" }, [
          _c("i", {
            staticClass: "fa fa-list-alt large-icon-font",
            attrs: { "aria-hidden": "true" }
          })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "messgae text-center" }, [
          _vm._v("There are no time logs")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }