<template>
  <div @mouseover="loadContactData" ref="contactContent">
    <span class='tooltip-target d-block full-width' v-if="!loadedContactData">
      <span v-if="withNameWidth || showLabels" class="d-flex align-center">
        <a :href="contactShowPath(contactData)" class="lh-123 d-block clickable-item card-assign-user m-r-10">
          <img :src="contactData.profile_picture" class="img img-responsive">
        </a>
        <a :href="contactShowPath(contactData)" target="_blank" class="tootltip-name clickable-item d-block text-truncate" :class="[showLabels ? 'mw-100' : 'mw-135']">{{ contactData.name }}</a>
      </span>
      <span v-else>
        <a :href="contactShowPath(contactData)" class="lh-123 d-block clickable-item card-assign-user">
          <img :src="contactData.profile_picture" class="img img-responsive">
        </a>
      </span>
    </span>
    <v-popover placement='top' trigger='hover' popoverArrowClass='hide' v-else>
      <span v-if="withNameWidth || showLabels" class="d-flex align-center">
        <a :href="contactShowPath(contactData)" class="lh-123 d-block clickable-item card-assign-user m-r-10">
          <img :src="contactData.profile_picture" class="img img-responsive">
        </a>
        <a :href="contactShowPath(contactData)" target="_blank" class="tootltip-name clickable-item d-block text-truncate" :class="[showLabels ? 'mw-100' : 'mw-135']">{{ contactData.name }}</a>
      </span>
      <span v-else>
        <a :href="contactShowPath(contactData)" class="lh-123 d-block clickable-item card-assign-user">
          <img :src="contactData.profile_picture" class="img img-responsive">
        </a>
      </span>
      <template slot='popover'>
        <ContactTooltipHover
          :contactId="contactData.id"
        ></ContactTooltipHover>
      </template>
    </v-popover>
  </div>
</template>
<script>
  import { mapActions, mapState } from 'vuex';
  import { contactShowPath } from 'concerns/path_helper_methods.js';
  import ContactTooltipHover from 'components/shared/contacts/contact_tooltip_hover.vue';
  export default {
    name: 'ContactDataTooltip',
    props: {
      contactData: Object,
      showLabels: {
        type: Boolean,
        default: false
      }
    },
    data: function() {
      return {
        clientWidth: 0
      }
    },
    components: {
      ContactTooltipHover
    },
    methods: {
      ...mapActions('GeneralConfiguration', ['fetchContactData']),
      contactShowPath,
      loadContactData: function() {
        if(!this.loadedContactData) {
          window.disableAjaxLoader = true;
          this.fetchContactData({
            apollo: this.$apollo,
            contactId: this.contactData.id
          }).then(() => window.disableAjaxLoader = false);
        }
      },
      calculateWidth() {
        this.clientWidth = this.$refs.contactContent.clientWidth;
      },
      bindWindowEvents() {
        var _this = this;
        window.addEventListener('sidebarToggled', (event) => {
          _this.calculateWidth();
        });
      }
    },
    mounted() {
      this.calculateWidth();
      this.bindWindowEvents();
    },
    computed: {
      ...mapState('GeneralConfiguration', ['contactsData']),
      loadedContactData: function() {
        return this.contactsData[this.contactData.id];
      },
      withNameWidth() {
        return (this.clientWidth >= 170);
      },
    }
  }
</script>
