<template>
  <select :id='vid' :data-minimum-input-length='search_input_length' :data-request-param='request_param' :data-request-path='request_path' data-ajax-select-element='true' :data-placeholder='placeholder'>
    <slot></slot>
  </select>
</template>

<script>

  import { select2Mixin } from 'mixin/shared/select2_mixin';
  import '../../../assets/javascripts/admin/shared/select_options_populator.js'

  export default {
    name: 'ajaxSelect2',
    props: ['options', 'value', 'vid', 'disable_search', 'placeholder', 'dataConfirmationMessage', 'search_input_length', 'request_param', 'request_path', 'delay_val'],
    mixins: [select2Mixin],
    methods: {
      initData: function(options, value) {
        var vm = this,
          selectOptions = {};

        if(!this.disable_placeholder) {
          selectOptions['placeholder'] = this.placeholder || 'Please Select'
        }

        $(this.$el).select2({
          data: options,
          ajax: {
            type: "GET",
            delay: this.delay_val || 250,
            data: function (params) {
              var query = { q: {}};
              query['q'][vm.request_param] = params.term;
              query['page'] = params.page || 1;
              query['admin_api_token'] = gon.adminApiToken;
              return query;
            },
            url: this.request_path,
            dataType: 'json',
            processResults: function (data, params) {
              params.page = params.page || 1;
              return {
                results: data.results,
                pagination: {
                  more: (params.page * data.per_page) < data.total_records
                }
              };
            }
          },
          minimumInputLength: this.search_input_length || 3,
          dropdownParent: $(this.$el).closest('.form-group'),
          ...selectOptions
        }).on('change', function(){
          vm.$emit('input', $(this).val());
        }).val(value)
        .on('change', function (e, internal) {
          if(!internal) {
            vm.$emit('input', $(this).val())
          }
        })
        .trigger('change');
        this.handleSelectingUnselecting();
      },
      setOptions: function(options, value) {
        $(this.$el).select2('data', null);
        $(this.$el).select2('destroy');
        $(this.$el).html('');
        this.initData(options, value);
      }
    }
  }
</script>

<style scoped>
</style>
