'use strict';

/* globals unknownError, gon */

////////////////////////////////////////////////
// This JS is used by forms with attachments.
///////////////////////////////////////////////
// This JS integrates jquery-fileupload plugin with application forms


function AttachmentManager(inputs) {
  this.templateName = inputs.templateName;
  this.formSelector = inputs.formSelector;
  this.uploadForm = inputs.$uploadForm;
  this.progressBarSelector = inputs.progressBarSelector;
  this.listSelector = inputs.listSelector;
  this.submitElements = [$('[for="form-id"]'), this.uploadForm.find("input[type='submit']")];
  this.ajaxLoader = $('[data-behavior="ajax-loader"]');
  this.assetHosts = JSON.parse(gon.asset_hosts);
  this.uploadUrl = this.uploadForm.data('uploadUrl');
  this.splitshipSubmitBtn = this.uploadForm.find('[data-submit-btn="true"]')

  if (!this.uploadUrl.toLocaleLowerCase().endsWith('.json')) {
    this.uploadUrl += '.json'
  }


  if (this.assetHosts.length === 0) {
    this.assetHosts = window.location.host;
  }
}

AttachmentManager.prototype.init = function () {
  this.bindEvents();
  this.removeInvalidCheckbox();
  this.initView();
};

AttachmentManager.prototype.initView = function () {
  this.uploadForm.find('input[type=checkbox][value=""],input[type=checkbox][value="on"]').removeAttr('value');
};

AttachmentManager.prototype.removeInvalidCheckbox = function () {
  this.uploadForm.find('[type="checkbox"][checked="checked"]:not([value])').remove();
};

// To bind fileupload to all uploadable forms
AttachmentManager.prototype.bindEvents = function () {
  this.bindUploadEventToForm();
  this.bindFormEvents();
  this.bindCancelEvents();
};

AttachmentManager.prototype.bindCancelEvents = function () {
  var _this = this;
  this.uploadForm.on('click', '.cancel', function () {
    var $parentContainer = $(this).closest('[data-attachment-parent="true"]');
    var vueContainer = $parentContainer.find('[data-behavior="vue-file-container"]')
    if ($parentContainer.data('single-attachment')) {
      $parentContainer.find('input[value="' + $(this).closest('tr').data('id') + '"]').val('');
      $parentContainer.find('[data-remove-attachment-field="true"]').val("true");
    } else {
      $parentContainer.find('input[value="' + $(this).closest('tr').data('id') + '"]').prop('checked', false);
    }
    _this.splitshipSubmitBtn.attr('disabled', true);
    _this.enableForm();

    if (vueContainer.length) {
      vueContainer.val($(this).closest('tr').data('id')).trigger('vue-file-delete');
    }
  });
};

AttachmentManager.prototype.bindFormEvents = function () {
  $('body').on('submit', this.formSelector, function (e) {
    if ($(this).attr('disabled')) {
      e.preventDefault();
      return false;
    }
  });
};

// To disable form submit during upload
AttachmentManager.prototype.disableForm = function () {
  $('body').addClass('no-ajax-loader');
  this.ajaxLoader.addClass('ajax-send');
  $.each(this.submitElements, function (index, element) {
    $(element).attr('disabled', true);
  });
  this.uploadForm.attr('disabled', true);
  this.uploadForm.find('button[type=submit]').addClass('disabled');
};

// To enable form submit after upload
AttachmentManager.prototype.enableForm = function () {
  var inprogressElementsCount = this.uploadForm.find('tr.template-upload.fade.opacity-half:not(.with-error)').length;
  if (inprogressElementsCount <= 1) {
    $('body').removeClass('no-ajax-loader');
    this.ajaxLoader.removeClass('ajax-send');
    $('[for="form-id"]').removeAttr('disabled');
    this.uploadForm.find("input[type='submit']").removeAttr('disabled');
    this.uploadForm.find('button[type=submit]').removeClass('disabled');
    this.uploadForm.removeAttr('disabled');
  } else {
    $('body').addClass('no-ajax-loader');
    this.ajaxLoader.addClass('ajax-send');
  }
};

// This method will find the field containing the comma separated attachment ids
// And will append the uploaded attachment's id to that field's value
AttachmentManager.prototype.sendDataToVue = function (data) {
  var $file = data.result.files[0];
  var fileInputData = data.fileInput.data();
  if (fileInputData.parentSelector) {
    this.parentContainer = this.uploadForm.find('#' + data.fileInput.attr('id') + '[data-attachment-type="' + fileInputData.attachmentType + '"]').closest(fileInputData.parentSelector);
  } else {
    this.parentContainer = this.uploadForm;
  }
  var vueContainer = this.parentContainer.find('[data-behavior="vue-file-container"]')
  if (vueContainer.length) {
    $file['field'] = data.context[0];
    vueContainer.val($file.id).trigger('vue-file-add', $file);
  }
}

AttachmentManager.prototype.addValueToField = function (data) {
  var $file = data.result.files[0];
  var fileInputData = data.fileInput.data();
  if (fileInputData.parentSelector) {
    this.parentContainer = this.uploadForm.find('#' + data.fileInput.attr('id') + '[data-attachment-type="' + fileInputData.attachmentType + '"]').closest(fileInputData.parentSelector);
  } else {
    this.parentContainer = this.uploadForm;
  }
  if (this.parentContainer.data('single-attachment')) {
    this.parentContainer.find(fileInputData.fileFieldSelector).val($file.id);
  } else {
    this.fileField = this.parentContainer.find(fileInputData.fileFieldSelector + '[type="checkbox"]:not([value])');
    this.parentContainer.find('table.attachment-table').removeClass('hide');
    if (!this.parentContainer.find('input[type="checkbox"][value="' + $file.id + '"]').length) {
      var $copy = $(this.fileField.clone());
      var container = this.parentContainer.find('[data-behavior="attachment-field-container"]');
      container.append($copy);
      $copy.prop('checked', true).val($file.id);
    }
  }
};

// This updates the upload progress percentage
AttachmentManager.prototype.updateProgressBar = function (data) {
  var $progressBar = data.context.find(this.progressBarSelector);
  var progressValue = parseInt(data.loaded / data.total * 100, 10);
  $progressBar.text(progressValue + "%");
  $progressBar.data('progress', progressValue);
};

// This will remove the destroyed attachment's id from the view
AttachmentManager.prototype.removeValueFromField = function (data) {
  var $removableField = $(data.context);
  var removableFieldData = $removableField.data();
  var id = removableFieldData.id;
  this.parentContainer = $removableField.closest('[data-attachment-parent="true"]');
  this.fileField = this.parentContainer.find('input[type="checkbox"][value="' + id + '"]');
  if (this.fileField.length) {
    this.fileField.prop('checked', false);
  }
};

// This will find the files container with respect to the selected file input
// This is necessary in case of multiple type attachment forms
AttachmentManager.prototype.getFilesContainer = function (fileInput) {
  var fileInputData = fileInput.data();
  if (fileInputData.parentSelector) {
    this.parentContainer = this.uploadForm.find('#' + fileInput.attr('id') + '[data-attachment-type="' + fileInputData.attachmentType + '"]').closest(fileInputData.parentSelector);
  } else {
    this.parentContainer = this.uploadForm;
  }
  var attachmentType = fileInputData.attachmentType;
  var selectedContainer = null;
  $.each(this.parentContainer.find('tbody.files'), function (index, container) {
    var $container = $(container);
    if ($container.data().attachmentType === attachmentType) {
      selectedContainer = $container;
    }
  });
  return selectedContainer;
};

AttachmentManager.prototype.getRequestURL = function () {
  var host = this.assetHosts[Math.floor(Math.random() * this.assetHosts.length)];
  return window.location.protocol + "//" + host + this.uploadUrl;
};

// This method binds upload event to a form
AttachmentManager.prototype.bindUploadEventToForm = function () {
  var _this = this;

  $.widget('blueimp.fileupload', $.blueimp.fileupload, {
    // This code has been overriden from jquery-fileupload/jquery.fileupload-ui.js
    _renderDownload: function (files) {
      var template = this._renderTemplate(
        this.options.downloadTemplate,
        files
      ).find('a[download]').each(this._enableDragToDesktop).end();
      // The following line has been added by vinsol
      _this.enableForm();
      return template;
    },
  });

  this.uploadForm.fileupload({
    dropZone: null,
    maxFileSize: parseInt(_this.uploadForm.data('maxfilesize')),
    autoUpload: true,
    url: this.getRequestURL(),
    // url: this.uploadUrl,
    type: 'POST',
    dataType: 'json',
    xhrFields: {
      withCredentials: true
    },
    progressInterval: 500,
    prependFiles: true,
    messages: {
      unknownError: unknownError
    },
    start: function () {
      _this.disableForm();
    },
    add: function (e, data) {
      data.url = _this.getRequestURL();

      // Overriden from basic-ui.js
      if (e.isDefaultPrevented()) {
        return false;
      }
      var $this = $(this),
        that = $this.data('blueimp-fileupload') ||
        $this.data('fileupload'),
        options = that.options;
      data.context = that._renderUpload(data.files)
        .data('data', data)
        .addClass('processing');
      // The following code has been added by vinsol
      that.options.filesContainer = _this.getFilesContainer($(e.delegatedEvent.target));
      options = that.options;
      data.context.filesContainer = that.options.filesContainer;
      data.context.filesContainer.closest('table').removeClass('hide');
      var $parentContainer = data.context.filesContainer.closest('[data-attachment-parent="true"]');
      var vueFileContainer = that.options.filesContainer.closest("[data-behavior='vue-file-container']");
      if (vueFileContainer) {
        vueFileContainer.trigger('vue-file-start')
      }
      if ($parentContainer.data('single-attachment')) {
        $parentContainer.find('.cancel').click();
        $parentContainer.find('[data-remove-attachment-field="true"]').val(false);
      }
      // The above code has been added by vinsol
      options.filesContainer[
        options.prependFiles ? 'prepend' : 'append'
      ](data.context);
      that._forceReflow(data.context);
      that._transition(data.context);
      data.process(function () {
        return $this.fileupload('process', data);
      }).always(function () {
        data.context.each(function (index) {
          $(this).find('.size').text(
            that._formatFileSize(data.files[index].size)
          );
        }).removeClass('processing');
        that._renderPreviews(data);
      }).done(function () {
        _this.splitshipSubmitBtn.removeAttr('disabled');
        data.context.find('.start').prop('disabled', false);
        if ((that._trigger('added', e, data) !== false) &&
          (options.autoUpload || data.autoUpload) &&
          data.autoUpload !== false) {
          data.submit();
        }
      }).fail(function () {
        if (data.files.error) {
          data.context.each(function (index) {
            var error = data.files[index].error;
            if (error) {
              $(this).find('.error').text(error);
              $(this).addClass('with-error');
              $(this).find('[data-behavior="progress-bar"]').remove();
            }
          });
        }
      });
    },
    destroy: function (e, data) {
      // Overriden from basic-ui.js
      if (e.isDefaultPrevented()) {
        return false;
      }
      var that = $(this).data('blueimp-fileupload') ||
        $(this).data('fileupload'),
        removeNode = function () {
          $(data.context).remove();
          that._trigger('destroyed', e, data);
          // The following code has been commented by vinsol
          // that._transition(data.context).done(function () {});
        };
      if (data.url) {
        data.dataType = data.dataType || that.options.dataType;
        $.ajax(data).done(function () {
          removeNode();
        }).fail(function () {
          that._trigger('destroyfailed', e, data);
        });
      } else {
        removeNode();
      }
    }
  }).bind('fileuploadprogress', function (event, data) {
    // When file upload is being progressed
    _this.updateProgressBar(data);
  }).bind('fileuploaddone', function (event, data) {
    // When file upload is complete
    var fileID = JSON.parse(data.xhr().responseText).files[0].id;
    if (fileID) {
      _this.addValueToField(data);
    }
    _this.sendDataToVue(data);
  }).bind('fileuploaddestroy', function (event, data) {
    // To remove the id from the list of attachment_ids of the form
    _this.removeValueFromField(data);
  }).bind('fileuploaddestroyed', function () {
    // When file is destroyed completely enable Form
    _this.enableForm();
  }).bind('fileuploadsubmit', function (event, data) {
    _this.disableForm();
    // When file upload submission is called
    // To send the type of attachment with the form data
    // data.formData = { attachment_type: data.fileInput.data().attachmentType, authenticity_token: gon.CSRF_TOKEN };

    // Now we don't need to pass access token as we are submitting attachments on assets hosts. and CSRF token check is skipped in controller
    data.formData = {
      attachment_type: data.fileInput.data().attachmentType
    };
  });
};

$(function () {
  $.each($('[data-attachment-form=true]'), function (index, element) {
    var attachmentManager = new AttachmentManager({
      templateName: 'attachment-template',
      formSelector: '[data-attachment-form=true]',
      $uploadForm: $(element),
      progressBarSelector: '[data-behavior="progress-bar"]',
      listSelector: '[data-behavior="files-container"]'
    });
    attachmentManager.init();
  });

  $(document).on('nested:fieldAdded vuefile:added', function (event) {
    var field = event.field || event.target,
      form = $(field.closest('form'));

    if (form.data('blueimpFileupload')) {
      form.find('input[type=file]').unbind('change');
      form.fileupload('destroy');
      var attachmentManager = new AttachmentManager({
        templateName: 'attachment-template',
        formSelector: '[data-attachment-form=true]',
        $uploadForm: form,
        progressBarSelector: '[data-behavior="progress-bar"]',
        listSelector: '[data-behavior="files-container"]'
      });
      attachmentManager.init();
    }
  });

  $(document).on('formcloned', function (event) {
    var form = $(event.target.closest('form'));
    form.find('input[type=file]').unbind('change');
    form.fileupload('destroy');
    var attachmentManager = new AttachmentManager({
      templateName: 'attachment-template',
      formSelector: '[data-attachment-form=true]',
      $uploadForm: form,
      progressBarSelector: '[data-behavior="progress-bar"]',
      listSelector: '[data-behavior="files-container"]'
    });
    attachmentManager.init();
  });
});
