var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "select",
    {
      attrs: {
        id: _vm.vid,
        "data-minimum-input-length": _vm.search_input_length,
        "data-request-param": _vm.request_param,
        "data-request-path": _vm.request_path,
        "data-ajax-select-element": "true",
        "data-placeholder": _vm.placeholder
      }
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }