var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "symbol",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 22 20",
        id: "sprite-download"
      }
    },
    [
      _c("path", {
        attrs: {
          fill: "#5D71FF",
          "fill-rule": "evenodd",
          d:
            "M16.5 8.1V8c0-2.8-2.42-5-5.5-5-2.75 0-5.06 1.8-5.39 4.3-1.98.6-3.41 2.2-3.41 4.2C2.2 14 4.4 16 7.15 16h8.25c2.42 0 4.4-1.8 4.4-4 0-1.9-1.43-3.4-3.3-3.9zM11 14l-4.4-4h3.3V7h2.2v3h3.3L11 14z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }