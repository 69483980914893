import Vue from 'vue/dist/vue.esm';
import Vuex from 'vuex';
import { Modules } from './modules.js';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: Modules
});

export default store;
