<template>
  <div data-behavior="shared-event-modal-container">
    <si-modal :modalOptions="modalOptions" @onDismiss="onDismiss">
      <template slot="modal-content">
        <form class="form" v-if="showForm">
          <article class="new-event-form-container">

            <div class="form-group">
              <input class="create-event-input" v-model="eventObject.title" type="text" :placeholder="$t('eventModal.addTitle')" autocomplete="off">
              <error :errors='eventObject.errors.title || []'></error>
            </div>

            <div class="event-create-timing d-flex">
              <!-- Start Date -->
              <div class="event-dt-col event-date-col">
                <div class="form-control-group">
                  <!-- Date Field -->
                  <date-field v-model="eventObject.start_date" :not_format="!eventObject.formatDate"></date-field>
                  <error :errors='eventObject.errors.start_date || []'></error>
                </div>
              </div>

              <div class="event-dt-col event-time-col" v-if="!hideTimeFields">
                <div class="form-control-group">
                  <!-- Time Field -->
                  <time-picker :field_name="'event[start_time]'" :field_value="eventObject.start_time" :element_id="'event_start_time'" :placeholder="''" @time_value_update="eventObject.start_time = $event"></time-picker>
                  <error :errors='eventObject.errors.start_time || []'></error>
                </div>
              </div>

              <div class="event-dt-col time-bar">
                <div class="time-to-text p-t-10 m-t-2 fs-15">{{$t('default_calendar_range_label')}}</div>
              </div>

              <div class="event-dt-col event-date-col">
                <div class="form-control-group">
                  <!-- Date Field -->
                  <date-field v-model="eventObject.end_date" :not_format="!eventObject.formatDate"></date-field>
                  <error :errors='eventObject.errors.end_date || []'></error>
                </div>
              </div>

              <div class="event-dt-col event-time-col dt-picker-right white-space-normal" v-if="!hideTimeFields">
                <div class="form-control-group">
                  <!-- Time Field -->
                  <time-picker :field_name="'event[end_time]'" @time_value_update="eventObject.end_time = $event" :field_value="eventObject.end_time" :element_id="'event_end_time'" :placeholder="''"></time-picker>
                  <error :errors='eventObject.errors.end_time || []'></error>
                </div>
              </div>
            </div>

            <div class="row no-side-margin p-b-10">
              <label class="si-checkbox pull-left">
                <input type="checkbox" v-model="eventObject.all_day">
                <span class="helper"></span>
                <span class="helper-label permission-name fs-13 text-secondary">
                  {{ $t('event.all_day') }}
                </span>
              </label>
            </div>
            <hr>
            <!-- AutoComplete -->
            <div class="row no-side-margin">
              <div class="fs-12 p-t-b-10">{{$t('eventModal.invitee_email_info')}}</div>
                <auto-complete
                  url="/admin/events/invitee_search.json"
                  id="events_invitee_autocomplete"
                  anchor="name"
                  param="q[name_or_email_cont]"
                  :placeholder="$t('autoComplete.invitee_placeholder')"
                  :ref="'autocomplete'"
                  :min="3"
                  :classes="{wrapper: 'form-control-group select2-placeholder placholder-add-guest', input: 'form-control place-holder ui-autocomplete-input height-40 fs-13', list:'ui-menu ui-widget ui-widget-content ui-autocomplete ui-front manual-list', item:'ui-menu-item'}"
                  :onShouldRenderChild="renderAutoCompleteListItems"
                  :process="autoCompleteFilterData"
                  :onInput="autoCompleteInputListener"
                  :customParams="{ page_length : 5 }"
                  :onBeforeAjax="onAutoCompleteRequest"
                  :onAjaxLoaded="onAutoCompleteResponse"
                  :onSelect="onInviteeSelectFromAutoCompleteList">
                </auto-complete>
                <div class="text-muted fs-11 p-t-10">{{$t('autoComplete.default_hint')}}</div>
            </div>

            <!-- Inviteeeeeees  -->
            <invitee-list-item :invitees="invitees" :removeInviteeHandler="removeInvitee"></invitee-list-item>
            <!-- ___________________________ -->
            <hr>
            <div class="create-event-action row no-side-margin">
              <button type="button" class="btn btn-si btn-si-sm fontregular" @click="findTimeListener">{{$t('eventModal.findTimeActionLabel')}}</button>
              <button type="button" class="btn btn-si-sm btn-primary m-l-10" @click="submitEventListener">{{$t('eventModal.addEventActionLabel')}}</button>
            </div>

          </article>
        </form>
      </template>
    </si-modal>
  </div>
</template>

<script>
import siModal from './si_modal.vue';
import timePicker from '../global/time_picker.vue';
import dateField from './date_field.vue';
import error from './error.vue';
import inviteeListItem from './invitee_list_item.vue';
import autoComplete from 'vue2-autocomplete-js';
import axios from 'axios';
import {} from 'concerns/axios';

export default {
  data() {
    return {
      modalOptions: {
        title: this.$t('eventModal.title'),
        modalContainerClass : 'new-event-modal',
        topBarColor:''
      },
      eventObject: null,
      invitees : [],
      dateWatcher : null,
      hideTimeFields:false,
      showForm: false
    };
  },
  components: {
    siModal, timePicker, dateField, autoComplete, error, inviteeListItem
  },
  watch: {
    'eventObject.all_day'(value){
      this.hideTimeFields = value;
    },
    invitees(invitees){
      let inviteeIds = [];
      invitees.map(({value}) => {
        inviteeIds.push(value);
      });
      this.eventObject.invitee_list = inviteeIds.join(", ");
    }
  },
  methods: {
    onDismiss(){
      if(this.eventObject){
        this.showForm = false;
        // Watcher Removal
        this.dateWatcher();
      }
    },
    findTimeListener() {
      var queryString = $.param({event: this.eventObject, authenticity_token : this.eventObject.authenticity_token, utf8: '✓', eventable_id: this.eventObject.eventable_id, eventable_type: this.eventObject.eventable_type });
      window.location.href = "/admin/events/new?" + queryString + "&redirect_uri=" + window.location.pathname;
    },
    submitEventListener() {
      var _this = this;
      var url = this.eventObject.url ? this.jsonifyUrl(this.eventObject.url) : '/admin/events.json';
      $(document).trigger('ajax:send');

      axios.post(url, {event: this.eventObject, authenticity_token : this.eventObject.authenticity_token, utf8: '✓', eventable_id: this.eventObject.eventable_id, eventable_type: this.eventObject.eventable_type })
        .then(response => {
          $('[data-behavior=shared-event-modal-container]').find('[data-card-modal=true]').modal('hide');
          _this.$root.showAlert('alert-success', response.data.message);
          $(document).trigger('ajax:success');
          window.dispatchEvent(new CustomEvent('newEventCreated', {
            detail: {
              eventable_type: response.data.event.eventable_type,
              eventable_id: String(response.data.event.eventable_id)
            }
          }));
        }).catch(e => {
          $(document).trigger('ajax:complete');
          _this.eventObject.errors = e.response.data.event.errors || {};
        });
    },
    removeInvitee(value){
      this.invitees = this.invitees.filter((invitee) => {
        return value != invitee.value;
      })
    },
    initInviteeList(){
      var _this = this;

      axios.get('/admin/events/invitee_search.json?invitee_list=')
      .then(response => {
        _this.invitees = response.data;
        $(document).trigger('ajax:success');
      }).catch(err => {
        $(document).trigger('ajax:success');
        console.log(err);
      });
    },
    jsonifyUrl(url){
      if(url.includes('?')){
        return url.split('?').join('.json?');
      }else{
        return url += '.json';
      }
    },
    buildExternalEmailItem(email){
      return {
        name: email,
        val: email,
        value: email,
        email: email,
        imageUrl: new GravatarGenerator().getImageUrl(email, email),
        response: '',
        responseClass: 'response-awaiting',
        type: null
      }
    },
    autoCompleteInputListener(value){
      if(value.trim().length < 3){
        this.$refs.autocomplete.showList = false;
      }
    },
    autoCompleteFilterData(data){
      var inviteeValues = this.invitees.map(inv => inv.value);
      var inputValue = this.$refs.autocomplete.type;

      // External Email Item
      if ((data.length === 0) && !(inviteeValues.includes(inputValue.toLowerCase())) && (EMAIL_REGEX.test(inputValue))) {
        data.push(this.buildExternalEmailItem(inputValue.toLowerCase()));
      }

      return data.filter((invitee) => {
        return !(inviteeValues.includes(invitee.value));
      });
    },
    onInviteeSelectFromAutoCompleteList(invitee){
      if(!this.inviteeEmails.includes(invitee.email.toLowerCase())){
        this.invitees.push(invitee);
      }
    },
    renderAutoCompleteListItems(data){
      return `
      <div class="recipient-list-option d-flex align-center justify-between ui-menu-item-wrapper">
        <div>
          <div class="dark-gray name">
            <span class="fontmedium">${data.name}</span><small> ${data.type || ''}</small>
          </div>
          <div class="fs-12">
            ${data.email}
          </div>
        </div>
        <div class="user-img-block">
          <span class="card-assign-user pointer">
            <img class="img-responsive assign-user-image" src="${data.imageUrl}">
          </span>
        </div>
      </div>`;
    },
    onAutoCompleteRequest(){
      $(document).trigger('ajax:send');
    },
    onAutoCompleteResponse(){
      $(document).trigger('ajax:success');
    }
  },
  mounted() {
    var _this = this;
    $('[data-behavior=shared-event-modal-container]').on('event-modal-toggle', function(event, options) {
      if(options.formatDate === undefined) {
        options.formatDate = true;
      }
      _this.eventObject = {
        title: '',
        invitee_list : '',
        invitee_list_autocomplete :'',
        errors: {},
        ...options
      };
      _this.initInviteeList();
      _this.modalOptions.topBarColor = _this.eventObject.topBarColor;
      // Dynamic Watcher Adding
      _this.dateWatcher = _this.$watch('eventObject.start_date', function(changedDate) {
        this.eventObject.end_date = changedDate;
      });
      _this.showForm = true;
      var eventModal = $('[data-behavior=shared-event-modal-container]').find('[data-card-modal=true]');

      if(!(eventModal.data('bs.modal') || {}).isShown){
        $('[data-behavior=shared-event-modal-container]').find('[data-card-modal=true]').modal('show');
      }
    });
  },
  computed: {
    inviteeEmails: function() {
      return this.invitees.map((invitee)=> invitee.email);
    }
  }
};
</script>
