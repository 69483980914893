var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "contactContent" },
    [
      _c(
        "v-popover",
        {
          attrs: {
            placement: "top",
            trigger: "hover",
            popoverArrowClass: "hide"
          }
        },
        [
          _c("span", { staticClass: "d-flex align-center" }, [
            _c(
              "a",
              {
                staticClass:
                  "tootltip-name clickable-item d-block text-truncate",
                attrs: {
                  href: _vm.contactShowPath(_vm.contact_object),
                  target: "_blank"
                }
              },
              [_vm._v(_vm._s(_vm.contact_object.name))]
            )
          ]),
          _vm._v(" "),
          _c(
            "template",
            { slot: "popover" },
            [
              _c("ContactHoverDataTooltip", {
                attrs: { contact_object: _vm.contact_object }
              })
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }