<template>

</template>

<script>
  import { mapMutations } from "vuex";

  export default {
    name: 'ManageTopNavLinks',
    props: ['links'],
    mounted() {
      this.insertTopNavLinks();
    },
    methods: {
      ...mapMutations('GeneralConfiguration', ['updateTopNavLinks']),
      insertTopNavLinks() {
        this.updateTopNavLinks(this.links);
      }
    }
  }
</script>
