<template>
  <div class="invitees-tag-list">
    <ul data-behavior="select-list">
      <li class="d-flex align-center" v-for="(invitee,index) in invitees" :key="index">
        <div>
          <span class="card-assign-user pointer clickable-item">
            <img class="img-responsive assign-user-image" :src="invitee.imageUrl">
          </span>
        </div>
        <div class="invitee-detail-col fs-13 text-truncate">
          <div class="col-md-9 col-sm-9">
            <div class="black-2">
              <span class="d-block text-truncate">
                {{ invitee.name }}
                <span class="fs-11 semibold" v-if="index == 0">({{$t('eventModal.organizer')}})</span>
              </span>
            </div>
            <div class="text-truncate">
              <span class=" fs-11">{{ invitee.email }}</span>
            </div>
          </div>
          <div class="col-md-3 col-sm-3 p-t-5 m-t-2 text-right">
            <span :class="invitee.responseClass"></span>
          </div>
          <a v-if="invitee.responseClass == 'response-awaiting'" @click="removeInviteeHandler(invitee.value)" class="destroy-tag-selected"></a>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ['invitees', 'removeInviteeHandler']
};
</script>
