<template>
</template>

<script>
  import { mapMutations, mapActions } from "vuex";

  export default {
    props: {
      static_permission: {
        type: Object,
      },
    },
    methods: {
      makePermissionSystemDynamic: function () {
        this.mutateDynamicPermissions({ newPermissions: {}, getDynamicPermission: this.getDynamicPermission, mutateDynamicPermissions: this.mutateDynamicPermissions, $apollo: this.$apollo })
      },
      loadStaticPermission: function () {
        this.updatePermissions(this.static_permission);
      },
      ...mapActions("Permission", ["updatePermissions", "getDynamicPermission"]),
      ...mapMutations("Permission", ["mutateDynamicPermissions"]),
    },
    mounted() {
      this.loadStaticPermission();
      this.makePermissionSystemDynamic();
      window.addEventListener('resetPermissionSystem', () => {
        this.mutateDynamicPermissions({ resetAll: true, newPermissions: {}, getDynamicPermission: this.getDynamicPermission, mutateDynamicPermissions: this.mutateDynamicPermissions, $apollo: this.$apollo })
      })
    },
  };
</script>
