var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    {
      class: _vm.classNames,
      attrs: {
        "data-confirm-button-text": _vm.data_confirm_button_text,
        "data-heading": _vm.data_heading,
        "data-vue-component": "true",
        "data-confirm-type": "delete",
        disabled: _vm.disabled,
        "data-sweet-alert-confirm": _vm.data_sweet_alert_confirm,
        "data-description": _vm.data_description
      },
      on: {
        click: function($event) {
          return _vm.$emit("delete_started")
        }
      }
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }