<template>
  <span>
    <span v-if="isUpdated">
      <span class="d-inline-block fs-12">{{ updateText }}</span>
      <div class="m-l-22 wo-tab-detail">
        <div class="dark tooltip-inline">
          <user-popover-tooltip :user_id="user_changes_ids[1]"></user-popover-tooltip>
          <span class="fontmedium">›
            <user-popover-tooltip :user_id="user_changes_ids[0]"></user-popover-tooltip>
          </span>
        </div>
      </div>
    </span>
    <span v-else>
      <span class="d-inline-block fs-12">{{ addText }}</span>
      <div class="m-l-22 wo-tab-detail dark">
        <user-popover-tooltip :user_id="user_changes_ids[0]"></user-popover-tooltip>
      </div>
    </span>
  </span>
</template>

<script>
  import UserPopoverTooltip from 'components/shared/users/user_popover_tooltip.vue';

  export default {
    name: 'userDetail',
    props: [
      'user_changes_ids',
      'updateText',
      'addText'
    ],
    computed: {
      isUpdated: function() {
        if(this.user_changes_ids.length == 2) {
          return true;
        } else {
          return false;
        }
      }
    },
    components: {
      UserPopoverTooltip
    }
  };
</script>

<style lang="css" scoped>
</style>
