<template>
  <span v-if='author_id'>
    <span class="task-user">
      <user-profile-pic-with-tooltip :user_id="author_id"/>
    </span>
    <span class="user-color fontmedium fs-13 d-inline-block m-b-5 tooltip-inline">
      <user-popover-tooltip :user_id="author_id"></user-popover-tooltip>
    </span>
  </span>
</template>

<script>
  import UserProfilePicWithTooltip from 'components/shared/users/user_profile_pic_with_tooltip.vue';
  import UserPopoverTooltip from 'components/shared/users/user_popover_tooltip.vue';

  export default {
    name: 'userPic',
    props: ['author_id'],
    components: {
      UserProfilePicWithTooltip,
      UserPopoverTooltip
    }
  };
</script>

<style lang="css" scoped>
</style>
