var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "contactContent", on: { mouseover: _vm.loadContactData } },
    [
      !_vm.loadedContactData
        ? _c("span", { staticClass: "tooltip-target d-block full-width" }, [
            _vm.withNameWidth || _vm.showLabels
              ? _c("span", { staticClass: "d-flex align-center" }, [
                  _c(
                    "a",
                    {
                      staticClass:
                        "lh-123 d-block clickable-item card-assign-user m-r-10",
                      attrs: { href: _vm.contactShowPath(_vm.contactData) }
                    },
                    [
                      _c("img", {
                        staticClass: "img img-responsive",
                        attrs: { src: _vm.contactData.profile_picture }
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass:
                        "tootltip-name clickable-item d-block text-truncate",
                      class: [_vm.showLabels ? "mw-100" : "mw-135"],
                      attrs: {
                        href: _vm.contactShowPath(_vm.contactData),
                        target: "_blank"
                      }
                    },
                    [_vm._v(_vm._s(_vm.contactData.name))]
                  )
                ])
              : _c("span", [
                  _c(
                    "a",
                    {
                      staticClass:
                        "lh-123 d-block clickable-item card-assign-user",
                      attrs: { href: _vm.contactShowPath(_vm.contactData) }
                    },
                    [
                      _c("img", {
                        staticClass: "img img-responsive",
                        attrs: { src: _vm.contactData.profile_picture }
                      })
                    ]
                  )
                ])
          ])
        : _c(
            "v-popover",
            {
              attrs: {
                placement: "top",
                trigger: "hover",
                popoverArrowClass: "hide"
              }
            },
            [
              _vm.withNameWidth || _vm.showLabels
                ? _c("span", { staticClass: "d-flex align-center" }, [
                    _c(
                      "a",
                      {
                        staticClass:
                          "lh-123 d-block clickable-item card-assign-user m-r-10",
                        attrs: { href: _vm.contactShowPath(_vm.contactData) }
                      },
                      [
                        _c("img", {
                          staticClass: "img img-responsive",
                          attrs: { src: _vm.contactData.profile_picture }
                        })
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass:
                          "tootltip-name clickable-item d-block text-truncate",
                        class: [_vm.showLabels ? "mw-100" : "mw-135"],
                        attrs: {
                          href: _vm.contactShowPath(_vm.contactData),
                          target: "_blank"
                        }
                      },
                      [_vm._v(_vm._s(_vm.contactData.name))]
                    )
                  ])
                : _c("span", [
                    _c(
                      "a",
                      {
                        staticClass:
                          "lh-123 d-block clickable-item card-assign-user",
                        attrs: { href: _vm.contactShowPath(_vm.contactData) }
                      },
                      [
                        _c("img", {
                          staticClass: "img img-responsive",
                          attrs: { src: _vm.contactData.profile_picture }
                        })
                      ]
                    )
                  ]),
              _vm._v(" "),
              _c(
                "template",
                { slot: "popover" },
                [
                  _c("ContactTooltipHover", {
                    attrs: { contactId: _vm.contactData.id }
                  })
                ],
                1
              )
            ],
            2
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }