var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _vm.word_set.added
        ? [
            _c("ins", { staticClass: "differ" }, [
              _vm._v(_vm._s(_vm.word_set.value))
            ])
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.word_set.removed
        ? [
            _c("del", { staticClass: "differ" }, [
              _vm._v(_vm._s(_vm.word_set.value))
            ])
          ]
        : _vm._e(),
      _vm._v(" "),
      !(_vm.word_set.added || _vm.word_set.removed)
        ? [_vm._v("\n    " + _vm._s(_vm.word_set.value) + "\n  ")]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }