var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "ajax-loading si-page-loader",
        attrs: { id: "loading", "data-behavior": "ajax-loader" }
      },
      [
        _c("div", { staticClass: "bar bar-1" }),
        _vm._v(" "),
        _c("div", { staticClass: "bar bar-2" }),
        _vm._v(" "),
        _c("div", { staticClass: "bar bar-3" }),
        _vm._v(" "),
        _c("div", { staticClass: "bar-complete bar-1" })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }