var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "chat-comment-container" }, [
    _c("div", { staticClass: "si-chat" }, [
      _c("div", { staticClass: "si-chat-body" }, [
        _c(
          "form",
          {
            staticClass: "in-focus",
            on: {
              submit: function($event) {
                $event.preventDefault()
                return _vm.submitComment($event)
              },
              keydown: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                if (
                  $event.ctrlKey ||
                  $event.shiftKey ||
                  $event.altKey ||
                  $event.metaKey
                ) {
                  return null
                }
                $event.preventDefault()
                return _vm.submitComment($event)
              }
            }
          },
          [
            _c(
              "div",
              {
                staticClass: "relative form-group-textarea",
                attrs: { "data-checkbox-togglable-container": "true" }
              },
              [
                _c(
                  "div",
                  { staticClass: "form-group vue-chat-box" },
                  [
                    _c(
                      "at-ta",
                      {
                        ref: "textMemberTagged",
                        attrs: {
                          members: _vm.taggableMembers,
                          at: "#",
                          suffix: "-"
                        }
                      },
                      [
                        _c(
                          "at-ta",
                          {
                            ref: "textMember",
                            attrs: {
                              members: _vm.members,
                              at: "@",
                              "name-key": "username"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "item",
                                fn: function(s) {
                                  return [
                                    s.item.profile_picture_url
                                      ? [
                                          _c(
                                            "div",
                                            { staticClass: "d-flex m-r-15" },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "card-assign-user x-16"
                                                },
                                                [
                                                  _c("img", {
                                                    staticClass:
                                                      "img-circle img-responsive",
                                                    attrs: {
                                                      src:
                                                        s.item
                                                          .profile_picture_url,
                                                      width: "16"
                                                    }
                                                  })
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "p-l-5 m-w-0" },
                                                [
                                                  _c("span", {
                                                    staticClass:
                                                      "user-name d-block text-truncate",
                                                    domProps: {
                                                      textContent: _vm._s(
                                                        s.item.name
                                                      )
                                                    }
                                                  }),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass:
                                                      "fs-12 text-truncate m-t-2 m-b-0 no-hover",
                                                    domProps: {
                                                      textContent: _vm._s(
                                                        s.item.username
                                                      )
                                                    }
                                                  })
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      : [
                                          _c("span", {
                                            domProps: {
                                              textContent: _vm._s(s.item)
                                            }
                                          })
                                        ]
                                  ]
                                }
                              }
                            ])
                          },
                          [
                            _c("textarea", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value:
                                    _vm.edit_comment_object.unformatted_body,
                                  expression:
                                    "edit_comment_object.unformatted_body"
                                }
                              ],
                              ref: "textMemberText",
                              staticClass:
                                "form-control chat-comment-textarea place-holder autoheight",
                              attrs: {
                                cols: "30",
                                rows: "10",
                                placeholder: "Write your comment here."
                              },
                              domProps: {
                                value: _vm.edit_comment_object.unformatted_body
                              },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.edit_comment_object,
                                    "unformatted_body",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "text-area-helper" }),
                    _vm._v(" "),
                    _c("error", {
                      attrs: {
                        errors:
                          _vm.edit_comment_object.errors &&
                          _vm.edit_comment_object.errors.body
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "toolbar d-flex justify-between",
                    attrs: { "data-behavior": "toolbar" }
                  },
                  [
                    _c("div", [
                      _c(
                        "span",
                        {
                          staticClass: "toolbar-option pointer",
                          attrs: { title: "Mention Somebody" },
                          on: { click: _vm.initiateMention }
                        },
                        [
                          _c(
                            "svg",
                            {
                              attrs: {
                                xmlns: "http://www.w3.org/2000/svg",
                                width: "16",
                                height: "16",
                                viewBox: "0 0 16 16"
                              }
                            },
                            [
                              _c("path", {
                                attrs: {
                                  fill: "#A1A8C3",
                                  "fill-rule": "evenodd",
                                  d:
                                    "M8.001 10.255A2.254 2.254 0 1 1 8 5.746 2.254 2.254 0 0 1 8 10.256M8.002 1h-.001c-.688 0-1.786.102-2.983.664A7.002 7.002 0 0 0 2.612 3.53C1.034 5.44 1 7.591 1 8.001c.002 2.935 1.864 4.771 2.17 5.063C3.251 13.144 5.23 14.978 8 15h.05c1.098 0 1.98-.282 2.136-.333a6.686 6.686 0 0 0 1.832-.937.59.59 0 1 0-.671-.973c-.37.267-.977.64-1.804.865a5.921 5.921 0 0 1-1.488.195h-.055c-2.394-.024-4.06-1.65-4.06-1.65-1.724-1.677-1.757-3.804-1.757-4.166 0-.257.032-2.312 1.568-3.97a5.781 5.781 0 0 1 1.66-1.238 5.837 5.837 0 0 1 2.589-.61h.009c1.243 0 2.164.397 2.55.585.243.119 1.285.65 2.176 1.851.496.668 1.082 1.988 1.082 3.381 0 .653-.537 1.19-1.19 1.19-.653 0-1.19-.537-1.19-1.19 0-.722-.088-1.12-.527-1.836a3.497 3.497 0 0 0-1.583-1.338A3.479 3.479 0 0 0 8 4.563h-.003a3.47 3.47 0 0 0-1.22.22 3.484 3.484 0 0 0-1.97 1.944 3.455 3.455 0 0 0 .026 2.617c.11.262.39.831.99 1.32a3.517 3.517 0 0 0 2.174.774h.003a3.453 3.453 0 0 0 2.983-1.728 2.4 2.4 0 0 0 .702.468c.141.061.488.194.935.194h.007c.971-.003 1.615-.637 1.689-.712.658-.671.683-1.49.684-1.66.018-2.22-1.246-4.036-1.564-4.422a7.18 7.18 0 0 0-3.253-2.23A7.006 7.006 0 0 0 8.002 1"
                                }
                              })
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "toolbar-option pointer",
                          attrs: {
                            "data-behavior": "tag-entity",
                            title: "Tag items, orders, accounts etc..."
                          },
                          on: { click: _vm.initiateTagging }
                        },
                        [
                          _c(
                            "svg",
                            {
                              attrs: {
                                xmlns: "http://www.w3.org/2000/svg",
                                width: "16",
                                height: "16",
                                viewBox: "0 0 16 16"
                              }
                            },
                            [
                              _c(
                                "g",
                                {
                                  attrs: {
                                    fill: "none",
                                    "fill-rule": "evenodd"
                                  }
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      fill: "#A1A8C3",
                                      d:
                                        "M9.422 11H5.936l-.433 4.053a.5.5 0 0 1-.994-.106L4.93 11H2a.5.5 0 1 1 0-1h3.038l.427-4H2a.5.5 0 0 1 0-1h3.572L6.006.947A.5.5 0 0 1 7 1.053L6.578 5h3.486l.433-4.053a.5.5 0 1 1 .994.106L11.07 5h2.92a.5.5 0 1 1 0 1h-3.027l-.427 4H14a.5.5 0 1 1 0 1h-3.572l-.434 4.053A.5.5 0 0 1 9 14.947L9.422 11zm.107-1l.428-4H6.47l-.428 4H9.53z"
                                    }
                                  })
                                ]
                              )
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "toolbar-option pointer",
                          attrs: {
                            "data-behavior": "check-box-toggler",
                            title: "Mark this comment as urgent."
                          }
                        },
                        [
                          _c("label", { staticClass: "m-b-0-hard" }, [
                            _c(
                              "span",
                              {
                                staticClass: "pointer",
                                class: {
                                  "checkbox-inactive": !_vm.edit_comment_object
                                    .urgent,
                                  "checkbox-active":
                                    _vm.edit_comment_object.urgent
                                }
                              },
                              [_vm._v("🔴")]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.edit_comment_object.urgent,
                                  expression: "edit_comment_object.urgent"
                                }
                              ],
                              staticClass: "hide",
                              attrs: { type: "checkbox" },
                              domProps: {
                                checked: _vm.edit_comment_object.urgent,
                                checked: Array.isArray(
                                  _vm.edit_comment_object.urgent
                                )
                                  ? _vm._i(
                                      _vm.edit_comment_object.urgent,
                                      null
                                    ) > -1
                                  : _vm.edit_comment_object.urgent
                              },
                              on: {
                                change: function($event) {
                                  var $$a = _vm.edit_comment_object.urgent,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.edit_comment_object,
                                          "urgent",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.edit_comment_object,
                                          "urgent",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.edit_comment_object,
                                      "urgent",
                                      $$c
                                    )
                                  }
                                }
                              }
                            })
                          ])
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "char-post" }, [
                      _c(
                        "span",
                        {
                          staticClass: "char-counter",
                          class: { "text-danger": _vm.char_count < 0 }
                        },
                        [_vm._v(_vm._s(_vm.char_count))]
                      ),
                      _vm._v(" "),
                      _c(
                        "label",
                        {
                          staticClass: "toolbar-option pointer",
                          on: { click: _vm.submitComment }
                        },
                        [
                          _c(
                            "svg",
                            {
                              attrs: {
                                xmlns: "http://www.w3.org/2000/svg",
                                width: "16",
                                height: "16",
                                viewBox: "0 0 16 16"
                              }
                            },
                            [
                              _c("path", {
                                attrs: {
                                  fill: "#3D4465",
                                  "fill-rule": "evenodd",
                                  d:
                                    "M1.839 6.068l3.353 1.547a.5.5 0 1 1-.42.908L.292 6.455a.5.5 0 0 1 .046-.927l14.5-5a.5.5 0 0 1 .636.636l-5 14.5a.5.5 0 0 1-.927.046l-3-6.5a.5.5 0 0 1 .1-.563l3-3a.5.5 0 0 1 .708.707L7.6 9.108l2.333 5.054 4.26-12.354-12.354 4.26z"
                                }
                              })
                            ]
                          )
                        ]
                      )
                    ])
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "p-t-b-15" }, [
              _c(
                "a",
                {
                  staticClass: "btn btn-primary",
                  attrs: { href: "javascript:void(0)" },
                  on: { click: _vm.submitComment }
                },
                [_vm._v("Update")]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "btn m-l-10",
                  attrs: { href: "javascript:void(0);" },
                  on: { click: _vm.cancelEdit }
                },
                [_vm._v(_vm._s(_vm.$i18n.tc("comments_list.cancel")))]
              )
            ])
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }