<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
    <path
      fill="#646C9A"
      fill-rule="evenodd"
      d="M3.5 1a.5.5 0 0 1 0 1H2a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1h-1.5a.5.5 0 1 1 0-1H14a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1.5zm8 11a.5.5 0 1 1 0 1h-7a.5.5 0 1 1 0-1h7zm0-3a.5.5 0 1 1 0 1h-7a.5.5 0 0 1 0-1h7zm0-3a.5.5 0 1 1 0 1h-7a.5.5 0 0 1 0-1h7zM10 0a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1h4zm0 1H6v2h4V1z"
    />
  </svg>
</template>

<script>
export default {
  name: 'WorkOrdersIcon'
}
</script>
