var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    {
      staticClass:
        "dropdown-menu dropdown-menu-right hover-blue-text settings-dropdown"
    },
    [
      _vm.showView
        ? _c("li", [
            _c("a", { attrs: { href: _vm.showPath, target: "_blank" } }, [
              _vm._v("View")
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.canEdit
        ? _c("li", [
            _c(
              "a",
              {
                attrs: {
                  "data-reference": "form-cancel-link",
                  href: _vm.work_order.edit_path
                }
              },
              [_vm._v("Edit")]
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.canCancel
        ? _c(
            "li",
            [
              _c("cancel-button", {
                attrs: {
                  workOrder: _vm.work_order,
                  buttonClasses: "full-width nowrap-hard"
                },
                on: { onSuccess: _vm.onSuccess }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.canClone
        ? _c("li", [
            _c(
              "a",
              {
                attrs: {
                  "data-method": "patch",
                  href: _vm.work_order.clone_path,
                  target: "_blank"
                }
              },
              [_vm._v("Clone")]
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.canDestroy
        ? _c(
            "li",
            [
              _vm.deleteListbutton
                ? _c("delete-list-button", {
                    attrs: {
                      workOrder: _vm.work_order,
                      buttonClasses: "del full-width",
                      containerClasses: "dropdown-div d-flex"
                    },
                    on: { onSuccess: _vm.onDelete }
                  })
                : _c("delete-button", {
                    attrs: {
                      workOrder: _vm.work_order,
                      buttonClasses: "del full-width",
                      containerClasses: "dropdown-div d-flex"
                    },
                    on: { onSuccess: _vm.onSuccess }
                  })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.canArchive
        ? _c(
            "li",
            [
              _c("archive-button", {
                attrs: {
                  workOrder: _vm.work_order,
                  buttonClasses: "full-width nowrap-hard"
                },
                on: { onSuccess: _vm.onSuccess }
              })
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }