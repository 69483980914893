<template>
  <div class="dropdown-div d-flex">
    <a @click.stop='getArchiveDescription' data-task-alert-update="true" href="javascript:" :class="[{ disabled: ajaxRequestSent }, buttonClasses]">Archive</a>
    <sweet-alert @input="archiveCurrentWorkOrder" container_classes='dropdown-div d-flex hide'>
      <a class="full-width nowrap-hard hide" ref="archiveLink" href='javascript:void(0)' :data-description='archiveDescription' :data-sweet-alert-confirm='alertConfirmText' :data-heading='headingText' data-confirm-type='archive' data-vue-component=true data-confirm-button-text='Archive' data-cancel-button-text='Discard'>Archive</a>
    </sweet-alert>
  </div>
</template>

<script>
  import axios from 'axios';
  import { mapActions, mapState } from "vuex";
  import sweetAlert from 'components/shared/sweet_alert';

  export default {
    name: 'ArchiveButton',
    props: {
      workOrder: Object,
      buttonClasses: String
    },
    components: {
      sweetAlert
    },
    computed: {
      ...mapState('GeneralConfiguration', [
        'ajaxRequestSent'
      ]),
      alertConfirmText() {
        if(this.workOrder.is_work_order) {
          return 'Are you sure you want to archive this Work Order?';
        } else {
          return 'Are you sure you want to archive this Sub-Work Order?'
        }
      },
      headingText() {
        if(this.workOrder.is_work_order) {
          return 'Archive Work Order';
        } else {
          return 'Archive Sub-Work Order'
        }
      }
    },
    data: function() {
      return {
        archiveDescription: ''
      }
    },
    methods: {
      ...mapActions('WorkOrderQuickActions', [
        'archiveWorkOrder',
        'archiveSubWorkOrder'
      ]),
      getArchiveDescription: function() {
        axios.get(this.workOrder.task_count_path, { params: { message_type: 'archive' } }).then((response) => {
          this.archiveDescription = response.data.description_message;
          window.setTimeout(() => this.$refs.archiveLink.click(), 100);
        })
      },
      archiveCurrentWorkOrder: function() {
        if (this.workOrder.is_work_order) {
          this.archiveWorkOrder({
            apollo: this.$apollo,
            workOrderId: this.workOrder.db_id,
            showAlert: this.$root.showAlert
          }).then(() => this.onSuccess());
        } else {
          this.archiveSubWorkOrder({
            apollo: this.$apollo,
            subWorkOrderId: this.workOrder.db_id,
            showAlert: this.$root.showAlert
          }).then(() => this.onSuccess());
        }
      },
      onSuccess: function() {
        this.$emit('onSuccess');
      }
    }
  }
</script>
