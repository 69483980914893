var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", [
    _vm.contact_changes[1]
      ? _c("span", [
          _c("span", { staticClass: "d-inline-block fs-12" }, [
            _vm._v(_vm._s(_vm.updateText))
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "m-l-22 wo-tab-detail" }, [
            _c("div", { staticClass: "dark tooltip-inline" }, [
              _c(
                "span",
                { staticClass: "d-inline-block" },
                [
                  _vm.contact_changes[1].loggable_name == "Contact"
                    ? _c("ContactDataTooltipWithName", {
                        attrs: { contact_object: _vm.contact_changes[1] }
                      })
                    : _c("SupplierDataTooltip", {
                        attrs: { contact_object: _vm.contact_changes[1] }
                      })
                ],
                1
              ),
              _vm._v(" "),
              _c("span", { staticClass: "fontmedium d-inline-block" }, [
                _c("span", { staticClass: "d-inline-block" }, [_vm._v("›")]),
                _vm._v(" "),
                _c(
                  "span",
                  { staticClass: "d-inline-block" },
                  [
                    _vm.contact_changes[0].loggable_name == "Contact"
                      ? _c("ContactDataTooltipWithName", {
                          attrs: { contact_object: _vm.contact_changes[0] }
                        })
                      : _c("SupplierDataTooltip", {
                          attrs: { contact_object: _vm.contact_changes[0] }
                        })
                  ],
                  1
                )
              ])
            ])
          ])
        ])
      : _c("span", [
          _c("span", { staticClass: "d-inline-block fs-12" }, [
            _vm._v(_vm._s(_vm.addText))
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "m-l-22 wo-tab-detail dark" },
            [
              _vm.contact_changes[0].loggable_name == "Contact"
                ? _c("ContactDataTooltipWithName", {
                    attrs: { contact_object: _vm.contact_changes[0] }
                  })
                : _c("SupplierDataTooltip", {
                    attrs: { contact_object: _vm.contact_changes[0] }
                  })
            ],
            1
          )
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }