import { mapState } from 'vuex';
import { userShowPath } from 'concerns/path_helper_methods.js';
import UserHoverDataTooltip from 'components/shared/users/user_hover_data_tooltip.vue';
import store from 'store/shared/store.js';
import { DefaultMixins } from 'packs/default.js'

export const PictureWithTooltipMixin = {
  name: 'PictureWithTooltipMixin',
  store: store,
   props: {
    user_id: {
      required: true,
      default: ''
    },
    image_size: {
      type: String,
      default: '20'
    }
  },
  data: function() {
    return {
      componentFullyLoaded: false
    }
  },
  components: {
    UserHoverDataTooltip
  },
  mounted() {
    this.componentFullyLoaded = true;
  },
  computed: {
    ...mapState('GeneralConfiguration', ['user_data']),
    currentUserObject() {
      return this.user_data[String(this.user_id)] || {};
    }
  },
  methods: {
    userShowPath
  }
}
