var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pop-over-box" }, [
    _c(
      "span",
      {
        staticClass: "card-assign-user pointer normal",
        class: _vm.widthConfig
      },
      [
        _c(
          "FollowerUserPicWithTooltip",
          { attrs: { user_id: _vm.followerId, imageClass: _vm.widthConfig } },
          [
            _c("template", { slot: "extra-content" }, [
              _c("a", {
                staticClass: "pop-close-btn",
                attrs: { href: "javascript:void(0);" },
                on: { click: _vm.removeFollower }
              })
            ])
          ],
          2
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }