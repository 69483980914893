var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "36",
        height: "36",
        viewBox: "0 0 512 512",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("path", {
        staticStyle: {
          fill: "none",
          stroke: "#000000",
          "stroke-miterlimit": "10",
          "stroke-width": "32px"
        },
        attrs: { d: "M256,80A176,176,0,1,0,432,256,176,176,0,0,0,256,80Z" }
      }),
      _vm._v(" "),
      _c("path", {
        staticStyle: {
          fill: "none",
          stroke: "#000000",
          "stroke-linecap": "round",
          "stroke-miterlimit": "10",
          "stroke-width": "28px"
        },
        attrs: {
          d:
            "M200,202.29s.84-17.5,19.57-32.57C230.68,160.77,244,158.18,256,158c10.93-.14,20.69,1.67,26.53,4.45,10,4.76,29.47,16.38,29.47,41.09,0,26-17,37.81-36.37,50.8S251,281.43,251,296"
        }
      }),
      _vm._v(" "),
      _c("circle", { attrs: { cx: "250", cy: "348", r: "20" } })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }