var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.author_id
    ? _c("span", [
        _c(
          "span",
          { staticClass: "task-user" },
          [
            _c("user-profile-pic-with-tooltip", {
              attrs: { user_id: _vm.author_id }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "span",
          {
            staticClass:
              "user-color fontmedium fs-13 d-inline-block m-b-5 tooltip-inline"
          },
          [_c("user-popover-tooltip", { attrs: { user_id: _vm.author_id } })],
          1
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }