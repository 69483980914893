var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "account-info text-left fs-13" }, [
    _c(
      "div",
      {
        staticClass:
          "full-width d-flex justify-between m-b-20 border-b si-border p-b-10 p-t-10"
      },
      [
        _c(
          "a",
          {
            staticClass: "fs-16 semibold",
            attrs: { href: _vm.accountShowPath(_vm.loadedAccountData) }
          },
          [_vm._v("\n      " + _vm._s(_vm.loadedAccountData.name) + "\n    ")]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "m-l-5" }, [
          _vm.loadedAccountData.is_target
            ? _c("span", { staticClass: "target-account label si-label" }, [
                _vm._v(_vm._s(_vm.$i18n.t("accounts.target")))
              ])
            : _c(
                "span",
                {
                  staticClass:
                    "target-account label si-label non-target-account"
                },
                [_vm._v(_vm._s(_vm.$i18n.t("accounts.non_target")))]
              )
        ])
      ]
    ),
    _vm._v(" "),
    _c("ul", { staticClass: "list-unstyled m-b-0" }, [
      _c(
        "li",
        {
          staticClass:
            "row border-b si-border m-b-5 margin-horizontal-none p-b-5"
        },
        [
          _c("span", { staticClass: "col-md-5 p-l-0 ls-03 text-muted" }, [
            _vm._v(_vm._s(_vm.$i18n.t("accounts.account")))
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "col-md-4 p-r-0 semibold dark" }, [
            _vm._v(_vm._s(_vm.loadedAccountData.number))
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-3 p-r-0 d-flex justify-flex-end" }, [
            _c("img", {
              staticClass: "img img-responsive",
              attrs: {
                src: _vm.loadedAccountData.profile_picture,
                width: "20px"
              }
            })
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "li",
        {
          staticClass:
            "row border-b si-border m-b-5 margin-horizontal-none p-b-5"
        },
        [
          _c("span", { staticClass: "col-md-5 p-l-0 ls-03 text-muted" }, [
            _vm._v(_vm._s(_vm.$i18n.t("accounts.potential_rating")))
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "col-md-7 semibold dark" }, [
            _vm._v(_vm._s(_vm.loadedAccountData.potential_rating))
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "li",
        {
          staticClass:
            "row border-b si-border m-b-5 margin-horizontal-none p-b-5"
        },
        [
          _c("span", { staticClass: "col-md-5 p-l-0 ls-03 text-muted" }, [
            _vm._v(_vm._s(_vm.$i18n.t("accounts.last_ordered_at")))
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "col-md-7 p-r-0 semibold dark" }, [
            _vm.loadedAccountData.last_ordered_at
              ? _c("span", [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm._f("formatSiDateExpanded")(
                          _vm.loadedAccountData.last_ordered_at
                        )
                      ) +
                      "\n        "
                  )
                ])
              : _c("span", [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$i18n.tc("default_no_data")) +
                      "\n        "
                  )
                ])
          ])
        ]
      ),
      _vm._v(" "),
      _c("li", { staticClass: "row m-b-10 margin-horizontal-none p-b-10" }, [
        _c("span", { staticClass: "col-md-5 p-l-0 ls-03 text-muted" }, [
          _vm._v(_vm._s(_vm.$i18n.t("accounts.current_year_spend")))
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "semibold dark col-md-7 p-r-0" }, [
          _vm._v(_vm._s(_vm.loadedAccountData.current_year_spend))
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }