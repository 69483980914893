<template>
  <ul class="dropdown-menu dropdown-menu-right hover-blue-text settings-dropdown">
    <li v-if="showView"><a :href="showPath" target="_blank">View</a></li>
    <li v-if='canEdit'><a data-reference="form-cancel-link" :href="work_order.edit_path">Edit</a></li>
    <li v-if='canCancel'>
      <cancel-button
        :workOrder="work_order"
        buttonClasses='full-width nowrap-hard'
        @onSuccess="onSuccess"
      ></cancel-button>
    </li>
    <li v-if='canClone'><a data-method="patch" :href="work_order.clone_path" target='_blank'>Clone</a></li>
    <li v-if='canDestroy'>
      <delete-list-button
        v-if="deleteListbutton"
        :workOrder="work_order"
        buttonClasses="del full-width"
        containerClasses="dropdown-div d-flex"
        @onSuccess="onDelete"
      ></delete-list-button>
      <delete-button
        v-else
        :workOrder="work_order"
        buttonClasses="del full-width"
        containerClasses="dropdown-div d-flex"
        @onSuccess="onSuccess"
      ></delete-button>
    </li>
    <li v-if='canArchive'>
      <archive-button
        :workOrder="work_order"
        buttonClasses='full-width nowrap-hard'
        @onSuccess="onSuccess"
      ></archive-button>
    </li>
  </ul>
</template>

<script>
  import sweetAlert from 'components/shared/sweet_alert';
  import CancelButton from 'components/work_orders/show/cancel_button.vue';
  import ArchiveButton from 'components/work_orders/show/archive_button.vue';
  import DeleteButton from 'components/work_orders/show/delete_button.vue';
  import DeleteListButton from 'components/shared/list/delete_list_button.vue';
  import { workOrderShowPath, subWorkOrderShowPath } from 'concerns/path_helper_methods.js';
  import { mapActions } from "vuex";
  import axios from 'axios';

  export default {
    name: 'WorkOrderQuickActions',
    props: [
      'work_order',
      'showView',
      'deleteListbutton'
    ],
    data: function() {
      return {
        archive_description: '',
        cancel_description: ''
      }
    },
    methods: {
      ...mapActions('WorkOrderQuickActions', [
        'deleteWorkOrder',
        'deleteSubWorkOrder',
        'archiveWorkOrder',
        'archiveSubWorkOrder'
      ]),
      workOrderShowPath,
      subWorkOrderShowPath,
      onSuccess: function() {
        this.$emit('quick-action-performed');
      },
      onDelete: function() {
        this.$emit('onDeleted');
      }
    },
    computed: {
      showPath: function() {
        if(this.isWorkOrder) {
          return this.workOrderShowPath(this.work_order);
        } else {
          return this.subWorkOrderShowPath(this.work_order.work_order, this.work_order);
        }
      },
      currentWorkOrderId() {
        return this.work_order.db_id || this.work_order.id;
      },
      canClone() {
        return this.work_order.can_clone || this.work_order.can_clone_work_order
      },
      canDestroy() {
        if(this.isWorkOrder) {
          return this.dsp[`manage__work_order__${this.currentWorkOrderId}__can_destroy`];
        } else {
          return this.dsp[`manage__sub_work_order__${this.currentWorkOrderId}__can_destroy`];
        }
      },
      canCancel() {
        if(this.isWorkOrder) {
          return this.dsp[`manage__work_order__${this.currentWorkOrderId}__can_cancel`];
        } else {
          return this.dsp[`manage__sub_work_order__${this.currentWorkOrderId}__can_cancel`];
        }
      },
      isWorkOrder() {
        return this.work_order.is_work_order;
      },
      canEdit() {
        if(this.isWorkOrder) {
          return this.dsp[`manage__work_order__${this.currentWorkOrderId}__can_edit`];
        } else {
          return this.dsp[`manage__sub_work_order__${this.currentWorkOrderId}__can_edit`];
        }
      },
      canArchive() {
        if(this.isWorkOrder) {
          return this.dsp[`manage__work_order__${this.currentWorkOrderId}__can_archive`];
        } else {
          return this.dsp[`manage__sub_work_order__${this.currentWorkOrderId}__can_archive`];
        }
      }
    },
    components: {
      sweetAlert,
      DeleteButton,
      ArchiveButton,
      CancelButton,
      DeleteListButton
    }
  }
</script>
