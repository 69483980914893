import { createHelpers } from 'vuex-map-fields';
import { mapGetters, mapState, mapActions, mapMutations } from 'vuex';

const { mapFields: mapTaskDetailFields } = createHelpers({
  getterType: 'getTask',
  mutationType: 'updateTask'
});

const { mapFields: mapTimeLogFields } = createHelpers({
  getterType: 'getTimeLog',
  mutationType: 'createTimeLog'
});

export const taskActionMixin = {
  methods: {
    ...mapActions('TaskDetailModalWidget', [
      'requestDeleteTask',
      'requestArchiveTask',
      'requestCancelTask',
      'markIncomplete'
    ]),
    markInComplete: function(task) {
      var _this = this;
      if(task.is_archived && !this.currentUser.owner_or_super_admin) {
        return;
      }
      this.markIncomplete({
        apollo: this.$apollo,
        loggable_id: task.id,
        showAlert: (this.rootComponent || this.$root).showAlert,
        componentObject: this
      }).then(function(task){
        _this.afterMarkIncompleteTask(task);
      });
    },
    afterMarkIncompleteTask: function(task) {
      this.updateTask(task);
    },
    deleteTask: function(task_id) {
      var _this = this;
      this.requestDeleteTask({
        apollo: this.$apollo,
        showAlert: (this.rootComponent || this.$root).showAlert,
        task_id: task_id.toString(),
      }).then((task) => {
        _this.afterDeleteTask(task);
      });
    },
    afterDeleteTask: function(task) {
      this.deletedTaskId = task.id;
    },
    cancelTask: function(task_id) {
      var _this = this;
      this.requestCancelTask({
        apollo: this.$apollo,
        showAlert: (this.rootComponent || this.$root).showAlert,
        task_id: task_id.toString()
      }).then((task) => {
        _this.afterCancelTask(task);
      });
    },
    afterCancelTask: function(task) {
      this.updateTask(task);
    },
    archiveTask: function(task_id) {
      var _this = this;
      this.requestArchiveTask({
        apollo: this.$apollo,
        showAlert: (this.rootComponent || this.$root).showAlert,
        task_id: task_id.toString(),
      }).then((task) => {
        _this.afterArchiveTask(task);
      });
    },
    afterArchiveTask: function(task) {
      this.updateTask(task);
      // Currently using update task instead of archive ID as we need to remove from current view but maintain the data in state for some filter purposes.
      // this.archivedTaskId = task.id;
    }
  },
  computed: {
    ...mapState('GeneralConfiguration', ['currentUser']),
    ...mapTaskDetailFields('TaskDetailModalWidget', [
        'archivedTaskId',
        'deletedTaskId'
      ]),
    ...mapTimeLogFields('TimeLogModalWidget', [
      'displayTimeLogModal',
      'displayMarkCompleteModal',
      'currentLoggable',
      'ajaxRequestSent',
      'timeLogCreateSuccessful',
      'taskMarkCompleteCancel',
      'timeLogUpdateSuccessful',
      'time_log_object'
    ])
  }
}
