import DashboardIcon from './dashboard_icon.vue';
import WorkOrdersIcon from './work_orders_icon.vue';
import InternalWorkOrdersIcon from './internal_work_orders_icon.vue';
import CalendarIcon from './calendar_icon.vue';
import EventsIcon from './events_icon.vue';
import EstimatesIcon from './estimates_icon.vue';
import ContactsIcon from './contacts_icon.vue';
import AccountsIcon from './accounts_icon.vue';
import GoalsIcon from './goals_icon.vue';
import InboxIcon from './inbox_icon.vue';
import OrdersIcon from './orders_icon.vue';
import PurchaseOrdersIcon from './purchase_orders_icon.vue';
import ShipmentsIcon from './shipments_icon.vue';
import TasksIcon from './tasks_icon.vue';
import ResourcingIcon from './resourcing_icon.vue';
import EmployeeManagementIcon from './/employee_management_icon.vue';
import CatalogManagementIcon from './catalog_management_icon.vue';
import SystemSettingsIcon from './system_settings_icon.vue';
import DownloadIcon from './download_icon.vue';
import HelpIcon from './help_icon.vue';

export {
  DashboardIcon,
  CalendarIcon,
  EventsIcon,
  EstimatesIcon,
  ContactsIcon,
  AccountsIcon,
  GoalsIcon,
  InboxIcon,
  OrdersIcon,
  WorkOrdersIcon,
  InternalWorkOrdersIcon,
  PurchaseOrdersIcon,
  ShipmentsIcon,
  TasksIcon,
  ResourcingIcon,
  EmployeeManagementIcon,
  CatalogManagementIcon,
  SystemSettingsIcon,
  DownloadIcon,
  HelpIcon
};
