<template>
  <span>
    <span v-if="!isUpdated">
      <div class="fs-13 d-inline-block">{{ addedText }}</div>
      <div class="black m-l-22 wo-tab-detail">{{ changeSet.to_value | timeDurationFormat }} hours</div>
    </span>
    <span v-else>
      <div class="fs-13 d-inline-block">{{ updatedText }}</div>
      <div class="black m-l-22 wo-tab-detail">{{ changeSet.from_value | timeDurationFormat }} hours<span class="fontmedium"> › {{ changeSet.to_value | timeDurationFormat }} hours</span> </div>
    </span>
  </span>
</template>

<script>
  export default {
    name: 'timeChangesDetail',
    props: [
      'updatedText',
      'addedText',
      'changeSet'
    ],
    computed: {
      isUpdated: function() {
        return this.changeSet.to_value && this.changeSet.from_value;
      }
    }
  };
</script>

<style lang="css" scoped>
</style>
