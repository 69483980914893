<template>
</template>

<script>

  import { createHelpers } from 'vuex-map-fields';

  const { mapFields: mapTaskDetailFields } = createHelpers({
    getterType: 'getTask',
    mutationType: 'updateTask'
  });

  export default {
    name: 'task_modal_opener',
    computed: {
      ...mapTaskDetailFields('TaskDetailModalWidget', ['entity_id']),
    },

    mounted() {
      var _this = this;
      $(document).on('open-task-modal', function(event, data){
        if(!$.isEmptyObject(data)) {
          _this.entity_id = (data.id || '').toString();
        }
      });
    }
  };

</script>

<style lang="css" scoped>
</style>
