var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "16",
        height: "16",
        viewBox: "0 0 16 16"
      }
    },
    [
      _c("path", {
        attrs: {
          fill: "#646C9A",
          "fill-rule": "evenodd",
          d:
            "M9 0a.5.5 0 0 1 .493.418l.311 1.865c.332.104.654.238.963.398l1.539-1.098a.5.5 0 0 1 .643.053l1.415 1.414a.5.5 0 0 1 .054.645l-1.1 1.538c.161.309.295.631.399.963l1.865.31A.5.5 0 0 1 16 7v2a.5.5 0 0 1-.418.493l-1.865.311a5.958 5.958 0 0 1-.398.963l1.098 1.54a.5.5 0 0 1-.053.644l-1.414 1.414a.5.5 0 0 1-.645.053l-1.538-1.1c-.309.161-.631.295-.963.399l-.31 1.865A.5.5 0 0 1 9 16H7a.5.5 0 0 1-.493-.418l-.311-1.865a5.958 5.958 0 0 1-.963-.398l-1.539 1.098a.5.5 0 0 1-.644-.053L1.636 12.95a.5.5 0 0 1-.053-.644l1.098-1.54a5.958 5.958 0 0 1-.398-.962l-1.865-.31A.5.5 0 0 1 0 9V7a.5.5 0 0 1 .418-.493l1.865-.311c.104-.332.238-.654.398-.963L1.583 3.694a.5.5 0 0 1 .053-.644L3.05 1.636a.5.5 0 0 1 .644-.053l1.54 1.098c.308-.16.63-.294.962-.398l.31-1.865A.5.5 0 0 1 7 0h2zm-.424 1H7.424L7.13 2.76a.5.5 0 0 1-.37.401 4.963 4.963 0 0 0-1.305.541.5.5 0 0 1-.545-.023L3.458 2.643l-.815.815L3.679 4.91a.5.5 0 0 1 .023.545 4.963 4.963 0 0 0-.54 1.306.5.5 0 0 1-.403.37L1 7.423v1.152l1.76.294a.5.5 0 0 1 .401.37c.119.462.3.9.54 1.305a.5.5 0 0 1-.022.545l-1.036 1.452.815.815 1.452-1.036a.5.5 0 0 1 .545-.023c.405.24.843.422 1.306.54a.5.5 0 0 1 .37.403L7.423 15h1.152l.294-1.76a.5.5 0 0 1 .37-.401 4.963 4.963 0 0 0 1.305-.541.5.5 0 0 1 .546.023l1.451 1.037.815-.815-1.036-1.453a.5.5 0 0 1-.023-.545c.24-.405.422-.843.54-1.306a.5.5 0 0 1 .403-.37L15 8.577V7.424l-1.76-.294a.5.5 0 0 1-.401-.37 4.963 4.963 0 0 0-.541-1.305.5.5 0 0 1 .023-.546l1.037-1.45-.816-.816-1.452 1.036a.5.5 0 0 1-.545.023 4.963 4.963 0 0 0-1.306-.54.5.5 0 0 1-.37-.403L8.577 1zM8 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6zm0 1a2 2 0 1 0 0 4 2 2 0 0 0 0-4z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }