<template>
  <div ref='taskMarkCompleteModal' class="modal padding-left-none-hard padding-right-none-hard" id="task_time_log" tabindex="-1" role="dialog" aria-labelledby="new_task" aria-hidden="true" data-modal=true>
    <div class="modal-dialog si-modal modal-modal-dialog" role="document">
      <div class="modal-content gutter-20">
        <aside class="modal-header m-b-0 default">
          <div class="black-2 fs-13 modal-title-color m-r-15 p-r-15" id="new_task">
            {{ currentLoggable.class_name }}:
            <span>{{ currentLoggable.id }}</span>
            <i class="fa fa-circle small align-vertical-middle"></i>
            <span data-behavior='task-title'>{{ currentLoggable.title }}</span>
          </div>

          <button type="button" class="modal-close-btn" data-dismiss="modal" aria-label="Close" @click='cancelOperation'></button>
          <div data-behavior="ajax-loader" class="ajax-loading si-page-loader">
            <div class="bar bar-1"></div>
            <div class="bar bar-2"></div>
            <div class="bar bar-3"></div>
            <div class="bar-complete bar-1"></div>
          </div>
        </aside>

        <div class="row margin-horizontal-none m-b-10" v-if='currentLoggable.cancelled_at'>
          <div class="alert alert-danger m-b-0 text-center">
            <span class="btn-icon-helper cancel-task-info-icon"></span>
            {{ this.$i18n.t('task_modal.cancelled_text') }} {{ currentLoggable.cancelled_at | formatSIExpandedDateTime }}
          </div>
        </div>

        <div class="modal-body m-t-15">
          <article class="">
            <aside class="si-form-content">
              <div class="si-form-data">
                <form @submit='saveTimeLog'>
                  <div data-behavior="task-fields-container" data-nested-task='container' class="">

                    <div class="row row-col-space-10 task-timer-container d-flex" data-behavior="nested-time-log-container">
                      <div class="col-md-3 timer-group form-group" data-error-container='form-group'>
                        <input type="text" name="task_time_log[duration]" class="form-control place-holder form-control-timer text-center" :placeholder="$tc('task_time_log_modal.duration_placeholder')" v-model='time_log_object.duration'>
                        <error :errors="time_log_object.errors && time_log_object.errors.duration"></error>
                      </div>

                      <div class="col-md-7 task-description-group form-group" data-error-container='form-group'>
                        <input type="text" name="task_time_log[duration]" class="form-control place-holder" :placeholder="$tc('task_time_log_modal.notes_placeholder')" v-model='time_log_object.description'>
                        <error :errors="time_log_object.errors && time_log_object.errors.description"></error>
                      </div>

                      <div class="col- form-group task-date-group" data-error-container="form-group">
                        <div class="form-date-group">
                          <date-field class_names='modal-date-icon' v-model='time_log_object.loggable_date'></date-field>
                          <error :errors="time_log_object.errors && time_log_object.errors.loggable_date"></error>
                        </div>
                      </div>
                    </div>

                  </div>

                  <div class="" v-if='!currentLoggable.is_cancelled'>
                    <label class="clickable-item card-status-label relative pointer pointer-event-none" data-behavior='task-complete-toggler-container'>
                      <input type="checkbox" class="card-status-input pointer-event-none" checked="true">
                      <span class="card-status-helper pointer d-inline-block">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                          <g fill="none" fill-rule="evenodd">
                            <circle cx="10" cy="10" r="9.5" class="status-circle-svg"/>
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 9.667l2.667 2.666L14 7" class="status-path-svg"/>
                          </g>
                        </svg>
                      </span>

                      <span class="d-inline-block align-vertical-middle m-l-10 semibold">Mark task as complete</span>
                    </label>
                  </div>

                  <hr class="with-dash-border si-15" />

                  <div class="action-button">
                    <a href="javascript:void(0)" class="btn btn-si text-uppercase" data-dismiss='modal' @click='cancelOperation'>Cancel</a>
                    <input type="submit" class="btn btn-primary text-uppercase m-l-10" @click.prevent='saveTimeLog' :class="{ 'disabled' : ajaxRequestSent }" value="Save Entry">
                  </div>
                </form>
              </div>
            </aside>
          </article>

        </div>
      </div>
    </div>
  </div>
</template>

<script>

  import * as deepmerge from 'deepmerge';
  import dateField from 'components/shared/date_field';
  import error from 'components/shared/error.vue';
  import { createHelpers } from 'vuex-map-fields';
  import { mapGetters, mapState, mapActions, mapMutations } from 'vuex';
  // mixins
  import { timeLogMixin } from 'mixin/tasks/time_log_mixin.js';

  export default {
    name: 'taskMarkCompleteModal',
    mixins: [timeLogMixin],
    data() {
      return {
        default_time_log_object: {
          duration: '',
          id: null,
          loggable_id: null,
          loggable_type: 'Task',
          loggable_date: moment().format('MM/DD/YYYY'),
          mark_complete: true,
          description: '',
          errors: {},
        }
      }
    },

    components: {
      error,
      dateField
    },
    methods: {

      focusModal() {
        $(this.$refs.taskMarkCompleteModal).focus();
        $('body').addClass('modal-open');
      },

      displayModal() {
        $(this.$refs.taskMarkCompleteModal).modal('show');
        this.taskMarkCompleteCancel = false;
      },

      hideModal() {
        $(this.$refs.taskMarkCompleteModal).modal('hide');
        this.taskMarkCompleteCancel = true;
        this.resetTimeObject();
      },

      resetTimeObject: function() {
        this.time_log_object = deepmerge({}, this.default_time_log_object);
      }

    },
    watch: {
      displayMarkCompleteModal: function(value) {
        if (value) {
          this.displayModal();
          this.displayMarkCompleteModal = false;
        }
      }
    },
    mounted() {
      const _this = this;
      $(this.$refs.taskMarkCompleteModal).on('shown.bs.modal', function() {
        // This Timeout Makes 'Shown' event Async which renders the modal so that it runs after entire modal DOM is loaded
        _this.$emit('beforeShow');
        setTimeout(() => {
          _this.$emit('onShown');
        });
      });
      $(this.$refs.taskMarkCompleteModal).on('hidden.bs.modal', function() {
        _this.hideModal();
        _this.$emit('onDismiss');
      });
    }
  };
</script>

<style lang="css" scoped>
</style>
