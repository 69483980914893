var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "taskMarkCompleteModal",
      staticClass: "modal padding-left-none-hard padding-right-none-hard",
      attrs: {
        id: "task_time_log",
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "new_task",
        "aria-hidden": "true",
        "data-modal": "true"
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "modal-dialog si-modal modal-modal-dialog",
          attrs: { role: "document" }
        },
        [
          _c("div", { staticClass: "modal-content gutter-20" }, [
            _c("aside", { staticClass: "modal-header m-b-0 default" }, [
              _c(
                "div",
                {
                  staticClass: "black-2 fs-13 modal-title-color m-r-15 p-r-15",
                  attrs: { id: "new_task" }
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.currentLoggable.class_name) +
                      ":\n          "
                  ),
                  _c("span", [_vm._v(_vm._s(_vm.currentLoggable.id))]),
                  _vm._v(" "),
                  _c("i", {
                    staticClass: "fa fa-circle small align-vertical-middle"
                  }),
                  _vm._v(" "),
                  _c("span", { attrs: { "data-behavior": "task-title" } }, [
                    _vm._v(_vm._s(_vm.currentLoggable.title))
                  ])
                ]
              ),
              _vm._v(" "),
              _c("button", {
                staticClass: "modal-close-btn",
                attrs: {
                  type: "button",
                  "data-dismiss": "modal",
                  "aria-label": "Close"
                },
                on: { click: _vm.cancelOperation }
              }),
              _vm._v(" "),
              _vm._m(0)
            ]),
            _vm._v(" "),
            _vm.currentLoggable.cancelled_at
              ? _c(
                  "div",
                  { staticClass: "row margin-horizontal-none m-b-10" },
                  [
                    _c(
                      "div",
                      { staticClass: "alert alert-danger m-b-0 text-center" },
                      [
                        _c("span", {
                          staticClass: "btn-icon-helper cancel-task-info-icon"
                        }),
                        _vm._v(
                          "\n          " +
                            _vm._s(this.$i18n.t("task_modal.cancelled_text")) +
                            " " +
                            _vm._s(
                              _vm._f("formatSIExpandedDateTime")(
                                _vm.currentLoggable.cancelled_at
                              )
                            ) +
                            "\n        "
                        )
                      ]
                    )
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "modal-body m-t-15" }, [
              _c("article", {}, [
                _c("aside", { staticClass: "si-form-content" }, [
                  _c("div", { staticClass: "si-form-data" }, [
                    _c("form", { on: { submit: _vm.saveTimeLog } }, [
                      _c(
                        "div",
                        {
                          attrs: {
                            "data-behavior": "task-fields-container",
                            "data-nested-task": "container"
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "row row-col-space-10 task-timer-container d-flex",
                              attrs: {
                                "data-behavior": "nested-time-log-container"
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-md-3 timer-group form-group",
                                  attrs: {
                                    "data-error-container": "form-group"
                                  }
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.time_log_object.duration,
                                        expression: "time_log_object.duration"
                                      }
                                    ],
                                    staticClass:
                                      "form-control place-holder form-control-timer text-center",
                                    attrs: {
                                      type: "text",
                                      name: "task_time_log[duration]",
                                      placeholder: _vm.$tc(
                                        "task_time_log_modal.duration_placeholder"
                                      )
                                    },
                                    domProps: {
                                      value: _vm.time_log_object.duration
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.time_log_object,
                                          "duration",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("error", {
                                    attrs: {
                                      errors:
                                        _vm.time_log_object.errors &&
                                        _vm.time_log_object.errors.duration
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-md-7 task-description-group form-group",
                                  attrs: {
                                    "data-error-container": "form-group"
                                  }
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.time_log_object.description,
                                        expression:
                                          "time_log_object.description"
                                      }
                                    ],
                                    staticClass: "form-control place-holder",
                                    attrs: {
                                      type: "text",
                                      name: "task_time_log[duration]",
                                      placeholder: _vm.$tc(
                                        "task_time_log_modal.notes_placeholder"
                                      )
                                    },
                                    domProps: {
                                      value: _vm.time_log_object.description
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.time_log_object,
                                          "description",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("error", {
                                    attrs: {
                                      errors:
                                        _vm.time_log_object.errors &&
                                        _vm.time_log_object.errors.description
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col- form-group task-date-group",
                                  attrs: {
                                    "data-error-container": "form-group"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "form-date-group" },
                                    [
                                      _c("date-field", {
                                        attrs: {
                                          class_names: "modal-date-icon"
                                        },
                                        model: {
                                          value:
                                            _vm.time_log_object.loggable_date,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.time_log_object,
                                              "loggable_date",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "time_log_object.loggable_date"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("error", {
                                        attrs: {
                                          errors:
                                            _vm.time_log_object.errors &&
                                            _vm.time_log_object.errors
                                              .loggable_date
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              )
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      !_vm.currentLoggable.is_cancelled
                        ? _c("div", {}, [
                            _c(
                              "label",
                              {
                                staticClass:
                                  "clickable-item card-status-label relative pointer pointer-event-none",
                                attrs: {
                                  "data-behavior":
                                    "task-complete-toggler-container"
                                }
                              },
                              [
                                _c("input", {
                                  staticClass:
                                    "card-status-input pointer-event-none",
                                  attrs: { type: "checkbox", checked: "true" }
                                }),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "card-status-helper pointer d-inline-block"
                                  },
                                  [
                                    _c(
                                      "svg",
                                      {
                                        attrs: {
                                          xmlns: "http://www.w3.org/2000/svg",
                                          width: "20",
                                          height: "20",
                                          viewBox: "0 0 20 20"
                                        }
                                      },
                                      [
                                        _c(
                                          "g",
                                          {
                                            attrs: {
                                              fill: "none",
                                              "fill-rule": "evenodd"
                                            }
                                          },
                                          [
                                            _c("circle", {
                                              staticClass: "status-circle-svg",
                                              attrs: {
                                                cx: "10",
                                                cy: "10",
                                                r: "9.5"
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c("path", {
                                              staticClass: "status-path-svg",
                                              attrs: {
                                                "stroke-linecap": "round",
                                                "stroke-linejoin": "round",
                                                "stroke-width": "2",
                                                d: "M6 9.667l2.667 2.666L14 7"
                                              }
                                            })
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "d-inline-block align-vertical-middle m-l-10 semibold"
                                  },
                                  [_vm._v("Mark task as complete")]
                                )
                              ]
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("hr", { staticClass: "with-dash-border si-15" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "action-button" }, [
                        _c(
                          "a",
                          {
                            staticClass: "btn btn-si text-uppercase",
                            attrs: {
                              href: "javascript:void(0)",
                              "data-dismiss": "modal"
                            },
                            on: { click: _vm.cancelOperation }
                          },
                          [_vm._v("Cancel")]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          staticClass: "btn btn-primary text-uppercase m-l-10",
                          class: { disabled: _vm.ajaxRequestSent },
                          attrs: { type: "submit", value: "Save Entry" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.saveTimeLog($event)
                            }
                          }
                        })
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "ajax-loading si-page-loader",
        attrs: { "data-behavior": "ajax-loader" }
      },
      [
        _c("div", { staticClass: "bar bar-1" }),
        _vm._v(" "),
        _c("div", { staticClass: "bar bar-2" }),
        _vm._v(" "),
        _c("div", { staticClass: "bar bar-3" }),
        _vm._v(" "),
        _c("div", { staticClass: "bar-complete bar-1" })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }