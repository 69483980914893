var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", [
    _vm.isUpdated
      ? _c("span", [
          _c("div", { staticClass: "fs-12 d-inline-block" }, [
            _vm._v(_vm._s(_vm.updatedText))
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "dark m-l-22 wo-tab-detail" }, [
            _vm._v(_vm._s(_vm._f("formatSiDateExpanded")(_vm.from_value))),
            _c("span", { staticClass: "fontmedium" }, [
              _vm._v(
                " › " + _vm._s(_vm._f("formatSiDateExpanded")(_vm.to_value))
              )
            ])
          ])
        ])
      : _vm.isRemoved
      ? _c("span", [
          _c("div", { staticClass: "fs-12 d-inline-block" }, [
            _vm._v(_vm._s(_vm.removedText))
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "dark m-l-22 wo-tab-detail" }, [
            _vm._v(_vm._s(_vm._f("formatSiDateExpanded")(_vm.from_value)))
          ])
        ])
      : _c("span", [
          _c("div", { staticClass: "fs-12 d-inline-block" }, [
            _vm._v(_vm._s(_vm.addedText))
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "dark m-l-22 wo-tab-detail" }, [
            _vm._v(_vm._s(_vm._f("formatSiDateExpanded")(_vm.to_value)))
          ])
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }