var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "hide" },
        [
          _vm._l(_vm.selectedFollowers, function(follower) {
            return [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selectedFollowers,
                    expression: "selectedFollowers"
                  }
                ],
                attrs: {
                  type: "checkbox",
                  multiple: "true",
                  name: _vm.field_name
                },
                domProps: {
                  value: follower,
                  checked: Array.isArray(_vm.selectedFollowers)
                    ? _vm._i(_vm.selectedFollowers, follower) > -1
                    : _vm.selectedFollowers
                },
                on: {
                  keydown: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "esc", 27, $event.key, [
                        "Esc",
                        "Escape"
                      ])
                    ) {
                      return null
                    }
                    $event.stopPropagation()
                    return _vm.$emit("escapePressed")
                  },
                  change: function($event) {
                    var $$a = _vm.selectedFollowers,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = follower,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.selectedFollowers = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.selectedFollowers = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.selectedFollowers = $$c
                    }
                  }
                }
              })
            ]
          })
        ],
        2
      ),
      _vm._v(" "),
      _c("autocomplete", {
        ref: "autoComplete",
        attrs: {
          url: "some_random_string",
          id: _vm.field_for,
          anchor: "name",
          label: "email",
          min: 0,
          "on-focus": _vm.callInputEvent,
          "on-should-get-data": _vm.filterFollowers,
          name: "autocomplete",
          options: _vm.followerOptions,
          customParams: { token: "dev" },
          classes: {
            input: "form-control place-holder",
            wrapper: "input-wrapper"
          },
          "on-select": _vm.onFollowerSelect,
          "on-should-render-child": _vm.populateFollowersList,
          placeholder: _vm.placeholder
        }
      }),
      _vm._v(" "),
      _vm.followerOptions.length
        ? _c("div", [
            _c(
              "div",
              { staticClass: "auto-follower-list" },
              _vm._l(_vm.selectedFollowers, function(followerId) {
                return _c("follower", {
                  key: followerId,
                  attrs: {
                    "follower-id": followerId,
                    "followers-hash": _vm.followerOptions,
                    follower_icon_width: _vm.followerWidth
                  },
                  on: {
                    follower_remove: function($event) {
                      return _vm.onFollowerRemove($event)
                    }
                  }
                })
              }),
              1
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }