<template>
  <span ref='userGridContent' class='tooltip-popover'>
    <span v-if='Object.keys(currentUserObject).length && componentFullyLoaded'>

      <v-popover placement='top' trigger='hover' popoverArrowClass='hide'>

        <span class='tooltip-target'>
          <a :href="userShowPath(currentUserObject)" target="_blank" class="m-r-10 margin-horizontal-none-hard card-assign-user clickable-item" :class='imageClass'>
            <img :src="currentUserObject.profile_picture_url" class="img img-responsive d-inline">
          </a>
          <slot name="extra-content"></slot>
        </span>
        <template slot='popover'>
          <UserHoverDataTooltip :user_id='currentUserObject.id'></UserHoverDataTooltip>
        </template>

      </v-popover>
    </span>
    <span v-else>
      {{ $i18n.tc('default_no_data') }}
    </span>
  </span>
</template>

<script>
  import { PictureWithTooltipMixin } from 'mixin/users/picture_with_tooltip_mixin.js';
  export default {
    name: 'FollowerUserPicWithTooltip',
    mixins: [PictureWithTooltipMixin],
    props: {
      imageClass: {
        type: String,
        default: ''
      },
      parentClass: {
        type: String,
        default: ''
      }
    },
  }
</script>
