var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dropdown-div d-flex" },
    [
      _c(
        "a",
        {
          class: [{ disabled: _vm.ajaxRequestSent }, _vm.buttonClasses],
          attrs: { "data-task-alert-update": "true", href: "javascript:" },
          on: { click: _vm.getCancelDescription }
        },
        [_vm._v("Cancel")]
      ),
      _vm._v(" "),
      _c(
        "sweet-alert",
        {
          attrs: { container_classes: "dropdown-div d-flex hide" },
          on: { input: _vm.cancelCurrentWorkOrder }
        },
        [
          _c(
            "a",
            {
              ref: "cancelLink",
              staticClass: "full-width nowrap-hard",
              attrs: {
                href: "javascript:",
                "data-description": _vm.cancelDescription,
                "data-sweet-alert-confirm": _vm.alertConfirmText,
                "data-heading": _vm.headingText,
                "data-confirm-type": "cancel",
                "data-vue-component": "true",
                "data-confirm-button-text": "Yes, Cancel",
                "data-cancel-button-text": "Discard"
              }
            },
            [_vm._v("Cancel")]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }