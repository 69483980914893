<template>
  <div id='loading' class="ajax-loading si-page-loader" data-behavior="ajax-loader">
    <div class="bar bar-1"></div>
    <div class="bar bar-2"></div>
    <div class="bar bar-3"></div>
    <div class="bar-complete bar-1"></div>
  </div>
</template>

<script>
  export default {
    name: 'AjaxLoaderBar'
  }
</script>
