var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", [
    _vm.from_value
      ? _c("span", { staticClass: "fs-13", class: _vm.complete_class_names }, [
          _vm._v("\n    marked "),
          _c("span", { staticClass: "black" }, [
            _vm._v(_vm._s(_vm.object_name))
          ]),
          _vm._v(" as incomplete.\n  ")
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.to_value
      ? _c(
          "span",
          { staticClass: "fs-13", class: _vm.incomplete_class_names },
          [
            _vm._v("\n    marked "),
            _c("span", { staticClass: "black" }, [
              _vm._v(_vm._s(_vm.object_name))
            ]),
            _vm._v(" as complete.\n  ")
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }