<template>
  <span class="history-manage-block">

    <span v-if="data_key == 'cost'">
      <span class="d-inline-block fs-12">
        updated the shipping cost
      </span>
      <div class="m-l-22 wo-tab-detail">
        <div class="dark tooltip-inline">
          <div class="d-inline-block">$ {{ from_value }}</div>
          <span class="fontmedium d-inline-block">›
            <div class="d-inline-block">$ {{ to_value }}</div>
          </span>
        </div>
      </div>
    </span>

    <template v-else-if="data_key == 'tracking_number'">
      <span v-if="from_value.length && to_value.length">
        <span class="d-inline-block fs-12">
          updated the shipping cost
        </span>
        <div class="m-l-22 wo-tab-detail">
          <div class="dark tooltip-inline">
            <div class="d-inline-block">{{ from_value }}</div>
            <span class="fontmedium d-inline-block">›
              <div class="d-inline-block">{{ to_value }}</div>
            </span>
          </div>
        </div>
      </span>
      <span v-else>
        <span class="d-inline-block fs-12">
          {{ from_value.length ? "removed" : "added" }} the tracking number for shipping cost
          <span class="black">$ {{ shipping_cost }}</span>
        </span>
        <div class="m-l-22 wo-tab-detail dark">
          <div>{{ from_value.length ? from_value : to_value }}</div>
        </div>
      </span>
    </template>

    <template v-else-if="data_key == 'purchase_order_id'">
      <span class="fs-13">
        {{ from_value ? "un" : "" }}allocated the shipping cost
        <span class="black">$ {{ shipping_cost }}</span>
      </span>
    </template>

  </span>
</template>

<script>
  export default {
    name: "shipping_cost_detail",
    props: ["shipping_cost", "data_key", "from_value", "to_value"],
  };
</script>
