<template>
  <span :data-confirm-button-text='data_confirm_button_text' @click="$emit('delete_started')" :data-heading="data_heading" data-vue-component=true data-confirm-type="delete" :disabled="disabled" :data-sweet-alert-confirm="data_sweet_alert_confirm" :data-description='data_description' :class='classNames'>
    <slot></slot>
  </span>
</template>

<script>
  export default {
    name: 'deleteButton',
    props: ['value', 'data_confirm_button_text', 'data_heading', 'data_description', 'data_sweet_alert_confirm', 'classname', 'disabled'],
    mounted() {
      $(document).trigger('vue:sweetalert:added')
      $(this.$el).on('vue-sweet-alert-success', ()=> {
        this.$emit('input', false)
      })
    },
    computed: {
      classNames: function() {
        if(this.classname) {
          return this.classname;
        } else {
          return 'btn remove-panel remove-panel-static';
        }
      }
    }
  }
</script>

<style scoped>
</style>
