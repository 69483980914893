<template>
  <div>
    <div class="hide">
      <template v-for='follower in selectedFollowers'>
        <input type="checkbox" multiple="true" :name='field_name' :value='follower' v-model='selectedFollowers' @keydown.esc.stop="$emit('escapePressed')">
      </template>
    </div>
    <autocomplete
      ref='autoComplete'
      url="some_random_string"
      :id="field_for"
      anchor="name"
      label="email"
      :min='0'
      :on-focus=callInputEvent
      :on-should-get-data=filterFollowers
      name="autocomplete"
      :options='followerOptions'
      :customParams="{ token: 'dev' }"
      :classes="{ input: 'form-control place-holder', wrapper: 'input-wrapper'}"
      :on-select="onFollowerSelect"
      :on-should-render-child="populateFollowersList"
      :placeholder='placeholder'
    >
    </autocomplete>

    <div v-if='followerOptions.length'>
      <div class="auto-follower-list">
        <follower @follower_remove='onFollowerRemove($event)' v-for="followerId in selectedFollowers" v-bind:key='followerId' :follower-id='followerId' :followers-hash='followerOptions' :follower_icon_width='followerWidth'></follower>
      </div>
    </div>
  </div>
</template>

<script>
  import Autocomplete from 'vue2-autocomplete-js';
  import AutocompleteMethods from 'concerns/work_orders/shared/autocomplete_methods.js';
  import follower from 'components/shared/users/follower';

  export default {
    name: 'AutoCompleteFollowers',
    props: ['follower_options', 'field_name', 'field_for', 'persisted_followers', 'placeholder', 'auto_suggest_followers', 'follower_width'],
    data() {
      return {
        selectedFollowers: this.persisted_followers
      };
    },
    methods: {
      ...AutocompleteMethods,

      onFollowerRemove: function(data) {
        let followerIndex = this.selectedFollowers.indexOf((data));
        if (followerIndex > -1) {
          this.selectedFollowers.splice(followerIndex, 1);
          this.$emit('input', this.selectedFollowers);
          this.$emit('followersChanged');
        }
      },

      onFollowerSelect: function(data) {
        var dataId = String(data.id)
        if(!this.selectedFollowers.includes(dataId)) {
          this.selectedFollowers.push(dataId);
          this.$emit('input', this.selectedFollowers);
          this.$emit('followersChanged');
        }
      },

      getEntityIds: function() {
        return this.selectedFollowers;
      },

      maxFollowersSuggestion: function() {
        return this.auto_suggest_followers || 5;
      },

      focusField: function() {
        this.$refs.autoComplete.$el.querySelector('input').focus();
      },

      resetFollowers: function() {
        this.selectedFollowers = [];
      }

    },
    computed: {
      followerOptions: function() {
        return this.follower_options;
      },

      followerWidth: function() {
        return this.follower_width || 'x-36';
      }
    },
    components: {
      Autocomplete,
      follower
    },

    mounted: function() {
      var _this = this,
          taskModal = $('[data-behavior="task-modal-global"]');
      taskModal.on('hide.bs.modal', function(){
        _this.selectedFollowers = [];
      });
    }

  };
</script>

<style lang="css" scoped>
</style>
