var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "userGridContent",
      staticClass: "profile-value trigger-block text-truncate"
    },
    [
      Object.keys(_vm.currentUserObject).length && _vm.componentFullyLoaded
        ? _c(
            "div",
            { staticClass: "parentClass" },
            [
              _c(
                "v-popover",
                {
                  attrs: {
                    placement: "top",
                    trigger: "hover",
                    popoverArrowClass: "hide"
                  }
                },
                [
                  _c(
                    "span",
                    { staticClass: "tooltip-target d-block full-width" },
                    [
                      _vm.fullNameWidth
                        ? _c(
                            "span",
                            {
                              staticClass: "d-flex text-truncate align-center"
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "clickable-item m-r-5-hard card-assign-user",
                                  attrs: {
                                    href: _vm.userShowPath(
                                      _vm.currentUserObject
                                    ),
                                    target: "_blank"
                                  }
                                },
                                [
                                  _c("img", {
                                    staticClass: "img img-responsive",
                                    attrs: {
                                      src:
                                        _vm.currentUserObject
                                          .profile_picture_url
                                    }
                                  })
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "tootltip-name clickable-item d-block text-truncate",
                                  attrs: {
                                    href: _vm.userShowPath(
                                      _vm.currentUserObject
                                    ),
                                    target: "_blank"
                                  }
                                },
                                [_vm._v(_vm._s(_vm.currentUserObject.name))]
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.abbreviatedNameWidth
                        ? _c(
                            "span",
                            {
                              staticClass: "d-flex text-truncate align-center"
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "clickable-item m-r-5-hard card-assign-user",
                                  attrs: {
                                    href: _vm.userShowPath(
                                      _vm.currentUserObject
                                    ),
                                    target: "_blank"
                                  }
                                },
                                [
                                  _c("img", {
                                    staticClass: "img img-responsive",
                                    attrs: {
                                      src:
                                        _vm.currentUserObject
                                          .profile_picture_url
                                    }
                                  })
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "tootltip-name clickable-item d-block text-truncate",
                                  attrs: {
                                    href: _vm.userShowPath(
                                      _vm.currentUserObject
                                    ),
                                    target: "_blank"
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.currentUserObject.abbreviated_name
                                    )
                                  )
                                ]
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.profileWidth
                        ? _c("span", { staticClass: "d-inline-block" }, [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "m-r-10 card-assign-user clickable-item"
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass: "clickable-item",
                                    attrs: {
                                      href: _vm.userShowPath(
                                        _vm.currentUserObject
                                      ),
                                      target: "_blank"
                                    }
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "img img-responsive",
                                      attrs: {
                                        src:
                                          _vm.currentUserObject
                                            .profile_picture_url
                                      }
                                    })
                                  ]
                                )
                              ]
                            )
                          ])
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "template",
                    { slot: "popover" },
                    [
                      _c("UserHoverDataTooltip", {
                        attrs: { user_id: _vm.currentUserObject.id }
                      })
                    ],
                    1
                  )
                ],
                2
              )
            ],
            1
          )
        : _c("div", [
            _vm._v("\n    " + _vm._s(_vm.$i18n.tc("default_no_data")) + "\n  ")
          ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }