import Vue from 'vue/dist/vue.esm';

Vue.directive("uppercase", {
    bind(el, binding, vnode) {
      el.addEventListener('change', (e) => {
        e.target.value = e.target.value.toUpperCase();
      });
      el.style.textTransform = 'uppercase';
    },
    update(el, binding, vnode) {
      el.addEventListener('change', (e) => {
        e.target.value = e.target.value.toUpperCase();
      })
      el.style.textTransform = 'uppercase';
    }
});
