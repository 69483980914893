<template>
  <div class="clickmenu d-flex align-center h-full notification-menu justify-center" :class="{'open' : openNotificationWidget}" @click.stop>
    <!-- Notification Switch -->
    <a class="dropdown-toggle relative pointer" @click="openNotificationWidget = !openNotificationWidget">
      <span class="notification-icon-control">
        <span v-if="getUnreadCount" class="notification-indicator">
          {{getUnreadCount}}
        </span>
      </span>
    </a>

    <div class="dropdown-menu dropdown-menu-right notification-dropdown-menu">
      <!-- CHeck to show notifications if web notifications exist for User -->
      <div class="arrow"></div>
      <div class="d-flex justify-between notification-title-bar align-center">
        <span class="fs-11 super-light ls-1 text-uppercase">{{ $t('web_notifications.title') }}</span>
        <span>
          <a class="mark-read-btn" v-if="getNotifications.length" @click="markAllNotificationsRead">
            {{ $t('web_notifications.mark_all_read') }}
          </a>
        </span>
      </div>

      <template v-if="!notificationsFetched">
        <div class="notifications-loading">{{ $t('default_loading_message') }}</div>
      </template>

      <template v-else>
        <template v-if="getNotifications.length">
          <notification-list :notifications="getNotifications" :storeModule="'NotificationWidgetModule'"></notification-list>

          <div class="notification-footer-bar d-flex align-center justify-center">
            <a class="view-all-notification-btn semibold btn-block" :href="redirectToAllNotifications()">
                {{ $t('web_notifications.view_all_notification') }}
            </a>
          </div>
        </template>
        <template v-else>
          <div class="no-notifications-present">
            {{ $t('web_notifications.no_notifications') }}
          </div>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";
  import notificationList from "./notification_list.vue";

  export default {
    name: "notificationWidget",
    data() {
      return {
        openNotificationWidget: false,
        notificationsFetched: false
      };
    },
    computed: {
      ...mapGetters("NotificationWidgetModule", [
        "getNotifications",
        "getUnreadCount"
      ])
    },
    methods: {
      ...mapActions("NotificationWidgetModule", [
        "fetchNotifications",
        "checkForUpdate",
        "markAllNotificationsAsRead"
      ]),
      listenToPubnubChannel() {
        const _this = this;
        var pubnub = new PubNub({
          subscribe_key: gon.pubnubSubscribeKey
        });
        pubnub.subscribe({
          channels: [gon.pubnubWebNotificationChannel]
        });
        pubnub.addListener({
          message: function(response) {
            _this.checkForUpdate({ response, rootRef: _this.$root });
          }
        });
      },
      markAllNotificationsRead() {
        this.markAllNotificationsAsRead(this.$apollo);
      },
      redirectToAllNotifications() {
        return router.makePath("all_notifications");
      },
      bindModalEvents() {
        var data = {
          targetSelector: '[data-modal-click=card-click-overlay]',
          ignoreDataElement: 'toggle',
          dropDownSelector: '[data-menu=dropdown]'
        };
        this.unBindDocumentEvents(data);
      },

      unBindDocumentEvents(data) {
        $(document).off('click', data.targetSelector, function(){
          var modal = new ResourceModalHandler(data);
          modal.init();
        });
      }
    },
    created() {
      this.fetchNotifications({
        apollo: this.$apollo,
        data: {}
      }).then(() => {
        this.notificationsFetched = true;
      });
      this.listenToPubnubChannel();
    },
    mounted() {
      const _this = this;
      $("body").on("click", function(event) {
        if (_this.openNotificationWidget) {
          _this.openNotificationWidget = false;
          _this.bindModalEvents();
        }
      });
    },
    watch: {
      openNotificationWidget: function(value) {
        if(value) {
          this.bindModalEvents();
        }
      }
    },
    components: {
      notificationList
    }
  };
</script>
