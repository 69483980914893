<template>

  <form action='/admin/search'>
    <label class="si-search-box m-width-100 full-base pointer" style="">
      <input placeholder="" v-model='search' type="search" name="q[global_search]" id="" class="form-control place-holder" :class="{'with-input': (search || '').length}">
      <i class="search-icon"></i>
    </label>
  </form>

</template>

<script>
const queryString = require('querystring');
export default {
  name: 'Search',
  data: function () {
    return {
      search: ''
    }
  },
  mounted() {
    this.search = queryString.parse(window.location.search.split('?')[1])['q[global_search]']
  }
}
</script>
