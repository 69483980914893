<template>
  <div @mouseover="loadAccountData" class="m-r-10" ref="accountContent">
    <span class='tooltip-target d-block full-width' v-if="!loadedAccountData">
      <span v-if="withNameWidth || showLabels" class="d-flex align-center">
        <a :href="accountShowPath(accountData)" class="lh-123 clickable-item card-assign-user">
          <img :src="accountData.profile_picture" class="img img-responsive full-width">
        </a>
        <a :href="accountShowPath(accountData)" class="lh-123 clickable-item account-name text-truncate" :class="[showLabels ? 'mw-100' : 'mw-135']">
          {{ accountData.name }}
        </a>
      </span>
      <span v-else>
        <a :href="accountShowPath(accountData)" class="lh-123 d-block clickable-item card-assign-user">
          <img :src="accountData.profile_picture" class="img img-responsive full-width">
        </a>
      </span>
    </span>
    <v-popover popoverWrapperClass="account-tooltip" :placement='placementValue' :openClass="popoverClasses" trigger='hover' popoverArrowClass='hide' v-else>
      <span v-if="withNameWidth || showLabels" class="d-flex align-center">
        <a :href="accountShowPath(accountData)" class="lh-123 d-block clickable-item card-assign-user">
          <img :src="accountData.profile_picture" class="img img-responsive full-width">
        </a>
        <a :href="accountShowPath(accountData)" class="lh-123 d-block clickable-item text-truncate" :class="[showLabels ? 'mw-100' : 'mw-135']">
          {{ accountData.name }}
        </a>
      </span>
      <span v-else>
        <a :href="accountShowPath(accountData)" class="lh-123 d-block clickable-item card-assign-user">
          <img :src="accountData.profile_picture" class="img img-responsive full-width">
        </a>
      </span>
      <template slot='popover'>
        <AccountTooltipHover
          :accountId="accountData.id.toString()"
        ></AccountTooltipHover>
      </template>
    </v-popover>
  </div>
</template>
<script>
  import { mapActions, mapState } from 'vuex';
  import { accountShowPath } from 'concerns/path_helper_methods.js';
  import AccountTooltipHover from 'components/shared/accounts/account_tooltip_hover.vue';
  export default {
    name: 'AccountDataTooltip',
    props: {
      accountData: Object,
      placementValue: {
        type: String,
        default: 'bottom'
      },
      popoverClasses: {
        type: String,
        default: ''
      },
      showLabels: {
        type: Boolean,
        default: false
      }
    },
    mounted() {
      this.calculateWidth();
      this.bindWindowEvents();
    },
    data: function() {
      return {
        clientWidth: 0
      }
    },
    components: {
      AccountTooltipHover
    },
    methods: {
      ...mapActions('GeneralConfiguration', ['fetchAccountData']),
      accountShowPath,
      loadAccountData: function() {
        if(!this.loadedAccountData) {
          window.disableAjaxLoader = true;
          this.fetchAccountData({
            apollo: this.$apollo,
            accountId: this.accountData.id
          }).then(() => window.disableAjaxLoader = false);
        }
      },
      calculateWidth() {
        this.clientWidth = this.$refs.accountContent.clientWidth;
      },
      bindWindowEvents() {
        var _this = this;
        window.addEventListener('sidebarToggled', (event) => {
          _this.calculateWidth();
        });
      }
    },
    computed: {
      ...mapState('GeneralConfiguration', ['accountsData']),
      loadedAccountData: function() {
        return this.accountsData[this.accountData.id];
      },
      withNameWidth() {
        return (this.clientWidth >= 170);
      }
    }
  }
</script>
