import AddConfiguration from 'components/general_configuration/add_configuration';
import ManageAjaxRequestBit from 'components/general_configuration/manage_ajax_request_bit';
import ManageTopNavLinks from 'components/general_configuration/manage_top_nav_links';

export const GeneralConfigurationMixin = {
  components: {
    AddConfiguration: AddConfiguration,
    ManageAjaxRequestBit: ManageAjaxRequestBit,
    ManageTopNavLinks: ManageTopNavLinks
  }
}
