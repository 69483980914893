var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.to_value
    ? _c("span", [
        _c(
          "span",
          { staticClass: "fs-13", class: _vm.cancelled_text_classes },
          [
            _vm._v("\n    marked "),
            _c("span", { staticClass: "black" }, [
              _vm._v(_vm._s(_vm.object_name))
            ]),
            _vm._v(" as canceled\n  ")
          ]
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }