<template>
  <span @click="emitEvent()" class="btn btn-default pointer btn-h-40-only fontmedium">
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <g fill="none" fill-rule="evenodd" transform="translate(2 2)">
        <circle cx="8" cy="8" r="7.5" fill="#FFF" stroke="#646c9a"/>
        <rect width="1" height="5" x="7" y="4" fill="#646c9a" rx=".5"/>
        <rect width="5.333" height="1" x="7" y="8" fill="#646c9a" rx=".5"/>
      </g>
    </svg>
    <span class="m-l-5 align-vertical-middle">Log Time</span>
  </span>
</template>

<script>
  export default {
    name: 'logTimeButton',
    methods: {
      emitEvent: function() {
        this.$emit('logTime');
      }
    }
  };
</script>

<style lang="css" scoped>
</style>
