<template>
  <span>
    <div class="fs-12 d-inline-block">
      {{ updatedText }}
    </div>
    <div class="black wo-tab-detail" :class="transitionClasses">{{ changeSet[0] }}
      <span class="fontmedium"> › {{ changeSet[1] }}</span>
    </div>
  </span>
</template>

<script>
  export default {
    name: 'StateChangesDetail',
    props: [
      'updatedText',
      'transitionClasses',
      'changeSet'
    ]
  };
</script>

<style lang="css" scoped>
</style>
