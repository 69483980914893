var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "header",
    {
      staticClass: "si-global-header sidebar-hover-control",
      attrs: {
        "data-behavior": "si-global-header",
        "data-stick-behavior": "extra-height"
      }
    },
    [
      _c("article", { staticClass: "navigation-header" }, [
        _c("div", { staticClass: "si-nav-container center-block" }, [
          _c("div", { staticClass: "d-flex align-center justify-between" }, [
            _c(
              "aside",
              { staticClass: "nav-left relative hover quick-add-links" },
              [
                _vm._m(0),
                _vm._v(" "),
                _c(
                  "ul",
                  {
                    staticClass: "dropdown-menu fs-13",
                    attrs: { "data-quick-links": "true" }
                  },
                  [
                    _vm.gsp.manage_tasks
                      ? _c("li", [
                          _c(
                            "a",
                            {
                              staticClass: "fs-13",
                              attrs: {
                                href: "#quick_add_task_modal",
                                "data-toggle": "modal"
                              }
                            },
                            [
                              _vm._v(
                                "\n                Task\n                "
                              ),
                              _c(
                                "span",
                                { staticClass: "link-icon" },
                                [_c("TasksIcon")],
                                1
                              )
                            ]
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.gsp.manage_work_orders
                      ? _c(
                          "li",
                          [
                            _c(
                              "open-work-order-quick-add-button",
                              { attrs: { is_internal: false } },
                              [
                                _vm._v(
                                  "\n                Work Order\n                "
                                ),
                                _c(
                                  "span",
                                  { staticClass: "link-icon" },
                                  [_c("WorkOrdersIcon")],
                                  1
                                )
                              ]
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.gsp.manage_work_orders
                      ? _c(
                          "li",
                          [
                            _c(
                              "open-work-order-quick-add-button",
                              {
                                attrs: {
                                  default_account_id:
                                    _vm.configurations.internal_account_id,
                                  is_internal: true,
                                  modal_header: "NEW INTERNAL WORK ORDER"
                                }
                              },
                              [
                                _vm._v(
                                  "\n                Internal Work Order\n                "
                                ),
                                _c(
                                  "span",
                                  { staticClass: "link-icon" },
                                  [_c("InternalWorkOrdersIcon")],
                                  1
                                )
                              ]
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("li", [
                      _c(
                        "a",
                        {
                          staticClass: "fs-13",
                          attrs: {
                            href: "#event",
                            "data-toggle": "modal",
                            "data-quick-create-event": "true"
                          }
                        },
                        [
                          _vm._v("\n                Event\n                "),
                          _c(
                            "span",
                            { staticClass: "link-icon" },
                            [_c("EventsIcon")],
                            1
                          )
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _vm.gsp.manage_accounts
                      ? _c("li", [
                          _c(
                            "a",
                            {
                              staticClass: "fs-13",
                              attrs: {
                                href: "#quick_add_account_modal",
                                "data-toggle": "modal",
                                "data-behavior": "wo-modal-button"
                              }
                            },
                            [
                              _vm._v(
                                "\n                Account\n                "
                              ),
                              _c(
                                "span",
                                { staticClass: "link-icon" },
                                [_c("AccountsIcon")],
                                1
                              )
                            ]
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.gsp.manage_contacts
                      ? _c("li", [
                          _c(
                            "a",
                            {
                              staticClass: "fs-13",
                              attrs: {
                                href: "#shared-quick-add-contact",
                                "data-toggle": "modal",
                                "data-behavior": "wo-modal-button",
                                "data-contact-type": "Lead"
                              }
                            },
                            [
                              _vm._v(
                                "\n                Lead\n                "
                              ),
                              _c(
                                "span",
                                { staticClass: "link-icon" },
                                [_c("ContactsIcon")],
                                1
                              )
                            ]
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.gsp.manage_contacts
                      ? _c("li", [
                          _c(
                            "a",
                            {
                              staticClass: "fs-13",
                              attrs: {
                                href: "#shared-quick-add-contact",
                                "data-toggle": "modal",
                                "data-behavior": "wo-modal-button",
                                "data-contact-type": "Prospect"
                              }
                            },
                            [
                              _vm._v(
                                "\n                Prospect\n                "
                              ),
                              _c(
                                "span",
                                { staticClass: "link-icon" },
                                [_c("ContactsIcon")],
                                1
                              )
                            ]
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.gsp.manage_orders
                      ? _c("li", [
                          _c(
                            "a",
                            {
                              staticClass: "fs-13",
                              attrs: {
                                href: "#create-opportunity-widget",
                                "data-toggle": "modal",
                                "data-behavior": "wo-modal-button"
                              }
                            },
                            [
                              _vm._v(
                                "\n                Opportunity\n                "
                              ),
                              _c(
                                "span",
                                { staticClass: "link-icon" },
                                [_c("AccountsIcon")],
                                1
                              )
                            ]
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    false && _vm.gsp.manage_orders
                      ? _c("li", [
                          _c(
                            "a",
                            {
                              staticClass: "fs-13",
                              attrs: {
                                href: "#create-estimate-modal",
                                "data-toggle": "modal",
                                "data-behavior": "wo-modal-button"
                              }
                            },
                            [
                              _vm._v(
                                "\n                Estimate\n                "
                              ),
                              _c(
                                "span",
                                { staticClass: "link-icon" },
                                [_c("AccountsIcon")],
                                1
                              )
                            ]
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("span", { staticClass: "arrow-icon" })
                  ]
                ),
                _vm._v(" "),
                _vm._m(1)
              ]
            ),
            _vm._v(" "),
            _c("nav", { staticClass: "si-primary-nav" }, [
              _c(
                "ul",
                { staticClass: "primary-nav-list d-flex align-center" },
                [
                  _vm._l(_vm.topNavLinks, function(topNavLink, index) {
                    return [
                      topNavLink.canDisplay === true ||
                      _vm.gsp[topNavLink.canDisplay]
                        ? _c(
                            "li",
                            {
                              key: index,
                              ref: topNavLink.highlightRef,
                              refInFor: true,
                              staticClass: "primary-menu"
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "primary-link",
                                  attrs: {
                                    href: topNavLink.url,
                                    "data-element-type": "global"
                                  }
                                },
                                [_vm._v(_vm._s(topNavLink.displayName))]
                              )
                            ]
                          )
                        : _vm._e()
                    ]
                  })
                ],
                2
              )
            ]),
            _vm._v(" "),
            _c(
              "aside",
              {
                staticClass:
                  "primary-right-nav width-150 justify-flex-end d-flex"
              },
              [
                _c("dd", { staticClass: "d-flex list-unstyled" }, [
                  _c(
                    "dl",
                    {
                      staticClass:
                        "relative m-r-15 d-flex justify-center align-center global-search"
                    },
                    [_c("aside", {}, [_c("Search")], 1)]
                  ),
                  _vm._v(" "),
                  _c(
                    "dl",
                    { staticClass: "relative m-r-10" },
                    [_c("notification-widget")],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "dl",
                    {
                      staticClass:
                        "relative m-r-10 d-flex align-center justify-center"
                    },
                    [
                      _c(
                        "a",
                        {
                          attrs: {
                            href:
                              "https://docs.google.com/document/d/1QHcVG6hsed7YvPNn6mbqlzSK0TqK5jqE_CELjKlxmlY/edit",
                            target: "_blank"
                          }
                        },
                        [_c("HelpIcon")],
                        1
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("dl", {}, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "user-nav-dropdown d-flex align-center relative hover p-l-5"
                      },
                      [
                        _c("a", { staticClass: "dropdown-toggle" }, [
                          _c("span", { staticClass: "user-profile-img" }, [
                            _c("img", {
                              staticClass: "img-responsive",
                              attrs: {
                                src: _vm.currentUser.profile_picture_url
                              }
                            })
                          ])
                        ]),
                        _vm._v(" "),
                        _c("ul", { staticClass: "dropdown-menu" }, [
                          _c("li", [
                            _c("a", { attrs: { href: _vm.adminEditPath() } }, [
                              _vm._v("My Profile")
                            ])
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: _vm.adminNotificationPreferencesPath()
                                }
                              },
                              [_vm._v("Email Notification Preferences")]
                            )
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c(
                              "form",
                              {
                                staticClass: "button_to",
                                attrs: {
                                  method: "post",
                                  action: _vm.adminSignOutPath()
                                }
                              },
                              [
                                _c("input", {
                                  attrs: {
                                    type: "hidden",
                                    name: "_method",
                                    value: "delete"
                                  }
                                }),
                                _vm._v(" "),
                                _c("input", {
                                  attrs: {
                                    "data-behavior": "sign_out_link",
                                    type: "submit",
                                    value: "Sign Out"
                                  }
                                }),
                                _vm._v(" "),
                                _c("input", {
                                  attrs: {
                                    type: "hidden",
                                    name: "authenticity_token"
                                  },
                                  domProps: { value: _vm.getGon("CSRF_TOKEN") }
                                })
                              ]
                            )
                          ])
                        ])
                      ]
                    )
                  ])
                ])
              ]
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("AjaxLoaderBar")
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "dropdown-toggle d-flex justify-center align-center pointer"
      },
      [
        _c("span", { staticClass: "quick-add-icon" }, [
          _c("span", { staticClass: "quick-add-circle" })
        ]),
        _vm._v("\n            Add New\n          ")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "si-nav-hamburger hidden",
        attrs: { "data-behavior": "sidebar-manage-icon" }
      },
      [_c("span", { staticClass: "line" })]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }