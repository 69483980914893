var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", { staticClass: "history-manage-block" }, [
    _c(
      "span",
      { staticClass: "d-inline-block fs-12", class: _vm.text_class_names },
      [_vm._v("\n    " + _vm._s(_vm.updated_text) + "\n  ")]
    ),
    _vm._v(" "),
    _vm.from_value && _vm.to_value
      ? _c("span", [
          _c("div", { class: _vm.transition_class_names }, [
            _c(
              "div",
              { staticClass: "row history-update-description task-tab-detail" },
              [
                _c("div", { staticClass: "col-md-6 old" }, [
                  _c(
                    "p",
                    {
                      staticClass:
                        "fs-12 text-uppercase m-b-5 ls-04 primary-text-color"
                    },
                    [_vm._v("old")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "lh-13" },
                    [
                      _vm._l(_vm.computed_diff_value, function(word_set) {
                        return [
                          _c("text-detail-diff", {
                            attrs: { word_set: word_set }
                          })
                        ]
                      })
                    ],
                    2
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-6 new fontmedium" }, [
                  _c(
                    "p",
                    {
                      staticClass:
                        "fs-12 text-uppercase m-b-5 ls-04 primary-text-color"
                    },
                    [_vm._v("updated")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "lh-13" },
                    [
                      _vm._l(_vm.computed_diff_value, function(word_set) {
                        return [
                          _c("text-detail-diff", {
                            attrs: { word_set: word_set }
                          })
                        ]
                      })
                    ],
                    2
                  )
                ])
              ]
            )
          ])
        ])
      : _c("span", { staticClass: "history-manage-block black-2" }, [
          _c(
            "div",
            {
              staticClass: "task-tab-detail",
              class: _vm.transition_class_names
            },
            [_vm._v("\n      " + _vm._s(_vm.to_value) + "\n    ")]
          )
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }