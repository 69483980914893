<template>
  <span>
    <template v-if="word_set.added">
      <ins class="differ">{{ word_set.value }}</ins>
    </template>
    <template v-if="word_set.removed">
      <del class="differ">{{ word_set.value }}</del>
    </template>
    <template v-if="!(word_set.added || word_set.removed)">
      {{ word_set.value }}
    </template>
  </span>
</template>

<script>
  import * as Jsdiff from 'diff';

  export default {
    name: 'text_detail_difference',
    props: ['word_set']
  };
</script>

<style lang="css" scoped>
</style>
