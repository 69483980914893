<template>
  <span>
    <span v-if="isUpdated">
      <div class="fs-12 d-inline-block">{{ updatedText }}</div>
      <div class="dark m-l-22 wo-tab-detail">{{ changeSet.from_value | formatSIExpandedDateTime }}<span class="fontmedium"> › {{ changeSet.to_value | formatSIExpandedDateTime }}</span> </div>
    </span>
    <span v-else-if="isRemoved">
      <div class="fs-12 d-inline-block">{{ removedText }}</div>
      <div class="m-l-22 wo-tab-detail">{{ changeSet.from_value | formatSIExpandedDateTime }}</div>
    </span>
    <span v-else>
      <div class="fs-12 d-inline-block">{{ addedText }}</div>
      <div class="m-l-22 wo-tab-detail">{{ changeSet.to_value | formatSIExpandedDateTime }}</div>
    </span>
  </span>
</template>

<script>
  export default {
    name: 'updatedDateTimeDetail',
    props: [
      'updatedText',
      'addedText',
      'removedText',
      'changeSet'
    ],
    computed: {
      isUpdated: function() {
        return this.changeSet.to_value && this.changeSet.from_value;
      },
      isRemoved: function() {
        return this.changeSet.from_value && !this.changeSet.to_value
      }
    }
  };
</script>

<style lang="css" scoped>
</style>
