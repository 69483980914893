var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "16",
        height: "16",
        viewBox: "0 0 16 16"
      }
    },
    [
      _c("path", {
        attrs: {
          fill: "#646C9A",
          "fill-rule": "evenodd",
          d:
            "M13 15a1 1 0 0 0 1-1V6.92a1 1 0 0 0-.35-.76L8 1.318 2.35 6.161A1 1 0 0 0 2 6.92V14a1 1 0 0 0 1 1h2v-5a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v5h2zm-3-5a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1v6H3a2 2 0 0 1-2-2V6.92A2 2 0 0 1 1.698 5.4L8 0l6.302 5.401A2 2 0 0 1 15 6.92V14a2 2 0 0 1-2 2h-3v-6z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }