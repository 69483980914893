<template>
  <div class="pop-over-box">
    <span class="card-assign-user pointer normal" :class='widthConfig'>
      <FollowerUserPicWithTooltip :user_id='followerId' :imageClass='widthConfig'>
        <template slot="extra-content">
          <a href="javascript:void(0);" @click="removeFollower" class="pop-close-btn"></a>
        </template>
      </FollowerUserPicWithTooltip>
    </span>


  </div>
</template>

<script>
  import FollowerUserPicWithTooltip from 'components/shared/users/follower_user_pic_with_tooltip.vue';
  export default {
    name: 'follower',
    props: ['followerId', 'followersHash', 'follower_icon_width'],
    components: {
      FollowerUserPicWithTooltip
    },
    computed: {
      followerData() {
        return (this.followersHash.find((hash) => { return hash.id == this.followerId })) || {};
      },
      widthConfig() {
        return this.follower_icon_width || 'x-36';
      }
    },
    methods: {
      removeFollower() {
        this.$emit('follower_remove', this.followerId);
      }
    }
  }
</script>

<style scoped>
</style>
