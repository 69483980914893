export const defaultAccountSetterMixin = {
  data() {
    return {
      default_account: {},
      disable_account_field: false
    }
  },
  methods: {
    fetchedAccountFromId() {
      var _this = this;
      this.fetchAccount({ apollo: this.$apollo, id: _this.account_id.toString() }).then((account) => {
        this.default_account = account;
      });
    }
  },
  watch: {
    account_id: function(value) {
      if(value && !this.disable_account_field) {
        this.fetchedAccountFromId();
      }
    }
  },
};
