<template>
  <span class="history-manage-block black-2">
    <span class="fs-12 d-inline-block" :class='text_class_names'>
      {{ updated_text }}
    </span>
    <div :class='transition_class_names' class="task-tab-detail">
      <span>{{ due_date_changes[0] }}</span>
       › <span class="fontmedium">{{ due_date_changes[1] }}</span>
    </div>
  </span>
</template>

<script>
  export default {
    name: 'update_due_date_detail',
    props: [
      'updated_text',
      'due_date_changes',
      'text_class_names',
      'transition_class_names'
    ]
  };
</script>

<style lang="css" scoped>
</style>
