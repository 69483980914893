<template>
  <div v-if='Object.keys(contact_object).length'>
    <div class='d-flex justfy-between'>
      <img :src="contact_object.gravatar_url || contact_object.profile_picture_url" height='40' width='40'>
      <div class="">
        <div class='text-left tooltip-text'>
          <a :href="contactShowPath(contact_object)" target="_blank" class="super-dark clickable-item fs-14 m-b-2 semibold ls-01">{{ contact_object.name }}</a>
        </div>
        <div class='super-dark text-left tooltip-text fs-13 ls-01' v-html='contact_object.email'></div>
      </div>
    </div>
  </div>
</template>

<script>
  import { contactShowPath } from 'concerns/path_helper_methods.js';

  export default {
    name: 'ContactHoverDataTooltip',
    props: {
      contact_object: {
        required: true
      }
    },
    methods: {
      contactShowPath
    }
  }
</script>
