var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", { staticClass: "history-manage-block black-2" }, [
    _c(
      "span",
      { staticClass: "fs-12 d-inline-block", class: _vm.text_class_names },
      [_vm._v("\n    " + _vm._s(_vm.updated_text) + "\n  ")]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "task-tab-detail", class: _vm.transition_class_names },
      [
        _c("span", [_vm._v(_vm._s(_vm.due_date_changes[0]))]),
        _vm._v("\n     › "),
        _c("span", { staticClass: "fontmedium" }, [
          _vm._v(_vm._s(_vm.due_date_changes[1]))
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }