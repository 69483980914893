<template>
  <div :class='container_classes'>
    <slot></slot>
  </div>
</template>

<script>
  export default {
    name: 'sweetAlert',
    props: ['value', 'container_classes'],
    mounted() {
      $(document).trigger('vue:sweetalert:added')
      $(this.$el).find("[data-vue-component]").on('vue-sweet-alert-success', ()=> {
        this.$emit('input', false)
      })
    }
  }
</script>

<style scoped>
</style>
