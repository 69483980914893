import TaskDetailModal from 'components/tasks/task_detail_modal';
import TimeLogModal from 'components/tasks/time_log_modal';
import TaskMarkCompleteModal from 'components/tasks/task_mark_complete_modal';

export const TaskDetailModalMixin = {
  components: {
    TaskDetailModal: TaskDetailModal,
    TimeLogModal: TimeLogModal,
    TaskMarkCompleteModal: TaskMarkCompleteModal
  }
}
