<template>
</template>
<script>
  import { createHelpers } from 'vuex-map-fields';
  const { mapFields } = createHelpers({
    getterType: 'getWorkOrderQuickShow',
    mutationType: 'updateWorkOrderQuickShow',
  });
  export default {
    name: 'WorkOrderOpener',
    computed: {
      ...mapFields('WorkOrderQuickViewWidget', [
        'quickViewWorkOrderId'
      ])
    },
    mounted() {
      var _this = this;
      $(document).on('open-wo-modal', function(event, data){
        if(!$.isEmptyObject(data)) {
          _this.openWorkOrder(data.id);
        }
      });
    },
    methods: {
      openWorkOrder(id) {
        this.quickViewWorkOrderId = (id || '').toString();
      }
    }
  }
</script>
