var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "product-create-modal" } },
    [
      _c(
        "si-modal",
        {
          ref: "workOrderCreateModal",
          attrs: { modalOptions: _vm.modalOptions },
          on: { onDismiss: _vm.resetData, onShown: _vm.focusField }
        },
        [
          _c("template", { slot: "modal-content" }, [
            _c(
              "form",
              {
                staticClass: "form",
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                  }
                }
              },
              [
                _c(
                  "div",
                  {},
                  [
                    _c("div", [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-md-12" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c(
                              "label",
                              {
                                staticClass: "required",
                                attrs: { for: "work_order_title" }
                              },
                              [_vm._v("Title")]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "form-control-group" },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.title,
                                      expression: "title"
                                    }
                                  ],
                                  ref: "workOrderTitle",
                                  staticClass: "form-control",
                                  attrs: { type: "text" },
                                  domProps: { value: _vm.title },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.title = $event.target.value
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c("error", {
                                  attrs: { errors: _vm.errors.title }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("small", { staticClass: "text-muted" }, [
                              _vm._v(
                                "Please include Customer, Description of item, PO number in the Title"
                              )
                            ])
                          ])
                        ])
                      ]),
                      _vm._v(" "),
                      _vm.wo_contact_present
                        ? _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-md-6" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "form-group",
                                  attrs: {
                                    "data-error-container": "form-group"
                                  }
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "required",
                                      attrs: { for: "work_order_account" }
                                    },
                                    [_vm._v("Account")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "form-control disabled-field p-t-b-10-hard text-truncate fs-13 lh-15-hard"
                                    },
                                    [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(_vm.account_details.name) +
                                          "\n                  "
                                      )
                                    ]
                                  )
                                ]
                              )
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-md-6" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "form-group",
                                  attrs: {
                                    "data-error-container": "form-group"
                                  }
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "required",
                                      attrs: { for: "work_order_contact" }
                                    },
                                    [_vm._v("Contact")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "form-control disabled-field p-t-b-10-hard text-truncate fs-13 lh-15-hard"
                                    },
                                    [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(_vm.contact_details.name) +
                                          "\n                  "
                                      )
                                    ]
                                  )
                                ]
                              )
                            ])
                          ])
                        : _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-md-6" }, [
                              _c("div", { staticClass: "form-group" }, [
                                _c(
                                  "div",
                                  { staticClass: "d-flex justify-between" },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "required",
                                        attrs: { for: "pr_create_title" }
                                      },
                                      [_vm._v("Account")]
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  [
                                    _c("ajax-select2", {
                                      attrs: {
                                        request_param: "name_cont",
                                        search_input_length: "2",
                                        options: _vm.account_options,
                                        request_path: _vm.accountSearchPath()
                                      },
                                      model: {
                                        value: _vm.account_id,
                                        callback: function($$v) {
                                          _vm.account_id = $$v
                                        },
                                        expression: "account_id"
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("error", {
                                      attrs: { errors: _vm.errors.account }
                                    }),
                                    _vm._v(" "),
                                    _c("error", {
                                      attrs: { errors: _vm.errors.account_id }
                                    })
                                  ],
                                  1
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-md-6" }, [
                              _c("div", { staticClass: "form-group" }, [
                                _c(
                                  "div",
                                  { staticClass: "d-flex justify-between" },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "required",
                                        attrs: { for: "pr_create_title" }
                                      },
                                      [_vm._v("Contact")]
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    attrs: {
                                      "data-toggle": "tooltip",
                                      "data-original-title": _vm.account_id
                                        ? ""
                                        : "Please select the account to see the associated contacts."
                                    }
                                  },
                                  [
                                    _c("dependent-select2", {
                                      ref: "contactOptions",
                                      attrs: {
                                        disabled: !_vm.account_id,
                                        toggle: _vm.account_id ? "" : "tooltip",
                                        title: "Please Select Account",
                                        selectType: "select2WithoutTags",
                                        parent: _vm.account_id,
                                        placeholder: "Please Select"
                                      },
                                      model: {
                                        value: _vm.contact_id,
                                        callback: function($$v) {
                                          _vm.contact_id = $$v
                                        },
                                        expression: "contact_id"
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("error", {
                                      attrs: { errors: _vm.errors.contact_id }
                                    }),
                                    _vm._v(" "),
                                    _c("error", {
                                      attrs: { errors: _vm.errors.contact }
                                    })
                                  ],
                                  1
                                )
                              ])
                            ])
                          ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-md-6" }, [
                        _c(
                          "label",
                          { staticClass: "required", attrs: { for: "" } },
                          [_vm._v("Due Date & Time")]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            {
                              staticClass: "col-md-6 col-sm-6 form-group",
                              attrs: { "data-error-container": "form-group" }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "event-dt-col event-date-col" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "form-control-group form-date-group"
                                    },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "cal-icon-right",
                                          attrs: { for: "work_order_due_date" }
                                        },
                                        [_vm._v("Due date")]
                                      ),
                                      _vm._v(" "),
                                      _c("date-field", {
                                        attrs: { id: "work_order_due_date" },
                                        model: {
                                          value: _vm.due_date,
                                          callback: function($$v) {
                                            _vm.due_date = $$v
                                          },
                                          expression: "due_date"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("error", {
                                        attrs: { errors: _vm.errors.due_date }
                                      }),
                                      _vm._v(" "),
                                      _c("error", {
                                        attrs: {
                                          errors: _vm.errors.due_datetime
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "col-md-6 col-sm-6 form-group",
                              attrs: { "data-error-container": "form-group" }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "event-dt-col event-time-col" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "form-control-group form-date-group"
                                    },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass:
                                            "cal-icon-right clock-icon",
                                          attrs: { for: "work_order_due_time" }
                                        },
                                        [_vm._v("Due time")]
                                      ),
                                      _vm._v(" "),
                                      _c("time-picker", {
                                        ref: "timeField",
                                        attrs: {
                                          field_name: "work_order[due_time]",
                                          field_value: _vm.default_time,
                                          element_id: "work_order_due_time",
                                          placeholder: ""
                                        },
                                        on: {
                                          time_value_update: function($event) {
                                            _vm.due_time = $event
                                          }
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("error", {
                                        attrs: { errors: _vm.errors.due_time }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              )
                            ]
                          )
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _c("WorkOrderTypeSelector", {
                      attrs: {
                        work_order_presentable_name:
                          _vm.work_order_presentable_name,
                        subclass_chain: _vm.subclass_chain,
                        internal_work_orders: _vm.internal_work_orders
                      }
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "row row-col-space-15 m-b-15" }, [
                      _c("div", { staticClass: "col-md-6" }, [
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-lg fontmedium btn-block fs-16 btn-with-icon icon-right",
                            attrs: { disabled: _vm.cannotSaveWorkOrder },
                            on: {
                              click: function($event) {
                                return _vm.saveWorkOrder(true)
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n                Continue Editing\n                "
                            ),
                            _c("span", { staticClass: "btn-icon" }, [
                              _c(
                                "svg",
                                {
                                  attrs: {
                                    xmlns: "http://www.w3.org/2000/svg",
                                    width: "16",
                                    height: "16",
                                    viewBox: "0 0 16 16"
                                  }
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      fill: "#646c9a",
                                      "fill-rule": "evenodd",
                                      d:
                                        "M14.293 8l-4.147-4.146a.5.5 0 0 1 .708-.708l5 5a.5.5 0 0 1 0 .708l-5 5a.5.5 0 0 1-.708-.708L14.293 9H.5a.5.5 0 0 1 0-1h13.793z"
                                    }
                                  })
                                ]
                              )
                            ])
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-md-6" }, [
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-save-draft btn-block btn-primary btn-lg fontmedium",
                            attrs: { disabled: _vm.cannotSaveWorkOrder },
                            on: {
                              click: function($event) {
                                return _vm.saveWorkOrder(false)
                              }
                            }
                          },
                          [_vm._v("Save as Draft")]
                        )
                      ])
                    ])
                  ],
                  1
                )
              ]
            )
          ])
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }