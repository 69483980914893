var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "16",
        height: "16",
        viewBox: "0 0 16 16"
      }
    },
    [
      _c("path", {
        attrs: {
          fill: "#646C9A",
          "fill-rule": "evenodd",
          d:
            "M12 0a1 1 0 0 1 1 1v1h1a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h1V1a1 1 0 1 1 2 0v1h6V1a1 1 0 0 1 1-1zm3 6H1v8a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V6zm-8.5 5a.5.5 0 1 1 0 1h-2a.5.5 0 1 1 0-1h2zm5 0a.5.5 0 1 1 0 1h-2a.5.5 0 1 1 0-1h2zm-5-3a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1h2zm5 0a.5.5 0 1 1 0 1h-2a.5.5 0 0 1 0-1h2zM14 3H2a1 1 0 0 0-1 1v1h14V4a1 1 0 0 0-1-1z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }