<template>
  <div v-if='this.task.timelogs && timelogs.length'>
    <div data-behavior="empty-time-log" class="row time-log-info-header d-flex align-center black fs-14 ">
      <div data-behavior="time-log-total-entries" class="fontmedium text-uppercase total-time-entry col-md-8">
        {{ timelogs.length }} ENTRIES
      </div>
      <span data-behavior="time-log-total-time-logged" class="fs-18 fontbold total-time-log col-md-4 text-right ls-04">
        {{ task.total_time_logs }}
      </span>
    </div>
    <div class="time-log-container">
      <div data-behavior="empty-time-log" class="row no-side-margin fs-11 semibold text-uppercase task-time-header ls-1 ">
        <div data-hook="name" class="time-flex-col col-md-3 padding-horizontal-none">
          Date
        </div>
        <div data-hook="date" class="time-flex-col col-md-6">
          NOTES
        </div>
        <div data-hook="date" class="time-flex-col col-md-3 padding-horizontal-none text-right">
          Time Logged
        </div>
      </div>
    </div>
    <template v-for='(logs, value) in groupBy("loggable_short_date")(timelogs)'>
      <div :key='value'>
        <div data-behavior="timelog-group-heading" :data-date-category="value" class="timelog-group-heading semibold light-gray">
          {{value | dateAndMonth }}
        </div>
        <template v-for='log in logs'>
          <div :key='log.id' data-behavior="resource-container" :data-id="log.id" data-form-url="/admin/work_orders/287/sub_work_orders/36/tasks/152/time_logs/422">
            <article data-behavior="resource-detail" class="row no-side-margin time-log-row">
              <div class="time-flex-col col-md-3 padding-horizontal-none fs-14 fontmedium black">
                <span>
                  <user-table-grid-content :user_id='log.user.id'/>
                </span>
              </div>
              <div data-hook="date" class="time-flex-col col-md-6">
                {{ log.description }}
              </div>
              <div data-attribute="time-log-loggable-date" data-value="07/26/2019" class="hide"></div>
              <div data-attribute="time-log-duration" :data-value="log.duration" class="time-flex-col col-md-3 padding-horizontal-none text-right">
                <span class="fs-14 dark-gray ls-04 semibold">{{log.formatted_duration}}</span>
                <div data-menu="dropdown" class="dropdown card-header-dropdown clickable-item d-inline-block smart-dropdown-menu" v-if='currentUser.owner_or_super_admin'>
                  <button type="button" data-toggle="dropdown" class="btn btn-xs dropdown-toggle js-show-button">
                    <span class="dots top-2">
                      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="4" viewBox="0 0 18 4">
                        <g fill="#D8E1E9" fill-rule="evenodd">
                          <circle cx="9" cy="2" r="2"></circle>
                          <circle cx="16" cy="2" r="2"></circle>
                          <circle cx="2" cy="2" r="2"></circle>
                        </g>
                      </svg>
                    </span>
                  </button>
                  <ul class="dropdown-menu dropdown-menu-right">
                    <li>
                      <a data-behavior="time-log-edit" href="javascript:void(0)" @click="editTimeLog(log)">
                        Edit
                      </a>
                    </li>
                    <li>
                      <destroy-button
                        @input='deleteTimeLog(log.id)'
                        data_confirm_button_text="Delete Permanently"
                        data_heading="Delete TimeLog"
                        :data_sweet_alert_confirm="'Are you sure, you want to delete time log entry of ' + log.formatted_duration + ' hours?'"
                        data-confirm-type="delete"
                        value="Delete">
                      </destroy-button>
                    </li>
                  </ul>
                </div>
              </div>
            </article>
            <template v-if='!currentUser.owner_or_super_admin && log.editable && !task.is_completed'>
              <div class="time-edit-row text-center row no-side-margin" data-behavior="action-buttons-container">
                <div class="p-10 fs-13">
                  <span>This entry can be edited or deleted till {{ log.editabled_till }}.</span>

                  <span>
                    <a data-behavior="time-log-edit" href="javascript:void(0)" @click="editTimeLog(log)" class="fontbold text-underline">
                      Edit
                    </a>

                    or
                    <destroy-button
                      @input='deleteTimeLog(log.id)'
                      data_confirm_button_text="Delete Permanently"
                      data_heading="Delete TimeLog"
                      :data_sweet_alert_confirm="'Are you sure, you want to delete time log entry of ' + log.formatted_duration + ' hours?'"
                      data-confirm-type="delete"
                      class_names="pointer fontbold text-underline"
                      value="Delete">
                    </destroy-button>
                  </span>
                </div>
              </div>
            </template>
          </div>
        </template>
      </div>
    </template>
  </div>
  <div v-else>
    <div class="full-width text-center">
      <div class="no-result padding-horizontal-none">
          <div class='text-center'>
            <i class="fa fa-list-alt large-icon-font" aria-hidden="true"></i>
          </div>
          <div class="messgae text-center">There are no time logs</div>
      </div>
    </div>
  </div>
</template>

<script>

  import destroyButton from 'components/shared/buttons/destroy_button.vue'
  import UserTableGridContent from 'components/shared/users/user_table_grid_content.vue';
  import { mapActions, mapMutations, mapState } from 'vuex';
  import { createHelpers } from 'vuex-map-fields';

  const { mapFields: mapTaskDetailFields } = createHelpers({
    getterType: 'getTask',
    mutationType: 'updateTask'
  });

  export default {
    name: 'taskTimeLog',
    mounted() {
      this.timelogs && this.timelogs.map((log) => {
        if(log.editable) {
          window.setTimeout(() => {
            log.editable = false
          }, Number(log.seconds_to_cutoff) * 1000)
        }
      })
    },
    methods: {
      groupBy(key) {
        return array =>
          array.reduce((objectsByKeyValue, obj) => {
            const value = obj[key];
            objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
            return objectsByKeyValue;
          }, {});
      },
      editTimeLog(log) {
        this.$emit('edit-time-log', { ...log })
      },
      deleteTimeLog(logId) {
        this.deleteTaskTimeLog({
          apollo: this.$apollo,
          showAlert: this.$root.showAlert,
          logId: logId,
          componentObject: this
        }).then(() => {
          this.$emit('changed')
        })
      },
      ...mapActions('TaskDetailModalWidget', ['deleteTaskTimeLog']),
    },
    computed: {
      timelogs: function() {
        return this.task.timelogs
      },
      ...mapTaskDetailFields('TaskDetailModalWidget', ['task']),
      ...mapState('GeneralConfiguration', ['currentUser'])
    },
    components: {
      destroyButton,
      UserTableGridContent
    }
  };

</script>
