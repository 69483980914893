var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dropdown-div d-flex" },
    [
      _c(
        "a",
        {
          class: [{ disabled: _vm.ajaxRequestSent }, _vm.buttonClasses],
          attrs: { "data-task-alert-update": "true", href: "javascript:" },
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.getArchiveDescription($event)
            }
          }
        },
        [_vm._v("Archive")]
      ),
      _vm._v(" "),
      _c(
        "sweet-alert",
        {
          attrs: { container_classes: "dropdown-div d-flex hide" },
          on: { input: _vm.archiveCurrentWorkOrder }
        },
        [
          _c(
            "a",
            {
              ref: "archiveLink",
              staticClass: "full-width nowrap-hard hide",
              attrs: {
                href: "javascript:void(0)",
                "data-description": _vm.archiveDescription,
                "data-sweet-alert-confirm": _vm.alertConfirmText,
                "data-heading": _vm.headingText,
                "data-confirm-type": "archive",
                "data-vue-component": "true",
                "data-confirm-button-text": "Archive",
                "data-cancel-button-text": "Discard"
              }
            },
            [_vm._v("Archive")]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }