var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "clickmenu d-flex align-center h-full notification-menu justify-center",
      class: { open: _vm.openNotificationWidget },
      on: {
        click: function($event) {
          $event.stopPropagation()
        }
      }
    },
    [
      _c(
        "a",
        {
          staticClass: "dropdown-toggle relative pointer",
          on: {
            click: function($event) {
              _vm.openNotificationWidget = !_vm.openNotificationWidget
            }
          }
        },
        [
          _c("span", { staticClass: "notification-icon-control" }, [
            _vm.getUnreadCount
              ? _c("span", { staticClass: "notification-indicator" }, [
                  _vm._v("\n        " + _vm._s(_vm.getUnreadCount) + "\n      ")
                ])
              : _vm._e()
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "dropdown-menu dropdown-menu-right notification-dropdown-menu"
        },
        [
          _c("div", { staticClass: "arrow" }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "d-flex justify-between notification-title-bar align-center"
            },
            [
              _c(
                "span",
                { staticClass: "fs-11 super-light ls-1 text-uppercase" },
                [_vm._v(_vm._s(_vm.$t("web_notifications.title")))]
              ),
              _vm._v(" "),
              _c("span", [
                _vm.getNotifications.length
                  ? _c(
                      "a",
                      {
                        staticClass: "mark-read-btn",
                        on: { click: _vm.markAllNotificationsRead }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("web_notifications.mark_all_read")) +
                            "\n        "
                        )
                      ]
                    )
                  : _vm._e()
              ])
            ]
          ),
          _vm._v(" "),
          !_vm.notificationsFetched
            ? [
                _c("div", { staticClass: "notifications-loading" }, [
                  _vm._v(_vm._s(_vm.$t("default_loading_message")))
                ])
              ]
            : [
                _vm.getNotifications.length
                  ? [
                      _c("notification-list", {
                        attrs: {
                          notifications: _vm.getNotifications,
                          storeModule: "NotificationWidgetModule"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "notification-footer-bar d-flex align-center justify-center"
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass:
                                "view-all-notification-btn semibold btn-block",
                              attrs: { href: _vm.redirectToAllNotifications() }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.$t(
                                      "web_notifications.view_all_notification"
                                    )
                                  ) +
                                  "\n          "
                              )
                            ]
                          )
                        ]
                      )
                    ]
                  : [
                      _c("div", { staticClass: "no-notifications-present" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.$t("web_notifications.no_notifications")
                            ) +
                            "\n        "
                        )
                      ])
                    ]
              ]
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }