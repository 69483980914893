<template>
  <div>
    <div data-behavior="shared-account-quick-add-modal-container">
      <si-modal :modalOptions="modalOptions" @onDismiss="resetAccountForm" @onShown='focusField' ref="quickAddAccountModal">
        <template slot="modal-content">
          <form class="form" @submit.prevent>
            <article class="new-account-form-container p-t-15">

              <div class="form-group p-b-5">
                <label for="account_name" class="required">{{$t('accountQuickAddModal.name_label')}}</label>
                <input class="form-control" v-model="current_account_object.name" type="text" autocomplete="off" name="account[name]" id="account_name" ref="accountName">
                <error :errors='current_account_object.errors.name'></error>
              </div>

              <div class="form-group p-b-5">
                <label for="account_name" class="required">Abbreviated Name</label>
                <input class="form-control" v-model="current_account_object.abbreviated_name" type="text" autocomplete="off" name="account[abbreviated_name]" id="account_abb_name" v-uppercase />
                <error :errors='current_account_object.errors.abbreviated_name'></error>
              </div>

              <!-- <div class="form-group p-b-5">
                <label class="required" for="account_email">{{$t('accountQuickAddModal.email_label')}}</label>
                <input class="form-control" v-model="current_account_object.email" type="text" autocomplete="off" name="account[email]" id="account_email">
                <error :errors='current_account_object.errors.email'></error>
              </div>
 -->
              <div class="form-group p-b-5">
                <div class="semibold fs-16 dark" name="account[information]" id="account_information">{{$t('accountQuickAddModal.target_account_information')}}</div>
                <span class="fs-12 text-muted light-color" name="account[helper_text]" id="account_helper_text">{{$t('accountQuickAddModal.target_account_information_helper_text')}}</span>
              </div>

              <div class="row p-b-5">

                <div class="col-md-6 form-group form-control-group">
                  <label class="required" for="account_parent_size">{{$t('accountQuickAddModal.company_size_label')}}</label>
                  <v-select name="account[parent_size]" id="account_parent_size" :options="employee_count_options_select2" v-model="current_account_object.qualification_criteria.employees_count_range" :placeholder=" 'Please Select' " :clearable="false">
                  </v-select>
                  <error :errors='current_account_object.qualification_criteria.errors.employees_count_range'></error>
                </div>

                <div class="col-md-6 form-group form-control-group">
                  <label class="required" for="account_funding_raised">{{$t('accountQuickAddModal.funding_label')}}</label>
                  <v-select :options="funding_options_select2" name="account[funding_raised]" id="account_funding_raised" v-model="current_account_object.qualification_criteria.funding_raised_range" :placeholder=" 'Please Select' " :clearable="false">
                  </v-select>
                  <error :errors='current_account_object.qualification_criteria.errors.funding_raised_range'></error>
                </div>
              </div>

              <div class="row p-b-5">
                <div class="col-md-6 col-sm-6 form-group normal">
                  <label for="potential_rating" class='lh-143 required'>{{ $i18n.t("accountQuickAddModal.potential_rating") }}</label>
                  <span class="inline-block">
                    <v-popover openClass='popover-dark' trigger='hover' popoverArrowClass='tooltip-arrow'>
                      <span class="fa fa-info-circle pointer fs-16"></span>
                      <template slot='popover'>
                        <ul class="list-unstyled text-left text-white fs-12 m-b-0">
                          <li class="m-b-3">
                            Potential Revenue over next 12 months: 
                          </li>
                          <li class="m-b-3">
                            <i class="fa fa-circle p-r-5 fs-7" aria-hidden="true"></i>
                            A: >$100K
                          </li>
                          <li class="m-b-3">
                            <i class="fa fa-circle p-r-5 fs-7" aria-hidden="true"></i>
                            B: $50K - $99K
                          </li>
                          <li class="m-b-3">
                            <i class="fa fa-circle p-r-5 fs-7" aria-hidden="true"></i>
                            C: $25K - $49K
                          </li>
                          <li class="m-b-3">
                            <i class="fa fa-circle p-r-5 fs-7" aria-hidden="true"></i>
                            NULL: <$25K
                          </li>
                        </ul>
                      </template>
                    </v-popover>
                  </span>
                  <v-select :options="rating_options_select2" name="account[potential_rating]" id="account_potential_rating" v-model="current_account_object.potential_rating" :placeholder=" 'Please Select' " :clearable="false">
                  </v-select>
                  <error :errors='current_account_object.errors.potential_rating'/>
                </div>

                <div class="col-md-6 col-sm-6 form-group normal">
                  <label for="estimated_spend">Estimated Spend</label>
                  <input class="form-control" v-model="current_account_object.estimated_spend" type="number" autocomplete="off" name="account[estimated_spend]" id="account_estimated_spend" />
                  <error :errors='current_account_object.errors.estimated_spend'></error>
                </div>
              </div>

              <div class="row no-side-margin">
                <div class="p-b-10">
                  <label class="si-checkbox dark">
                    <input type="checkbox" name="account[special_event]" id="account_special_event" v-model="current_account_object.qualification_criteria.special_event">
                    <span class="helper size18"></span>
                    <span class="helper-label fs-14">
                      {{ $t('accountQuickAddModal.special_event') }}
                    </span>
                  </label>
                </div>

                <div class="p-b-10">
                  <label class="si-checkbox dark">
                    <input type="checkbox" name="account[large_customer_affilates]" id="account_large_customer_affilates" v-model="current_account_object.qualification_criteria.large_customer_affilates">
                    <span class="helper size18"></span>
                    <span class="helper-label fs-14">
                      {{ $t('accountQuickAddModal.large_customer_affiliate') }}
                    </span>
                  </label>
                </div>
              </div>

              <div class="row row-col-space-15 m-t-20 m-b-15">
                <div class="col-md-6">
                  <button type="button" :class="{disabled: ajaxRequestSent}" class="btn btn-lg fontmedium btn-block fs-16 btn-with-icon icon-right" @click="continueEditing(true)">
                    {{$t('accountQuickAddModal.continue_editing')}}
                    <span class="btn-icon"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path fill="#646c9a" fill-rule="evenodd" d="M14.293 8l-4.147-4.146a.5.5 0 0 1 .708-.708l5 5a.5.5 0 0 1 0 .708l-5 5a.5.5 0 0 1-.708-.708L14.293 9H.5a.5.5 0 0 1 0-1h13.793z"></path></svg></span>
                  </button>
                </div>
                <div class="col-md-6">
                  <button type="submit" class="btn btn-lg btn-primary btn-block fs-16 fontmedium" :disabled="ajaxRequestSent" @click="sendValidateAccountRequest(false)">{{$t('accountQuickAddModal.submit')}}</button>
                </div>
              </div>
            </article>
          </form>
        </template>
      </si-modal>
    </div>

    <div>

      <div data-behavior="account-target-confirmation-modal-container" class="modal-480 modal-tm-130 modal-ghost-header">
        <si-modal :modalOptions="targetConfirmationModalOptions" ref="accountTargetConfirmationModal" @onDismiss="restoreFormModalConfig">
          <template slot="modal-content">
            <div class="p-l-r-10 text-center word-wrap">
              <div class='circle-icon blue-bg'>
                <i class="icon--bizdev"></i>
              </div>

              <div class='fs-22 dark p-t-b-20 fontmedium' name="account[target]" id="account_target">
                {{$t('accountQuickAddModal.targetConfirmationHeading')}}
              </div>

              <div class='account-detail-info  fs-16 light p-b-5 fontmedium' name="account[object_name]" id="account_object_name">
                {{current_account_object.name}}
              </div>

              <hr class="extra-light-border"/>

              <div class='account-detailed-space'>
                <ul class='list-group lgi-36 fs-13 no-horizontal-border p-t-10 last-child-no-border m-b-10'>
                  <!-- <li class="list-group-item padding-horizontal-none no-top-border">
                    <div class="row">
                      <div class="col-md-5 col-sm-5">{{$t('accountQuickAddModal.contact_email_id')}}</div>
                      <div class="col-md-7 col-sm-7 dark fs-14">{{current_account_object.email}}</div>
                    </div>
                  </li> -->
                  <li class="list-group-item p-t-b-7 padding-horizontal-none no-top-border">
                    <div class="row">
                      <div class="col-md-5 col-sm-5" name="account[company_size_label]" id="account_company_size_label">{{$t('accountQuickAddModal.company_size_label')}}</div>
                      <div class="col-md-7 col-sm-7 dark fs-14" name="account[count_range]" id="account_count_range">{{current_account_object.qualification_criteria.employees_count_range}}</div>
                    </div>
                  </li>
                  <li class="list-group-item p-t-b-7 padding-horizontal-none extra-light-border">
                    <div class="row">
                      <div class="col-md-5 col-sm-5" name="account[funding_label]" id="account_funding_label">{{$t('accountQuickAddModal.funding_label')}}</div>
                      <div class="col-md-7 col-sm-7 dark fs-14" name="account[funding_raised_range]" id="account_funding_raised_range">${{current_account_object.qualification_criteria.funding_raised_range}}</div>
                    </div>
                  </li>
                  <li class="list-group-item p-t-b-7 padding-horizontal-none extra-light-border">
                    <div class="row">
                      <div class="col-md-5 col-sm-5" name="account[large_customer_affiliate]" id="account_large_customer_affilates">{{$t('accountQuickAddModal.large_customer_affiliate')}}</div>
                      <div class="col-md-7 col-sm-7 dark fs-14" name="account[large_customer_affilates1]" id="account_large_customer_affilates1">{{current_account_object.qualification_criteria.large_customer_affilates | formatBoolean }}</div>
                    </div>
                  </li>
                  <li class="list-group-item p-t-b-7 padding-horizontal-none extra-light-border">
                    <div class="row">
                      <div class="col-md-5 col-sm-5" name="account[special_event1]" id="account_special_event1">{{$t('accountQuickAddModal.special_event')}}</div>
                      <div class="col-md-7 col-sm-7 dark fs-14" name="account[qualification_criteria_special_event]" id="account_qualification_criteria_special_event">{{current_account_object.qualification_criteria.special_event | formatBoolean }}</div>
                    </div>
                  </li>
                </ul>
              </div>

              <div class="row row-col-space-20 p-t-b-15">
                <div class="col-md-6">
                  <button type="button" class="btn btn-lg btn-block fs-16 fontmedium blue relative" @click="hideTargetConfirmationModal">
                    <span class="btn-icon-helper left-center m-l-20 size24 back-nav"></span>
                    {{$t('accountQuickAddModal.cancel')}}
                  </button>
                </div>
                <div class="col-md-6">
                  <button type="button" class="btn btn-lg btn-primary btn-block fs-16 fontmedium" :disabled="ajaxRequestSent" @click="sendCreateAccountRequest">{{$t('accountQuickAddModal.save_text')}}</button>
                </div>
              </div>
            </div>
          </template>
        </si-modal>
      </div>

      <div data-behavior="account-not-target-confirmation-modal-container" class="modal-480 modal-tm-70 modal-ghost-header">
        <si-modal :modalOptions="nonTargetConfirmationModalOptions" ref="accountNotTargetConfirmationModal" @onDismiss="restoreFormModalConfig">
          <template slot="modal-content">
            <div class="p-l-r-10 word-wrap">
              <div class="text-center">
                <div class='circle-icon red-bg'>
                  <i class="icon--bizdev"></i>
                </div>
              </div>

              <div class='fs-22 dark p-t-b-20 fontmedium text-center' name="account[head]" id="account_head">
                {{$t('accountQuickAddModal.nonTargetConfirmationHeading')}}
              </div>

              <div class='account-detail-info  fs-16 light p-b-5 fontmedium text-center' name="account[object_name]" id="account_object_name">
                {{current_account_object.name}}
              </div>

              <hr class="extra-light-border"/>

              <div class='account-detailed-space'>
                <ul class='list-group lgi-36 fs-13 no-horizontal-border p-t-10 last-child-no-border m-b-10'>
                  <!-- <li class="list-group-item padding-horizontal-none no-top-border">
                    <div class="row">
                      <div class="col-md-5 col-sm-5">{{$t('accountQuickAddModal.contact_email_id')}}</div>
                      <div class="col-md-7 col-sm-7 dark fs-14">{{current_account_object.email}}</div>
                    </div>
                  </li> -->
                  <li class="list-group-item p-t-b-7 padding-horizontal-none no-top-border">
                    <div class="row">
                      <div class="col-md-5 col-sm-5" name="account[company_size_label]" id="account_company_size_label">{{$t('accountQuickAddModal.company_size_label')}}</div>
                      <div class="col-md-7 col-sm-7 dark fs-14" name="account[employees_count_range]" id="account_employees_count_range">{{current_account_object.qualification_criteria.employees_count_range}}</div>
                    </div>
                  </li>
                  <li class="list-group-item p-t-b-7 padding-horizontal-none extra-light-border">
                    <div class="row">
                      <div class="col-md-5 col-sm-5" name="account[funding_label]" id="account_funding_label">{{$t('accountQuickAddModal.funding_label')}}</div>
                      <div class="col-md-7 col-sm-7 dark fs-14" name="account[funding_raised_range]" id="account_funding_raised_range">${{current_account_object.qualification_criteria.funding_raised_range}}</div>
                    </div>
                  </li>
                  <li class="list-group-item p-t-b-7 padding-horizontal-none extra-light-border">
                    <div class="row">
                      <div class="col-md-5 col-sm-5" name="account[large_customer_affiliate]" id="account_large_customer_affiliate">{{$t('accountQuickAddModal.large_customer_affiliate')}}</div>
                      <div class="col-md-7 col-sm-7 dark fs-14" name="account[large_customer_affiliate]" id="account_large_customer_affiliate">{{current_account_object.qualification_criteria.large_customer_affilates | formatBoolean }}</div>
                    </div>
                  </li>
                  <li class="list-group-item p-t-b-7 padding-horizontal-none extra-light-border">
                    <div class="row">
                      <div class="col-md-5 col-sm-5" name="account[special_event]" id="account_special_event">{{$t('accountQuickAddModal.special_event')}}</div>
                      <div class="col-md-7 col-sm-7 dark fs-14" name="account[special_event1]" id="account_special_event1">{{current_account_object.qualification_criteria.special_event | formatBoolean }}</div>
                    </div>
                  </li>
                </ul>
              </div>

              <div class="row row-col-space-20 p-t-10 p-b-15">
                <div class="col-md-6">
                  <button type="button" class="btn btn-lg btn-block fs-16 fontmedium blue relative" @click="hideNotTargetConfirmationModal">
                    <span class="btn-icon-helper left-center m-l-20 size24 back-nav"></span>
                    {{$t('accountQuickAddModal.cancel')}}
                  </button>
                </div>
                <div class="col-md-6">
                  <button type="button" class="btn btn-lg btn-primary btn-block fs-16 fontmedium" :disabled="ajaxRequestSent" @click="sendCreateAccountRequest">{{$t('accountQuickAddModal.save_text')}}</button>
                </div>
              </div>
            </div>
          </template>
        </si-modal>
      </div>
    </div>
  </div>
</template>

<script>
  import siModal from 'components/shared/si_modal.vue';
  import { DefaultMixins } from 'packs/default.js'
  import error from 'components/shared/error.vue';
  import vSelect from 'vue-select';
  import * as deepmerge from 'deepmerge'

  import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
  import { createHelpers } from 'vuex-map-fields';

  const { mapFields: mapQuickAccountFields } = createHelpers({
    getterType: 'getAccount',
    mutationType: 'createAccount',
  });

  const { mapFields: mapQuickContactFields } = createHelpers({
    getterType: 'getContact',
    mutationType: 'createContact'
  });

  export default {
    name: 'AccountQuickAddModal',
    props: ['funding_options', 'employee_count_options', 'potential_rating_options'],
    data() {
      return {
        default_account: {},
        continue_editing: false,
        modalOptions: {
          title: this.$t('accountQuickAddModal.title'),
          modalContainerClass : 'new-account-modal',
          modalId: 'quick_add_account_modal',
          topBarColor:'',
          modalClasses: ''
        },
        nonTargetConfirmationModalOptions: {
          title: '',
          modalContainerClass : 'account-target-confirmation-modal',
          modalId: 'account_target_confirmation_modal',
          topBarColor:''
        },
        targetConfirmationModalOptions: {
          title: '',
          modalContainerClass : 'account-non-target-confirmation-modal',
          modalId: 'account_non_target_confirmation_modal',
          topBarColor:''
        },
        employee_count_options_select2: JSON.parse(this.employee_count_options),
        funding_options_select2: JSON.parse(this.funding_options),
        rating_options_select2: JSON.parse(this.potential_rating_options),
        emptyAccountObject: {
          name: '',
          email: '',
          abbreviated_name: '',
          qualification_criteria: {
            funding_raised_range: '',
            large_customer_affilates: false,
            special_event: false,
            employees_count_range: '',
            errors: {}
          },
          errors: {}
        }
      };
    },
    components: {
      siModal,
      error,
      vSelect
    },
    computed: {
      ...mapState('AccountQuickAddWidget', []),
      ...mapQuickAccountFields('AccountQuickAddWidget', [
                               'current_account_object',
                               'accountCreateSuccessful',
                               'ajaxRequestSent',
                               'accountModalStatus',
                               'skipAccountValidation',
                               'createdAccountData'
                              ]),
      ...mapQuickContactFields('ContactQuickAddWidget', ['preselected_account_id'])
    },
    watch: {
      accountModalStatus: function(val) {
        if(val) {
          this.$refs.quickAddAccountModal.displayModal();
        }
      }
    },
    methods: {
      ...mapActions('AccountQuickAddWidget', ['requestAccountCreate', 'requestAccountValid']),
      resetAccountForm() {
        this.current_account_object = deepmerge({}, this.emptyAccountObject);
        this.accountModalStatus = false;
        this.skipAccountValidation = false;
      },
      focusField() {
        this.$refs.accountName.focus();
      },
      hideAccountFormModal() {
        this.hideConfirmationModal();
        this.$refs.quickAddAccountModal.hideModal();
      },
      sendValidateAccountRequest(continue_editing) {
        this.ajaxRequestSent = true;
        this.continue_editing = continue_editing
        if(this.skipAccountValidation) {
          this.sendCreateAccountRequest();
        } else {
          this.requestAccountValid({ apollo: this.$apollo, showAlert: this.$root.showAlert, componentObject: this });
        }
      },
      continueEditing(continue_editing) {
        this.continue_editing = continue_editing
        this.sendCreateAccountRequest()
      },
      sendCreateAccountRequest() {
        this.ajaxRequestSent = true;
        this.requestAccountCreate({ apollo: this.$apollo, showAlert: this.$root.showAlert, componentObject: this, continue_editing: this.continue_editing })
        .then((account)=>{
          if(this.skipAccountValidation) {
            this.createdAccountData = account;
          } else {
            this.preselected_account_id = account.id;
          }
          this.hideAccountFormModal();
        });
      },
      hideTargetConfirmationModal() {
        this.$refs.accountTargetConfirmationModal.hideModal();
      },
      hideNotTargetConfirmationModal() {
        this.$refs.accountNotTargetConfirmationModal.hideModal();
      },
      showConfirmationModal() {
        this.modalOptions.modalClasses = 'last-modal';
        if (this.current_account_object.is_target) {
          this.$refs.accountTargetConfirmationModal.displayModal();
        } else {
          this.$refs.accountNotTargetConfirmationModal.displayModal();
        }
      },
      hideConfirmationModal() {
        if (this.current_account_object.is_target) {
          this.hideTargetConfirmationModal();
        } else {
          this.hideNotTargetConfirmationModal();
        }
      },
      restoreFormModalConfig() {
        this.modalOptions.modalClasses = '';
        this.$refs.quickAddAccountModal.focusModal();
      }
    }
  };
</script>
