<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
    <path
      fill="#646C9A"
      fill-rule="evenodd"
      d="M2.5 0a.5.5 0 0 1 .5.5v10.55c.98.2 1.751.97 1.95 1.95H15.5a.5.5 0 1 1 0 1H4.95A2.5 2.5 0 1 1 2 11.05V1H.5a.5.5 0 0 1 0-1h2zm0 12a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM13 2a2 2 0 0 1 2 2v4a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h5zm0 1H8a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1z"
    />
  </svg>
</template>

<script>
export default {
  name: 'OrdersIcon'
}
</script>
