import * as constant from './autocomplete_constants.js'

export const AutocompleteMethods = {
  filterFollowers(searchedVal) {
    let _this = this;
    return new Promise(function(resolve) {
      let options = _this.followerOptions.filter(function(userHash) {
        let condition = !_this.getEntityIds().includes(String(userHash.id)),
          searchedValLower = '';
        if(searchedVal) {
          searchedValLower = searchedVal.toLowerCase();
          condition = condition && (userHash.name.toLowerCase().includes(searchedValLower) || userHash.email.toLowerCase().includes(searchedValLower));
        }
        return condition;
      });
      let sortedOptions = options.sort(function (firstOption, secondOption) {
        return firstOption.name.toLowerCase().localeCompare(secondOption.name.toLowerCase());
      });
      return resolve(sortedOptions.slice(0, _this.maxFollowersSuggestion()));
    });
  },

  maxFollowersSuggestion() {
    return constant.MAX_FOLLOWERS_SUGGESTION;
  },

  populateFollowersList(data) {
    return `
      <span class='card-assign-user normal'><img class='img-responsive' src="${data.profile_picture_url}" /></span>
      <div class="follower-detail">
      <p class="auto-follower-name">${data.name}</p>
      ${data.email}
      </div>
    `
  },
  callInputEvent: function(e, autcompleteVueComponent) {
    autcompleteVueComponent.handleInput(e);
  }
}

export default AutocompleteMethods;
