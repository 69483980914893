var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "16",
        height: "16",
        viewBox: "0 0 16 16"
      }
    },
    [
      _c("path", {
        attrs: {
          fill: "#646C9A",
          "fill-rule": "evenodd",
          d:
            "M3 16a3 3 0 0 1-3-3V9h4V2a2 2 0 0 1 2-2h10v13a3 3 0 0 1-3 3H3zM15 1H6a1 1 0 0 0-1 1v13h8a2 2 0 0 0 2-2V1zM4 10H1v3a2 2 0 0 0 2 2h1v-5zm8.5 0a.5.5 0 1 1 0 1h-5a.5.5 0 1 1 0-1h5zm0-3a.5.5 0 1 1 0 1h-5a.5.5 0 0 1 0-1h5zm0-3a.5.5 0 1 1 0 1h-5a.5.5 0 0 1 0-1h5z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }