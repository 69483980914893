<template>
  <div class="account-info text-left fs-13">

    <div class="full-width d-flex justify-between m-b-20 border-b si-border p-b-10 p-t-10">
      <a class="fs-16 semibold" :href="accountShowPath(loadedAccountData)">
        {{ loadedAccountData.name }}
      </a>

      <div class="m-l-5">
        <span v-if="loadedAccountData.is_target" class="target-account label si-label">{{$i18n.t('accounts.target') }}</span>
        <span class="target-account label si-label non-target-account" v-else>{{$i18n.t('accounts.non_target') }}</span>
      </div>
    </div>

    <ul class="list-unstyled m-b-0">
      <li class="row border-b si-border m-b-5 margin-horizontal-none p-b-5">
        <span class="col-md-5 p-l-0 ls-03 text-muted">{{$i18n.t('accounts.account') }}</span>
        <span class="col-md-4 p-r-0 semibold dark">{{ loadedAccountData.number }}</span>
        <div class="col-md-3 p-r-0 d-flex justify-flex-end">
          <img :src="loadedAccountData.profile_picture" class="img img-responsive" width="20px">
        </div>
      </li>

      <li class="row border-b si-border m-b-5 margin-horizontal-none p-b-5">
        <span class="col-md-5 p-l-0 ls-03 text-muted">{{$i18n.t('accounts.potential_rating') }}</span>
        <span class="col-md-7 semibold dark">{{ loadedAccountData.potential_rating }}</span>
      </li>

      <li class="row border-b si-border m-b-5 margin-horizontal-none p-b-5">
        <span class="col-md-5 p-l-0 ls-03 text-muted">{{$i18n.t('accounts.last_ordered_at') }}</span>
        <span class="col-md-7 p-r-0 semibold dark">
          <span v-if="loadedAccountData.last_ordered_at">
            {{ loadedAccountData.last_ordered_at | formatSiDateExpanded }}
          </span>
          <span v-else>
            {{ $i18n.tc('default_no_data') }}
          </span>
        </span>
      </li>

      <li class="row m-b-10 margin-horizontal-none p-b-10">
        <span class="col-md-5 p-l-0 ls-03 text-muted">{{$i18n.t('accounts.current_year_spend') }}</span>
        <span class="semibold dark col-md-7 p-r-0">{{ loadedAccountData.current_year_spend }}</span>
      </li>
    </ul>
  </div>
</template>
<script>
  import { mapState } from 'vuex';
  import { accountShowPath } from 'concerns/path_helper_methods.js';

  export default {
    name: 'AccountTooltipHover',
    props: {
      accountId: String
    },
    computed: {
      ...mapState('GeneralConfiguration', ['accountsData']),
      loadedAccountData: function() {
        return this.accountsData[this.accountId];
      }
    },
    methods: {
      accountShowPath
    }
  }
</script>
