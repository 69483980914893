<template>
  <div ref='timeLogModal' class="modal padding-left-none-hard padding-right-none-hard" id="time_log" tabindex="-1" role="dialog" aria-labelledby="new_task" aria-hidden="true" data-modal=true>
    <div class="modal-dialog si-modal modal-modal-dialog" role="document">
      <div class="modal-content gutter-20">
        <aside class="modal-header m-b-0 default">
          <div class="black-2 fs-13 modal-title-color m-r-15 p-r-15" id="new_task">
            {{ currentLoggable.class_name }}:
            <span>{{ currentLoggable.id }}</span>
            <i class="fa fa-circle small align-vertical-middle"></i>
            <span data-behavior='task-title'>{{ currentLoggable.title }}</span>
          </div>

          <button type="button" class="modal-close-btn" data-dismiss="modal" aria-label="Close" @click='hideModal'></button>
          <div data-behavior="ajax-loader" class="ajax-loading si-page-loader">
            <div class="bar bar-1"></div>
            <div class="bar bar-2"></div>
            <div class="bar bar-3"></div>
            <div class="bar-complete bar-1"></div>
          </div>
        </aside>

        <div class="row margin-horizontal-none m-b-10" v-if='currentLoggable.cancelled_at'>
          <div class="alert alert-danger m-b-0 text-center">
            <span class="btn-icon-helper cancel-task-info-icon"></span>
            {{ this.$i18n.t('task_modal.cancelled_text') }} {{ currentLoggable.cancelled_at | formatSIExpandedDateTime }}
          </div>
        </div>

        <div class="modal-body m-t-20">
          <article class="">
            <aside class="si-form-content">
              <div class="si-form-data">
                <form @submit.prevent>
                  <div data-behavior="task-fields-container" data-nested-task='container' class="">

                    <div class="row row-col-space-10 task-timer-container d-flex" data-behavior="nested-time-log-container">
                      <div class="col-md-3 timer-group form-group" data-error-container='form-group'>
                        <input type="text" name="task_time_log[duration]" class="form-control place-holder form-control-timer text-center" :placeholder="$tc('task_time_log_modal.duration_placeholder')" v-model='time_log_object.duration'>
                        <error :errors="time_log_object.errors && time_log_object.errors.duration"></error>
                      </div>

                      <div class="col-md-7 task-description-group form-group" data-error-container='form-group'>
                        <input type="text" name="task_time_log[duration]" class="form-control place-holder" :placeholder="$tc('task_time_log_modal.notes_placeholder')" v-model='time_log_object.description'>
                        <error :errors="time_log_object.errors && time_log_object.errors.description"></error>
                      </div>

                      <div class="col- form-group task-date-group demo" data-error-container="form-group">
                        <label class="form-date-group">
                          <date-field :not_format="true" class_names='modal-date-icon' v-model='time_log_object.loggable_date'></date-field>
                          <error :errors="time_log_object.errors && time_log_object.errors.loggable_date"></error>
                        </label>
                      </div>
                    </div>

                  </div>
                  <div class="action-button">
                    <a href="javascript:void(0)" class="btn btn-si text-uppercase" data-dismiss='modal' @click='hideModal'>Cancel</a>
                    <input type="submit" class="btn btn-primary text-uppercase m-l-10"  @click.prevent='saveTimeLogEntry' :class="{ 'disabled' : ajaxRequestSent }" value="Save Entry">
                  </div>
                </form>
              </div>
            </aside>
          </article>

        </div>
      </div>
    </div>
  </div>
</template>

<script>

  import * as deepmerge from 'deepmerge';
  import dateField from 'components/shared/date_field';
  import error from 'components/shared/error.vue';
  import { defaultMomentTime } from 'concerns/default_moment_time.js';
  import { createHelpers } from 'vuex-map-fields';
  import { mapGetters, mapState, mapActions, mapMutations } from 'vuex';
  import { timeLogMixin } from 'mixin/tasks/time_log_mixin.js';

  const { mapFields: mapTimeLogFields } = createHelpers({
    getterType: 'getTimeLog',
    mutationType: 'createTimeLog'
  });

  export default {
    name: 'timeLogModal',
    mixins: [timeLogMixin],
    data() {
      return {
        default_time_log_object: {
          duration: '',
          loggable_id: null,
          loggable_type: 'Task',
          loggable_date: defaultMomentTime(),
          mark_complete: false,
          description: '',
          errors: {},
        }
      }
    },
    mounted() {
      const _this = this;
      $(this.$refs.timeLogModal).on('shown.bs.modal', function() {
        // This Timeout Makes 'Shown' event Async which renders the modal so that it runs after entire modal DOM is loaded
        _this.$emit('beforeShow');
        setTimeout(() => {
          _this.$emit('onShown');
          _this.focusModal();
        });
      });
      $(this.$refs.timeLogModal).on('hidden.bs.modal', function() {
        _this.hideModal();
        _this.$emit('onDismiss');
      });
    },

    methods: {
      focusModal() {
        $(this.$refs.timeLogModal).focus();
        $('body').addClass('modal-open');
      },
      displayModal() {
        $(this.$refs.timeLogModal).modal('show');
      },
      hideModal() {
        $(this.$refs.timeLogModal).modal('hide');
        this.resetTimeObject();
      },
      resetTimeObject: function() {
        this.time_log_object = deepmerge({}, this.default_time_log_object);
      },

      cancelOperation: function() {
        this.resetTimeObject();
        this.$emit('onCancel');
      },

      saveTimeLogEntry: function() {
        if(this.time_log_object.id) {
          this.updateTimeLogEntry();
        } else {
          this.saveTimeLog();
        }
      },

      updateTimeLogEntry: function() {
        var _this = this;
        this.ajaxRequestSent = true;

        this.updateNewTimeLog({
          apollo: this.$apollo,
          showAlert: this.$root.showAlert,
          componentObject: this,
          loggable_id: this.currentLoggable.id,
          loggable_type: this.currentLoggable.class_name
        }).then(function(){
          _this.hideModal();
          _this.timeLogUpdateSuccessful = true;
        });
      },

      saveTimeLog: function() {
        var _this = this;
        this.ajaxRequestSent = true;
        this.requestCreateTimeLog({
          apollo: this.$apollo,
          loggable_id: this.currentLoggable.id,
          loggable_type: this.currentLoggable.class_name,
          duration: this.time_log_object.duration,
          description: this.time_log_object.description,
          loggable_date: this.time_log_object.loggable_date,
          showAlert: this.$root.showAlert,
          user_id: this.currentUser.id,
          componentObject: this
        }).then(function(object) {
          _this.currentLoggable = object.loggable;
          _this.timeLogCreateSuccessful = true;
          _this.hideModal();
        });

      },
      ...mapActions('TimeLogModalWidget', ['requestCreateTimeLog', 'updateNewTimeLog']),
    },
    computed: {
      ...mapState('GeneralConfiguration', ['currentUser'])
    },
    watch: {
      displayTimeLogModal: function(value) {
        if (value) {
          this.displayModal();
          this.displayTimeLogModal = false;
        }
      }
    },
    components: {
      dateField,
      error
    }
  };
</script>

<style lang="css" scoped>
</style>
