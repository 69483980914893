<template>
  <li class="list-group-item" ref='container' v-if='sideBarOptions.length'>
    <a href="javascript:void(0)" class="toggle-link toggle-collapse-link" :class="showAccordian ? '' : 'collapsed'"  @click="handleAccordianClick">
      <span class="sidebar-link-title">
        {{ tabName }}
        <span class="caret-icon"></span>
      </span>
      <slot></slot>
    </a>
    <transition name="slide">
      <div class='panel-collapse ul-submenu' v-show='showAccordian'>
        <ul class="list-unstyled">
          <li class='panel-collapse' v-for="option in sideBarOptions" :key='option.url' :ref='option.ref || option.url'>
            <a :href="option.url" class="primary-link">{{ option.display_name }}</a>
          </li>
        </ul>
      </div>
    </transition>
  </li>
</template>

<script>
  // tabOptions: [{ url, ref, display_name }]
  import { mapState } from 'vuex';

  export default {
    name: 'sidebarCollectionLink',
    props: {
      tabName: String,
      mainLink: String,
      isSidebarActive: Boolean,
      tabOptions: Array
    },
    data: function() {
      return {
        showAccordian: false
      }
    },
    methods: {
      handleAccordianClick: function() {
        let windowSize = $(window).width();
        if(!this.isSidebarActive && this.mainLink && windowSize >= 1600) {
          location.href = this.mainLink
        } else {
          this.showAccordian = !this.showAccordian
        }
      },
      highlightLink: function() {
        window.highlightableBehaviour.forEach(highlightableTag => {
          if(this.$refs[highlightableTag]) {
            this.showAccordian = true
            this.$refs[highlightableTag][0].classList.add('active-link')
            this.$refs.container.classList.add('active-link')
          }
        });
      }
    },
    computed: {
      sideBarOptions: function() {
        return this.tabOptions.filter(option => option.display)
      },
      ...mapState('GeneralConfiguration', ['configurations']),
    },
    mounted() {
      this.highlightLink()
    },
    watch: {
      tabOptions: function(val) {
        this.$nextTick(() => {
          this.highlightLink()
        })
      }
    }
  }
</script>
