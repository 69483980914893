var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { style: "white-space:" + _vm.isWrapped },
    [
      _vm._t("default"),
      _vm._v(" "),
      _vm.text
        ? _c(
            "span",
            {
              staticStyle: { "margin-left": "4px", "vertical-align": "middle" }
            },
            [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: {
                        content: _vm.text,
                        classes: ["tooltip-theme-open tooltip-wrapped"]
                      },
                      expression:
                        "{ content: text, classes: ['tooltip-theme-open tooltip-wrapped'] }"
                    }
                  ]
                },
                [
                  _c(
                    "svg",
                    {
                      staticClass: "bi bi-info-circle",
                      staticStyle: { padding: "0 0 1px" },
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        width: _vm.fontSize,
                        height: _vm.fontSize,
                        fill: "currentColor",
                        viewBox: "0 0 16 16"
                      }
                    },
                    [
                      _c("path", {
                        attrs: {
                          d:
                            "M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                        }
                      }),
                      _vm._v(" "),
                      _c("path", {
                        attrs: {
                          d:
                            "m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"
                        }
                      })
                    ]
                  )
                ]
              )
            ]
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }