<template>
  <div ref="contactContent">
    <v-popover placement='top' trigger='hover' popoverArrowClass='hide'>
      <span class="d-flex align-center">
        <a :href="contactShowPath(contact_object)" target="_blank" class="tootltip-name clickable-item d-block text-truncate">{{ contact_object.name }}</a>
      </span>
      <template slot='popover'>
        <ContactHoverDataTooltip :contact_object="contact_object"></ContactHoverDataTooltip>
      </template>
    </v-popover>
  </div>
</template>

<script>
  import { contactShowPath } from 'concerns/path_helper_methods.js';
  import ContactHoverDataTooltip from 'components/shared/contacts/contact_hover_data_tooltip.vue';

  export default {
    name: 'ContactDataTooltipWithName',
    props: ['contact_object'],
    components: {
      ContactHoverDataTooltip
    },
    methods: {
      contactShowPath,
    }
  }
</script>
