<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
    <path
      fill="#646C9A"
      fill-rule="evenodd"
      d="M14.5 4A1.5 1.5 0 0 1 16 5.5v9a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 14.5v-9A1.5 1.5 0 0 1 1.5 4zm0 1h-13a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5zM8 7a3 3 0 1 1 0 6 3 3 0 0 1 0-6zm0 1a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm5.5-7a.5.5 0 0 1 .09.992L13.5 2h-11a.5.5 0 0 1-.09-.992L2.5 1h11z"
    />
  </svg>
</template>

<script>
export default {
  name: 'ShipmentsIcon'
}
</script>
