<template>

</template>

<script>
  import { mapState, mapActions, mapMutations } from "vuex";

  export default {
    name: 'AddConfiguration',
    props: {
      current_user: {
        type: Object,
        default: () => { return {} }
      },
      user_data: {
        type: Object,
        default: () => { return {} }
      },
      configurations: {
        type: Object,
        default: () => { return {} }
      }
    },
    mounted() {
      this.insertConfigurations();
    },
    methods: {
      ...mapMutations('GeneralConfiguration', [
        'updateConfigurations',
        'updateCurrentUser',
        'updateUserData'
      ]),
      insertConfigurations: function() {
        if(Object.keys(this.current_user).length) {
          this.updateCurrentUser(this.current_user);
        }
        if(Object.keys(this.configurations).length) {
          this.updateConfigurations(this.configurations);
        }
        if(Object.keys(this.user_data).length) {
          this.updateUserData(this.user_data);
        }
      }
    }
  }
</script>
