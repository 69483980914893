var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { "data-behavior": "shared-event-modal-container" } },
    [
      _c(
        "si-modal",
        {
          attrs: { modalOptions: _vm.modalOptions },
          on: { onDismiss: _vm.onDismiss }
        },
        [
          _c("template", { slot: "modal-content" }, [
            _vm.showForm
              ? _c("form", { staticClass: "form" }, [
                  _c(
                    "article",
                    { staticClass: "new-event-form-container" },
                    [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.eventObject.title,
                                expression: "eventObject.title"
                              }
                            ],
                            staticClass: "create-event-input",
                            attrs: {
                              type: "text",
                              placeholder: _vm.$t("eventModal.addTitle"),
                              autocomplete: "off"
                            },
                            domProps: { value: _vm.eventObject.title },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.eventObject,
                                  "title",
                                  $event.target.value
                                )
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("error", {
                            attrs: {
                              errors: _vm.eventObject.errors.title || []
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "event-create-timing d-flex" }, [
                        _c(
                          "div",
                          { staticClass: "event-dt-col event-date-col" },
                          [
                            _c(
                              "div",
                              { staticClass: "form-control-group" },
                              [
                                _c("date-field", {
                                  attrs: {
                                    not_format: !_vm.eventObject.formatDate
                                  },
                                  model: {
                                    value: _vm.eventObject.start_date,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.eventObject,
                                        "start_date",
                                        $$v
                                      )
                                    },
                                    expression: "eventObject.start_date"
                                  }
                                }),
                                _vm._v(" "),
                                _c("error", {
                                  attrs: {
                                    errors:
                                      _vm.eventObject.errors.start_date || []
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        ),
                        _vm._v(" "),
                        !_vm.hideTimeFields
                          ? _c(
                              "div",
                              { staticClass: "event-dt-col event-time-col" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "form-control-group" },
                                  [
                                    _c("time-picker", {
                                      attrs: {
                                        field_name: "event[start_time]",
                                        field_value: _vm.eventObject.start_time,
                                        element_id: "event_start_time",
                                        placeholder: ""
                                      },
                                      on: {
                                        time_value_update: function($event) {
                                          _vm.eventObject.start_time = $event
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("error", {
                                      attrs: {
                                        errors:
                                          _vm.eventObject.errors.start_time ||
                                          []
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", { staticClass: "event-dt-col time-bar" }, [
                          _c(
                            "div",
                            { staticClass: "time-to-text p-t-10 m-t-2 fs-15" },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("default_calendar_range_label"))
                              )
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "event-dt-col event-date-col" },
                          [
                            _c(
                              "div",
                              { staticClass: "form-control-group" },
                              [
                                _c("date-field", {
                                  attrs: {
                                    not_format: !_vm.eventObject.formatDate
                                  },
                                  model: {
                                    value: _vm.eventObject.end_date,
                                    callback: function($$v) {
                                      _vm.$set(_vm.eventObject, "end_date", $$v)
                                    },
                                    expression: "eventObject.end_date"
                                  }
                                }),
                                _vm._v(" "),
                                _c("error", {
                                  attrs: {
                                    errors:
                                      _vm.eventObject.errors.end_date || []
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        ),
                        _vm._v(" "),
                        !_vm.hideTimeFields
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "event-dt-col event-time-col dt-picker-right white-space-normal"
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "form-control-group" },
                                  [
                                    _c("time-picker", {
                                      attrs: {
                                        field_name: "event[end_time]",
                                        field_value: _vm.eventObject.end_time,
                                        element_id: "event_end_time",
                                        placeholder: ""
                                      },
                                      on: {
                                        time_value_update: function($event) {
                                          _vm.eventObject.end_time = $event
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("error", {
                                      attrs: {
                                        errors:
                                          _vm.eventObject.errors.end_time || []
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            )
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "row no-side-margin p-b-10" }, [
                        _c("label", { staticClass: "si-checkbox pull-left" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.eventObject.all_day,
                                expression: "eventObject.all_day"
                              }
                            ],
                            attrs: { type: "checkbox" },
                            domProps: {
                              checked: Array.isArray(_vm.eventObject.all_day)
                                ? _vm._i(_vm.eventObject.all_day, null) > -1
                                : _vm.eventObject.all_day
                            },
                            on: {
                              change: function($event) {
                                var $$a = _vm.eventObject.all_day,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.eventObject,
                                        "all_day",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.eventObject,
                                        "all_day",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(_vm.eventObject, "all_day", $$c)
                                }
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "helper" }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass:
                                "helper-label permission-name fs-13 text-secondary"
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.$t("event.all_day")) +
                                  "\n              "
                              )
                            ]
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("hr"),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "row no-side-margin" },
                        [
                          _c("div", { staticClass: "fs-12 p-t-b-10" }, [
                            _vm._v(
                              _vm._s(_vm.$t("eventModal.invitee_email_info"))
                            )
                          ]),
                          _vm._v(" "),
                          _c("auto-complete", {
                            ref: "autocomplete",
                            attrs: {
                              url: "/admin/events/invitee_search.json",
                              id: "events_invitee_autocomplete",
                              anchor: "name",
                              param: "q[name_or_email_cont]",
                              placeholder: _vm.$t(
                                "autoComplete.invitee_placeholder"
                              ),
                              min: 3,
                              classes: {
                                wrapper:
                                  "form-control-group select2-placeholder placholder-add-guest",
                                input:
                                  "form-control place-holder ui-autocomplete-input height-40 fs-13",
                                list:
                                  "ui-menu ui-widget ui-widget-content ui-autocomplete ui-front manual-list",
                                item: "ui-menu-item"
                              },
                              onShouldRenderChild:
                                _vm.renderAutoCompleteListItems,
                              process: _vm.autoCompleteFilterData,
                              onInput: _vm.autoCompleteInputListener,
                              customParams: { page_length: 5 },
                              onBeforeAjax: _vm.onAutoCompleteRequest,
                              onAjaxLoaded: _vm.onAutoCompleteResponse,
                              onSelect: _vm.onInviteeSelectFromAutoCompleteList
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "text-muted fs-11 p-t-10" },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("autoComplete.default_hint"))
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("invitee-list-item", {
                        attrs: {
                          invitees: _vm.invitees,
                          removeInviteeHandler: _vm.removeInvitee
                        }
                      }),
                      _vm._v(" "),
                      _c("hr"),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "create-event-action row no-side-margin"
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-si btn-si-sm fontregular",
                              attrs: { type: "button" },
                              on: { click: _vm.findTimeListener }
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("eventModal.findTimeActionLabel"))
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-si-sm btn-primary m-l-10",
                              attrs: { type: "button" },
                              on: { click: _vm.submitEventListener }
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("eventModal.addEventActionLabel"))
                              )
                            ]
                          )
                        ]
                      )
                    ],
                    1
                  )
                ])
              : _vm._e()
          ])
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }