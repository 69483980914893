<template>
  <span v-if="to_value">
    <span class="fs-13" :class='cancelled_text_classes'>
      marked <span class='black'>{{ object_name }}</span> as canceled
    </span>
  </span>
</template>

<script>
  export default {
    name: 'cancelled_detail',
    props: [
      'data_key',
      'to_value',
      'object_name',
      'cancelled_text_classes',
    ]
  };
</script>

<style lang="css" scoped>
</style>
