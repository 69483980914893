var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    {
      staticClass: "list-unstyled notification-listing",
      attrs: { "data-behavior": "notification-bar" }
    },
    _vm._l(_vm.notifications, function(notification, index) {
      return _c(
        "li",
        {
          key: index,
          staticClass: "notification-list-item d-flex pointer",
          class: notification.is_read
            ? "notification-read"
            : "notification-unread",
          attrs: { "data-behavior": "notification-list" },
          on: {
            click: function($event) {
              return _vm.redirectToNotification(notification, $event)
            }
          }
        },
        [
          _c("a", {
            staticClass: "pointer",
            attrs: {
              href: _vm.generateLink(notification),
              "data-modal-click": _vm.dataValue(notification),
              "data-url": notification.entity_url
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "user-image-space-area m-r-15" }, [
            notification.icon_class_name
              ? _c("div", { staticClass: "card-assign-user x-32" }, [
                  _c("span", { class: notification.icon_class_name })
                ])
              : _c("span", {
                  staticClass: "card-assign-user x-32",
                  domProps: { innerHTML: _vm._s(notification.profile_picture) }
                })
          ]),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { "data-behavior": "notification_clamped_content" } },
            [
              _c("div", {
                staticClass: "notification-text",
                domProps: {
                  innerHTML: _vm._s(
                    notification.content || notification.formatted_content
                  )
                }
              }),
              _vm._v(" "),
              _c("span", { staticClass: "fs-12 notification-date" }, [
                _c(
                  "span",
                  { attrs: { "data-title": notification.created_at } },
                  [
                    _vm._v(
                      _vm._s(_vm._f("formatDate")(notification.created_at))
                    )
                  ]
                )
              ])
            ]
          ),
          _vm._v(" "),
          _c("div", {
            staticClass: "notification-icon",
            attrs: { "data-title": _vm.$t("web_notifications.mark_read") }
          })
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }