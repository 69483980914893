<template>
  <ul class="list-group no-horizontal-border task-history-list-group m-b-0">
    <template v-for='version in versions'>
      <history-row
        :version='version'
        :object='task'
      >
      </history-row>
    </template>
  </ul>
</template>

<script>

  import historyRow from 'components/history/history_row';

  export default {
    name: 'task_history',
    props: ['versions', 'task'],
    computed: {
    },
    components: {
      historyRow
    }
  };

</script>

<style lang="css" scoped>
</style>
