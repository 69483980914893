var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "sweet-alert",
    {
      attrs: { container_classes: _vm.containerClasses },
      on: { input: _vm.deleteCurrentWorkOrder }
    },
    [
      [
        _c(
          "a",
          {
            class: [{ disabled: _vm.ajaxRequestSent }, _vm.buttonClasses],
            attrs: {
              href: _vm.workOrderPath(),
              "data-description":
                "#" + _vm.workOrder.db_id + " - " + _vm.workOrder.title,
              "data-sweet-alert-confirm": _vm.alertConfirmText,
              "data-heading": _vm.headingText,
              "data-confirm-type": "delete",
              "data-vue-component": "true",
              "data-confirm-button-text": "Delete",
              "data-cancel-button-text": "Cancel",
              "data-method": "delete"
            }
          },
          [_vm._v("Delete")]
        )
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }