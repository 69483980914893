<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
    <path fill="#646C9A" fill-rule="evenodd" d="M12.154 0c1.337 0 1.822.14 2.311.4.49.262.873.646 1.134 1.135.262.489.401.974.401 2.31v8.31c0 1.336-.14 1.821-.4 2.31a2.726 2.726 0 0 1-1.135 1.134c-.489.262-.974.401-2.31.401h-8.31c-1.336 0-1.821-.14-2.31-.4A2.726 2.726 0 0 1 .4 14.464C.139 13.976 0 13.491 0 12.155v-8.31c0-1.336.14-1.821.4-2.31A2.726 2.726 0 0 1 1.536.4C2.024.139 2.509 0 3.845 0h8.31zm0 1H3.846c-1.068 0-1.449.073-1.84.283-.314.168-.555.409-.723.723-.21.391-.283.772-.283 1.84v8.308c0 1.068.073 1.449.283 1.84.168.314.409.555.723.723.391.21.772.283 1.84.283h8.308c1.068 0 1.449-.073 1.84-.283.314-.168.555-.409.723-.723.21-.391.283-.772.283-1.84V3.846c0-1.068-.073-1.449-.283-1.84a1.726 1.726 0 0 0-.723-.723c-.391-.21-.772-.283-1.84-.283zM10.5 10a.5.5 0 1 1 0 1h-6a.5.5 0 1 1 0-1h6zm2-3a.5.5 0 1 1 0 1h-5a.5.5 0 0 1 0-1h5zm-2-3a.5.5 0 1 1 0 1h-7a.5.5 0 0 1 0-1h7z">
    </path>
  </svg>
</template>

<script>
export default {
  name: 'ResourcingIcon'
}
</script>
