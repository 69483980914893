var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-popover",
    {
      attrs: { placement: "top", trigger: "hover", popoverArrowClass: "hide" }
    },
    [
      _vm._t("default", [
        _c("a", { attrs: { href: _vm.userShowPath(_vm.userObject) } }, [
          _vm._v(_vm._s(_vm.userObject.name))
        ])
      ]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "popover" },
        [_c("user-hover-data-tooltip", { attrs: { user_id: _vm.user_id } })],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }