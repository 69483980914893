var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", [
    _c("span", { staticClass: "d-inline-block fs-12" }, [
      _vm._v(_vm._s(_vm.version_event))
    ]),
    _vm._v(" "),
    _vm.addresses[1]
      ? _c("span", [
          _c("div", { staticClass: "m-l-22 wo-tab-detail" }, [
            _c("div", { staticClass: "user-color tooltip-inline" }, [
              _c("div", { staticClass: "d-inline-block" }, [
                _vm._v(_vm._s(_vm.addresses[0]))
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "fontmedium d-inline-block" }, [
                _vm._v("›\n          "),
                _c("div", { staticClass: "d-inline-block" }, [
                  _vm._v(_vm._s(_vm.addresses[1]))
                ])
              ])
            ])
          ])
        ])
      : _c("span", { staticClass: "history-manage-block black-2" }, [
          _c("div", { staticClass: "task-tab-detail absolute po-address" }, [
            _vm._v("\n      " + _vm._s(_vm.addresses[0]) + "\n    ")
          ])
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }