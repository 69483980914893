var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "si-modal",
        {
          ref: "contactModal",
          attrs: { modalOptions: _vm.modalOptions },
          on: {
            onDismiss: _vm.resetContactForm,
            onShown: function($event) {
              return _vm.setContactStatus($event)
            }
          }
        },
        [
          _c("template", { slot: "modal-content" }, [
            _c(
              "form",
              {
                staticClass: "form",
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                  }
                }
              },
              [
                _c(
                  "article",
                  { staticClass: "new-contact-form-container p-t-15" },
                  [
                    _c(
                      "div",
                      { staticClass: "fs-14 super-dark semibold m-b-30" },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              this.current_contact_object.type + " Information"
                            ) +
                            "\n          "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "form-group p-b-5 col-md-6" },
                        [
                          _c(
                            "label",
                            {
                              class: { required: _vm.isLeadOrProspect },
                              attrs: { for: "contact_name" }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  this.$i18n.tc("quick_add_contact.name_label")
                                )
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.current_contact_object.name,
                                expression: "current_contact_object.name"
                              }
                            ],
                            ref: "nameInput",
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              autocomplete: "off",
                              name: "contact[name]",
                              id: "contact_name",
                              autofocus: "true"
                            },
                            domProps: {
                              value: _vm.current_contact_object.name
                            },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.current_contact_object,
                                  "name",
                                  $event.target.value
                                )
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("error", {
                            attrs: {
                              errors: _vm.current_contact_object.errors.name
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "form-group p-b-5 col-md-6" },
                        [
                          _c(
                            "label",
                            {
                              class: { required: _vm.isLeadOrProspect },
                              attrs: { for: "contact_email" }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  this.$i18n.tc("quick_add_contact.email_label")
                                )
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.current_contact_object.email,
                                expression: "current_contact_object.email"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              autocomplete: "off",
                              name: "contact[email]",
                              id: "contact_email"
                            },
                            domProps: {
                              value: _vm.current_contact_object.email
                            },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.current_contact_object,
                                  "email",
                                  $event.target.value
                                )
                              }
                            }
                          }),
                          _vm._v(" "),
                          _vm.current_contact_object.errors.email_taken_warning
                            ? _c("span", { staticClass: "warning-span" })
                            : _vm._e(),
                          _vm._v(" "),
                          _c("error", {
                            attrs: {
                              errors: _vm.current_contact_object.errors.email
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "clearfix" }),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "form-group col-md-6 p-b-10 m-b-0" },
                        [
                          _c("label", { attrs: { for: "contact_phone" } }, [
                            _vm._v(
                              _vm._s(
                                this.$i18n.tc("quick_add_contact.phone_label")
                              )
                            )
                          ]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.current_contact_object.phone,
                                expression: "current_contact_object.phone"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              autocomplete: "off",
                              name: "contact[phone]",
                              id: "contact_phone"
                            },
                            domProps: {
                              value: _vm.current_contact_object.phone
                            },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.current_contact_object,
                                  "phone",
                                  $event.target.value
                                )
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v("Please enter a valid 10 digit number.")
                          ]),
                          _vm._v(" "),
                          _c("error", {
                            attrs: {
                              errors: _vm.current_contact_object.errors.phone
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "form-group col-md-6 p-b-10 m-b-0" },
                        [
                          _c(
                            "label",
                            { attrs: { for: "contact_mobile_number" } },
                            [
                              _vm._v(
                                _vm._s(
                                  this.$i18n.tc(
                                    "quick_add_contact.mobile_number"
                                  )
                                )
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.current_contact_object.mobile_number,
                                expression:
                                  "current_contact_object.mobile_number"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              autocomplete: "off",
                              name: "contact[mobile_number]",
                              id: "contact_mobile_number"
                            },
                            domProps: {
                              value: _vm.current_contact_object.mobile_number
                            },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.current_contact_object,
                                  "mobile_number",
                                  $event.target.value
                                )
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v("Please enter a valid 10 digit number.")
                          ]),
                          _vm._v(" "),
                          _c("error", {
                            attrs: {
                              errors:
                                _vm.current_contact_object.errors.mobile_number
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("hr"),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "form-group p-t-10 dropdown-clear" },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "fs-14 super-dark semibold m-b-15 relative"
                          },
                          [
                            _c(
                              "span",
                              { class: { required: _vm.prospectOrCustomer } },
                              [
                                _vm._v(
                                  _vm._s(
                                    this.$i18n.tc(
                                      "quick_add_contact.account_label"
                                    )
                                  )
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _vm.account && Object.keys(_vm.account).length
                              ? [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "text-right semibold fs-12 account-target-helper"
                                    },
                                    [
                                      _vm.account.target ||
                                      _vm.account.is_target
                                        ? _c(
                                            "span",
                                            { staticClass: "text green-link" },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    this.$i18n.tc(
                                                      "quick_add_contact.account_target"
                                                    )
                                                  ) +
                                                  "\n                  "
                                              )
                                            ]
                                          )
                                        : _c(
                                            "span",
                                            { staticClass: "text red" },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    this.$i18n.tc(
                                                      "quick_add_contact.account_not_target"
                                                    )
                                                  ) +
                                                  "\n                  "
                                              )
                                            ]
                                          )
                                    ]
                                  )
                                ]
                              : _vm._e()
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _vm.disable_account_field
                          ? _c("div", { staticClass: "disabled-div" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    this.account.label || this.account.name
                                  ) +
                                  "\n            "
                              )
                            ])
                          : _c(
                              "v-select",
                              {
                                ref: "vSelectSearch",
                                attrs: {
                                  name: "contact[account]",
                                  id: "contact_account",
                                  label: "name",
                                  options: _vm.accounts,
                                  placeholder: "Please Select",
                                  clearable: _vm.accountSelected
                                },
                                on: {
                                  input: _vm.handleEmptyInput,
                                  search: _vm.search
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "option",
                                    fn: function(option) {
                                      return [
                                        _c("div", { staticClass: "d-center" }, [
                                          _c("img", {
                                            attrs: {
                                              src: option.favicon_image,
                                              width: "16",
                                              height: "16"
                                            }
                                          }),
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(option.name) +
                                              "\n                "
                                          )
                                        ])
                                      ]
                                    }
                                  },
                                  {
                                    key: "selected-option",
                                    fn: function(option) {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "selected d-center text-truncate fs-13"
                                          },
                                          [
                                            option.favicon_image
                                              ? _c("img", {
                                                  attrs: {
                                                    src: option.favicon_image,
                                                    width: "16",
                                                    height: "16"
                                                  }
                                                })
                                              : _vm._e(),
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(option.name) +
                                                "\n                "
                                            )
                                          ]
                                        )
                                      ]
                                    }
                                  }
                                ]),
                                model: {
                                  value: _vm.account,
                                  callback: function($$v) {
                                    _vm.account = $$v
                                  },
                                  expression: "account"
                                }
                              },
                              [
                                _vm._v(" "),
                                _vm._v(" "),
                                _c("template", { slot: "no-options" }, [
                                  _vm.minimumLengthEntered
                                    ? _c("span", [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm.$t(
                                                "quick_add_contact.no_results_found"
                                              )
                                            ) +
                                            "\n                "
                                        )
                                      ])
                                    : _c("span", [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm.$t(
                                                "quick_add_contact.helper_message",
                                                {
                                                  min_characters: this
                                                    .min_input_for_search
                                                }
                                              )
                                            ) +
                                            "\n                "
                                        )
                                      ])
                                ])
                              ],
                              2
                            ),
                        _vm._v(" "),
                        _c("error", {
                          attrs: {
                            errors: _vm.current_contact_object.errors.account
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "row row-col-space-20 p-t-15 m-b-15" },
                      [
                        _c("div", { staticClass: "col-md-6" }, [
                          _vm.hide_continue_edit
                            ? _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn-lg btn-block fs-16 fontmedium blue relative",
                                  attrs: { type: "button" },
                                  on: { click: _vm.hideContactFormModal }
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.$t("quick_add_contact.cancel")
                                      ) +
                                      "\n              "
                                  )
                                ]
                              )
                            : _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn-lg fontmedium btn-block fs-14 btn-with-icon icon-right",
                                  class: { disabled: _vm.ajaxRequestSent },
                                  attrs: { type: "button" },
                                  on: {
                                    click: function($event) {
                                      return _vm.requestCreateContact(true)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.$t(
                                          "quick_add_contact.continue_editing"
                                        )
                                      ) +
                                      "\n                "
                                  ),
                                  _c("span", { staticClass: "btn-icon" }, [
                                    _c(
                                      "svg",
                                      {
                                        attrs: {
                                          xmlns: "http://www.w3.org/2000/svg",
                                          width: "16",
                                          height: "16",
                                          viewBox: "0 0 16 16"
                                        }
                                      },
                                      [
                                        _c("path", {
                                          attrs: {
                                            fill: "#646c9a",
                                            "fill-rule": "evenodd",
                                            d:
                                              "M14.293 8l-4.147-4.146a.5.5 0 0 1 .708-.708l5 5a.5.5 0 0 1 0 .708l-5 5a.5.5 0 0 1-.708-.708L14.293 9H.5a.5.5 0 0 1 0-1h13.793z"
                                          }
                                        })
                                      ]
                                    )
                                  ])
                                ]
                              )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-md-6" }, [
                          _c(
                            "button",
                            {
                              staticClass:
                                "btn btn-lg btn-block fs-16 btn-primary fontmedium",
                              attrs: {
                                type: "submit",
                                disabled: _vm.ajaxRequestSent
                              },
                              on: {
                                click: function($event) {
                                  return _vm.validateNewContact(false)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.$t("quick_add_contact.submit")) +
                                  "\n              "
                              )
                            ]
                          )
                        ])
                      ]
                    )
                  ]
                )
              ]
            )
          ])
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "modal-480 modal-tm-130 modal-ghost-header",
          attrs: {
            "data-behavior": "account-target-confirmation-modal-container"
          }
        },
        [
          _c(
            "si-modal",
            {
              ref: "accountTargetConfirmationModal",
              attrs: { modalOptions: _vm.targetConfirmationModalOptions },
              on: {
                onDismiss: function($event) {
                  return _vm.removeShadowClass()
                },
                onShown: function($event) {
                  return _vm.addShadowClass()
                }
              }
            },
            [
              _c("template", { slot: "modal-content" }, [
                _c("div", { staticClass: "p-l-r-10 text-center word-wrap" }, [
                  _c("div", { staticClass: "circle-icon blue-bg" }, [
                    _c("i", { staticClass: "icon--bizdev" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "fs-22 dark p-t-b-20 fontmedium" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.$t("quick_add_contact.targetConfirmationHeading")
                        ) +
                        "\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "account-detail-info  fs-16 light p-b-5 fontmedium"
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.current_contact_object.name) +
                          "\n          "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("hr", { staticClass: "extra-light-border" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "row row-col-space-20 p-t-b-15" }, [
                    _c("div", { staticClass: "col-md-6" }, [
                      _c(
                        "button",
                        {
                          staticClass:
                            "btn btn-lg btn-block fs-16 fontmedium blue relative",
                          attrs: { type: "button" },
                          on: { click: _vm.hideTargetConfirmationModal }
                        },
                        [
                          _c("span", {
                            staticClass:
                              "btn-icon-helper left-center m-l-20 size24 back-nav",
                            attrs: { "data-v-fdf14b5e": "" }
                          }),
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.$t("accountQuickAddModal.cancel")) +
                              "\n              "
                          )
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-6" }, [
                      _c(
                        "button",
                        {
                          staticClass:
                            "btn btn-lg btn-primary btn-block fs-16 fontmedium",
                          attrs: {
                            type: "button",
                            disabled: _vm.ajaxRequestSent
                          },
                          on: {
                            click: function($event) {
                              return _vm.requestCreateContact()
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.$t("accountQuickAddModal.save_text")) +
                              "\n              "
                          )
                        ]
                      )
                    ])
                  ])
                ])
              ])
            ],
            2
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "si-modal",
        {
          ref: "emailReuseWarningModal",
          attrs: { modalOptions: _vm.emailReuseWarningOptions },
          on: {
            onDismiss: function($event) {
              return _vm.removeShadowClass()
            },
            onShown: function($event) {
              return _vm.addShadowClass()
            }
          }
        },
        [
          _c("template", { slot: "modal-content" }, [
            _c("div", { staticClass: "p-l-r-10 text-center word-wrap" }, [
              _c("div", { staticClass: "fs-22 dark p-t-b-20 fontmedium" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("quick_add_contact.email_reuse_warning")) +
                    "\n        "
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row row-col-space-20 p-t-b-15" }, [
                _c("div", { staticClass: "col-md-6" }, [
                  _c(
                    "button",
                    {
                      staticClass:
                        "btn btn-lg btn-block fs-16 fontmedium blue relative",
                      attrs: { type: "button" },
                      on: { click: _vm.hideEmailReuseWarningModal }
                    },
                    [
                      _c("span", {
                        staticClass:
                          "btn-icon-helper left-center m-l-20 size24 back-nav",
                        attrs: { "data-v-fdf14b5e": "" }
                      }),
                      _vm._v("\n              No\n            ")
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-6" }, [
                  _c(
                    "button",
                    {
                      staticClass:
                        "btn btn-lg btn-primary btn-block fs-16 fontmedium",
                      attrs: { type: "button", disabled: _vm.ajaxRequestSent },
                      on: {
                        click: function($event) {
                          return _vm.warningConfirmationCreate()
                        }
                      }
                    },
                    [_vm._v("\n              Yes\n            ")]
                  )
                ])
              ])
            ])
          ])
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }