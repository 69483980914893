<template>
  <div v-if='Object.keys(currentUserObject).length'>
    <div class='d-flex justfy-between'>
      <img :src="currentUserObject.profile_picture_url" height='40' width='40'>
      <div class="">
        <div class='text-left tooltip-text'>
          <a :href="userShowPath(currentUserObject)" target="_blank" class="super-dark clickable-item fs-14 m-b-2 semibold ls-01">{{ currentUserObject.name }}</a>
        </div>
        <div class='super-dark text-left tooltip-text fs-13 ls-01' v-html='currentUserObject.email'></div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import { userShowPath } from 'concerns/path_helper_methods.js';

  export default {
    name: 'UserHoverDataTooltip',
    props: {
      user_id: {
        required: true
      }
    },
    computed: {
      ...mapState('GeneralConfiguration', ['user_data']),
      currentUserObject() {
        return this.user_data[this.user_id.toString()] || {};
      }
    },
    methods: {
      userShowPath
    }
  }
</script>
