var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "accountContent",
      staticClass: "m-r-10",
      on: { mouseover: _vm.loadAccountData }
    },
    [
      !_vm.loadedAccountData
        ? _c("span", { staticClass: "tooltip-target d-block full-width" }, [
            _vm.withNameWidth || _vm.showLabels
              ? _c("span", { staticClass: "d-flex align-center" }, [
                  _c(
                    "a",
                    {
                      staticClass: "lh-123 clickable-item card-assign-user",
                      attrs: { href: _vm.accountShowPath(_vm.accountData) }
                    },
                    [
                      _c("img", {
                        staticClass: "img img-responsive full-width",
                        attrs: { src: _vm.accountData.profile_picture }
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass:
                        "lh-123 clickable-item account-name text-truncate",
                      class: [_vm.showLabels ? "mw-100" : "mw-135"],
                      attrs: { href: _vm.accountShowPath(_vm.accountData) }
                    },
                    [
                      _vm._v(
                        "\n        " + _vm._s(_vm.accountData.name) + "\n      "
                      )
                    ]
                  )
                ])
              : _c("span", [
                  _c(
                    "a",
                    {
                      staticClass:
                        "lh-123 d-block clickable-item card-assign-user",
                      attrs: { href: _vm.accountShowPath(_vm.accountData) }
                    },
                    [
                      _c("img", {
                        staticClass: "img img-responsive full-width",
                        attrs: { src: _vm.accountData.profile_picture }
                      })
                    ]
                  )
                ])
          ])
        : _c(
            "v-popover",
            {
              attrs: {
                popoverWrapperClass: "account-tooltip",
                placement: _vm.placementValue,
                openClass: _vm.popoverClasses,
                trigger: "hover",
                popoverArrowClass: "hide"
              }
            },
            [
              _vm.withNameWidth || _vm.showLabels
                ? _c("span", { staticClass: "d-flex align-center" }, [
                    _c(
                      "a",
                      {
                        staticClass:
                          "lh-123 d-block clickable-item card-assign-user",
                        attrs: { href: _vm.accountShowPath(_vm.accountData) }
                      },
                      [
                        _c("img", {
                          staticClass: "img img-responsive full-width",
                          attrs: { src: _vm.accountData.profile_picture }
                        })
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass:
                          "lh-123 d-block clickable-item text-truncate",
                        class: [_vm.showLabels ? "mw-100" : "mw-135"],
                        attrs: { href: _vm.accountShowPath(_vm.accountData) }
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.accountData.name) +
                            "\n      "
                        )
                      ]
                    )
                  ])
                : _c("span", [
                    _c(
                      "a",
                      {
                        staticClass:
                          "lh-123 d-block clickable-item card-assign-user",
                        attrs: { href: _vm.accountShowPath(_vm.accountData) }
                      },
                      [
                        _c("img", {
                          staticClass: "img img-responsive full-width",
                          attrs: { src: _vm.accountData.profile_picture }
                        })
                      ]
                    )
                  ]),
              _vm._v(" "),
              _c(
                "template",
                { slot: "popover" },
                [
                  _c("AccountTooltipHover", {
                    attrs: { accountId: _vm.accountData.id.toString() }
                  })
                ],
                1
              )
            ],
            2
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }