export const select2Mixin = {
  watch: {
    value: function (value) {
      $(this.$el)
        .val(value)
        .trigger('change', true)
    }
  },
  destroyed: function () {
    $(this.$el).off().select2('destroy')
  },
  mounted() {
    this.initSelelect2();
  },
  methods: {
    initSelelect2() {
      this.initData(this.options, this.value);
    },
    handleSelectingUnselecting() {
      var vm = this;
      if(this.dataConfirmationMessage) {
        $(this.$el).on('select2:selecting', function(e) {
          vm.$emit('onSelecting', e, $(this).val());
        });
      }
      $(this.$el).on('select2:selecting', function(e) {
        vm.$emit('onSelectingOption', e.params.args.data);
      });
      this.handleParentGroupId();
    },
    handleParentGroupId() {
      // FIXME: WHen Using Select2 for option groups 'isOptionGroup' is to be passed true
      // All Parent Option Groups must not have id
      if (this.isOptionGroup && this.multiple) {
        $(this.$el).on('select2:unselecting', function(e) {
          $(this).val($(this).val().filter(ele => ele != e.params.args.data.id))
        })
      }
    }
  }
}
