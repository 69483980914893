<template>

  <header class="si-global-header sidebar-hover-control" data-behavior='si-global-header' data-stick-behavior='extra-height'>

    <article class="navigation-header">
      <div class="si-nav-container center-block">
        <div class="d-flex align-center justify-between">
          <aside class="nav-left relative hover quick-add-links">
            <div class="dropdown-toggle d-flex justify-center align-center pointer">
              <span class="quick-add-icon">
                <span class="quick-add-circle"></span>
              </span>
              Add New
            </div>

            <ul class="dropdown-menu fs-13" data-quick-links='true'>
              <li v-if='gsp.manage_tasks'>
                <a href="#quick_add_task_modal" data-toggle='modal' class='fs-13'>
                  Task
                  <span class="link-icon">
                    <TasksIcon></TasksIcon>
                  </span>
                </a>
              </li>

              <li v-if='gsp.manage_work_orders'>
                <open-work-order-quick-add-button :is_internal='false'>
                  Work Order
                  <span class='link-icon'>
                    <WorkOrdersIcon></WorkOrdersIcon>
                  </span>
                </open-work-order-quick-add-button>
              </li>

              <li v-if='gsp.manage_work_orders'>
                <open-work-order-quick-add-button :default_account_id='configurations.internal_account_id' :is_internal='true' modal_header='NEW INTERNAL WORK ORDER'>
                  Internal Work Order
                  <span class='link-icon'>
                    <InternalWorkOrdersIcon></InternalWorkOrdersIcon>
                  </span>
                </open-work-order-quick-add-button>
              </li>

              <li>
                <a href="#event" data-toggle='modal' data-quick-create-event='true' class='fs-13'>
                  Event
                  <span class='link-icon'>
                    <EventsIcon></EventsIcon>
                  </span>
                </a>
              </li>

              <li v-if='gsp.manage_accounts'>
                <a href="#quick_add_account_modal" data-toggle='modal' data-behavior='wo-modal-button' class='fs-13'>
                  Account
                  <span class="link-icon">
                    <AccountsIcon></AccountsIcon>
                  </span>
                </a>
              </li>

              <li v-if='gsp.manage_contacts'>
                <a href="#shared-quick-add-contact" data-toggle='modal' data-behavior='wo-modal-button' data-contact-type='Lead' class='fs-13'>
                  Lead
                  <span class="link-icon">
                    <ContactsIcon></ContactsIcon>
                  </span>
                </a>
              </li>

              <li v-if='gsp.manage_contacts'>
                <a href="#shared-quick-add-contact" data-toggle='modal' data-behavior='wo-modal-button' data-contact-type='Prospect' class='fs-13'>
                  Prospect
                  <span class="link-icon">
                    <ContactsIcon></ContactsIcon>
                  </span>
                </a>
              </li>

              <li v-if='gsp.manage_orders'>
                <a href="#create-opportunity-widget" data-toggle='modal' data-behavior='wo-modal-button' class='fs-13'>
                  Opportunity
                  <span class="link-icon">
                    <AccountsIcon></AccountsIcon>
                  </span>
                </a>
              </li>

              <li v-if='false && gsp.manage_orders'>
                <a href="#create-estimate-modal" data-toggle='modal' data-behavior='wo-modal-button' class='fs-13'>
                  Estimate
                  <span class="link-icon">
                    <AccountsIcon></AccountsIcon>
                  </span>
                </a>
              </li>

              <span class="arrow-icon"></span>
            </ul>


            <div class="si-nav-hamburger hidden" data-behavior="sidebar-manage-icon">
              <span class="line"></span>
            </div>
          </aside>

          <nav class="si-primary-nav">
            <ul class="primary-nav-list d-flex align-center">
              <template v-for='(topNavLink, index) in topNavLinks'>
                <li :key='index' class='primary-menu' :ref='topNavLink.highlightRef' v-if='topNavLink.canDisplay === true || gsp[topNavLink.canDisplay]'>
                  <a :href="topNavLink.url" class='primary-link' data-element-type='global'>{{topNavLink.displayName}}</a>
                </li>
              </template>
            </ul>
          </nav>


          <aside class="primary-right-nav width-150 justify-flex-end d-flex">

            <dd class="d-flex list-unstyled">

              <dl class="relative m-r-15 d-flex justify-center align-center global-search">
                <aside class="">
                  <Search />
                </aside>
              </dl>

              <dl class="relative m-r-10">
                <notification-widget></notification-widget>
              </dl>

              <dl class="relative m-r-10 d-flex align-center justify-center">
                <a href="https://docs.google.com/document/d/1QHcVG6hsed7YvPNn6mbqlzSK0TqK5jqE_CELjKlxmlY/edit" target="_blank">
                  <HelpIcon></HelpIcon>
                </a>
              </dl>

              <dl class="">
                <div class="user-nav-dropdown d-flex align-center relative hover p-l-5">
                  <a class="dropdown-toggle">
                    <span class="user-profile-img">
                      <img :src="currentUser.profile_picture_url" class='img-responsive'>
                    </span>
                  </a>

                  <ul class="dropdown-menu">
                    <li><a :href="adminEditPath()">My Profile</a></li>
                    <li><a :href="adminNotificationPreferencesPath()">Email Notification Preferences</a></li>
                    <li>
                      <form method="post" :action="adminSignOutPath()" class="button_to">
                        <input type="hidden" name="_method" value="delete">
                        <input data-behavior="sign_out_link" type="submit" value="Sign Out">
                        <input type="hidden" name="authenticity_token" :value="getGon('CSRF_TOKEN')">
                      </form>
                    </li>
                  </ul>
                </div>
              </dl>
            </dd>
          </aside>
        </div>
      </div>
    </article>

    <AjaxLoaderBar></AjaxLoaderBar>

  </header>
</template>

<script>
  import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
  import { getGon } from 'concerns/get_gon.js'
  import { adminEditPath, adminSignOutPath, adminNotificationPreferencesPath } from 'concerns/path_helper_methods.js'
  import { NotificationWidgetMixin } from 'mixin/shared/notification_widget.js'
  import { EventModalMixin } from 'mixin/shared/event_modal.js';
  import { QuickAddWorkOrderButtonMixin } from 'mixin/shared/quick_add_work_order_button_mixin';
  import AjaxLoaderBar from 'components/shared/ajax_loader_bar.vue'
  import { WorkOrdersIcon, InternalWorkOrdersIcon, EventsIcon, AccountsIcon, ContactsIcon, TasksIcon, HelpIcon } from 'components/shared/svg/index.js'
  import Search from 'components/global/topbar/search'

  export default {
    name: 'TopBar',
    mixins: [
      NotificationWidgetMixin,
      EventModalMixin,
      QuickAddWorkOrderButtonMixin
    ],
    computed: {
      ...mapState('GeneralConfiguration', ['currentUser', 'configurations', 'topNavLinks'])
    },
    methods: {
      getGon,
      adminEditPath,
      adminSignOutPath,
      adminNotificationPreferencesPath,
      highlightLink: function() {
        var _this = this;
        window.highlightableBehaviour.forEach(highlightableTag => {
          if(_this.$refs[highlightableTag]) {
            _this.$refs[highlightableTag][0].classList.add('active-link')
          }
        });
      }
    },
    mounted() {
      this.highlightLink();
    },
    watch: {
      topNavLinks() {
        this.$nextTick(() => {
          this.highlightLink();
        });
      }
    },
    components: {
      AjaxLoaderBar,
      TasksIcon,
      EventsIcon,
      ContactsIcon,
      AccountsIcon,
      WorkOrdersIcon,
      InternalWorkOrdersIcon,
      Search,
      HelpIcon
    }
  }
</script>
