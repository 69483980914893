<template>
  <span class="history-manage-block">
    <span class="d-inline-block fs-12" :class='text_class_names'>
      {{ updated_text }}
    </span>
    <span v-if='from_value && to_value'>
      <div :class='transition_class_names'>
        <div class="row history-update-description task-tab-detail">
          <div class="col-md-6 old">
            <p class="fs-12 text-uppercase m-b-5 ls-04 primary-text-color">old</p>
            <div class="lh-13">
              <template v-for="word_set in computed_diff_value">
                <text-detail-diff :word_set="word_set"></text-detail-diff>
              </template>
            </div>
          </div>
          <div class="col-md-6 new fontmedium">
            <p class="fs-12 text-uppercase m-b-5 ls-04 primary-text-color">updated</p>
            <div class="lh-13">
              <template v-for="word_set in computed_diff_value">
                <text-detail-diff :word_set="word_set"></text-detail-diff>
              </template>
            </div>
          </div>
        </div>
      </div>
    </span>
    <span v-else class="history-manage-block black-2">
      <div :class='transition_class_names' class="task-tab-detail">
        {{ to_value }}
      </div>
    </span>
  </span>
</template>

<script>
  import * as Jsdiff from 'diff';
  import textDetailDiff from 'components/history/text_detail_diff';

  export default {
    name: 'updated_task_due_detail',
    props: [
    'data_key',
    'from_value',
    'to_value',
    'text_class_names',
    'transition_class_names'
    ],
    components: {
      textDetailDiff
    },
    computed: {
      updated_text: function() {
        var eventType = '';
        if(this.from_value) {
          eventType = 'updated'
        } else {
          eventType = 'added'
        }
        return this.$i18n.tc('history_row.text_detail.' + eventType) + ' ' + this.$i18n.tc('history_row.text_detail.' + this.data_key);
      },
      computed_diff_value: function() {
        return Jsdiff.diffWords(this.from_value, this.to_value);
      }
    }
  };
</script>

<style lang="css" scoped>
</style>
