var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    {
      staticClass:
        "list-group no-horizontal-border task-history-list-group m-b-0"
    },
    [
      _vm._l(_vm.versions, function(version) {
        return [
          _c("history-row", { attrs: { version: version, object: _vm.task } })
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }