var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "si-modal",
    {
      ref: "quickAddTaskModal",
      attrs: { modalOptions: _vm.modalOptions },
      on: {
        onDismiss: function($event) {
          return _vm.handleModalClose()
        },
        onShown: _vm.handleOnShown
      }
    },
    [
      _c("template", { slot: "modal-content" }, [
        _c("div", [
          _c(
            "form",
            {
              staticClass: "form",
              attrs: {
                "data-maxFileSize": _vm.maxfileSize,
                "data-attachment-form": "true",
                "data-upload-url": "/admin/attachments"
              },
              on: {
                submit: function($event) {
                  $event.preventDefault()
                }
              }
            },
            [
              _c("article", {}, [
                _c(
                  "div",
                  {
                    staticClass: "form-group",
                    attrs: { "data-error-container": "form-group" }
                  },
                  [
                    _c("div", { staticClass: "form-control-group" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.current_task_object.title,
                            expression: "current_task_object.title"
                          }
                        ],
                        staticClass: "create-task-title-input place-holder",
                        attrs: {
                          type: "text",
                          name: "task[title]",
                          id: "title",
                          "data-focusable": "true",
                          placeholder: _vm.$t(
                            "quick_add_task.title_placeholder"
                          )
                        },
                        domProps: { value: _vm.current_task_object.title },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.current_task_object,
                              "title",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("error", {
                      attrs: {
                        errors:
                          _vm.current_task_object.errors &&
                          _vm.current_task_object.errors.title
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "row row-col-space-10" }, [
                  _c("div", { staticClass: "col-md-3" }, [
                    _c(
                      "label",
                      {
                        staticClass: "d-flex align-center justify-between",
                        attrs: { for: "assignee_id-" + _vm.attachment_name }
                      },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.$t("quick_add_task.assignee_label"))
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "svg",
                          {
                            staticClass: "pointer",
                            attrs: {
                              xmlns: "http://www.w3.org/2000/svg",
                              width: "24",
                              height: "24",
                              viewBox: "0 0 24 24"
                            }
                          },
                          [
                            _c("path", {
                              attrs: {
                                fill: "#646C9A",
                                "fill-rule": "evenodd",
                                d:
                                  "M16.327 17.503a4.991 4.991 0 0 0-8.654 0A6.97 6.97 0 0 0 12 19a6.97 6.97 0 0 0 4.327-1.497zm-9.407-.687a5.991 5.991 0 0 1 10.16 0 7 7 0 1 0-10.16 0zM12 13a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0-1a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm0 8a8 8 0 1 1 0-16 8 8 0 0 1 0 16z"
                              }
                            })
                          ]
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-9" }, [
                    _c(
                      "div",
                      {
                        staticClass: "form-group",
                        attrs: { "data-error-container": "form-group" }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "form-control-group" },
                          [
                            _c("v-select", {
                              attrs: {
                                inputId: "assignee_id-" + _vm.attachment_name,
                                options: _vm.assignee_options,
                                placeholder: "Please Select",
                                clearable: false
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "selected-option",
                                  fn: function(option) {
                                    return [
                                      _c("recipient-list-option", {
                                        attrs: { recipient: option }
                                      })
                                    ]
                                  }
                                },
                                {
                                  key: "option",
                                  fn: function(option) {
                                    return [
                                      _c("recipient-list-option", {
                                        attrs: { recipient: option }
                                      })
                                    ]
                                  }
                                }
                              ]),
                              model: {
                                value: _vm.selectedAssignee,
                                callback: function($$v) {
                                  _vm.selectedAssignee = $$v
                                },
                                expression: "selectedAssignee"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("error", {
                          attrs: {
                            errors:
                              _vm.current_task_object.errors &&
                              _vm.current_task_object.errors.assignee_id
                          }
                        })
                      ],
                      1
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row row-col-space-10" }, [
                  _c("div", { staticClass: "col-md-3" }, [
                    _c(
                      "label",
                      {
                        staticClass: "d-flex align-center justify-between",
                        attrs: { for: "virtual_due_date" + _vm.attachment_name }
                      },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.$t("quick_add_task.due_date_label"))
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "svg",
                          {
                            staticClass: "pointer",
                            attrs: {
                              xmlns: "http://www.w3.org/2000/svg",
                              width: "24",
                              height: "24",
                              viewBox: "0 0 24 24"
                            }
                          },
                          [
                            _c("path", {
                              attrs: {
                                fill: "#646C9A",
                                "fill-rule": "evenodd",
                                d:
                                  "M15 7H9v1.5a.5.5 0 0 1-1 0V7H6a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1h-2v1.5a.5.5 0 1 1-1 0V7zm1-1h2a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h2V4.5a.5.5 0 0 1 1 0V6h6V4.5a.5.5 0 1 1 1 0V6zM5 11h14v1H5v-1z"
                              }
                            })
                          ]
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-9 date-group" }, [
                    _c("div", { staticClass: "row row-col-space-10" }, [
                      _c("div", { staticClass: "col-md-6 form-group" }, [
                        _c(
                          "div",
                          { staticClass: "form-control-group" },
                          [
                            _c("date-field", {
                              attrs: {
                                id: "virtual_due_date" + _vm.attachment_name
                              },
                              model: {
                                value: _vm.current_task_object.virtual_due_date,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.current_task_object,
                                    "virtual_due_date",
                                    $$v
                                  )
                                },
                                expression:
                                  "current_task_object.virtual_due_date"
                              }
                            }),
                            _vm._v(" "),
                            _c("error", {
                              attrs: {
                                errors:
                                  _vm.current_task_object.errors &&
                                  (_vm.current_task_object.errors
                                    .virtual_due_date ||
                                    _vm.current_task_object.errors.due_date)
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "col-md-6 form-group",
                          attrs: { "data-error-container": "form-group" }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "form-control-group dt-picker-right"
                            },
                            [
                              _c("time-picker", {
                                ref: "timePicker",
                                attrs: {
                                  field_name: "task[virtual_due_time]",
                                  field_value:
                                    _vm.current_task_object.virtual_due_time,
                                  time_icon_class: "hide",
                                  element_id:
                                    "task_due_time" + _vm.attachment_name,
                                  placeholder: "08:00 AM"
                                },
                                model: {
                                  value:
                                    _vm.current_task_object.virtual_due_time,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.current_task_object,
                                      "virtual_due_time",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "current_task_object.virtual_due_time"
                                }
                              }),
                              _vm._v(" "),
                              _c("error", {
                                attrs: {
                                  errors:
                                    _vm.current_task_object.errors
                                      .virtual_due_time
                                }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row row-col-space-10" }, [
                  _c("div", { staticClass: "col-md-3" }, [
                    _c(
                      "label",
                      {
                        staticClass: "d-flex justify-between align-center",
                        attrs: { for: "task_followers" },
                        on: { click: _vm.focusFollowerField }
                      },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.$t("quick_add_task.followers_label"))
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "svg",
                          {
                            staticClass: "pointer",
                            attrs: {
                              xmlns: "http://www.w3.org/2000/svg",
                              width: "24",
                              height: "24",
                              viewBox: "0 0 24 24"
                            }
                          },
                          [
                            _c("path", {
                              attrs: {
                                fill: "#646C9A",
                                "fill-rule": "evenodd",
                                d:
                                  "M18.864 14.648c.668.172 1.136.793 1.136 1.505v3.33a.509.509 0 0 1-.5.517h-3a.509.509 0 0 1-.5-.517c0-.286.224-.517.5-.517H19v-2.813a.514.514 0 0 0-.378-.502l-3.243-.839a.511.511 0 0 1-.374-.429l-.253-1.835a.521.521 0 0 1 .287-.543c.89-.42 1.46-1.339 1.461-2.35v-.914c.024-1.276-.827-2.391-2.018-2.661a2.46 2.46 0 0 0-1.316.102.496.496 0 0 1-.638-.317.522.522 0 0 1 .306-.66 3.44 3.44 0 0 1 1.848-.138c1.67.378 2.85 1.925 2.818 3.684v.904c-.001 1.284-.658 2.46-1.708 3.108l.156 1.13 2.916.755zm-4.952 1.537c.644.19 1.088.799 1.088 1.492v1.806a.509.509 0 0 1-.5.517h-10a.509.509 0 0 1-.5-.517v-1.807c0-.692.444-1.3 1.088-1.491l2.463-.728.12-.868a3.948 3.948 0 0 1-2.483-.909.53.53 0 0 1-.09-.71c.67-.945.99-2.107.902-3.315 0-2 1.567-3.62 3.5-3.62s3.5 1.62 3.499 3.66a5.013 5.013 0 0 0 .904 3.274.53.53 0 0 1-.091.711 3.948 3.948 0 0 1-2.482.909l.12.868 2.462.728zM14 17.677a.515.515 0 0 0-.362-.497l-2.775-.82a.512.512 0 0 1-.358-.425l-.253-1.835c-.047-.345.24-.639.573-.584a2.946 2.946 0 0 0 1.985-.379A6.034 6.034 0 0 1 12 9.655c0-1.428-1.12-2.586-2.5-2.586S7 8.227 6.999 9.615a6.09 6.09 0 0 1-.81 3.522 2.946 2.946 0 0 0 1.986.379c.332-.055.62.24.573.584l-.253 1.835a.512.512 0 0 1-.358.424l-2.775.82a.515.515 0 0 0-.362.498v1.289h9v-1.29z"
                              }
                            })
                          ]
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-9" }, [
                    _c(
                      "div",
                      { staticClass: "form-class-group wo-follower-block" },
                      [
                        _c("auto-complete-followers", {
                          ref: "autoCompleteFollowers",
                          attrs: {
                            auto_suggest_followers: 5,
                            placeholder: _vm.$t(
                              "quick_add_task.followers_placeholder"
                            ),
                            follower_options: _vm.follower_options,
                            persisted_followers: [],
                            field_for: "task_followers"
                          },
                          model: {
                            value: _vm.current_task_object.task_follower_ids,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.current_task_object,
                                "task_follower_ids",
                                $$v
                              )
                            },
                            expression: "current_task_object.task_follower_ids"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row row-col-space-10" }, [
                  _c("div", { staticClass: "col-md-3" }, [
                    _c(
                      "label",
                      { staticClass: "d-flex justify-between align-center" },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.$t("quick_add_task.category_label"))
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "svg",
                          {
                            attrs: {
                              xmlns: "http://www.w3.org/2000/svg",
                              width: "24",
                              height: "24",
                              viewBox: "0 0 24 24"
                            }
                          },
                          [
                            _c("path", {
                              attrs: {
                                fill: "#646C9A",
                                "fill-rule": "evenodd",
                                d:
                                  "M6 19h12v-8.281L12 5.18 6 10.72V19zm-1 .5v-9a.5.5 0 0 1 .16-.367l6.5-6a.5.5 0 0 1 .68 0l6.5 6a.5.5 0 0 1 .16.367v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5zm7-6.5a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0-1a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-3.5 4a.5.5 0 1 1 0-1h7a.5.5 0 1 1 0 1h-7z"
                              }
                            })
                          ]
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-9" }, [
                    _c("div", { staticClass: "form-class-group lh-40 fs-13" }, [
                      _c("span", { staticClass: "super-dark" }, [
                        _vm._v(_vm._s(this.default_category.label))
                      ])
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { attrs: { "data-attachment-parent": "true" } }, [
                  _c(
                    "div",
                    {
                      staticClass: "task-attachment-container",
                      attrs: {
                        "data-nested-attachment": "container",
                        "data-association": _vm.association_name,
                        "data-nested-association": "container"
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "task-modal-attachment-group relative" },
                        [
                          _c(
                            "label",
                            {
                              staticClass:
                                "fs-13 attachment-label d-inline-block lh-40 pointer",
                              attrs: { for: _vm.attachment_name }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  this.$i18n.tc("quick_add_task.attachments")
                                )
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("multiple-file-upload", {
                            ref: "taskAttachment",
                            class: {
                              "attachment-unavailable": !_vm.current_task_object
                                .attachment_ids.length,
                              "attachment-available":
                                _vm.current_task_object.attachment_ids.length
                            },
                            attrs: {
                              value: _vm.current_task_object.attachment_ids,
                              association_value_ids:
                                _vm.current_task_object.attachment_ids,
                              required_class: "",
                              attachment_label_name: _vm.attachment_name,
                              t_add_file: "Add File",
                              single_attachment: false,
                              t_attachments: _vm.attachment_name,
                              file_field_selector:
                                "[data-attachment-file-field=true]",
                              attachment_type: _vm.attachment_type,
                              parent_selector: "[data-attachment-parent=true]",
                              t_file_upload_hint: "(max size 500 MB)",
                              attachments:
                                _vm.current_task_object
                                  .attachables_attachments_attributes
                            },
                            on: {
                              attachment_update: function($event) {
                                return _vm.updateValue(
                                  _vm.current_task_object
                                    .attachables_attachments_attributes,
                                  $event
                                )
                              }
                            },
                            model: {
                              value: _vm.current_task_object.attachment_ids,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.current_task_object,
                                  "attachment_ids",
                                  $$v
                                )
                              },
                              expression: "current_task_object.attachment_ids"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row row-col-space-15" }, [
                  _c("div", { staticClass: "col-md-12" }, [
                    _c("div", { staticClass: "form-class-group" }, [
                      _c(
                        "label",
                        {
                          staticClass: "description-label-icon",
                          attrs: { for: "task_description" }
                        },
                        [
                          _c(
                            "svg",
                            {
                              staticClass: "pointer",
                              attrs: {
                                xmlns: "http://www.w3.org/2000/svg",
                                width: "16",
                                height: "16",
                                viewBox: "0 0 16 16"
                              }
                            },
                            [
                              _c("path", {
                                attrs: {
                                  fill: "#A1A8C3",
                                  "fill-rule": "evenodd",
                                  d:
                                    "M.5 3a.5.5 0 1 1 0-1h15a.5.5 0 1 1 0 1H.5zm0 4a.5.5 0 0 1 0-1h9a.5.5 0 0 1 0 1h-9zm0 4a.5.5 0 0 1 0-1h15a.5.5 0 1 1 0 1H.5zm0 4a.5.5 0 1 1 0-1h9a.5.5 0 1 1 0 1h-9z"
                                }
                              })
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.current_task_object.description,
                            expression: "current_task_object.description"
                          }
                        ],
                        staticClass:
                          "form-control place-holder description-textarea mh-66",
                        attrs: {
                          name: "task[description]",
                          id: "task_description",
                          placeholder: _vm.$t(
                            "quick_add_task.description_placeholder"
                          ),
                          cols: "30",
                          rows: "10"
                        },
                        domProps: {
                          value: _vm.current_task_object.description
                        },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.current_task_object,
                              "description",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "row row-col-space-20 p-t-10 m-b-15" },
                  [
                    _c("div", { staticClass: "col-md-6" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-lg btn-block fs-16 font-medium",
                          attrs: { type: "button" },
                          on: { click: _vm.hideTaskFormModal }
                        },
                        [_vm._v(_vm._s(_vm.$t("quick_add_task.cancel")))]
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-6" }, [
                      _c("input", {
                        staticClass:
                          "btn btn-lg btn-block fs-16 btn-primary font-medium",
                        attrs: {
                          type: "submit",
                          disabled: _vm.ajaxRequestSent
                        },
                        domProps: { value: _vm.$t("quick_add_task.submit") },
                        on: { click: _vm.createNewTask }
                      })
                    ])
                  ]
                )
              ])
            ]
          )
        ])
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }