var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", [
    _vm.isUpdated
      ? _c("span", [
          _c("span", { staticClass: "d-inline-block fs-12" }, [
            _vm._v(_vm._s(_vm.updateText))
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "m-l-22 wo-tab-detail" }, [
            _c(
              "div",
              { staticClass: "dark tooltip-inline" },
              [
                _c("user-popover-tooltip", {
                  attrs: { user_id: _vm.user_changes_ids[1] }
                }),
                _vm._v(" "),
                _c(
                  "span",
                  { staticClass: "fontmedium" },
                  [
                    _vm._v("›\n          "),
                    _c("user-popover-tooltip", {
                      attrs: { user_id: _vm.user_changes_ids[0] }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ])
        ])
      : _c("span", [
          _c("span", { staticClass: "d-inline-block fs-12" }, [
            _vm._v(_vm._s(_vm.addText))
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "m-l-22 wo-tab-detail dark" },
            [
              _c("user-popover-tooltip", {
                attrs: { user_id: _vm.user_changes_ids[0] }
              })
            ],
            1
          )
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }