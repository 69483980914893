<template>

</template>

<script>
  import { mapMutations } from "vuex";

  export default {
    name: 'ManageAjaxRequestBit',
    mounted() {
      this.bindEventListeners();
    },
    methods: {
      ...mapMutations('GeneralConfiguration', ['updateAjaxRequestBit', 'updateCurrentTimeStamp']),
      bindEventListeners() {
        var _this = this;
        window.addEventListener('ajaxRequestStart', (event) => {
          _this.updateAjaxRequestBit(true);
        })
        window.addEventListener('ajaxRequestComplete', (event) => {
          window.disableAjaxLoader = false;
          _this.updateAjaxRequestBit(false);
          _this.updateCurrentTimeStamp();
        })
      }
    }
  }
</script>
