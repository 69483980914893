var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "16px",
        height: "16px",
        viewBox: "0 0 16 16",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink"
      }
    },
    [
      _c(
        "g",
        {
          attrs: {
            id: "Page-1",
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd"
          }
        },
        [
          _c("g", { attrs: { id: "inbox", fill: "#646C9A" } }, [
            _c("path", {
              attrs: {
                d:
                  "M15.5,3 C15.7761424,3 16,3.22385763 16,3.5 L16,14.5 C16,15.3284271 15.3284271,16 14.5,16 L1.5,16 C0.671572875,16 1.01453063e-16,15.3284271 0,14.5 L0,3.5 C-3.38176876e-17,3.22385763 0.223857625,3 0.5,3 C0.776142375,3 1,3.22385763 1,3.5 L1,9 L5.5,9 C5.77614237,9 6,9.22385763 6,9.5 L6,12 L10,12 L10,9.5 C10,9.22385763 10.2238576,9 10.5,9 L15,9 L15,3.5 C15,3.22385763 15.2238576,3 15.5,3 Z M5,10 L1,10 L1,14.5 C1,14.7761424 1.22385763,15 1.5,15 L14.5,15 C14.7761424,15 15,14.7761424 15,14.5 L15,10 L11,10 L11,12.5 C11,12.7761424 10.7761424,13 10.5,13 L5.5,13 C5.22385763,13 5,12.7761424 5,12.5 L5,10 Z M13.5,0 C13.7761424,0 14,0.223857625 14,0.5 L14,7.5 C14,7.77614237 13.7761424,8 13.5,8 C13.2238576,8 13,7.77614237 13,7.5 L13,1 L3,1 L3,7.5 C3,7.77614237 2.77614237,8 2.5,8 C2.22385763,8 2,7.77614237 2,7.5 L2,0.5 C2,0.223857625 2.22385763,0 2.5,0 L13.5,0 Z M10.5,6 C10.7761424,6 11,6.22385763 11,6.5 C11,6.77614237 10.7761424,7 10.5,7 L5.5,7 C5.22385763,7 5,6.77614237 5,6.5 C5,6.22385763 5.22385763,6 5.5,6 L10.5,6 Z M10.5,3 C10.7761424,3 11,3.22385763 11,3.5 C11,3.77614237 10.7761424,4 10.5,4 L5.5,4 C5.22385763,4 5,3.77614237 5,3.5 C5,3.22385763 5.22385763,3 5.5,3 L10.5,3 Z",
                id: "Shape"
              }
            })
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }