<template>
  <div class="form-control-group" data-behavior='autofocusable-field-container'>
    <v-select ref='selector' :options="user_options" :placeholder=" placeholder || 'Select' " :clearable="clearable" @input="userSelected" :value='selectedUser' v-on:search:blur="$emit('focus_out')">
      <template slot="selected-option" slot-scope="option">
        <recipient-list-option :recipient="option"></recipient-list-option>
      </template>

      <template slot="option" slot-scope="option">
        <recipient-list-option :recipient="option"></recipient-list-option>
      </template>
    </v-select>
  </div>
</template>

<script>

  import recipientListOption from 'components/shared/recipient_list_option.vue'
  import vSelect from 'vue-select';

  export default {
    name: 'user_selector',
    props: ['user_options', 'clearable', 'placeholder', 'selected_option'],
    data: function() {
      return {
        selectedUser: this.selected_option
      };
    },

    methods: {
      userSelected: function(value) {
        this.selectedUser = value;
        this.$emit('userSelected', value);
      },
      openSelector: function() {
        var _this = this;
        this.$nextTick(function(){
          this.$refs.selector.open = true;
          this.$refs.selector.$el.querySelector('input').focus();
        });
      }
    },

    components: {
      recipientListOption,
      vSelect
    }

  };

</script>

<style lang="css" scoped>
</style>
