var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    { staticClass: "fs-13", class: _vm.archived_text_classes },
    [
      _vm._v("\n  marked "),
      _c("span", { staticClass: "black" }, [_vm._v(_vm._s(_vm.object_name))]),
      _vm._v(" as archived\n")
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }