<template>
  <span class="history-manage-block">
    <span v-if="from_value && to_value">
      <span class="d-inline-block fs-12">{{ updated_text }}</span>
      <div class="m-l-22 wo-tab-detail">
        <div class="dark tooltip-inline">
          <div class="d-inline-block">$ {{from_value}}</div>
          <span class="fontmedium d-inline-block">›
            <div class="d-inline-block">$ {{to_value}}</div>
          </span>
        </div>
      </div>
    </span>
    <span v-else>
      <span class="d-inline-block fs-12">{{ updated_text }}</span>
      <div class="m-l-22 wo-tab-detail dark">
        <div>$ {{to_value}}</div>
      </div>
    </span>
  </span>
</template>

<script>
  export default {
    name: 'currency_detail',
    props: [
      'data_key',
      'from_value',
      'to_value',
    ],
    computed: {
      updated_text: function() {
        var eventType = '';
        if(this.from_value) {
          eventType = 'updated'
        } else {
          eventType = 'added'
        }
        return this.$i18n.tc('history_row.text_detail.' + eventType) + ' ' + this.$i18n.tc('history_row.text_detail.' + this.data_key);
      }
    }
  };
</script>
