var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "16",
        height: "16",
        viewBox: "0 0 16 16"
      }
    },
    [
      _c("g", { attrs: { fill: "none", "fill-rule": "evenodd" } }, [
        _c("path", {
          attrs: {
            fill: "#33475B",
            "fill-rule": "nonzero",
            d:
              "M1 2v13h14V2H1zm12-1h2a1 1 0 0 1 1 1v13a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h2v1h2V1h6v1h2V1z"
          }
        }),
        _vm._v(" "),
        _c("rect", {
          attrs: {
            width: "5",
            height: "3",
            x: "5.5",
            y: ".5",
            fill: "#FFF",
            stroke: "#33475B",
            rx: "1"
          }
        }),
        _vm._v(" "),
        _c("rect", {
          attrs: {
            width: "2",
            height: "6",
            x: "7",
            y: "6",
            fill: "#33475B",
            rx: "1"
          }
        }),
        _vm._v(" "),
        _c("path", { attrs: { fill: "#33475B", d: "M5 10h6l-3 3z" } })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }