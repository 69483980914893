var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", [
    !_vm.isUpdated
      ? _c("span", [
          _c("div", { staticClass: "fs-13 d-inline-block" }, [
            _vm._v(_vm._s(_vm.addedText))
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "black m-l-22 wo-tab-detail" }, [
            _vm._v(
              _vm._s(_vm._f("timeDurationFormat")(_vm.changeSet.to_value)) +
                " hours"
            )
          ])
        ])
      : _c("span", [
          _c("div", { staticClass: "fs-13 d-inline-block" }, [
            _vm._v(_vm._s(_vm.updatedText))
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "black m-l-22 wo-tab-detail" }, [
            _vm._v(
              _vm._s(_vm._f("timeDurationFormat")(_vm.changeSet.from_value)) +
                " hours"
            ),
            _c("span", { staticClass: "fontmedium" }, [
              _vm._v(
                " › " +
                  _vm._s(_vm._f("timeDurationFormat")(_vm.changeSet.to_value)) +
                  " hours"
              )
            ])
          ])
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }