var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", [
    _c("div", { staticClass: "fs-12 d-inline-block" }, [
      _vm._v("\n    " + _vm._s(_vm.updatedText) + "\n  ")
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "black wo-tab-detail", class: _vm.transitionClasses },
      [
        _vm._v(_vm._s(_vm.changeSet[0]) + "\n    "),
        _c("span", { staticClass: "fontmedium" }, [
          _vm._v(" › " + _vm._s(_vm.changeSet[1]))
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }