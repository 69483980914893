function SelectOptionsPopulator(inputs) {
  this.selectElement = inputs.selectElement;
  this.initOptions = inputs.initOptions;
}

SelectOptionsPopulator.prototype.init = function() {
  this.bindEvents();
};

SelectOptionsPopulator.prototype.bindEvents = function () {
  this.initializeSelectSearch();
};

SelectOptionsPopulator.prototype.initializeSelectSearch = function () {
  this.selectElement.select2(this.select2Options());
}

SelectOptionsPopulator.prototype.defaultOptions = function() {
  return { minimumInputLength: this.selectElement.data('minimumInputLength') || 3 };
};

SelectOptionsPopulator.prototype.select2Options = function() {
  return $.extend(true, this.defaultOptions(), this.ajaxOptions(), this.initOptions);
};

SelectOptionsPopulator.prototype.ajaxOptions = function() {
  var requestUrl = this.selectElement.data('request-path'),
  _this = this;
  return {
    placeholder: _this.selectElement.data('placeholder') || 'Please Select',
    dropdownParent: _this.selectElement.closest('.form-group'),
    ajax: {
      type: "GET",
      delay: 250,
      data: function (params) {
        var query = { q: {}};
        query['q'][_this.selectElement.data('requestParam')] = params.term;
        query['page'] = params.page || 1;
        query['admin_api_token'] = gon.adminApiToken;
        return query;
      },
      url: requestUrl,
      dataType: 'json',
      processResults: function (data, params) {
        params.page = params.page || 1;
        return {
          results: data.results,
          pagination: {
            more: (params.page * data.per_page) < data.total_records
          }
        }
      }
    },
  };
};

