<template>
  <aside class="col-md-2 si-sidebar">
    <div class="sidebar-brand">
      <a href="/admin" class="si-brand-link"></a>
      <div data-behavior="sidebar-manage-icon" class="sidebar-manage-icon" @click="toggleSidebarStateCookie"></div>
      <div class="si-nav-hamburger">
        <span class="hamburger-inner"></span>
        <span class="brand-name">Social Imprints</span>
      </div>
    </div>

    <ul class="list-group panel-group">
      <!-- Homeviews -->
      <li class="list-group-item" ref="admin/homeviews#index" v-if="gsp.view_home_views_reports">
        <a :href="homeviewsBizDevReportListing" class="toggle-link">
          <span class="sidebar-link-title">Home View</span>
          <span class="link-icon">
            <DashboardIcon></DashboardIcon>
          </span>
        </a>
      </li>

      <!-- Goals -->
      <li class="list-group-item" v-if='false'>
        <a href="javascript:void(0)" class="toggle-link">
          <span class="sidebar-link-title">Goals</span>
          <span class="link-icon">
            <GoalsIcon></GoalsIcon>
          </span>
        </a>
      </li>

      <!-- Inbox -->
      <li class="list-group-item" ref="admin/message_chains#index">
        <a :href="MessageChainIndexPath" class="toggle-link">
          <span class="sidebar-link-title">Inbox</span>
          <span class="link-icon">
            <InboxIcon></InboxIcon>
          </span>
        </a>
      </li>

      <hr>

      <!-- Work Calendar -->
      <li class="list-group-item" ref='calendar_link'>
        <a href="/admin/calendar" class="toggle-link">
          <span class="sidebar-link-title">Calendar</span>
          <span class="link-icon">
            <CalendarIcon></CalendarIcon>
          </span>
        </a>
      </li>

      <li class="list-group-item" ref="tasks_link" v-if='gsp.view_tasks'>
        <a :href="taskListing" class="toggle-link">
          <span class="sidebar-link-title">Tasks</span>
          <span class="link-icon">
            <TasksIcon></TasksIcon>
          </span>
        </a>
      </li>
      <li class="list-group-item" v-if='gsp.view_work_orders' ref='admin/workorders/work_orders#index'>
        <a :href="workOrderListing" class="toggle-link">
          <span class="sidebar-link-title">Work Orders</span>
          <span class="link-icon">
            <WorkOrdersIcon></WorkOrdersIcon>
          </span>
        </a>
      </li>

      <sidebarCollectionLink
        tabName='Resourcing'
        :isSidebarActive='isSidebarActive'
        :mainLink='resourceListing'
        :tabOptions="[
          { url: resourceListing, ref: 'admin/resourcing#show', display_name: 'Resourcing View', display: true },
          { url: resourceReportListing, ref: 'admin/resourcing#report', display_name: 'Resourcing Report', display: true }
        ]"
      >
        <span class="link-icon">
          <ResourcingIcon></ResourcingIcon>
        </span>
      </sidebarCollectionLink>

      <hr>

      <sidebarCollectionLink
        tabName='Accounts &amp; Contacts'
        :isSidebarActive='isSidebarActive'
        :mainLink='gsp.view_contacts && contactsListing'
        :tabOptions="[
          { url: contactsListing, ref: 'contacts_navigation_link', display_name: 'Contacts', display: gsp.view_contacts },
          { url: accountsListing, ref: 'admin/accounts#index', display_name: 'Accounts', display: gsp.view_accounts },
          { url: designsListing, ref: 'admin/designs#index', display_name: 'Designs', display: gsp.view_orders },
          { url: parentsListing, ref: 'admin/companies#index', display_name: 'Parent Companies', display: gsp.view_accounts },
          { url: productConfigurationListing, ref: 'product_configurations_link', display_name: 'Products', display: gsp.view_accounts },
          // { url: teamListing, ref: 'account_team_link_for_highlight', display_name: 'Teams', display: gsp.view_teams }
        ]"
      >
        <i class="icon--bizdev link-icon"></i>
      </sidebarCollectionLink>

      <li class="list-group-item" v-if='gsp.view_orders' ref="admin/opportunities#index">
        <a :href="opportunityListing" class="toggle-link">
          <span class="sidebar-link-title">Opportunities</span>
          <i class="icon--bizdev link-icon"></i>
        </a>
      </li>
      <li class="list-group-item" v-if='gsp.view_orders' ref="admin/estimates#index">
        <a :href="estimateListing" class="toggle-link">
          <span class="sidebar-link-title">Estimates</span>
          <span class="link-icon">
            <EstimatesIcon></EstimatesIcon>
          </span>
        </a>
      </li>
      <li class="list-group-item" v-if='gsp.view_orders' ref='admin/orders#index'>
        <a :href="orderListing" class="toggle-link">
          <span class="sidebar-link-title">Orders</span>
          <span class="link-icon">
            <OrdersIcon></OrdersIcon>
          </span>
        </a>
      </li>
      <li class="list-group-item" v-if='gsp.view_orders' ref='purchase_orders_link'>
        <a :href="purchaseOrdersListing" class="toggle-link">
          <span class="sidebar-link-title">Purchase Orders</span>
          <span class="link-icon">
            <PurchaseOrdersIcon></PurchaseOrdersIcon>
          </span>
        </a>
      </li>
      <li class="list-group-item" v-if='gsp.view_orders' ref='shipment_link'>
        <a :href="shipmentFilesListing" class="toggle-link">
          <span class="sidebar-link-title">Shipment Allocation</span>
          <span class="link-icon">
            <ShipmentsIcon></ShipmentsIcon>
          </span>
        </a>
      </li>

      <sidebarCollectionLink
        tabName='Catalog Management'
        :isSidebarActive='isSidebarActive'
        :mainLink='gsp.view_catalog_manager && productsListing'
        :tabOptions="[
          { url: productsListing, ref: 'admin/catalog/products#index', display_name: 'Items', display: gsp.view_catalog_manager },
          { url: supplierListing, ref: 'admin/catalog/suppliers#index', display_name: 'Suppliers', display: gsp.view_catalog_manager },
          { url: sizeListing, ref: 'catalog_properties_for_highlight', display_name: 'Catalog Properties', display: gsp.view_catalog_manager }
        ]"
      >
        <span class="link-icon">
          <CatalogManagementIcon></CatalogManagementIcon>
        </span>
      </sidebarCollectionLink>

      <hr>

      <sidebarCollectionLink
        tabName='Accounting'
        :isSidebarActive='isSidebarActive'
        :mainLink='gsp.view_accounting && accountingAccountsListing'
        :tabOptions="[
          { url: accountingAccountsListing, ref: 'admin/accountings#accounts', display_name: 'Accounts', display: gsp.view_accounting },
          { url: accountingOpportunitiesListing, ref: 'admin/accountings#opportunities', display_name: 'Opportunities', display: gsp.view_accounting },
          { url: accountingEstimatesListing, ref: 'admin/accountings#estimates', display_name: 'Estimates', display: gsp.view_accounting },
          { url: accountingOrdersListing, ref: 'admin/accountings#orders', display_name: 'Orders', display: gsp.view_accounting },
          { url: accountingPurchaseOrdersListing, ref: 'admin/accountings#purchase_orders', display_name: 'Purchase Orders', display: gsp.view_accounting }
        ]"
      >
        <i class="icon--bizdev link-icon"></i>
      </sidebarCollectionLink>

      <sidebarCollectionLink
        tabName='Reports &amp; Dashboards'
        :isSidebarActive='isSidebarActive'
        :mainLink='gsp.view_work_order_reports && workOrderReportListing'
        :tabOptions="[
          { url: workOrderReportListing, ref: 'work_order_reports', display_name: 'Work Order Report', display: gsp.view_work_order_reports },
          { url: grossProfitReportListing, ref: 'admin/reports/financial_reports/gross_profit_report', display_name: 'Gross Revenue Report', display: gsp.view_financial_reports },
          { url: workInProcessReportListing, ref: 'work_in_process_reports', display_name: 'Work In Process Report', display: gsp.view_financial_reports },
          { url: accountReceivableReportListing, ref: 'account_receivable_report', display_name: 'Account Receivable Report', display: gsp.view_financial_reports },
          { url: unconfirmedPOReportListing, ref: 'unconfirmed_pos_report', display_name: 'Unconfirmed POs Report', display: gsp.view_financial_reports },
          { url: amTodosDashboard, ref: 'am_todos_dashboard', display_name: 'To-Dos Dashboards', display: this.canShowTodosDashboard(gsp.view_todo_dashboards) },
          { url: poReportListing, ref: 'po_report_listing', display_name: 'PO Report', display: gsp.view_financial_reports },
          { url: latePoReportListing, ref: 'late_po_report_listing', display_name: 'Late PO Report', display: gsp.view_financial_reports },
          { url: overdueOrderReportListing, ref: 'overdue_order_report_listing', display_name: 'Overdue Order Report', display: gsp.view_financial_reports },
          { url: newAccountRevenueReportListing, ref: 'new_account_revenue_report_listing', display_name: 'New Account Revenue Report', display: gsp.view_financial_reports },
          { url: accountSpendReportListing, ref: 'account_spend_report_listing', display_name: 'Account Spend Report', display: gsp.view_financial_reports },
          { url: newContactRevenueReportListing, ref: 'new_contact_revenue_report_listing', display_name: 'New Contact Revenue Report', display: gsp.view_financial_reports }
        ]"
      >
        <i class="icon--reports link-icon"></i>
      </sidebarCollectionLink>

      <hr>

      <sidebarCollectionLink
        tabName='Employee Management'
        :isSidebarActive='isSidebarActive'
        :mainLink='gsp.view_users && userListing'
        :tabOptions="[
          { url: userListing, ref: 'admin/users#index', display_name: 'Employees', display: gsp.view_users },
          { url: departmentListing, ref: 'admin/teams#index', display_name: 'Departments', display: gsp.view_accounts }
        ]"
      >
        <span class="link-icon">
          <EmployeeManagementIcon></EmployeeManagementIcon>
        </span>
      </sidebarCollectionLink>

      <sidebarCollectionLink
        tabName='System Settings'
        :isSidebarActive='isSidebarActive'
        :mainLink='gsp.view_rules && rulesListing'
        :tabOptions="[
          { url: rulesListing, ref: 'rules-link-for-highlight', display_name: 'Rule Engine', display: gsp.view_rules },
          { url: emailTemplateListing, ref: 'admin/email_templates#index', display_name: 'Email Templates', display: gsp.view_email_templates },
          { url: screenPrintingUpchargeListing, ref: 'decoration_link_for_highlight', display_name: 'Upcharges List', display: gsp.view_orders },
          { url: screenPrintingLocationListing, ref: 'printing_locations', display_name: 'Print Locations List', display: gsp.view_orders },
          { url: productCustomizerListing, ref: 'admin/legacy_customizer_products#index', display_name: 'Product Customizer', display: false },
          { url: shippingCalculator, ref: 'admin/shipment_calculator#show', display_name: 'Shipment Calculator', display: true },
          { url: holdInstructionsPath, ref: 'hold_instructions', display_name: 'Hold Instructions', display: gsp.view_orders },
        ]"
      >
        <span class="link-icon">
          <SystemSettingsIcon></SystemSettingsIcon>
        </span>
      </sidebarCollectionLink>

    </ul>
  </aside>
</template>

<script>
  import { setCookie } from 'utils/cookie'
  import {
    taskListing,
    workOrderListing,
    opportunityListing,
    estimateListing,
    orderListing,
    shipmentFilesListing,
    purchaseOrdersListing,
    contactsListing,
    accountsListing,
    parentsListing,
    designsListing,
    productsListing,
    supplierListing,
    shippingCalculator,
    resourceListing,
    resourceReportListing,
    workOrderReportListing,
    userListing,
    departmentListing,
    sizeListing,
    rulesListing,
    emailTemplateListing,
    screenPrintingUpchargeListing,
    screenPrintingLocationListing,
    productCustomizerListing,
    teamListing,
    accountingAccountsListing,
    accountingOpportunitiesListing,
    accountingOrdersListing,
    accountingEstimatesListing,
    accountingPurchaseOrdersListing,
    workInProcessReportListing,
    grossProfitReportListing,
    MessageChainIndexPath,
    accountReceivableReportListing,
    unconfirmedPOReportListing,
    amTodosDashboard,
    poReportListing,
    newAccountRevenueReportListing,
    accountSpendReportListing,
    overdueOrderReportListing,
    latePoReportListing,
    homeviewsBizDevReportListing,
    productConfigurationListing,
    newContactRevenueReportListing,
    holdInstructionsPath } from 'concerns/path_helper_methods'

  import { DashboardIcon, WorkOrdersIcon, CalendarIcon, EstimatesIcon, GoalsIcon, InboxIcon, OrdersIcon, PurchaseOrdersIcon, ShipmentsIcon, TasksIcon,   ResourcingIcon, EmployeeManagementIcon, CatalogManagementIcon, SystemSettingsIcon } from 'components/shared/svg/index.js'
  import sidebarCollectionLink from 'components/global/sidebar/sidebar_collection_link.vue'

  export default {
    name: "SideBar",
    data: function() {
      return {
        isSidebarActive: $('body').hasClass('sidebar-active')
      }
    },
    methods: {
      toggleSidebarStateCookie: function() {
        if(this.isSidebarActive) {
          setCookie('sidebar-status', 'inactive', '1000')
        } else {
          setCookie('sidebar-status', 'active', '1000')
        }
        this.isSidebarActive = !this.isSidebarActive
        $('body').toggleClass('sidebar-inactive sidebar-active');
      },
      highlightLink: function() {
        window.highlightableBehaviour.forEach(highlightableTag => {
          if(this.$refs[highlightableTag]) {
            this.$refs[highlightableTag].classList.add('active-link')
          }
        });
      },
      canShowTodosDashboard: function(viewTodoDashboards) {
        if (gon.railsEnvironment == "uat" || gon.railsEnvironment == "production") {
          return false;
        } else {
          return viewTodoDashboards;
        }
      }
    },
    watch: {
      gsp: function(val) {
        this.$nextTick(() => {
          this.highlightLink()
        })
      }
    },
    computed: {
      taskListing,
      workOrderListing,
      opportunityListing,
      estimateListing,
      orderListing,
      purchaseOrdersListing,
      shipmentFilesListing,
      contactsListing,
      accountsListing,
      parentsListing,
      MessageChainIndexPath,
      designsListing,
      productsListing,
      supplierListing,
      shippingCalculator,
      resourceListing,
      resourceReportListing,
      workOrderReportListing,
      workInProcessReportListing,
      userListing,
      departmentListing,
      sizeListing,
      rulesListing,
      emailTemplateListing,
      screenPrintingUpchargeListing,
      screenPrintingLocationListing,
      productCustomizerListing,
      teamListing,
      accountingAccountsListing,
      accountingOpportunitiesListing,
      accountingOrdersListing,
      accountingEstimatesListing,
      accountingPurchaseOrdersListing,
      grossProfitReportListing,
      accountReceivableReportListing,
      productConfigurationListing,
      unconfirmedPOReportListing,
      amTodosDashboard,
      poReportListing,
      holdInstructionsPath,
      newAccountRevenueReportListing,
      accountSpendReportListing,
      overdueOrderReportListing,
      homeviewsBizDevReportListing,
      newContactRevenueReportListing,
      latePoReportListing
    },
    components: {
      sidebarCollectionLink,
      DashboardIcon,
      WorkOrdersIcon,
      CalendarIcon,
      EstimatesIcon,
      GoalsIcon,
      InboxIcon,
      OrdersIcon,
      PurchaseOrdersIcon,
      ShipmentsIcon,
      TasksIcon,
      ResourcingIcon,
      EmployeeManagementIcon,
      CatalogManagementIcon,
      SystemSettingsIcon
    }
  };
</script>
