<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
    <g fill="none" fill-rule="evenodd">
      <rect width="15" height="13" x=".5" y="2.5" stroke="#33475B" rx="2"/>
      <rect width="2" height="3" x="3" fill="#33475B" rx="1"/>
      <rect width="2" height="3" x="11" fill="#33475B" rx="1"/>
      <path fill="#33475B" d="M1 5h14v1H1z"/>
      <g fill="#33475B">
        <path d="M8 10h5v1H8z"/>
        <path d="M11 8v5h-1V8z"/>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'EventsIcon'
}
</script>
