var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    {
      staticClass: "btn btn-default pointer btn-h-40-only fontmedium",
      on: {
        click: function($event) {
          return _vm.emitEvent()
        }
      }
    },
    [
      _c(
        "svg",
        {
          attrs: {
            xmlns: "http://www.w3.org/2000/svg",
            width: "20",
            height: "20",
            viewBox: "0 0 20 20"
          }
        },
        [
          _c(
            "g",
            {
              attrs: {
                fill: "none",
                "fill-rule": "evenodd",
                transform: "translate(2 2)"
              }
            },
            [
              _c("circle", {
                attrs: {
                  cx: "8",
                  cy: "8",
                  r: "7.5",
                  fill: "#FFF",
                  stroke: "#646c9a"
                }
              }),
              _vm._v(" "),
              _c("rect", {
                attrs: {
                  width: "1",
                  height: "5",
                  x: "7",
                  y: "4",
                  fill: "#646c9a",
                  rx: ".5"
                }
              }),
              _vm._v(" "),
              _c("rect", {
                attrs: {
                  width: "5.333",
                  height: "1",
                  x: "7",
                  y: "8",
                  fill: "#646c9a",
                  rx: ".5"
                }
              })
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c("span", { staticClass: "m-l-5 align-vertical-middle" }, [
        _vm._v("Log Time")
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }