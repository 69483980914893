var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", [
    _vm.isUpdated
      ? _c("span", [
          _c("span", { staticClass: "d-inline-block" }, [
            _vm._v("updated department")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "m-l-22 wo-tab-detail" }, [
            _c("div", { staticClass: "user-color" }, [
              _vm._v("\n        " + _vm._s(_vm.names[0]) + "\n        "),
              _c("span", { staticClass: "fontmedium" }, [
                _vm._v("›\n          " + _vm._s(_vm.names[1]) + "\n        ")
              ])
            ])
          ])
        ])
      : _vm.isCreated
      ? _c("span", [
          _c("span", { staticClass: "d-inline-block" }, [
            _vm._v("added department")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "m-l-22 wo-tab-detail dark" }, [
            _vm._v("\n      " + _vm._s(_vm.names[0]) + "\n    ")
          ])
        ])
      : _vm.isDestroyed
      ? _c("span", [
          _c("span", { staticClass: "d-inline-block" }, [
            _vm._v("removed department")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "m-l-22 wo-tab-detail dark" }, [
            _vm._v("\n      " + _vm._s(_vm.names[0]) + "\n    ")
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }