<template>
  <a :class='classes' data-toggle="modal" href="#create-work-order-widget" @click='setDefaultData'>
    <slot></slot>
  </a>
</template>

<script>
  import { createHelpers } from "vuex-map-fields";

  const { mapFields } = createHelpers({
    getterType: "getCreateWorkOrder",
    mutationType: "createNewWorkOrder"
  });

  export default {
    name: 'OpenWorkOrderQuickAddButton',
    data: function() {
      return {
        created: false
      }
    },
    props: {
      classes: {
        type: String,
        default: 'fs-13'
      },
      is_internal: Boolean,
      default_account_id: Number,
      default_activity_id: Number,
      default_wo_contact_present: Boolean,
      linked_transaction_id: Number,
      default_account_details: Object,
      default_contact_details: Object,
      modal_header: {
        type: String,
        default: 'New Work Order'
      }
    },
    methods: {
      setDefaultData: function() {
        this.newWorkOrder.internal = this.is_internal
        this.modalHeader = this.modal_header
        this.newWorkOrder.account_id = this.default_account_id
        this.newWorkOrder.wo_contact_present = this.default_wo_contact_present
        if(this.default_wo_contact_present) {
          this.newWorkOrder.account_details = this.default_account_details
          this.newWorkOrder.contact_details = this.default_contact_details
          this.newWorkOrder.account_id = this.default_account_details.id
          this.newWorkOrder.contact_id = this.default_contact_details.id
        }
        this.newWorkOrder.linked_transaction_id = this.linked_transaction_id
        this.newWorkOrder.activity_id = this.default_activity_id
      }
    },
    watch: {
      'newWorkOrder.id': function(val, oldVal) {
        var parentContainer = $(this.$el).closest("[data-wo-parent-id]")
        if(val && !this.created && parentContainer && this.newWorkOrder.activity && parentContainer.data('wo-parent-id') === Number(this.newWorkOrder.activity.id)) {
          var container = $(this.$el).closest("[data-behavior='wo-create-container']").find("[data-behavior='work-order']")
          container.find('[data-behavior="data-modal-click-parent"]').attr('data-url', '/admin/work_orders/' + val)
          container.find('[data-behavior="wo-url"]').attr('data-url', '/admin/work_orders/' + val)
          container.find('[data-behavior="wo-url"]').html('#WO' + val)
          $(this.$el).hide()
          this.created = true
          this.$emit('set-wo-data', this.newWorkOrder)
        }
        this.$emit('wo-data-create-finish')
        window.dispatchEvent(new CustomEvent('updateList', { detail: { listNames: ['work_order_list'] } }));
      }
    },
    computed: {
      ...mapFields("QuickAddWorkOrderWidget", ["newWorkOrder", 'modalHeader'])
    }
  }
</script>
