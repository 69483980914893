<template>
  <aside class="placeholder-area" :style="{ height, width }"></aside>
</template>
<script>
  export default {
    name: 'PlaceholderLoading',
    props: {
      height: {
        type: String,
        required: true
      },
      width: {
        type: String,
        required: false,
        default: "100%"
      }
    }
  }
</script>
