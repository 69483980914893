var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "16",
        height: "16",
        viewBox: "0 0 16 16"
      }
    },
    [
      _c("path", {
        attrs: {
          fill: "#646C9A",
          "fill-rule": "evenodd",
          d:
            "M2.5 6a.5.5 0 0 1 .09.992L2.5 7H1v8h14V7h-3.5a.5.5 0 0 1-.492-.41L11 6.5a.5.5 0 0 1 .41-.492L11.5 6h4a.5.5 0 0 1 .492.41L16 6.5v9a.5.5 0 0 1-.41.492L15.5 16H.5a.5.5 0 0 1-.492-.41L0 15.5v-9a.5.5 0 0 1 .41-.492L.5 6h2zm10 5a.5.5 0 0 1 .09.992L12.5 12h-9a.5.5 0 0 1-.09-.992L3.5 11h9zM10.146.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1 0 .708l-5 5a.5.5 0 0 1-.196.12l-3 1a.5.5 0 0 1-.632-.632l1-3a.5.5 0 0 1 .12-.196zm.354 1.061L5.937 5.769l-.646 1.94 1.939-.647L11.793 2.5 10.5 1.207z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }