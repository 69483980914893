var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "16",
        height: "16",
        viewBox: "0 0 16 16"
      }
    },
    [
      _c("path", {
        attrs: {
          fill: "#33475B",
          "fill-rule": "evenodd",
          d:
            "M13 12h1.5a.5.5 0 1 1 0 1H13v1.5a.5.5 0 1 1-1 0V13h-1.5a.5.5 0 1 1 0-1H12v-1.5a.5.5 0 1 1 1 0V12zm-3.5 3a.5.5 0 1 1 0 1h-9a.5.5 0 0 1-.5-.5A5.5 5.5 0 0 1 5.5 10h3a.5.5 0 1 1 0 1h-3a4.5 4.5 0 0 0-4.473 4H9.5zM7 8a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm0-1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }