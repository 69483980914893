<template>
  <div @mouseover="loadContactData" ref="contactContent">
    <span class='tooltip-target d-block full-width' v-if="!loadedContactData">
      <span>
        <a :href="contactShowPath(contactData)" class="clickable-item card-assign-user normal">
          <img :src="contactData.profile_picture" class="img img-responsive">
        </a>
        <a :href="contactShowPath(contactData)" target="_blank" class="tootltip-name clickable-item m-l-5">{{ contactData.name }}</a>
      </span>
    </span>
    <v-popover placement='top' trigger='hover' popoverArrowClass='hide' v-else>
      <span>
        <a :href="contactShowPath(contactData)" class="clickable-item card-assign-user normal">
          <img :src="contactData.profile_picture" class="img img-responsive">
        </a>
        <a :href="contactShowPath(contactData)" target="_blank" class="tootltip-name clickable-item m-l-5">{{ contactData.name }}</a>
      </span>
      <template slot='popover'>
        <ContactTooltipHover
          :contactId="contactData.id"
        ></ContactTooltipHover>
      </template>
    </v-popover>
  </div>
</template>
<script>
  import { mapActions, mapState } from 'vuex';
  import { contactShowPath } from 'concerns/path_helper_methods.js';
  import ContactTooltipHover from 'components/shared/contacts/contact_tooltip_hover.vue';
  export default {
    name: 'ContactDataTooltipForHistory',
    props: {
      contactData: Object
    },
    components: {
      ContactTooltipHover
    },
    methods: {
      ...mapActions('GeneralConfiguration', ['fetchContactData']),
      contactShowPath,
      loadContactData: function() {
        if(!this.loadedContactData) {
          window.disableAjaxLoader = true;
          this.fetchContactData({
            apollo: this.$apollo,
            contactId: this.contactData.id
          }).then(() => window.disableAjaxLoader = false);
        }
      }
    },
    computed: {
      ...mapState('GeneralConfiguration', ['contactsData']),
      loadedContactData: function() {
        return this.contactsData[this.contactData.id];
      },
    }
  }
</script>
