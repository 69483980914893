var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "16",
        height: "16",
        viewBox: "0 0 16 16"
      }
    },
    [
      _c("path", {
        attrs: {
          fill: "#646C9A",
          "fill-rule": "evenodd",
          d:
            "M3.5 1a.5.5 0 0 1 .5.5V2a1 1 0 1 0 2 0v-.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5V2a1 1 0 0 0 2 0v-.5a.5.5 0 0 1 .5-.5h2A1.5 1.5 0 0 1 16 2.5v11a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 13.5v-11A1.5 1.5 0 0 1 1.5 1h2zM3 2H1.5a.5.5 0 0 0-.5.5v11a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-11a.5.5 0 0 0-.5-.5H13a2 2 0 0 1-4 0H7a2 2 0 1 1-4 0zm2 7a2 2 0 0 1 2 2H3a2 2 0 0 1 2-2zm7.5 1a.5.5 0 1 1 0 1h-3a.5.5 0 1 1 0-1h3zM5 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7.5 1a.5.5 0 1 1 0 1h-4a.5.5 0 0 1 0-1h4z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }