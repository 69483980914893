var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "16",
        height: "16",
        viewBox: "0 0 16 16"
      }
    },
    [
      _c("path", {
        attrs: {
          fill: "#646C9A",
          "fill-rule": "evenodd",
          d:
            "M4.436 9c.892 0 1.215.093 1.54.267.327.174.583.43.757.756.174.326.267.65.267 1.54v1.873c0 .892-.093 1.215-.267 1.54-.174.327-.43.583-.756.757-.326.174-.65.267-1.54.267H2.563c-.892 0-1.215-.093-1.54-.267a1.817 1.817 0 0 1-.757-.756C.093 14.65 0 14.327 0 13.437v-1.873c0-.892.093-1.215.267-1.54.174-.327.43-.583.756-.757.326-.174.65-.267 1.54-.267h1.873zm.17 1.001L2.564 10c-.662 0-.864.039-1.07.149a.818.818 0 0 0-.345.346c-.1.187-.141.37-.148.9L1 13.435c0 .662.039.864.149 1.07a.818.818 0 0 0 .346.345c.205.11.407.149 1.069.149h1.872c.662 0 .864-.039 1.07-.149a.818.818 0 0 0 .345-.346c.11-.205.149-.407.149-1.069v-1.872c0-.662-.039-.864-.149-1.07a.818.818 0 0 0-.346-.345c-.187-.1-.37-.141-.9-.148zM13.5 13a.5.5 0 1 1 0 1h-4a.5.5 0 1 1 0-1h4zm2-3a.5.5 0 1 1 0 1h-6a.5.5 0 1 1 0-1h6zM4.436 0c.892 0 1.215.093 1.54.267.327.174.583.43.757.756.174.326.267.65.267 1.54v1.873c0 .892-.093 1.215-.267 1.54-.174.327-.43.583-.756.757-.326.174-.65.267-1.54.267H2.563c-.892 0-1.215-.093-1.54-.267a1.817 1.817 0 0 1-.757-.756C.093 5.65 0 5.327 0 4.437V2.563c0-.892.093-1.215.267-1.54.174-.327.43-.583.756-.757C1.35.093 1.673 0 2.563 0h1.873zm.17 1.001L2.564 1c-.662 0-.864.039-1.07.149a.818.818 0 0 0-.345.346c-.1.187-.141.37-.148.9L1 4.435c0 .662.039.864.149 1.07a.818.818 0 0 0 .346.345c.205.11.407.149 1.069.149h1.872c.662 0 .864-.039 1.07-.149a.818.818 0 0 0 .345-.346C5.961 5.3 6 5.098 6 4.436V2.564c0-.662-.039-.864-.149-1.07a.818.818 0 0 0-.346-.345c-.187-.1-.37-.141-.9-.148zM13.5 4a.5.5 0 1 1 0 1h-4a.5.5 0 0 1 0-1h4zm2-3a.5.5 0 1 1 0 1h-6a.5.5 0 0 1 0-1h6z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }