var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    {
      staticClass:
        "list-group-item padding-horizontal-none task-history-item task-history-tab"
    },
    [
      _c(
        "div",
        { staticClass: "task-update-info tooltip-inline" },
        [
          _c("user-pic", { attrs: { author_id: _vm.version.author_id } }),
          _vm._v(" "),
          _vm.createOrDestroy(_vm.version)
            ? [
                _vm.version.version_item_type_is_follow
                  ? _c("span", [
                      _c("span", [_vm._v(_vm._s(_vm.version_event))]),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(_vm._s(this.$i18n.tc("history_row.follower")))
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "task-history-follower" }, [
                        _c(
                          "span",
                          { staticClass: "follower" },
                          [
                            _c("user-popover-tooltip", {
                              attrs: { user_id: _vm.version.follower_id }
                            })
                          ],
                          1
                        )
                      ])
                    ])
                  : _vm.version.version_item_type_is_attachment
                  ? _c("span", [
                      _c("span", { staticClass: "fs-13" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.version_event == "added"
                                ? "uploaded"
                                : _vm.version_event
                            ) +
                            " " +
                            _vm._s(_vm.$i18n.tc("history_row.attachment")) +
                            "\n          "
                        ),
                        _c("span", { staticClass: "black" }, [
                          _vm._v(_vm._s(_vm.version.attachment_name))
                        ])
                      ])
                    ])
                  : _c("span", [
                      _c("span", [_vm._v(_vm._s(_vm.version_event))]),
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.version.version_item_type) +
                          "\n        "
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "history-manage-block black p-l-4 task-tab-detail"
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.version.version_item_name) +
                              "\n        "
                          )
                        ]
                      )
                    ])
              ]
            : _vm._l(_vm.version.change_set, function(changeset) {
                return _c(
                  "span",
                  [
                    _vm.match_key(changeset.key, "completed_by_id")
                      ? _c("completed-detail", {
                          attrs: {
                            object_name: _vm.object.title,
                            to_value: changeset.to_value,
                            from_value: changeset.from_value
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.match_key(changeset.key, "assignee_id")
                      ? _c("user-changed-detail", {
                          attrs: {
                            updated_text: "updated assignee",
                            changed_user_classes: "fontmedium black-2",
                            user_changes_ids: _vm.version.assignee_changes_ids
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.match_key(changeset.key, "due_date")
                      ? _c("updated-due-date-detail", {
                          attrs: {
                            updated_text: "updated due date",
                            due_date_changes: _vm.version.due_date_changes
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.match_key(changeset.key, "canceller_id")
                      ? _c("cancelled-detail", {
                          attrs: {
                            to_value: changeset.to_value,
                            object_name: _vm.object.title
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.match_key(changeset.key, "archiver_id")
                      ? _c("archived-detail", {
                          attrs: {
                            to_value: changeset.to_value,
                            object_name: _vm.object.title
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.other_updates(changeset.key)
                      ? _c("text-detail", {
                          attrs: {
                            data_key: changeset.key,
                            from_value: changeset.from_value,
                            to_value: changeset.to_value,
                            object_name: _vm.object.title
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              })
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "fs-13 date-flex-col" }, [
        _c("small", { staticClass: "small" }, [
          _vm._v(
            "\n      " +
              _vm._s(_vm._f("formatDate")(_vm.version.created_at)) +
              "\n    "
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }