<template>
  
</template>

<script>
  export default {
    name: 'PubnubEntityUpdated',
    props: ['entity_type', 'entity_id', 'updated_at'],
    mounted: function() {
      this.bindPubNubEvents();
    },
    methods: {
      isLatestUpdate: function (updated_at) {
        return moment(updated_at, 'YYYY-MM-DDTHH:mm:ssZ').isAfter(this.updated_at);
      },
      isEntityUpdated: function (message) {
        return (
          message.event == 'update' &&
          this.entity_id == message.entity_id.toString() &&
          this.isLatestUpdate(message.updated_at)
        );
      },
      bindPubNubEvents: function() {
        var _this = this;

        var pubnub = new PubNub({ subscribe_key: gon.pubnubSubscribeKey });

        pubnub.subscribe({ channels: [gon.pubnubEntityUpdateChannel] });

        pubnub.addListener({
          message: function(response) {
            var message = response.message;

            if (message.entity_type == _this.entity_type && _this.isEntityUpdated(message)) {
              _this.$emit('entityUpdated', message.entity_id.toString());
            }

          }
        });
      }
    }
  }
</script>
