var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "si-modal",
        {
          ref: "workOrderModal",
          attrs: { modalOptions: _vm.modalOptions },
          on: { onDismiss: _vm.hideModal }
        },
        [
          _vm.workOrder.id
            ? _c("template", { slot: "modal-content" }, [
                _c(
                  "div",
                  { staticClass: "with-separator default p-b-15 p-t-5" },
                  [
                    _c(
                      "div",
                      { staticClass: "d-flex align-center justify-between" },
                      [
                        _c("div", { attrs: { "data-name": "true" } }, [
                          _c(
                            "span",
                            {
                              staticClass: "label label-warning si-label",
                              class: _vm.workOrder.state
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.workOrder.state_name) + " "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _vm.workOrder.is_archived
                            ? _c(
                                "span",
                                {
                                  staticClass:
                                    "label label-warning si-label archived"
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$i18n.t(
                                        "work_order.quick_show.archived"
                                      )
                                    )
                                  )
                                ]
                              )
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _c(
                            "div",
                            {
                              staticClass: "dropdown-right",
                              attrs: { "data-dropdown": "true" }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "dropdown clickable-item d-inline-block",
                                  attrs: { "data-menu": "dropdown" }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "dropdown-right d-flex justify-flex-end",
                                      attrs: { "data-dropdown": "true" }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "dropdown clickable-item d-inline-block",
                                          attrs: { "data-menu": "dropdown" }
                                        },
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn dropdown-toggle  p-t-b-5 fs-14 p-l-r-10 js-show-button",
                                              class: {
                                                disabled: _vm.ajaxRequestSent
                                              },
                                              attrs: {
                                                type: "button",
                                                "data-toggle": "dropdown"
                                              }
                                            },
                                            [
                                              _c("span", {
                                                staticClass:
                                                  "btn-icon-helper gear-icon m-l-0"
                                              }),
                                              _vm._v(" "),
                                              _c("span", {
                                                staticClass: "caret"
                                              })
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("work-order-quick-actions", {
                                            attrs: {
                                              work_order: _vm.workOrder,
                                              deleteListbutton: true,
                                              showView: "true"
                                            },
                                            on: {
                                              "quick-action-performed":
                                                _vm.onSuccess,
                                              onDeleted: _vm.onDeleted
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ]
                              )
                            ]
                          )
                        ])
                      ]
                    )
                  ]
                ),
                _vm._v(" "),
                _c("div", {}, [
                  _c(
                    "ul",
                    {
                      staticClass:
                        "list-group no-horizontal-border no-btm-margin task-list-group list-unstyled"
                    },
                    [
                      _c(
                        "li",
                        {
                          staticClass: "list-group-item padding-horizontal-none"
                        },
                        [
                          _c("div", { staticClass: "modal-title p-b-5" }, [
                            _c(
                              "span",
                              { staticClass: "fs-24 d-block p-t-15 semibold" },
                              [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: _vm.workOrderShowPath(
                                        _vm.workOrder
                                      ),
                                      target: "_blank"
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.workOrder.title))]
                                )
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _vm.workOrder.formatted_description
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "task-description-box m-t-0 lh-15 fs-12 v1-launch-hidden",
                                  attrs: { "data-behavior": "task-description" }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.workOrder.formatted_description
                                    ) + "\n              "
                                  ),
                                  _vm.descriptionOverFlowed
                                    ? _c("span", { staticClass: "fs-11" }, [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href: _vm.workOrderShowPath(
                                                _vm.workOrder
                                              )
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                  More\n                "
                                            )
                                          ]
                                        )
                                      ])
                                    : _vm._e()
                                ]
                              )
                            : _vm._e()
                        ]
                      ),
                      _vm._v(" "),
                      _vm.workOrder.unarchived_sub_work_orders.length
                        ? _c(
                            "li",
                            { staticClass: "list-group-item padding-none" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "list-detail-block sub-work-orders"
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "title-label text-muted" },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            this.$i18n.tc(
                                              "work_order.quick_show.sub_wo_count",
                                              _vm.workOrder
                                                .unarchived_sub_work_orders
                                                .length
                                            )
                                          ) +
                                          "\n              "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "sub-work-order-list" },
                                    _vm._l(
                                      _vm.workOrder.unarchived_sub_work_orders,
                                      function(subWorkOrder) {
                                        return _c(
                                          "div",
                                          {
                                            staticClass:
                                              "sub-work-order-item d-flex justify-between"
                                          },
                                          [
                                            _c("div", [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "link-title clickable-item fs-13 light-gray"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      #" +
                                                      _vm._s(subWorkOrder.id) +
                                                      "\n                    "
                                                  )
                                                ]
                                              ),
                                              _vm._v(
                                                "\n                    -\n                    "
                                              ),
                                              _c("span", [
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "link-title fs-13 text-obsidian",
                                                    attrs: {
                                                      href: _vm.subWorkOrderShowPath(
                                                        _vm.workOrder,
                                                        subWorkOrder
                                                      ),
                                                      target: "_blank"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(subWorkOrder.title)
                                                    )
                                                  ]
                                                )
                                              ]),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "fs-11 m-t-2" },
                                                [
                                                  subWorkOrder.not_accepted
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text green-link-secondary d-inlin-block p-r-10"
                                                        },
                                                        [
                                                          subWorkOrder.accept_by_datetime
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  class: {
                                                                    "red semibold":
                                                                      subWorkOrder.accept_by_date_passed
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                          " +
                                                                      _vm._s(
                                                                        _vm.$i18n.t(
                                                                          "work_order.quick_show.accept_by"
                                                                        )
                                                                      ) +
                                                                      "\n                          " +
                                                                      _vm._s(
                                                                        _vm._f(
                                                                          "formatSiDateExpanded"
                                                                        )(
                                                                          subWorkOrder.accept_by_datetime
                                                                        )
                                                                      ) +
                                                                      ", " +
                                                                      _vm._s(
                                                                        _vm._f(
                                                                          "formatSITime"
                                                                        )(
                                                                          subWorkOrder.accept_by_datetime
                                                                        )
                                                                      ) +
                                                                      "\n                        "
                                                                  )
                                                                ]
                                                              )
                                                            : _vm._e()
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$i18n.t(
                                                          "work_order.quick_show.estimated"
                                                        )
                                                      ) +
                                                        " " +
                                                        _vm._s(
                                                          subWorkOrder.estimated_work_time_logged_words
                                                        )
                                                    )
                                                  ])
                                                ]
                                              )
                                            ]),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "d-flex" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "label label-warning si-label",
                                                    class: subWorkOrder.state
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                      " +
                                                        _vm._s(
                                                          subWorkOrder.state_name
                                                        ) +
                                                        "\n                    "
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "card-width m-l-10"
                                                  },
                                                  [
                                                    _c(
                                                      "user-table-grid-content",
                                                      {
                                                        attrs: {
                                                          user_id:
                                                            subWorkOrder.assignee_id
                                                        }
                                                      }
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  )
                                ]
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("li", { staticClass: "list-group-item" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "row list-detail-block d-flex align-center"
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "col-md-4 title-label text-muted"
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$i18n.t(
                                      "work_order.quick_show.assignee"
                                    )
                                  )
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-md-8 semibold padding-horizontal-none text-obsidian fs-13"
                              },
                              [
                                _vm.workOrder.assignee_id
                                  ? _c(
                                      "div",
                                      [
                                        _c("user-table-grid-content", {
                                          attrs: {
                                            user_id: _vm.workOrder.assignee_id
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _c("span", [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.$i18n.t(
                                              "work_order.quick_show.not_assigned_to_anyone_yet"
                                            )
                                          ) +
                                          "\n                "
                                      )
                                    ])
                              ]
                            )
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c("li", { staticClass: "list-group-item" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "row list-detail-block d-flex align-center"
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "col-md-4 title-label text-muted"
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$i18n.t("work_order.quick_show.creator")
                                  )
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-md-8 semibold padding-horizontal-none text-obsidian fs-13"
                              },
                              [
                                _c("user-table-grid-content", {
                                  attrs: { user_id: _vm.workOrder.creator_id }
                                })
                              ],
                              1
                            )
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _vm.workOrder.not_accepted
                        ? _c("li", { staticClass: "list-group-item" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "row list-detail-block d-flex align-center"
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-md-4 title-label text-muted"
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$i18n.t(
                                          "work_order.quick_show.accept_by"
                                        )
                                      )
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-md-8  padding-horizontal-none fs-13",
                                    attrs: { "data-due-date": "true" }
                                  },
                                  [
                                    _vm.workOrder.accept_by_datetime
                                      ? _c("span", [
                                          _vm.workOrder.accept_by_date_passed
                                            ? _c(
                                                "span",
                                                { staticClass: "red semibold" },
                                                [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        _vm._f(
                                                          "formatSiDateExpanded"
                                                        )(
                                                          _vm.workOrder
                                                            .accept_by_datetime
                                                        )
                                                      ) +
                                                      ", "
                                                  ),
                                                  _c(
                                                    "span",
                                                    { staticClass: "red" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm._f(
                                                            "formatSITime"
                                                          )(
                                                            _vm.workOrder
                                                              .accept_by_datetime
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            : _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "text semibold green-link-secondary"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        _vm._f(
                                                          "formatSiDateExpanded"
                                                        )(
                                                          _vm.workOrder
                                                            .accept_by_datetime
                                                        )
                                                      ) +
                                                      ", "
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "text green-link-secondary"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm._f(
                                                            "formatSITime"
                                                          )(
                                                            _vm.workOrder
                                                              .accept_by_datetime
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                        ])
                                      : _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$i18n.tc("default_no_data")
                                            )
                                          )
                                        ])
                                  ]
                                )
                              ]
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("li", { staticClass: "list-group-item" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "row list-detail-block d-flex align-center"
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "col-md-4 title-label text-muted"
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$i18n.t(
                                      "work_order.quick_show.due_date"
                                    )
                                  )
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-md-8 padding-horizontal-none fs-13",
                                attrs: { "data-due-date": "true" }
                              },
                              [
                                _vm.workOrder.due_datetime
                                  ? _c("span", [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "text-obsidian semibold"
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("formatSiDateExpanded")(
                                                _vm.workOrder.due_datetime
                                              )
                                            )
                                          )
                                        ]
                                      ),
                                      _vm._v(",\n                  "),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("formatSITime")(
                                              _vm.workOrder.due_datetime
                                            )
                                          )
                                        )
                                      ])
                                    ])
                                  : _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.$i18n.tc("default_no_data"))
                                      )
                                    ])
                              ]
                            )
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c("li", { staticClass: "list-group-item" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "row list-detail-block d-flex align-center"
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "col-md-4 title-label text-muted",
                                attrs: {
                                  "data-behavior": "formatted-time-logged"
                                }
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.$i18n.t(
                                        "work_order.quick_show.total_time_logged"
                                      )
                                    ) +
                                    "\n              "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-md-8 semibold padding-horizontal-none text-obsidian fs-13"
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.workOrder
                                      .estimated_work_time_logged_words ||
                                      _vm.$i18n.tc("default_no_data")
                                  )
                                )
                              ]
                            )
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c("li", { staticClass: "list-group-item" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "row list-detail-block d-flex align-center"
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "col-md-4 title-label text-muted",
                                attrs: {
                                  "data-behavior": "formatted-time-logged"
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$i18n.t(
                                      "work_order.quick_show.time_spent"
                                    )
                                  )
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-md-8 semibold padding-horizontal-none text-obsidian fs-13"
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.workOrder
                                        .associated_total_time_logged_words ||
                                        _vm.$i18n.tc("default_no_data")
                                    ) +
                                    "\n              "
                                )
                              ]
                            )
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _vm.workOrder.account && !_vm.workOrder.is_internal
                        ? _c("li", { staticClass: "list-group-item" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "row list-detail-block d-flex align-center"
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-md-4 title-label text-muted"
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$i18n.t(
                                          "work_order.quick_show.account"
                                        )
                                      )
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-md-8 semibold padding-horizontal-none text-obsidian fs-13"
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "user-name-with-image trigger-block"
                                      },
                                      [
                                        _c("AccountDataTooltip", {
                                          attrs: {
                                            accountData: _vm.workOrder.account
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ]
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.workOrder.contact && !_vm.workOrder.is_internal
                        ? _c("li", { staticClass: "list-group-item" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "row list-detail-block d-flex align-center"
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-md-4 title-label text-muted"
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$i18n.t(
                                          "work_order.quick_show.contact"
                                        )
                                      )
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-md-8 padding-horizontal-none semibold text-obsidian fs-13"
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "user-name-with-image trigger-block"
                                      },
                                      [
                                        _c("ContactDataTooltip", {
                                          attrs: {
                                            contactData: _vm.workOrder.contact
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ]
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.workOrder.work_order_follower_ids.length
                        ? _c("li", { staticClass: "list-group-item" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "row list-detail-block d-flex align-center"
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-md-4 title-label text-muted"
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$i18n.t(
                                          "work_order.quick_show.followers"
                                        )
                                      )
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-md-8 semibold padding-horizontal-none text-obsidian fs-13"
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "d-flex align-center" },
                                      [
                                        _vm._l(_vm.followerIds, function(
                                          userId,
                                          index
                                        ) {
                                          return _c("span", [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "pop-over-box d-inline-block"
                                              },
                                              [
                                                _c("UserTableGridContent", {
                                                  attrs: {
                                                    user_id: String(userId),
                                                    parentClass: ""
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ])
                                        }),
                                        _vm._v(" "),
                                        _vm.workOrder.work_order_follower_ids
                                          .length > 5
                                          ? _c(
                                              "a",
                                              {
                                                staticClass: "card-assign-user",
                                                attrs: {
                                                  href: _vm.workOrderShowPath(
                                                    _vm.workOrder
                                                  )
                                                }
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "fs-11" },
                                                  [
                                                    _vm._v(
                                                      "+" +
                                                        _vm._s(
                                                          _vm.workOrder
                                                            .work_order_follower_ids
                                                            .length - 5
                                                        ) +
                                                        " " +
                                                        _vm._s(
                                                          _vm.$i18n.t(
                                                            "work_order.quick_show.more_followers"
                                                          )
                                                        )
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ],
                                      2
                                    )
                                  ]
                                )
                              ]
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "li",
                        {
                          staticClass:
                            "list-group-item-helper no-btm-border last-item-group padding-horizontal-none"
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass:
                                "btn btn-si btn-block m-t-b-15 semibold btn-with-icon icon-right",
                              attrs: {
                                href: _vm.workOrderShowPath(_vm.workOrder),
                                target: "_blank"
                              }
                            },
                            [
                              _c("span", { staticClass: "relative fs-14" }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.$i18n.t(
                                        "work_order.quick_show.view_completed"
                                      )
                                    ) +
                                    "\n              "
                                )
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "btn-icon no-space" }, [
                                _c(
                                  "svg",
                                  {
                                    staticClass: "d-block",
                                    attrs: {
                                      version: "1.1",
                                      id: "Layer_1",
                                      xmlns: "http://www.w3.org/2000/svg",
                                      "xmlns:xlink":
                                        "http://www.w3.org/1999/xlink",
                                      x: "0px",
                                      y: "0px",
                                      width: "20px",
                                      height: "14px",
                                      viewBox: "6 6 20 20",
                                      "enable-background": "new 6 6 20 20",
                                      "xml:space": "preserve",
                                      "data-ember-extension": "1"
                                    }
                                  },
                                  [
                                    _c("g", [
                                      _c("path", {
                                        attrs: {
                                          fill: "none",
                                          d: "M0,0h32v32H0V0z"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("g", [
                                        _c("path", {
                                          staticClass: "stroke-blue-hover",
                                          attrs: {
                                            fill: "none",
                                            stroke: "#a1a8c3",
                                            "stroke-width": "2",
                                            "stroke-linecap": "round",
                                            "stroke-linejoin": "round",
                                            d:
                                              "M7.75,16H23.5 M19,22l6-6    l-6-6"
                                          }
                                        })
                                      ])
                                    ])
                                  ]
                                )
                              ])
                            ]
                          )
                        ]
                      )
                    ]
                  )
                ])
              ])
            : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      _c("pubnub-entity-updated", {
        attrs: {
          entity_type: "WorkOrder",
          entity_id: _vm.quickViewWorkOrderId,
          updated_at: _vm.wo_updated_at
        },
        on: { entityUpdated: _vm.silentlyLoadData }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }