var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "recipient-list-option d-flex align-center pointer" },
    [
      _c("div", { staticClass: "user-img-block" }, [
        _c("span", { staticClass: "card-assign-user pointer" }, [
          _c("img", {
            staticClass: "img-responsive assign-user-image",
            attrs: { src: _vm.recipient.picture }
          })
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "p-l-10" }, [
        _c("div", { staticClass: "dark-gray name" }, [
          _c("span", { staticClass: "fontmedium" }, [
            _vm._v(_vm._s(_vm.recipient.label))
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "fs-12" }, [
          _vm._v("\n      " + _vm._s(_vm.recipient.email) + "\n    ")
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }