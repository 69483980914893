<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
    <path fill="#33475B " fill-rule="evenodd" d="M14.5 11a.5.5 0 0 1 .5.5v3a1.5 1.5 0 0 1-1.5 1.5h-11A1.5 1.5 0 0 1 1 14.5v-3a.5.5 0 1 1 1 0v3a.5.5 0 0 0 .5.5h11a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 1 .5-.5zm-4-11a.5.5 0 0 1 .5.5V3h3.5A1.5 1.5 0 0 1 16 4.5v5a.5.5 0 0 1-.5.5H.5a.5.5 0 0 1-.5-.5v-5A1.5 1.5 0 0 1 1.5 3H5V.5a.5.5 0 0 1 .5-.5h5zm4 4h-13a.5.5 0 0 0-.5.5V9h14V4.5a.5.5 0 0 0-.5-.5zM10 1H6v2h4V1z"/>
  </svg>
</template>

<script>
export default {
  name: 'AccountsIcon'
}
</script>
