<template>
  <div class="in-place-editor" :class='container_classes'>
    <span v-show="!editable" v-on:click="toggleEdit(this)" :class='display_text_class'>{{ value }}</span>

    <template v-if='!textarea'>
      <input type="text"
          class="form"
          v-on:focus='saveValue'
          :class='input_value_class'
          @input="$emit('input', $event.target.value)"
          @keydown.enter.stop='emitChangeEvent($event)'
          v-on:focusout.stop='emitChangeEvent($event)'
          v-show="editable"
          :placeholder='placeholder'
          :value='value'
          ref='editableInput'
          >
    </template>

    <template v-if='textarea'>
      <textarea
          class="form form-control"
          :class='input_value_class'
          v-on:focus='saveValue'
          @input="$emit('input', $event.target.value)"
          @keydown.enter.exact.prevent='emitChangeEvent($event)'
          v-on:focusout.stop='emitChangeEvent($event)'
          v-show="editable"
          :placeholder='placeholder'
          :value='value'
          ref='editableInput'
          :disabled='!can_be_edited'
          >
      </textarea>
    </template>
  </div>
</template>

<script>

  export default {
    name: 'InPlaceEditor',
    props: [
      'value',
      'display_text_class',
      'input_value_class',
      'textarea',
      'placeholder',
      'can_be_edited',
      'container_classes'
    ],
    data() {
      return {
        editable: !this.hasValue(),
        olderValue: this.value,
      }
    },
    methods: {

      toggleEdit: function() {
        if(!this.can_be_edited) {
          return false;
        }
        var _this = this;

        if(!$.isEmptyObject(this.value)){
          this.editable = !this.editable;
        }

        if(this.editable){
          this.$nextTick(function() {
            $(_this.$refs.editableInput).focus();
          });
        }
        this.$emit('editable')
      },

      emitChangeEvent: function({ target }) {
        this.toggleEdit();
        this.triggerChangeEvent(target);
      },

      triggerChangeEvent: function(target) {
        if(this.olderValue !== target.value.trim()) {
          this.$emit('changed');
        }
      },

      saveValue: function() {
        this.olderValue = event.target.value.trim();
      },
      hasValue: function() {
        return this.value.trim().length > 0;
      }
    }
  };
</script>

<style lang="css" scoped>
</style>
