var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", { ref: "userGridContent" }, [
    Object.keys(_vm.currentUserObject).length && _vm.componentFullyLoaded
      ? _c(
          "span",
          [
            _c(
              "v-popover",
              {
                attrs: {
                  placement: "top",
                  trigger: "hover",
                  popoverArrowClass: "hide"
                }
              },
              [
                _c(
                  "span",
                  { staticClass: "tooltip-target" },
                  [
                    _c(
                      "a",
                      {
                        staticClass:
                          "m-r-10 card-assign-user mx-center-hard clickable-item ",
                        attrs: {
                          href: _vm.userShowPath(_vm.currentUserObject),
                          target: "_blank"
                        }
                      },
                      [
                        _c("img", {
                          staticClass: "img img-responsive d-inline",
                          attrs: {
                            src: _vm.currentUserObject.profile_picture_url,
                            width: "image_size",
                            height: "image_size"
                          }
                        })
                      ]
                    ),
                    _vm._v(" "),
                    _vm._t("extra-content")
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "template",
                  { slot: "popover" },
                  [
                    _c("UserHoverDataTooltip", {
                      attrs: { user_id: _vm.currentUserObject.id }
                    })
                  ],
                  1
                )
              ],
              2
            )
          ],
          1
        )
      : _c("span", [
          _vm._v("\n    " + _vm._s(_vm.$i18n.tc("default_no_data")) + "\n  ")
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }