<template>
  <span>
    <span class="fs-12 d-inline-block" :class='text_class_names'>
      {{ updated_text }}
    </span>
    <div :class='transition_class_names' class="tooltip-inline p-l-4 task-tab-detail">
      <user-popover-tooltip :user_id="user_changes_ids[1]"></user-popover-tooltip>
       ›
      <user-popover-tooltip :user_id="user_changes_ids[0]"></user-popover-tooltip>
    </div>
  </span>
</template>

<script>
  import UserPopoverTooltip from 'components/shared/users/user_popover_tooltip.vue';

  export default {
    name: 'user_changed_detail',
    props: [
      'user_changes_ids',
      'updated_text',
      'text_class_names',
      'transition_class_names',
      'changed_user_classes'
    ],
    components: {
      UserPopoverTooltip
    }
  };
</script>

<style lang="css" scoped>
</style>
