<template>
  <span class="fs-13" :class='archived_text_classes'>
    marked <span class='black'>{{ object_name }}</span> as archived
  </span>
</template>

<script>
  export default {
    name: 'archived_detail',
    props: [
      'data_key',
      'from_value',
      'object_name',
      'archived_text_classes'
    ]
  };
</script>

<style lang="css" scoped>
</style>
