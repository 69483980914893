<template>
  <span>
    <span v-if="design_changes && design_changes.length">
      <span class="d-inline-block fs-12">changed {{ $i18n.tc('history_row.text_detail.' + data_key) }}</span>
      <div class="m-l-22 wo-tab-detail">
        <div class="dark tooltip-inline">
          <div class="d-inline-block">{{design_changes[1]}}</div>
          <span class="fontmedium d-inline-block">›
            <div class="d-inline-block">{{design_changes[0]}}</div>
          </span>
        </div>
      </div>
    </span>
    <span v-else>
      <span class="d-inline-block fs-12">{{ event }} {{ $i18n.tc('history_row.text_detail.' + data_key) }}</span>
      <div class="m-l-22 wo-tab-detail dark">
        <div>{{ design_name }}</div>
      </div>
    </span>
  </span>
</template>

<script>
  import simpleTextDetail from 'components/history/simple_text_detail';

  export default {
    name: 'designChanges',
    components: { simpleTextDetail },
    props: [
      'data_key',
      'event',
      'design_changes',
      'design_name',
    ]
  };
</script>
