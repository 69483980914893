<template>
  <li class='list-group-item padding-horizontal-none task-history-item task-history-tab'>
    <div class="task-update-info tooltip-inline">

      <user-pic :author_id='version.author_id' />

      <template v-if='createOrDestroy(version)'>

        <span v-if='version.version_item_type_is_follow'>
          <span>{{ version_event }}</span>
          <span>{{ this.$i18n.tc('history_row.follower') }}</span>
          <span class="task-history-follower">
            <span class="follower">
              <user-popover-tooltip :user_id="version.follower_id"></user-popover-tooltip>
            </span>
          </span>
        </span>

        <span v-else-if='version.version_item_type_is_attachment'>
          <span class="fs-13">
            {{ version_event == 'added' ? 'uploaded' : version_event }} {{ $i18n.tc('history_row.attachment') }}
            <span class='black'>{{ version.attachment_name }}</span>
          </span>
        </span>

        <span v-else>
          <span>{{ version_event }}</span>
          {{ version.version_item_type }}
          <div class='history-manage-block black p-l-4 task-tab-detail'>
            {{ version.version_item_name }}
          </div>
        </span>
      </template>

      <template v-else>
        <span v-for='changeset in version.change_set'>
          <completed-detail
            v-if="match_key(changeset.key, 'completed_by_id')"
            :object_name='object.title'
            :to_value='changeset.to_value'
            :from_value='changeset.from_value'
          ></completed-detail>

          <user-changed-detail
            v-if="match_key(changeset.key, 'assignee_id')"
            updated_text='updated assignee'
            changed_user_classes='fontmedium black-2'
            :user_changes_ids='version.assignee_changes_ids'
          ></user-changed-detail>

          <updated-due-date-detail
            v-if="match_key(changeset.key, 'due_date')"
            updated_text='updated due date'
            :due_date_changes='version.due_date_changes'
          ></updated-due-date-detail>

          <cancelled-detail
            v-if="match_key(changeset.key, 'canceller_id')"
            :to_value='changeset.to_value'
            :object_name='object.title'
          ></cancelled-detail>

          <archived-detail
            v-if="match_key(changeset.key, 'archiver_id')"
            :to_value='changeset.to_value'
            :object_name='object.title'
          ></archived-detail>

          <text-detail
            v-if="other_updates(changeset.key)"
            :data_key='changeset.key'
            :from_value='changeset.from_value'
            :to_value='changeset.to_value'
            :object_name='object.title'
          ></text-detail>
        </span>
      </template>

    </div>

    <div class="fs-13 date-flex-col">
      <small class="small">
        {{ version.created_at | formatDate }}
      </small>
    </div>
  </li>
</template>

<script>
  import { historyRowMixin } from 'components/history/history_row_mixin.js';

  export default {
    name: 'historyRow',
    mixins: [historyRowMixin]
  };

</script>

<style lang="css" scoped>
</style>
