var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "d-flex justfy-between" }, [
    _c("img", {
      attrs: {
        src: _vm.loadedContactData.profile_picture,
        height: "40",
        width: "40"
      }
    }),
    _vm._v(" "),
    _c("div", {}, [
      _c("div", { staticClass: "text-left tooltip-text" }, [
        _c(
          "a",
          {
            staticClass: "super-dark clickable-item fs-14 m-b-2 semibold ls-01",
            attrs: {
              href: _vm.contactShowPath(_vm.loadedContactData),
              target: "_blank"
            }
          },
          [_vm._v(_vm._s(_vm.loadedContactData.name))]
        )
      ]),
      _vm._v(" "),
      _c("div", {
        staticClass: "super-dark text-left tooltip-text fs-13 ls-01",
        domProps: { innerHTML: _vm._s(_vm.loadedContactData.email) }
      })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }