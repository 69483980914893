import Vue from 'vue/dist/vue.esm';

// Use for static permissions, no restrictions
// Use for dynamic permissions only when there is no other child present which require dynamic permission
// DEV Note: if we can find the implementation of v-if in future than we can look into this again
Vue.directive('can', (el, binding, vnode) => {
  if (!binding.value) {
    const comment = document.createComment(' ');

    Object.defineProperty(comment, 'setAttribute', {
      value: () => undefined,
    })

    vnode.text = ' ';
    vnode.elm = comment;
    vnode.isComment = true;
    vnode.tag = undefined;

    vnode.data = vnode.data || {};
    vnode.data.directives = undefined;

    if (vnode.componentInstance) {
      vnode.componentInstance.$el = comment;
    }

    if (el.parentNode) {
      el.parentNode.replaceChild(comment, el);
    }
  }
});
