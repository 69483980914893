var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.comments, function(comment) {
      return _c(
        "div",
        {
          key: comment.id,
          staticClass: "comment-container",
          class: { "editable-comment": comment.can_be_edited }
        },
        [
          _vm.edit_comment && _vm.edit_comment_object.id == comment.id
            ? [
                _c("edit-comment", {
                  attrs: {
                    comment_thread: _vm.comment_thread,
                    allowed_char_count: 500,
                    user_options: _vm.user_options
                  },
                  on: {
                    commentUpdated: function($event) {
                      return _vm.updatedComment($event)
                    }
                  }
                })
              ]
            : _vm._e(),
          _vm._v(" "),
          !_vm.edit_comment || _vm.edit_comment_object.id != comment.id
            ? [
                _c("div", { attrs: { "data-behavior": "resource-detail" } }, [
                  _c("article", { staticClass: "comment-added" }, [
                    _c(
                      "div",
                      { staticClass: "d-flex align-center" },
                      [
                        _c("user-table-grid-content", {
                          attrs: {
                            contentType: "abbreviatedName",
                            user_id: comment.creator_id || comment.creator.id
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass: "super-light fs-11",
                            attrs: {
                              title: _vm._f("formatDate")(comment.updated_at)
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(_vm._f("formatDate")(comment.updated_at))
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: comment.can_be_edited,
                                expression: "comment.can_be_edited"
                              }
                            ],
                            ref: "dropdown",
                            refInFor: true,
                            staticClass:
                              "comment-action card-header-dropdown ml-auto"
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "dropdown",
                                attrs: { "data-menu": "dropdown" }
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass: "btn p-r-0 js-show-button",
                                    attrs: {
                                      type: "button",
                                      "data-toggle": "dropdown",
                                      "aria-expanded": "false"
                                    }
                                  },
                                  [
                                    _c(
                                      "svg",
                                      {
                                        staticClass: "d-block",
                                        attrs: {
                                          xmlns: "http://www.w3.org/2000/svg",
                                          width: "18",
                                          height: "4",
                                          viewBox: "0 0 18 4"
                                        }
                                      },
                                      [
                                        _c(
                                          "g",
                                          { attrs: { fill: "#516f90" } },
                                          [
                                            _c("circle", {
                                              attrs: {
                                                cx: "9",
                                                cy: "2",
                                                r: "2"
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c("circle", {
                                              attrs: {
                                                cx: "16",
                                                cy: "2",
                                                r: "2"
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c("circle", {
                                              attrs: {
                                                cx: "2",
                                                cy: "2",
                                                r: "2"
                                              }
                                            })
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "ul",
                                  {
                                    staticClass:
                                      "dropdown-menu dropdown-menu-right fs-12"
                                  },
                                  [
                                    _c("li", [
                                      _c(
                                        "a",
                                        {
                                          attrs: { href: "javascript:void(0)" },
                                          on: {
                                            click: function($event) {
                                              return _vm.editComment(comment)
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$i18n.tc("comments_list.edit")
                                            )
                                          )
                                        ]
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("li", [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "p-0-hard",
                                          attrs: {
                                            "data-vue-component": "true"
                                          },
                                          on: {
                                            click: function($event) {
                                              $event.preventDefault()
                                              $event.stopPropagation()
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "delete-button",
                                            {
                                              attrs: {
                                                data_confirm_button_text: _vm.$i18n.tc(
                                                  "comments_list.delete_permanently"
                                                ),
                                                data_description: _vm.$i18n.t(
                                                  "comments_list.details",
                                                  {
                                                    id: comment.id,
                                                    body:
                                                      comment.unformatted_body
                                                  }
                                                ),
                                                data_heading: _vm.$i18n.t(
                                                  "comments_list.delete_heading"
                                                ),
                                                data_sweet_alert_confirm: _vm.$i18n.t(
                                                  "comments_list.confirmation_message"
                                                ),
                                                value: "Delete",
                                                classname:
                                                  "delete-icon d-block delete-cu-btn"
                                              },
                                              on: {
                                                input: function($event) {
                                                  return _vm.deleteComment(
                                                    comment.id
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                        " +
                                                  _vm._s(
                                                    _vm.$i18n.tc(
                                                      "comments_list.delete"
                                                    )
                                                  ) +
                                                  "\n                      "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ])
                                  ]
                                )
                              ]
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "p-t-5 lh-1-29",
                      domProps: { innerHTML: _vm._s(comment.body) }
                    })
                  ])
                ])
              ]
            : _vm._e()
        ],
        2
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }