var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.sideBarOptions.length
    ? _c(
        "li",
        { ref: "container", staticClass: "list-group-item" },
        [
          _c(
            "a",
            {
              staticClass: "toggle-link toggle-collapse-link",
              class: _vm.showAccordian ? "" : "collapsed",
              attrs: { href: "javascript:void(0)" },
              on: { click: _vm.handleAccordianClick }
            },
            [
              _c("span", { staticClass: "sidebar-link-title" }, [
                _vm._v("\n      " + _vm._s(_vm.tabName) + "\n      "),
                _c("span", { staticClass: "caret-icon" })
              ]),
              _vm._v(" "),
              _vm._t("default")
            ],
            2
          ),
          _vm._v(" "),
          _c("transition", { attrs: { name: "slide" } }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showAccordian,
                    expression: "showAccordian"
                  }
                ],
                staticClass: "panel-collapse ul-submenu"
              },
              [
                _c(
                  "ul",
                  { staticClass: "list-unstyled" },
                  _vm._l(_vm.sideBarOptions, function(option) {
                    return _c(
                      "li",
                      {
                        key: option.url,
                        ref: option.ref || option.url,
                        refInFor: true,
                        staticClass: "panel-collapse"
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "primary-link",
                            attrs: { href: option.url }
                          },
                          [_vm._v(_vm._s(option.display_name))]
                        )
                      ]
                    )
                  }),
                  0
                )
              ]
            )
          ])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }