<template>
  <span>
    <span v-if='from_value' class="fs-13" :class='complete_class_names'>
      marked <span class='black'>{{ object_name }}</span> as incomplete.
    </span>

    <span v-if='to_value' class="fs-13" :class='incomplete_class_names'>
      marked <span class='black'>{{ object_name }}</span> as complete.
    </span>
  </span>
</template>

<script>
  export default {
    name: 'completed_detail',
    props: [
      'to_value',
      'from_value',
      'object_name',
      'complete_class_names',
      'incomplete_class_names'
    ]
  };
</script>

<style lang="css" scoped>
</style>
