<template>
  <select :disabled='disabled' :multiple='multiple' :id='vid' :include_blank='include_blank' dropdown-css-class='filter_dropdown' container-css-class='filter_container'>
    <slot></slot>
  </select>
</template>

<script>

  import { select2Mixin } from 'mixin/shared/select2_mixin';
  import '../../../assets/javascripts/admin/select2_builder.js';

  export default {
    name: 'select2',
    props: [
      'include_blank',
      'options',
      'disabled',
      'value',
      'multiple',
      'vid',
      'disable_search',
      'placeholder',
      'disable_placeholder',
      'disable_select_number',
      'isOptionGroup',
      'dataConfirmationMessage',
      'with_tags'
    ],
    mixins: [select2Mixin],
    methods: {
      initData: function(options, value) {
        var vm = this,
        selectOptions = {
          allowClear: !!this.include_blank,
          data: options,
          tags: !!this.with_tags,
          minimumResultsForSearch: this.minSearchValue,
          dropdownParent: $(this.$el).closest('.form-group')
        },
        enabler = new Select2Builder({ select2WithoutTags: $(this.$el) });

        if(!this.disable_placeholder) {
          selectOptions['placeholder'] = this.placeholder || 'Please Select'
        }
        $(this.$el)
        .select2(selectOptions)
        .on('select2:select', function() {
          enabler.handleSelect(this);
          enabler.addOptionClass(this);
          if(this.disable_select_number) {
            enabler.handleMultiSelect(this);
          }
          vm.$emit('optionSelected', $(this).val());
        })
        .on('change', function() {
          enabler.addOptionClass(this);
        })
        .val(value)
        .trigger('change')
        .on('change', function (e, internal) {
          if(!internal) {
            vm.$emit('input', $(this).val())
          }
        })
        this.handleSelectingUnselecting();
      }
    },
    watch: {
      options: function (options) {
        $(this.$el).select2('data', null)
        $(this.$el).select2('destroy')
        $(this.$el).html('')
        this.initSelelect2();
      }
    },
    computed: {
      minSearchValue: function() {
        if(this.disable_search == "true") {
          return -1;
        }
      }
    }
  }
</script>

<style scoped>
</style>
