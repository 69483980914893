<template>
  <span>
    <span v-if="isUpdated">
      <span class="d-inline-block">updated department</span>
      <div class="m-l-22 wo-tab-detail">
        <div class="user-color">
          {{names[0]}}
          <span class="fontmedium">›
            {{names[1]}}
          </span>
        </div>
      </div>
    </span>
    <span v-else-if="isCreated">
      <span class="d-inline-block">added department</span>
      <div class="m-l-22 wo-tab-detail dark">
        {{names[0]}}
      </div>
    </span>
    <span v-else-if="isDestroyed">
      <span class="d-inline-block">removed department</span>
      <div class="m-l-22 wo-tab-detail dark">
        {{names[0]}}
      </div>
    </span>
  </span>
</template>

<script>
  export default {
    name: 'departmentDetail',
    props: ['changeSet', 'names'],
    computed: {
      isUpdated: function() {
        return !(this.isCreated || this.isDestroyed);
      },
      isCreated: function() {
        return !this.changeSet.from_value;
      },
      isDestroyed: function() {
        return !this.changeSet.to_value;
      }
    },
  };
</script>
