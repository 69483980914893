import { render, staticRenderFns } from "./accounts_icon.vue?vue&type=template&id=21a12590&"
import script from "./accounts_icon.vue?vue&type=script&lang=js&"
export * from "./accounts_icon.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../shared/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/si2/shared/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('21a12590')) {
      api.createRecord('21a12590', component.options)
    } else {
      api.reload('21a12590', component.options)
    }
    module.hot.accept("./accounts_icon.vue?vue&type=template&id=21a12590&", function () {
      api.rerender('21a12590', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/javascript/components/shared/svg/accounts_icon.vue"
export default component.exports