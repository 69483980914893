import QuickViewWorkOrder from 'components/work_orders/shared/quick_view_work_order.vue';
import WorkOrderOpener from 'components/work_orders/shared/work_order_opener.vue';
import QuickViewSubWorkOrder from 'components/work_orders/shared/quick_view_sub_work_order.vue';
import SubWorkOrderOpener from 'components/work_orders/shared/sub_work_order_opener.vue';
export const WorkOrderQuickShowMixin = {
  components: {
    QuickViewWorkOrder: QuickViewWorkOrder,
    WorkOrderOpener: WorkOrderOpener,
    QuickViewSubWorkOrder: QuickViewSubWorkOrder,
    SubWorkOrderOpener: SubWorkOrderOpener
  }
}
