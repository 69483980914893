<template>
  <div ref="contactContent" class="d-inline-block">
    <v-popover placement='top' trigger='hover' popoverArrowClass='hide'>
      <span class="d-flex align-center">
        <div class="tootltip-name clickable-item d-block text-truncate">{{ contact_object.name }}</div>
      </span>
      <template slot='popover'>
        <div class='d-flex justfy-between'>
          <img :src="contact_object.gravatar_url || contact_object.profile_picture_url" height='40' width='40'>
          <div class="">
            <div class='text-left tooltip-text'>
              <div class="super-dark clickable-item fs-14 m-b-2 semibold ls-01">{{ contact_object.name }}</div>
            </div>
            <div class='super-dark text-left tooltip-text ls-01' v-html='contact_object.email'></div>
          </div>
        </div>
      </template>
    </v-popover>
  </div>
</template>

<script>
  export default {
    name: 'SupplierDataTooltip',
    props: ['contact_object'],
  }
</script>
