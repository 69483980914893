var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "16",
        height: "16",
        viewBox: "0 0 16 16"
      }
    },
    [
      _c("g", { attrs: { fill: "none", "fill-rule": "evenodd" } }, [
        _c("rect", {
          attrs: {
            width: "15",
            height: "13",
            x: ".5",
            y: "2.5",
            stroke: "#33475B",
            rx: "2"
          }
        }),
        _vm._v(" "),
        _c("rect", {
          attrs: { width: "2", height: "3", x: "3", fill: "#33475B", rx: "1" }
        }),
        _vm._v(" "),
        _c("rect", {
          attrs: { width: "2", height: "3", x: "11", fill: "#33475B", rx: "1" }
        }),
        _vm._v(" "),
        _c("path", { attrs: { fill: "#33475B", d: "M1 5h14v1H1z" } }),
        _vm._v(" "),
        _c("g", { attrs: { fill: "#33475B" } }, [
          _c("path", { attrs: { d: "M8 10h5v1H8z" } }),
          _vm._v(" "),
          _c("path", { attrs: { d: "M11 8v5h-1V8z" } })
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }