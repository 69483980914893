<template>
  <si-modal :modalOptions="modalOptions" ref="quickAddTaskModal" @onDismiss="handleModalClose()" @onShown='handleOnShown'>
    <template slot="modal-content">
      <div>
        <form class="form" @submit.prevent :data-maxFileSize='maxfileSize' data-attachment-form=true data-upload-url='/admin/attachments'>
          <article class="">
            <div class="form-group" data-error-container='form-group'>
              <div class="form-control-group">
                <input type="text" name="task[title]" id="title" class="create-task-title-input place-holder" data-focusable='true' :placeholder="$t('quick_add_task.title_placeholder')" v-model="current_task_object.title">
              </div>
              <error :errors="current_task_object.errors && current_task_object.errors.title"></error>
            </div>

            <div class="row row-col-space-10">
              <div class="col-md-3">
                <label :for="'assignee_id-' + attachment_name" class="d-flex align-center justify-between">
                  <span>{{ $t('quick_add_task.assignee_label') }}</span>
                  <svg class="pointer" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <path fill="#646C9A" fill-rule="evenodd" d="M16.327 17.503a4.991 4.991 0 0 0-8.654 0A6.97 6.97 0 0 0 12 19a6.97 6.97 0 0 0 4.327-1.497zm-9.407-.687a5.991 5.991 0 0 1 10.16 0 7 7 0 1 0-10.16 0zM12 13a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0-1a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm0 8a8 8 0 1 1 0-16 8 8 0 0 1 0 16z"/>
                  </svg>
                </label>
              </div>
              <div class="col-md-9">
                <div class="form-group" data-error-container='form-group'>
                  <div class="form-control-group">
                    <v-select :inputId="'assignee_id-' + attachment_name" :options="assignee_options" v-model="selectedAssignee" :placeholder=" 'Please Select' " :clearable="false">
                      <template slot="selected-option" slot-scope="option">
                        <recipient-list-option :recipient="option"></recipient-list-option>
                      </template>

                      <template slot="option" slot-scope="option">
                        <recipient-list-option :recipient="option"></recipient-list-option>
                      </template>
                    </v-select>
                  </div>
                  <error :errors="current_task_object.errors && current_task_object.errors.assignee_id"></error>
                </div>
              </div>
            </div>

            <div class="row row-col-space-10">
              <div class="col-md-3">
                <label :for="'virtual_due_date' + attachment_name" class="d-flex align-center justify-between">
                  <span>{{ $t('quick_add_task.due_date_label') }}</span>
                  <svg class="pointer" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <path fill="#646C9A" fill-rule="evenodd" d="M15 7H9v1.5a.5.5 0 0 1-1 0V7H6a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1h-2v1.5a.5.5 0 1 1-1 0V7zm1-1h2a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h2V4.5a.5.5 0 0 1 1 0V6h6V4.5a.5.5 0 1 1 1 0V6zM5 11h14v1H5v-1z"/>
                </svg>
                </label>
              </div>
              <div class="col-md-9 date-group">
                <div class="row row-col-space-10">
                  <div class="col-md-6 form-group">
                    <div class="form-control-group">
                      <date-field :id="'virtual_due_date' + attachment_name" v-model="current_task_object.virtual_due_date"></date-field>
                      <error :errors='current_task_object.errors && (current_task_object.errors.virtual_due_date || current_task_object.errors.due_date)'></error>
                    </div>
                  </div>

                  <div class="col-md-6 form-group"  data-error-container='form-group'>
                    <div class="form-control-group dt-picker-right">
                      <time-picker ref='timePicker' :field_name="'task[virtual_due_time]'" :field_value='current_task_object.virtual_due_time' time_icon_class='hide'  :element_id="'task_due_time' + attachment_name" v-model="current_task_object.virtual_due_time" placeholder='08:00 AM'></time-picker>
                      <error :errors='current_task_object.errors.virtual_due_time'></error>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div class="row row-col-space-10">
              <div class="col-md-3">
                <label @click='focusFollowerField' for="task_followers" class="d-flex justify-between align-center">
                  <span>{{ $t('quick_add_task.followers_label') }}</span>

                  <svg class="pointer" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#646C9A" fill-rule="evenodd" d="M18.864 14.648c.668.172 1.136.793 1.136 1.505v3.33a.509.509 0 0 1-.5.517h-3a.509.509 0 0 1-.5-.517c0-.286.224-.517.5-.517H19v-2.813a.514.514 0 0 0-.378-.502l-3.243-.839a.511.511 0 0 1-.374-.429l-.253-1.835a.521.521 0 0 1 .287-.543c.89-.42 1.46-1.339 1.461-2.35v-.914c.024-1.276-.827-2.391-2.018-2.661a2.46 2.46 0 0 0-1.316.102.496.496 0 0 1-.638-.317.522.522 0 0 1 .306-.66 3.44 3.44 0 0 1 1.848-.138c1.67.378 2.85 1.925 2.818 3.684v.904c-.001 1.284-.658 2.46-1.708 3.108l.156 1.13 2.916.755zm-4.952 1.537c.644.19 1.088.799 1.088 1.492v1.806a.509.509 0 0 1-.5.517h-10a.509.509 0 0 1-.5-.517v-1.807c0-.692.444-1.3 1.088-1.491l2.463-.728.12-.868a3.948 3.948 0 0 1-2.483-.909.53.53 0 0 1-.09-.71c.67-.945.99-2.107.902-3.315 0-2 1.567-3.62 3.5-3.62s3.5 1.62 3.499 3.66a5.013 5.013 0 0 0 .904 3.274.53.53 0 0 1-.091.711 3.948 3.948 0 0 1-2.482.909l.12.868 2.462.728zM14 17.677a.515.515 0 0 0-.362-.497l-2.775-.82a.512.512 0 0 1-.358-.425l-.253-1.835c-.047-.345.24-.639.573-.584a2.946 2.946 0 0 0 1.985-.379A6.034 6.034 0 0 1 12 9.655c0-1.428-1.12-2.586-2.5-2.586S7 8.227 6.999 9.615a6.09 6.09 0 0 1-.81 3.522 2.946 2.946 0 0 0 1.986.379c.332-.055.62.24.573.584l-.253 1.835a.512.512 0 0 1-.358.424l-2.775.82a.515.515 0 0 0-.362.498v1.289h9v-1.29z"/></svg>
                </label>
              </div>
              <div class="col-md-9">
                <div class="form-class-group wo-follower-block">
                  <auto-complete-followers v-model='current_task_object.task_follower_ids' :auto_suggest_followers=5 :placeholder="$t('quick_add_task.followers_placeholder')" :follower_options=" follower_options" :persisted_followers='[]' field_for='task_followers' ref='autoCompleteFollowers'></auto-complete-followers>
                </div>
              </div>
            </div>

            <div class="row row-col-space-10">
              <div class="col-md-3">
                <label class="d-flex justify-between align-center">
                  <span>{{ $t('quick_add_task.category_label') }}</span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#646C9A" fill-rule="evenodd" d="M6 19h12v-8.281L12 5.18 6 10.72V19zm-1 .5v-9a.5.5 0 0 1 .16-.367l6.5-6a.5.5 0 0 1 .68 0l6.5 6a.5.5 0 0 1 .16.367v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5zm7-6.5a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0-1a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-3.5 4a.5.5 0 1 1 0-1h7a.5.5 0 1 1 0 1h-7z"/></svg>
                </label>
              </div>
              <div class="col-md-9">
                <div class="form-class-group lh-40 fs-13">
                  <span class="super-dark">{{ this.default_category.label }}</span>
                </div>
              </div>
            </div>

            <div data-attachment-parent="true">
              <div data-nested-attachment='container' :data-association='association_name' data-nested-association='container' class="task-attachment-container">
                <div class="task-modal-attachment-group relative">
                  <label class="fs-13 attachment-label d-inline-block lh-40 pointer" :for='attachment_name'>{{ this.$i18n.tc('quick_add_task.attachments') }}</label>
                  <multiple-file-upload ref='taskAttachment' @attachment_update='updateValue(current_task_object.attachables_attachments_attributes, $event)' :value='current_task_object.attachment_ids' :association_value_ids='current_task_object.attachment_ids' required_class='' :attachment_label_name="attachment_name" t_add_file='Add File' :single_attachment='false' :t_attachments='attachment_name' file_field_selector='[data-attachment-file-field=true]' :attachment_type='attachment_type' parent_selector='[data-attachment-parent=true]' t_file_upload_hint='(max size 500 MB)' :attachments='current_task_object.attachables_attachments_attributes' v-model="current_task_object.attachment_ids" :class="{ 'attachment-unavailable': !current_task_object.attachment_ids.length, 'attachment-available' : current_task_object.attachment_ids.length }"></multiple-file-upload>
                </div>
              </div>
            </div>

            <div class="row row-col-space-15">
              <div class="col-md-12">
                <div class="form-class-group">
                  <label for="task_description" class="description-label-icon">
                    <svg class="pointer" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                      <path fill="#A1A8C3" fill-rule="evenodd" d="M.5 3a.5.5 0 1 1 0-1h15a.5.5 0 1 1 0 1H.5zm0 4a.5.5 0 0 1 0-1h9a.5.5 0 0 1 0 1h-9zm0 4a.5.5 0 0 1 0-1h15a.5.5 0 1 1 0 1H.5zm0 4a.5.5 0 1 1 0-1h9a.5.5 0 1 1 0 1h-9z"/>
                    </svg>
                  </label>

                  <textarea class='form-control place-holder description-textarea mh-66' name="task[description]" id="task_description" :placeholder="$t('quick_add_task.description_placeholder')" v-model='current_task_object.description' cols="30" rows="10"></textarea>
                </div>
              </div>
            </div>

            <div class="row row-col-space-20 p-t-10 m-b-15">
              <div class="col-md-6">
                <button type="button" class="btn btn-lg btn-block fs-16 font-medium" @click="hideTaskFormModal">{{$t('quick_add_task.cancel')}}</button>
              </div>
              <div class="col-md-6">
                <input type="submit" class="btn btn-lg btn-block fs-16 btn-primary font-medium" :disabled="ajaxRequestSent" @click="createNewTask" :value="$t('quick_add_task.submit')">
              </div>
            </div>

          </article>
        </form>
      </div>
    </template>
  </si-modal>
</template>

<script>
  import siModal from 'components/shared/si_modal.vue';
  import error from 'components/shared/error.vue';
  import vSelect from 'vue-select';
  import * as deepmerge from 'deepmerge';
  import timePicker from '../global/time_picker.vue';
  import dateField from './date_field.vue';
  import multipleFileUpload from 'components/shared/multiple_file_upload';
  import recipientListOption from 'components/shared/recipient_list_option.vue';
  import AutoCompleteFollowers from 'components/shared/users/auto_complete_followers';

  import { mapGetters, mapState, mapActions, mapMutations } from 'vuex';

  const { mapFields: mapQuickTaskFields } = createHelpers({
    getterType: 'getTask',
    mutationType: 'createTask'
  });

  import { createHelpers } from 'vuex-map-fields';

  export default {
    name: 'QuickAddTaskModal',
    props: ['follower_options', 'assignee_options', 'current_user_id', 'taskable_type', 'taskable_id', 'trigger_button_class', 'default_category', 'default_selected_assignee', 'attachment_name', 'redirect_tab', 'redirect_path'],

    data() {
      return {
        modalOptions: {
          requiredHeader: '',
          title: " ",
          modalId: this.trigger_button_class,
          modalContainerClass: 'create-task-modal',
          body_class: 'modal-body-overflow-min overflow-initial-hard',
          pickerOverFlowClass: 'no-overflow',
          topBarColor: '#cd58ff'
        },
        empty_task_object: {
          title: '',
          assignee: '',
          virtual_due_date: '',
          virtual_due_time: '11:59 pm',
          task_follower_ids: [],
          attachment_ids: [],
          category_id: '',
          attachables_attachments_attributes: [{
            id: null,
            attachment_id: null,
            attachment_attributes: {
              id: null,
              errors: { }
            }
          }],
          description: '',
          errors: {}
        },
        showTaskModal: false,
        maxfileSize: gon.maxFileUploadSize
      };
    },

    methods: {
      handleOnShown: function() {
        $('[data-behavior="ajax-loader"]').removeClass('ajax-send');
        this.current_task_object.virtual_due_date = moment().format('MM/DD/YYYY');
      },

      focusFollowerField: function() {
        this.$refs.autoCompleteFollowers.focusField();
      },

      handleModalClose: function() {
        this.$refs.timePicker.setTimeValue(this.empty_task_object.virtual_due_time);
        this.$refs.autoCompleteFollowers.resetFollowers();
        this.selectedAssignee = this.default_selected_assignee;
        $(this.$refs.taskAttachment.$el).find('.cancel').trigger('click');
        this.$refs.taskAttachment.removeAjaxLoaderAndAttachment(this.$refs.taskAttachment);
        this.current_task_object = deepmerge({}, this.empty_task_object);
      },

      createNewTask: function() {
        var _this = this;
        this.ajaxRequestSent = true;

        this.requestCreateTask({
          apollo: this.$apollo,
          showAlert: this.$root.showAlert,
          componentObject: this,
          assignee_id: this.selectedAssignee.value,
          category_id: this.default_category.value,
          taskable_id: this.taskable_id,
          taskable_type: this.taskable_type,
          redirect_tab: this.redirect_tab,
          redirect_path: this.redirect_path,
        });
      },
      hideTaskFormModal: function() {
        this.$refs.quickAddTaskModal.hideModal();
      },

      updateValue: function(oldObject, newValue) {
        let storePosition = oldObject
        let filePresent = false;
        for (const [index, file] of storePosition.entries()) {
          if (file.attachment_id == newValue['attachment_id']) {
            storePosition[index] = newValue
            filePresent = true
          }
        }
        if (!filePresent) {
          storePosition.push(newValue)
        }
      },

      ...mapActions('TaskQuickAddWidget', ['requestCreateTask']),
      ...mapMutations('TaskQuickAddWidget', ['setAttachmentId']),
    },

    computed: {
      ...mapQuickTaskFields('TaskQuickAddWidget', ['current_task_object', 'taskCreateSuccessful', 'ajaxRequestSent', 'selectedTaskCategory', 'assignee', 'association_name', 'attachment_type']),
      selectedAssignee: {
        get: function() {
          if(this.assignee) {
            return this.assignee;
          } else {
            return this.default_selected_assignee;
          }
        },
        set: function(object) {
          this.assignee = object;
        }
      },
    },

    components: {
      siModal,
      error,
      timePicker,
      dateField,
      vSelect,
      recipientListOption,
      AutoCompleteFollowers,
      multipleFileUpload
    }
  };
</script>

<style lang="css" scoped>
</style>
