<template>
  <div id="product-create-modal">
    <si-modal
      ref="workOrderCreateModal"
      :modalOptions="modalOptions"
      @onDismiss="resetData"
      @onShown="focusField"
    >
      <template slot="modal-content">
        <form class="form" @submit.prevent>
          <div class>
            <div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="work_order_title" class="required">Title</label>
                    <div class="form-control-group">
                      <input ref='workOrderTitle' v-model='title' type="text" class="form-control" />
                      <error :errors="errors.title" />
                    </div>
                    <small
                      class="text-muted"
                    >Please include Customer, Description of item, PO number in the Title</small>
                  </div>
                </div>
              </div>
              <div v-if='wo_contact_present' class="row">
                <div class="col-md-6">
                  <div data-error-container="form-group" class="form-group">
                    <label for="work_order_account" class="required">Account</label>
                    <div class="form-control disabled-field p-t-b-10-hard text-truncate fs-13 lh-15-hard">
                      {{ account_details.name }}
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div data-error-container="form-group" class="form-group">
                    <label for="work_order_contact" class="required">Contact</label>
                    <div class="form-control disabled-field p-t-b-10-hard text-truncate fs-13 lh-15-hard">
                      {{ contact_details.name }}
                    </div>
                  </div>
                </div>
              </div>

              <div v-else class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <div class="d-flex justify-between">
                      <label for="pr_create_title" class="required">Account</label>
                    </div>
                    <div>
                      <ajax-select2
                        v-model="account_id"
                        request_param="name_cont"
                        search_input_length="2"
                        :options='account_options'
                        :request_path="accountSearchPath()"
                      ></ajax-select2>
                      <error :errors="errors.account" />
                      <error :errors="errors.account_id" />
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <div class="d-flex justify-between">
                      <label for="pr_create_title" class="required">Contact</label>
                    </div>
                    <div data-toggle="tooltip" :data-original-title="account_id ? '' : 'Please select the account to see the associated contacts.'">
                      <dependent-select2
                        :disabled="!account_id"
                        :toggle='account_id ? "" : "tooltip"'
                        title="Please Select Account"
                        selectType="select2WithoutTags"
                        v-model="contact_id"
                        :parent="account_id"
                        ref="contactOptions"
                        placeholder="Please Select"
                      />
                      <error :errors="errors.contact_id" />
                      <error :errors="errors.contact" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <label for="" class="required">Due Date & Time</label>
                <div class="row">
                  <div data-error-container="form-group" class="col-md-6 col-sm-6 form-group">
                    <div class="event-dt-col event-date-col">
                      <div class="form-control-group form-date-group">
                        <label for="work_order_due_date" class="cal-icon-right">Due date</label>
                        <date-field id="work_order_due_date" v-model="due_date"></date-field>
                        <error :errors='errors.due_date'></error>
                        <error :errors='errors.due_datetime'></error>
                      </div>
                    </div>
                  </div>
                  <div data-error-container="form-group" class="col-md-6 col-sm-6 form-group">
                    <div class="event-dt-col event-time-col">
                      <div class="form-control-group form-date-group">
                        <label for="work_order_due_time" class="cal-icon-right clock-icon">Due time</label>
                        <time-picker ref='timeField' :field_name="'work_order[due_time]'" :field_value="default_time" :element_id="'work_order_due_time'" :placeholder="''" @time_value_update="due_time = $event"></time-picker>
                        <error :errors='errors.due_time'></error>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <WorkOrderTypeSelector
              :work_order_presentable_name='work_order_presentable_name'
              :subclass_chain='subclass_chain'
              :internal_work_orders='internal_work_orders'
            />

            <div class="row row-col-space-15 m-b-15">
              <div class="col-md-6">
                <button
                  :disabled="cannotSaveWorkOrder"
                  class="btn btn-lg fontmedium btn-block fs-16 btn-with-icon icon-right"
                  @click="saveWorkOrder(true)"
                >
                  Continue Editing
                  <span class="btn-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill="#646c9a"
                        fill-rule="evenodd"
                        d="M14.293 8l-4.147-4.146a.5.5 0 0 1 .708-.708l5 5a.5.5 0 0 1 0 .708l-5 5a.5.5 0 0 1-.708-.708L14.293 9H.5a.5.5 0 0 1 0-1h13.793z"
                      />
                    </svg>
                  </span>
                </button>
              </div>
              <div class="col-md-6">
                <button
                  :disabled="cannotSaveWorkOrder"
                  class="btn btn-save-draft btn-block btn-primary btn-lg fontmedium"
                  @click="saveWorkOrder(false)"
                >Save as Draft</button>
              </div>
            </div>
          </div>
        </form>
      </template>
    </si-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { createHelpers } from "vuex-map-fields";
import error from "components/shared/error";
import ajaxSelect2 from "components/shared/ajax_select2.vue";
import siModal from "components/shared/si_modal";
import select2 from "components/shared/select2";
import dependentSelect2 from "components/shared/dependent_select2";
import dateField from "components/shared/date_field.vue";
import { accountSearchPath } from 'concerns/path_helper_methods.js';
import { initialState } from "store/data/work_orders/create/state";
import QuickAddContact from "components/shared/contacts/quick_add_contact.vue";
import timePicker from 'components/global/time_picker.vue';
import WorkOrderTypeSelector from 'components/shared/work_order/work_order_type_selector'

const { mapFields } = createHelpers({
  getterType: "getCreateWorkOrder",
  mutationType: "createNewWorkOrder"
});

export default {
  name: "WorkOrderQuickAddWidget",
  props: [
    "work_order_presentable_name",
    "subclass_chain",
    'default_date',
    'default_time',
    'internal_work_orders',
    'account_options'
  ],
  data() {
    return {
      disable_contact_field: false,
      contact_name: "",
      account_name: "",
      quick_add_contact_accounts: [],
      modalOptions: {
        title: this.modalHeader,
        topBarColor: '#e67e22',
        modalClasses: 'create-work-order-modal',
        pickerOverFlowClass:'no-overflow',
        modalId: "create-work-order-widget"
      },
      loader: false
    };
  },
  computed: {
    cannotSaveWorkOrder: function() {
      return !this.type || this.loader
    },
    ...mapFields("QuickAddWorkOrderWidget", [
      "newWorkOrder",
      "modalHeader",
      "newWorkOrder.title",
      "newWorkOrder.account_id",
      "newWorkOrder.contact_id",
      "newWorkOrder.errors",
      "newWorkOrder.due_date",
      "newWorkOrder.due_time",
      "newWorkOrder.internal",
      "newWorkOrder.type",
      "newWorkOrder.wo_contact_present",
      "newWorkOrder.account_details",
      "newWorkOrder.contact_details"
    ])
  },
  methods: {
    accountSearchPath,
    closeModal() {
      this.$refs.workOrderCreateModal.hideModal();
    },
    displayModal: function() {
      this.$refs.workOrderCreateModal.displayModal();
    },
    focusField() {
      this.modalOptions.title = this.modalHeader
      this.initializeData()
      this.$refs.workOrderTitle.focus();
    },
    initializeData() {
      this.due_date = this.default_date
      this.$refs.timeField.setTimeValue(this.default_time)
      this.due_time = this.default_time
    },
    resetData() {
      this.newWorkOrder = initialState();
    },
    saveWorkOrder(continue_edit) {
      this.loader = true;
      this.createWorkOrder({
        apollo: this.$apollo,
        showAlert: this.$root.showAlert,
        workOrderData: this.newWorkOrder,
        component: this,
        continue_edit: continue_edit
      }).then(() => {
        this.closeModal()
      })
    },
    ...mapActions('QuickAddWorkOrderWidget', ['createWorkOrder'])
  },
  components: {
    siModal,
    dependentSelect2,
    select2,
    ajaxSelect2,
    error,
    dateField,
    QuickAddContact,
    timePicker,
    WorkOrderTypeSelector
  }
};
</script>
