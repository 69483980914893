import UserProfilePicWithTooltip from 'components/shared/users/user_profile_pic_with_tooltip.vue';
import ContactDataTooltipForHistory from 'components/shared/contacts/contact_data_tooltip_for_history.vue';
import UserHoverDataTooltip from 'components/shared/users/user_hover_data_tooltip.vue';
import UserPopoverTooltip from 'components/shared/users/user_popover_tooltip.vue';
import userPic from 'components/history/user_pic';
import completedDetail from 'components/history/completed_detail';
import userChangedDetail from 'components/history/user_changed_detail';
import updatedDateTimeDetail from 'components/history/updated_date_time_detail.vue';
import timeChangesDetail from 'components/history/time_changes_detail.vue';
import userDetail from 'components/history/user_detail';
import contactDetail from 'components/history/contact_detail';
import updatedDateDetail from 'components/history/updated_date_detail';
import updatedDueDateDetail from 'components/history/updated_due_date_detail';
import cancelledDetail from 'components/history/cancelled_detail';
import archivedDetail from 'components/history/archived_detail';
import textDetail from 'components/history/text_detail';
import designChanges from 'components/history/design_changes';
import simpleTextDetail from 'components/history/simple_text_detail';
import departmentDetail from 'components/history/department_detail.vue';
import currencyDetail from 'components/history/currency_detail';
import shippingCostDetail from 'components/history/shipping_cost_detail';
import StateChangesDetail from 'components/history/state_changes_detail.vue';
import AddressDetail from 'components/history/address_detail.vue';

export const historyRowMixin = {
  props: ['version', 'object'],
  components: {
    userPic,
    UserProfilePicWithTooltip,
    ContactDataTooltipForHistory,
    UserHoverDataTooltip,
    UserPopoverTooltip,
    completedDetail,
    userDetail,
    contactDetail,
    StateChangesDetail,
    userChangedDetail,
    updatedDateDetail,
    updatedDueDateDetail,
    updatedDateTimeDetail,
    timeChangesDetail,
    archivedDetail,
    cancelledDetail,
    textDetail,
    designChanges,
    simpleTextDetail,
    currencyDetail,
    shippingCostDetail,
    departmentDetail,
    AddressDetail
  },
  methods: {
    createOrDestroy: function(version) {
      return version.event == 'create' || version.event == 'destroy';
    },
    other_updates: function(key) {
      return [
        'title',
        'description'
      ].includes(key);
    },
    match_key: function(key, dataToMatch) {
      return key == dataToMatch;
    }
  },
  computed: {
    version_item_type_is_same_as_object: function() {
      return (this.version.version_item_type == this.object.__typename && this.version.version_item_id == this.object.id);
    },
    version_event: function() {
      if(this.version_item_type_is_same_as_object) {
        return this.version.version_event;
      } else {
        return this.version.associated_version_event;
      }
    }
  },
}
