import { createHelpers } from 'vuex-map-fields';
import { mapGetters, mapState, mapActions, mapMutations } from 'vuex';

const { mapFields: mapTimeLogFields } = createHelpers({
  getterType: 'getTimeLog',
  mutationType: 'createTimeLog'
});

export const timeLogMixin = {
  methods: {
    ...mapMutations('TimeLogModalWidget',    ['updateTimeLogObject']),
    ...mapActions('TaskDetailModalWidget',   ['markComplete']),
    resetTimeObject: function() {
      this.time_log_object = deepmerge({}, this.default_time_log_object);
    },
    cancelOperation: function() {
      this.resetTimeObject();
      this.$emit('onCancel');
    },
    saveTimeLog: function() {
      var _this = this;
      this.ajaxRequestSent = true;

      this.markComplete({
        apollo: this.$apollo,
        loggable_id: this.currentLoggable.id.toString(),
        loggable_date: this.time_log_object.loggable_date,
        user_id: this.currentUser.id,
        duration: this.time_log_object.duration,
        description: this.time_log_object.description,
        showAlert: this.$root.showAlert,
        componentObject: this
      }).then(function(object){
        if(object.time_log && !$.isEmptyObject(object.time_log.errors)){
          _this.updateTimeLogObject(object.time_log);
        } else {
          _this.hideModal();
          _this.currentLoggable = object.task;
          _this.timeLogCreateSuccessful = true;
        }
      });
    },
  },
  computed: {
    ...mapState('GeneralConfiguration', ['currentUser']),
    ...mapTimeLogFields('TimeLogModalWidget', [
      'displayTimeLogModal',
      'displayMarkCompleteModal',
      'currentLoggable',
      'ajaxRequestSent',
      'timeLogCreateSuccessful',
      'taskMarkCompleteCancel',
      'timeLogUpdateSuccessful',
      'time_log_object'
    ])
  }
}
