var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("form", { attrs: { action: "/admin/search" } }, [
    _c(
      "label",
      { staticClass: "si-search-box m-width-100 full-base pointer" },
      [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.search,
              expression: "search"
            }
          ],
          staticClass: "form-control place-holder",
          class: { "with-input": (_vm.search || "").length },
          attrs: {
            placeholder: "",
            type: "search",
            name: "q[global_search]",
            id: ""
          },
          domProps: { value: _vm.search },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.search = $event.target.value
            }
          }
        }),
        _vm._v(" "),
        _c("i", { staticClass: "search-icon" })
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }