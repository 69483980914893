//= require shared/select2_custom_option_helper

/* globals matcherWithOptGroup */
'use strict';

function Select2Builder(inputs) {
  this.select2WithoutTags            = inputs.select2WithoutTags;
  this.select2WithoutTagsFilter      = inputs.select2WithoutTagsFilter;
  this.select2WithTagsWithCreation   = inputs.select2WithTagsWithCreation;
  this.select2WithTags               = inputs.select2WithTags;
  this.withLimit                     = inputs.withLimit;
  this.select2WithoutTagsSearch      = inputs.select2WithoutTagsSearch;
  this.select2ForCountry             = inputs.select2ForCountry;
  this.select2ForState               = inputs.select2ForState;
  this.employeeSelectorWithDetail    = inputs.employeeSelectorWithDetail;
}

Select2Builder.prototype.init = function() {
  this.bindEvents();
};

Select2Builder.prototype.bindEvents = function() {
  var _this = this;
  if (this.select2WithTags && this.select2WithTags.length) {
    this.select2WithTags.select2({
      tags: true,
      matcher: matcherWithOptGroup
    }).on('select2:select', function() {
      _this.handleSelect(this);
    }).trigger('select2:select');
  }

  if (this.select2WithTagsWithCreation && this.select2WithTagsWithCreation.length) {
    this.select2WithTagsWithCreation.select2({
      tags: true,
      matcher: matcherWithOptGroup
    }).on('change', function() {
      _this.handleChange($(this));
    });
  }

  if (this.select2WithoutTagsFilter && this.select2WithoutTagsFilter.length) {

    this.select2WithoutTagsFilter.select2({
      matcher: matcherWithOptGroup
    }).on('select2:select', function() {
      _this.handleMultiSelect(this);
      _this.handleSelect(this);
      _this.addMultiSelectClass(this);
      _this.addOptionClass(this);
    }).on('change', function() {
      _this.handleMultiSelect(this);
      _this.addOptionClass(this);
    }).trigger('select2:select');
  }

  if (this.select2WithoutTags && this.select2WithoutTags.length) {
    this.select2WithoutTags.each(function(elem) {
      $(this).select2({
        matcher: matcherWithOptGroup,
        dropdownParent: $(this).closest('.form-group')
      }).on('select2:select', function() {
        _this.handleSelect(this);
        _this.addOptionClass(this);
      }).on('change', function() {
        _this.addOptionClass(this);
      }).trigger('select2:select');
    })
  }

  if (this.select2WithoutTagsSearch && this.select2WithoutTagsSearch.length) {
    this.select2WithoutTagsSearch.select2({
      matcher: matcherWithOptGroup,
      minimumResultsForSearch: -1,
      containerCssClass: this.select2WithoutTagsSearch.data('select-container-class'),
      dropdownCssClass: this.select2WithoutTagsSearch.data('select-dropdown-class')
    }).on('select2:select', function() {
      _this.handleMultiSelect(this);
      _this.handleSelect(this);
      _this.addOptionClass(this);
      _this.addMultiSelectClass(this);
    }).on('change', function() {
      _this.handleMultiSelect(this);
      _this.addOptionClass(this);
    }).trigger('select2:select')
    .data('select2').$dropdown.addClass(this.select2WithoutTagsSearch.data('select-class'));
  }

  if (this.withLimit && this.withLimit.length) {
    this.withLimit.select2({
      maximumSelectionLength: 1,
      matcher: matcherWithOptGroup
    }).on('select2:opening', function() {
      var winTopOffset = $(window).scrollTop();
      $(window).scrollTop(winTopOffset + 1);
    });
  }

  // FIXME Vinay : Remove this as it is not needed after overriding DIACRITICS method.
  if (this.select2ForCountry && this.select2ForCountry.length) {
    this.select2ForCountry.select2({
    }).on('select2:select', function() {
      _this.handleSelect(this);
      _this.addOptionClass(this);
    }).on('change', function() {
      _this.addOptionClass(this);
    }).trigger('select2:select');
  }

  if (this.select2ForState && this.select2ForState.length) {
    this.select2ForState.select2({
      tags: true
    }).on('select2:select', function() {
      _this.handleSelect(this);
    }).trigger('select2:select');
  }

  if(this.employeeSelectorWithDetail && this.employeeSelectorWithDetail.length) {
    this.employeeSelectorWithDetail.select2({
      matcher: matcherWithOptGroup,
      templateResult: function(option) {
        return _this.renderUserTemplateResult(option);
      },
      templateSelection: function(option) {
        return _this.renderUserTemplateResult(option);
      }
    }).on('select2:select', function() {
      _this.handleSelect(this);
      _this.addOptionClass(this);
    }).on('change', function() {
      _this.addOptionClass(this);
    }).trigger('select2:select');
  }

  this.tempSolutionToAddErrorClassOnSelectbox();
};

Select2Builder.prototype.renderUserTemplateResult = function(option) {

  var selectedOption = $(option.element);
  if(selectedOption.data('email')) {
    var templateJson = {
      imageUrl: selectedOption.data('picture'),
      email: selectedOption.data('email'),
      name: option.text
    },
    listElement = $(tmpl('user-options-template', templateJson));
    return listElement;
  } else {
    return 'Please Select';
  }
};

Select2Builder.prototype.tempSolutionToAddErrorClassOnSelectbox = function(){
  var _this = this;
  $('.form-group').each(function(){
    var $selectElem =  $(this).find('select.form-control');
    _this.addErrorClassToElem($selectElem);
  });
};

Select2Builder.prototype.addErrorClassToElem = function($element) {
  var _this = this;
  if($element.length > 1) {
    $element.each(function() { _this.addErrorClassToElem($(this)); });
  } else if($element.length === 1 && $element.closest('.form-group').find('.form-error').length) {
    $element.addClass('field-with-errors');
  }
};

Select2Builder.prototype.addOptionClass = function(element) {
  $(element).siblings('.select2').find('.select2-selection__choice:last').addClass('last-child');
};

Select2Builder.prototype.handleMultiSelect = function (element) {
  if (element.multiple) {
    var uldiv = $(element).siblings('span.select2').find('ul'),
        selectedElements = uldiv.find('.select2-selection__choice'),
        count = selectedElements.length;
    if(count) {
      selectedElements.remove();
      uldiv.closest('.select2-container--default').addClass('select-active')
      uldiv.prepend("<li class='selected-option'>" + count + " selected</li>");
    }
  }
};

Select2Builder.prototype.addMultiSelectClass = function (element) {
  if (element.multiple) {
    var uldiv = $(element).siblings('span.select2').find('ul'),
        selectedElements = uldiv.find('.select2-selection__choice'),
        count = selectedElements.length;
    if(count) {
      uldiv.closest('.select2-container--default').addClass('select-active')
    }
  }
};

Select2Builder.prototype.handleSelect = function(element) {
  var id = element.id,
      container = $('#select2-' + id + '-container');
  if(!$(element).data('selectedIncludedBlank')) {
    if($(element).val() === '') {
      container.addClass('opacity-half');
      container.closest('.select2-container--default').removeClass('select-active')
    } else {
      container.removeClass('opacity-half');
      container.closest('.select2-container--default').addClass('select-active')
    }
  }
};

Select2Builder.prototype.handleChange = function($element) {
  $($element.data('hidden-element')).val($element.val());
};
