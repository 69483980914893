<template>
  <div class="recipient-list-option d-flex align-center pointer">
    <div class="user-img-block">
      <span class="card-assign-user pointer">
        <img class="img-responsive assign-user-image" :src="recipient.picture">
      </span>
    </div>

    <div class="p-l-10">
      <div class="dark-gray name">
        <span class="fontmedium">{{ recipient.label }}</span>
      </div>
      <div class="fs-12">
        {{ recipient.email }}
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'RecipientListOption',
    props: ['recipient'],
  };

</script>
