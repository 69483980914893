import Vue from 'vue/dist/vue.esm';
import axios from 'axios';
import VueAxios from 'vue-axios';

// This Request interceptor
axios.interceptors.request.use(function(config){
  if(!config.hideLoader){
    var document = $('body');
    document.trigger('ajax:beforeSend');
    document.trigger('ajaxSend');
  }
  return config;
});

// This Response Interceptor
axios.interceptors.response.use(function(response){
    if(!response.config.hideLoader){
      $('body').trigger('ajax:success');
    }
    return response;
  }, function(error){
    if (!error.config.hideLoader) {
      $('body').trigger('ajax:error');
    }
    return Promise.reject(error);
});

// Request Configuration
const config = {};

const jsonifyUrl = function(config){
  const url = config.url;
  if(url.includes('?')){
    config.url = config.url.split('?').join('.json?');
  }else{
    config.url += '.json';
  }
  return config;
}

Vue.use(VueAxios, axios);
