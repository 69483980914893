<template>
  <span>
    <label ref='labelButton' class="btn btn-default btn-complete btn-h-40-only fontmedium pointer" :class='{ "disabled": is_cancelled, "bg-success" : is_completed }' v-if='!is_completed'>
      <input type="checkbox" :value='is_completed' class="card-status-input" @click='markComplete'>
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <g fill="none" fill-rule="evenodd" stroke="#A1A8C3" class="status-stroke">
          <path class="status-fill" fill="#F7F8FA" d="M7.692.5c-2.363 0-3.358.192-4.387.742a4.952 4.952 0 0 0-2.063 2.063C.692 4.335.5 5.33.5 7.692v4.616c0 2.363.192 3.358.742 4.387a4.952 4.952 0 0 0 2.063 2.063c1.03.55 2.024.742 4.387.742h4.616c2.363 0 3.358-.192 4.387-.742a4.952 4.952 0 0 0 2.063-2.063c.55-1.03.742-2.024.742-4.387V7.692c0-2.363-.192-3.358-.742-4.387a4.952 4.952 0 0 0-2.063-2.063C15.665.692 14.67.5 12.308.5H7.692z"/>
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14.571 7l-6 6L6 10.429" class="stroke-white"/>
        </g>
      </svg>
      <span class="m-l-5 align-vertical-middle fs-14">
        <span v-if='!is_completed'>{{ this.$tc('task_modal.mark_as_complete') }}</span>
      </span>
    </label>

    <label class="btn btn-primary p-0-hard btn-complete fontmedium pointer btn-h-40-only" :class='{ "disabled": is_cancelled, "bg-success" : is_completed }' v-if='is_completed'>
      <sweet-alert v-if='is_completed' @input='markComplete'>
        <span :data-confirm-button-text=" 'Confirm' " :data-heading=" 'Mark Incomplete' " data-confirm-type="alert" data-vue-component=true :data-sweet-alert-confirm="'Are you sure you want to mark this as Incomplete?'" class="with-hover-zoom relative align-vertical-middle fs-14 btn-padding btn-h-40-only d-block" :data-description="$t('task_modal.task_details', { id: task_details.id, task_details: task_details.details })">

          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            <g fill="none" fill-rule="evenodd">
              <path class="if-hover" fill="#10CFA5" d="M7.692 0h4.616c2.675 0 3.645.278 4.623.801a5.452 5.452 0 0 1 2.268 2.268c.523.978.801 1.948.801 4.623v4.616c0 2.675-.278 3.645-.801 4.623a5.452 5.452 0 0 1-2.268 2.268c-.978.523-1.948.801-4.623.801H7.692c-2.675 0-3.645-.278-4.623-.801A5.452 5.452 0 0 1 .801 16.93C.278 15.953 0 14.983 0 12.308V7.692c0-2.675.278-3.645.801-4.623A5.452 5.452 0 0 1 3.07.801C4.047.278 5.017 0 7.692 0z"/>
              <path stroke="#FFF" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14.571 7l-6 6L6 10.429"/>
            </g>
          </svg>

          <span class="m-l-5">{{ this.$tc('task_modal.completed') }}</span>
        </span>
      </sweet-alert>
    </label>
  </span>
</template>

<script>
  import sweetAlert from 'components/shared/sweet_alert';

  export default {
    name: 'markCompleteButton',
    props: ['is_completed', 'is_cancelled', 'task_details'],

    methods: {
      markComplete: function() {
        if(!$(this.$refs.labelButton).hasClass('disabled')){
          if(!this.is_completed) {
            this.$emit('markComplete');
          } else {
            this.$emit('markInComplete');
          }
        }
      }
    },

    components: {
      sweetAlert
    }
  };
</script>

<style lang="css" scoped>
</style>
