<template>
  <span>
    <span class="d-inline-block fs-12">{{ version_event }}</span>
    <span v-if='addresses[1]'>
      <div class="m-l-22 wo-tab-detail">
        <div class="user-color tooltip-inline">
          <div class="d-inline-block">{{addresses[0]}}</div>
          <span class="fontmedium d-inline-block">›
            <div class="d-inline-block">{{addresses[1]}}</div>
          </span>
        </div>
      </div>
    </span>
    <span v-else class="history-manage-block black-2">
      <div class="task-tab-detail absolute po-address">
        {{ addresses[0] }}
      </div>
    </span>
  </span>
</template>

<script>
  export default {
    name: 'addressDetail',
    props: ['addresses', 'field_name'],
    computed: {
      version_event: function() {
        if (this.addresses[1]) {
          return 'updated ' + this.field_name;
        }

        return 'added ' + this.field_name;
      }
    },
  };
</script>
