<template>
  <div class="chat-comment-container">
    <div class="si-chat">
      <div class="si-chat-body">
        <form @submit.prevent='submitComment' @keydown.enter.exact.prevent='submitComment' class='in-focus'>
          <div class="relative form-group-textarea" data-checkbox-togglable-container='true'>
            <div class="form-group vue-chat-box">
              <at-ta :members="taggableMembers" at="#" suffix='-' ref='textMemberTagged'>
                <at-ta :members="members" at='@' ref='textMember' name-key="username">
                  <textarea ref='textMemberText' cols="30" rows="10" class="form-control chat-comment-textarea place-holder autoheight" placeholder="Write your comment here." v-model='edit_comment_object.unformatted_body'></textarea>
                  <template slot="item" slot-scope="s">
                    <template v-if='s.item.profile_picture_url'>
                      <div class="d-flex m-r-15">
                        <span class="card-assign-user x-16">
                          <img :src="s.item.profile_picture_url" width="16" class="img-circle img-responsive">
                        </span>
                        <div class="p-l-5 m-w-0">
                          <span v-text="s.item.name" class="user-name d-block text-truncate"></span>
                          <div v-text="s.item.username" class="fs-12 text-truncate m-t-2 m-b-0 no-hover"></div>
                        </div>
                      </div>
                      </template>
                      <template v-else>
                        <span v-text="s.item"></span>
                      </template>
                    </template>
                  </at-ta>
                </at-ta>
                <div class="text-area-helper"></div>
                <error :errors="edit_comment_object.errors && edit_comment_object.errors.body"></error>
              </div>
              <div data-behavior='toolbar' class="toolbar d-flex justify-between">
                <div>
                  <span class="toolbar-option pointer" @click="initiateMention" title='Mention Somebody'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                      <path fill="#A1A8C3" fill-rule="evenodd" d="M8.001 10.255A2.254 2.254 0 1 1 8 5.746 2.254 2.254 0 0 1 8 10.256M8.002 1h-.001c-.688 0-1.786.102-2.983.664A7.002 7.002 0 0 0 2.612 3.53C1.034 5.44 1 7.591 1 8.001c.002 2.935 1.864 4.771 2.17 5.063C3.251 13.144 5.23 14.978 8 15h.05c1.098 0 1.98-.282 2.136-.333a6.686 6.686 0 0 0 1.832-.937.59.59 0 1 0-.671-.973c-.37.267-.977.64-1.804.865a5.921 5.921 0 0 1-1.488.195h-.055c-2.394-.024-4.06-1.65-4.06-1.65-1.724-1.677-1.757-3.804-1.757-4.166 0-.257.032-2.312 1.568-3.97a5.781 5.781 0 0 1 1.66-1.238 5.837 5.837 0 0 1 2.589-.61h.009c1.243 0 2.164.397 2.55.585.243.119 1.285.65 2.176 1.851.496.668 1.082 1.988 1.082 3.381 0 .653-.537 1.19-1.19 1.19-.653 0-1.19-.537-1.19-1.19 0-.722-.088-1.12-.527-1.836a3.497 3.497 0 0 0-1.583-1.338A3.479 3.479 0 0 0 8 4.563h-.003a3.47 3.47 0 0 0-1.22.22 3.484 3.484 0 0 0-1.97 1.944 3.455 3.455 0 0 0 .026 2.617c.11.262.39.831.99 1.32a3.517 3.517 0 0 0 2.174.774h.003a3.453 3.453 0 0 0 2.983-1.728 2.4 2.4 0 0 0 .702.468c.141.061.488.194.935.194h.007c.971-.003 1.615-.637 1.689-.712.658-.671.683-1.49.684-1.66.018-2.22-1.246-4.036-1.564-4.422a7.18 7.18 0 0 0-3.253-2.23A7.006 7.006 0 0 0 8.002 1"/>
                    </svg>
                  </span>
                  <span data-behavior = 'tag-entity' class="toolbar-option pointer" @click="initiateTagging" title="Tag items, orders, accounts etc...">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                      <g fill="none" fill-rule="evenodd">
                        <path fill="#A1A8C3" d="M9.422 11H5.936l-.433 4.053a.5.5 0 0 1-.994-.106L4.93 11H2a.5.5 0 1 1 0-1h3.038l.427-4H2a.5.5 0 0 1 0-1h3.572L6.006.947A.5.5 0 0 1 7 1.053L6.578 5h3.486l.433-4.053a.5.5 0 1 1 .994.106L11.07 5h2.92a.5.5 0 1 1 0 1h-3.027l-.427 4H14a.5.5 0 1 1 0 1h-3.572l-.434 4.053A.5.5 0 0 1 9 14.947L9.422 11zm.107-1l.428-4H6.47l-.428 4H9.53z"/>
                      </g>
                    </svg>
                  </span>

                  <span data-behavior='check-box-toggler' class="toolbar-option pointer" title='Mark this comment as urgent.'>
                    <label class="m-b-0-hard">
                      <span class="pointer" :class="{ 'checkbox-inactive' : !edit_comment_object.urgent, 'checkbox-active' : edit_comment_object.urgent }">🔴</span>
                      <input type="checkbox" class="hide" :checked='edit_comment_object.urgent' v-model='edit_comment_object.urgent'>
                    </label>
                  </span>
                </div>

                <div class="char-post">
                  <span class="char-counter" :class="{ 'text-danger': char_count < 0 }">{{ char_count }}</span>
                  <label class="toolbar-option pointer" @click='submitComment'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                      <path fill="#3D4465" fill-rule="evenodd" d="M1.839 6.068l3.353 1.547a.5.5 0 1 1-.42.908L.292 6.455a.5.5 0 0 1 .046-.927l14.5-5a.5.5 0 0 1 .636.636l-5 14.5a.5.5 0 0 1-.927.046l-3-6.5a.5.5 0 0 1 .1-.563l3-3a.5.5 0 0 1 .708.707L7.6 9.108l2.333 5.054 4.26-12.354-12.354 4.26z"/>
                    </svg>
                  </label>
                </div>
              </div>
            </div>
            <div class="p-t-b-15">
              <a href="javascript:void(0)" class="btn btn-primary" @click='submitComment'>Update</a>
              <a href="javascript:void(0);" class="btn m-l-10" @click='cancelEdit'>{{ $i18n.tc('comments_list.cancel') }}</a>
            </div>
          </form>
        </div>
      </div>
    </div>
  </template>

  <script>
    import AtTa from 'vue-at/dist/vue-at-textarea'
    import { mapGetters, mapState, mapActions, mapMutations } from 'vuex';
    import error from 'components/shared/error.vue';
    import { createHelpers } from 'vuex-map-fields';
    import sweetAlert from 'components/shared/sweet_alert.vue'

    const { mapFields: mapCommentsField } = createHelpers({
      getterType: 'getComment',
      mutationType: 'createComment'
    });


    export default {
      name: 'editComment',
      props: [
      'allowed_char_count',
      'user_options'
      ],
      data: function() {
        return {
          focusClass: '',
          char_count: this.allowed_char_count,
          empty_comment_object: {
            urgent: false,
            body: '',
            errors: {}
          },
          members: this.user_options,
          taggableMembers: ['Account', 'Activity', 'Opportunity', 'Task', 'Event', 'Order', 'Estimate', 'Item', 'Ticket', 'WO', 'PO'].sort()
        }
      },
      computed: {
        ...mapCommentsField('AddCommentWidget', ['edit_comment_object', 'reset_comment', 'ajaxRequestSent', 'edit_comment']),
      },

      methods: {
        initiateTagging: function() {
          this.$refs.textMemberTagged.handleValueUpdate(this.edit_comment_object.unformatted_body + ' #');
          $(this.$refs.textMemberText).focus();
        },
        initiateMention: function() {
          this.$refs.textMember.handleValueUpdate(this.edit_comment_object.unformatted_body + ' @');
          $(this.$refs.textMemberText).focus();
        },
        cancelEdit: function() {
          this.edit_comment = false;
        },
        submitComment: function() {
          if(this.char_count < 0) {
            window.setTimeout(() => swal({title: 'Maximum 500 characters are allowed.'}))
            return;
          }
          var _this = this;

          this.ajaxRequestSent = true;

          this.requestUpdateComment({
            apollo: this.$apollo,
            showAlert: this.$root.showAlert,
            comment_id: this.edit_comment_object.id.toString(),
            translation: this.$i18n,
            componentObject: this,
          }).then(function(updatedComment){
            _this.edit_comment = false;
            _this.char_count = _this.allowed_char_count;
            _this.$emit('commentUpdated', updatedComment);
           });
        },
        ...mapActions('AddCommentWidget', ['requestUpdateComment']),
      },

      watch: {
        'edit_comment_object.unformatted_body' : function(newValue, oldValue) {
          var excludePatterns = [gon.usernameRegexp, gon.mentionableRegexp],
            character_entered;

          if (excludePatterns) {
            for (var i = excludePatterns.length - 1; i >= 0; i--) {
              character_entered = newValue.replace((new RegExp(excludePatterns[i], 'gi')), '');
            }
          }
          character_entered = character_entered.trim().length;
          this.char_count = this.allowed_char_count - character_entered;
        },
      },

      components: {
        error,
        sweetAlert,
        AtTa
      }
    };
  </script>

  <style lang="css" scoped>
  </style>
