<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
    <g fill="none" fill-rule="evenodd">
      <path fill="#33475B" fill-rule="nonzero" d="M1 2v13h14V2H1zm12-1h2a1 1 0 0 1 1 1v13a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h2v1h2V1h6v1h2V1z"/>
      <rect width="5" height="3" x="5.5" y=".5" fill="#FFF" stroke="#33475B" rx="1"/>
      <rect width="2" height="6" x="7" y="6" fill="#33475B" rx="1"/>
      <path fill="#33475B" d="M5 10h6l-3 3z"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'InternalWorkOrdersIcon'
}
</script>
