<template>
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
    <path
      fill="#646C9A"
      fill-rule="evenodd"
      d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zm0 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1zm3.538 4.478a.75.75 0 0 1-.016 1.06l-4.125 4a.75.75 0 0 1-1.044 0L4.478 8.72a.75.75 0 0 1 1.044-1.077l1.353 1.312 3.603-3.493a.75.75 0 0 1 1.06.016z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TasksIcon'
}
</script>
