<template>
  <ul class="list-unstyled notification-listing" data-behavior='notification-bar'>
    <li class="notification-list-item d-flex pointer" :class="notification.is_read ? 'notification-read' : 'notification-unread'" v-for="(notification, index) in notifications" :key="index" @click='redirectToNotification(notification, $event)' data-behavior='notification-list'>

      <a :href='generateLink(notification)' v-bind:data-modal-click="dataValue(notification)" v-bind:data-url='notification.entity_url' class="pointer"></a>

      <div class="user-image-space-area m-r-15">
        <div class='card-assign-user x-32' v-if="notification.icon_class_name">
          <span :class="notification.icon_class_name"></span>
        </div>
        <span v-else class="card-assign-user x-32" v-html="notification.profile_picture"></span>
      </div>

      <div data-behavior="notification_clamped_content">
        <div class="notification-text" v-html="notification.content || notification.formatted_content">
        </div>
        <span class="fs-12 notification-date">
          <span :data-title="notification.created_at">{{ notification.created_at | formatDate }}</span>
        </span>
      </div>

      <div class="notification-icon" :data-title="$t('web_notifications.mark_read')"></div>
    </li>
  </ul>
</template>

<script>
  import { mapActions } from "vuex";

  export default {
    name: "notificationList",
    props: ["notifications", "storeModule"],
    methods: {
      redirectToNotification({is_read, id, entity_url, is_entity_modal_previewable}, event) {
        if(is_read) {
          this.handleClick(is_entity_modal_previewable, entity_url, event);
        } else {
          this.$store.dispatch(`${this.storeModule}/markNotificationAsRead`,{
            apollo: this.$apollo,
            data: { notificationId : id }
          }).then(() => {
            this.handleClick(is_entity_modal_previewable, entity_url, event);
          });
        }

      },

      handleClick(is_entity_modal_previewable, entity_url, event) {
        if(is_entity_modal_previewable) {
          $(event.target).closest('[data-behavior=notification-list]')
                          .find('[data-modal-click=card-click-overlay]')
                          .trigger('click');
        } else {
          window.location.href = entity_url;
        }
      },

      generateLink(notification) {
        if(notification.is_entity_modal_previewable) {
          return 'javascript:void(0)';
        } else {
          return notification.entity_url;
        }
      },

      dataValue(notification) {
        if(notification.is_entity_modal_previewable) {
          return 'card-click-overlay';
        } else {
          return '';
        }
      }
    }
  };
</script>

