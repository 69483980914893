<template>
  <div>
    <div v-for='comment in comments' :key='comment.id' class='comment-container' :class="{ 'editable-comment' : comment.can_be_edited }">
      <template v-if='edit_comment && edit_comment_object.id == comment.id'>
        <edit-comment
          :comment_thread='comment_thread'
          :allowed_char_count='500'
          @commentUpdated='updatedComment($event)'
          :user_options='user_options'
        >
        </edit-comment>
      </template>

      <template v-if='!edit_comment || edit_comment_object.id != comment.id'>
        <div data-behavior='resource-detail'>
          <article class="comment-added">
            <div class="d-flex align-center">
              <user-table-grid-content contentType='abbreviatedName' :user_id="comment.creator_id || comment.creator.id"/>
              <span class="super-light fs-11" :title="comment.updated_at | formatDate">{{ comment.updated_at | formatDate }}</span>

              <div class="comment-action card-header-dropdown ml-auto" v-show='comment.can_be_edited' ref='dropdown'>
                <div class="dropdown" data-menu="dropdown">
                  <a class="btn p-r-0 js-show-button" type="button" data-toggle="dropdown" aria-expanded="false">
                    <svg class="d-block" xmlns="http://www.w3.org/2000/svg" width="18" height="4" viewBox="0 0 18 4">
                      <g fill="#516f90">
                        <circle cx="9" cy="2" r="2"></circle>
                        <circle cx="16" cy="2" r="2"></circle>
                        <circle cx="2" cy="2" r="2"></circle>
                      </g>
                    </svg>
                  </a>
                  <ul class="dropdown-menu dropdown-menu-right fs-12">
                    <li>
                      <a href='javascript:void(0)' @click="editComment(comment)">{{ $i18n.tc('comments_list.edit') }}</a>
                    </li>
                    <li>
                      <a @click.prevent.stop data-vue-component='true' class="p-0-hard">
                        <delete-button @input='deleteComment(comment.id)' :data_confirm_button_text="$i18n.tc('comments_list.delete_permanently')" :data_description='$i18n.t("comments_list.details", { id: comment.id, body: comment.unformatted_body })' :data_heading="$i18n.t('comments_list.delete_heading')"  :data_sweet_alert_confirm="$i18n.t('comments_list.confirmation_message')" value="Delete" :classname="'delete-icon d-block delete-cu-btn'">
                          {{ $i18n.tc('comments_list.delete') }}
                        </delete-button>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="p-t-5 lh-1-29" v-html='comment.body'>
            </div>

          </article>
        </div>
      </template>
    </div>
  </div>
</template>

<script>

  import newComment from 'components/shared/new_comment';
  import editComment from 'components/shared/edit_comment';
  import deleteButton from 'components/work_orders/new/delete_button';
  import UserTableGridContent from 'components/shared/users/user_table_grid_content.vue';
  import { mapGetters, mapState, mapActions, mapMutations } from 'vuex';
  import { createHelpers } from 'vuex-map-fields';

  const { mapFields: mapCommentsField } = createHelpers({
    getterType: 'getComment',
    mutationType: 'createComment'
  });

  export default {
    name: 'commentList',
    props: ['comment_thread', 'user_options', 'commentable_uuid', 'comments'],
    methods: {
      deleteComment: function(commentId){
        var _this = this;

        this.requestDeleteComment({
          apollo: this.$apollo,
          componentObject: this,
          showAlert: this.$root.showAlert,
          comment_id: commentId
        }).then(function(comment_id){
          _this.$emit('commentDelete', comment_id);
        })
      },

      updatedComment: function(comment){
        this.$emit('commentUpdated', comment);
      },

      editComment: function(commentObject) {
        this.edit_comment = true;
        this.edit_comment_object = commentObject;
      },

      setHideTimeOut: function() {
        if(!$.isEmptyObject(this.comments)){
          this.comments.map((comment) => {
            if(comment.can_be_edited) {
              window.setTimeout(() => {
                comment.can_be_edited = false
              }, Number(comment.seconds_to_cutoff) * 1000)
            }
          })
        }
      },

      bindPubNubEvents: function() {
        var _this = this;

        this.setHideTimeOut();

        var pubnub = new PubNub({
          subscribe_key: gon.pubnubSubscribeKey
        });

        pubnub.subscribe({
          channels: [_this.commentable_uuid]
        });

        pubnub.addListener({
          message: function(response) {
            if(response.message.event == 'destroy') {
              _this.$emit('commentDelete', response.message.entity_id.toString());
            } else {
              _this.$emit('commentUpdated', response.message.data);
            }
          }
        });

      },
      ...mapActions('AddCommentWidget', ['requestDeleteComment']),
    },
    mounted() {
      this.bindPubNubEvents();
    },

    computed: {
      ...mapCommentsField('AddCommentWidget', ['edit_comment', 'comment_object', 'edit_comment_object']),
    },

    components: {
      newComment,
      deleteButton,
      editComment,
      UserTableGridContent
    },

    watch: {
      comments : function(_value) {
        var _this = this;

        this.bindPubNubEvents();
      }
    }
  };
</script>

<style lang="css" scoped>
</style>
